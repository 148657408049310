import React from "react";
import styled from "styled-components";
import { Group, Stack, Text, Title } from "@mantine/core";
import background from "../../../Assets/SVGs/landing/topBackground.svg";
import child1 from "../../../Assets/SVGs/landing/child1.png";
import child2 from "../../../Assets/SVGs/landing/child2.png";
import child3 from "../../../Assets/SVGs/landing/child3.png";
import { useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";

export const FirstPart = ({ props }) => {
  const matches = useMediaQuery("(max-width: 576px)");
  const sizeMd = useMediaQuery("(max-width: 1460px)");
  const sizeSm = useMediaQuery("(max-width: 990px)");

  const upTextVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.1, // Altdan yuxarı animasiya üçün gecikmə
        duration: 0.5,
      },
    },
  };

  const underTextVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.1, // Altdan yuxarı animasiya üçün gecikmə
        duration: 0.5,
      },
    },
  };

  const { t } = props;

  // Mətn komponentini ayrı-ayrı simvollara bölün
  const slogan = t("slogan");
  const wordArray = slogan.split(" ");

  return (
    <FirstPartStyle>
      <BackgrounImageWrapper>
        <Title style={{ color: "transparent" }}>.</Title>
        {/* <img src={background} alt="bg" className="bg-image" /> */}
        <Stack className="title-con" align="center" mt={30}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={upTextVariants}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Title
              sx={{ textAlign: "center", width: "70%" }}
              size={sizeMd ? 32 : sizeSm ? 22 : 42}
              weight={700}
              color="#fff"
            >
              {t("slogan")}
            </Title>
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={underTextVariants}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              sx={{ textAlign: "center", width: "70%" }}
              size={sizeMd ? 16 : sizeSm ? 14 : 18}
              weight={400}
              color="#fff"
            >
              {t("slogan-under-text")}
            </Text>
          </motion.div>
        </Stack>

        <Group position="center" spacing={matches ? 15 : 40} className="images">
          <div className="child-image-container one">
            <motion.div
              animate={{ x: [0, 0, 0], y: [0, 15, 0] }} // Hərəkət üçün koordinatlar
              transition={{
                repeat: Infinity, // Sonsuz təkrar
                repeatType: "loop", // Döngüsüz təkrar
                duration: 3, // Hər təkrarın müddəti (saniyə)
                ease: "linear", // Xətti hərəkət
              }}
            >
              <img
                src={child1}
                alt="child-1"
                className="child-image imageOne"
              />
            </motion.div>
          </div>
          <div className="child-image-container two">
            <motion.div
              animate={{ x: [0, 0, 0], y: [0, 15, 0] }} // Hərəkət üçün koordinatlar
              transition={{
                repeat: Infinity, // Sonsuz təkrar
                duration: 3, // Hər təkrarın müddəti (saniyə)
                ease: "linear", // Xətti hərəkət
              }}
            >
              <img
                src={child2}
                alt="child-2"
                className="child-image imageTwo"
              />
            </motion.div>
          </div>
          <div className="child-image-container three">
            <motion.div
              animate={{ x: [0, 0, 0], y: [0, 15, 0] }} // Hərəkət üçün koordinatlar
              transition={{
                repeat: Infinity, // Sonsuz təkrar
                duration: 3, // Hər təkrarın müddəti (saniyə)
                ease: "linear", // Xətti hərəkət
              }}
            >
              <img
                src={child3}
                alt="child-3"
                className="child-image imageThree"
              />
            </motion.div>
          </div>
        </Group>
      </BackgrounImageWrapper>
    </FirstPartStyle>
  );
};

const FirstPartStyle = styled.div`

   margin-bottom : 250px;
  .images {
    margin-top: 10px;
  }
  .child-image-container {
    width: 18.5%;
    height: 408px;
    border: 1px solid #fff;
    border-radius: 148px;
    position: relative;
  }
  .child-image {
    width: 100%;
    height: 408px;
    position: absolute;
  }
  .three,
  .one {
    margin-top: 100px;
  }

  .imageOne {
    top: 15px;
    right: -15px;
  }
  .imageTwo {
    top: 15px;
  }
  .imageThree {
    top: 15px;
    left: -15px;
  }

  .bg-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0px;
    z-index: -1;
  }
  @media (max-width:1400px) {
    .child-image-container {
      width: 19%;
      height: 408px;
      border: 1px solid #fff;
      border-radius: 148px;
      position: relative;
    }
  }
  @media (max-width: 990px) {
    .child-image-container {
      width: 18%;
      height: 328px;
      border: 1px solid #fff;
      border-radius: 148px;
      position: relative;
    }
    .child-image {
      width: 194px;
      height: 328px;
      position: absolute;
    }
  }

  @media (max-width: 576px) {
    margin-bottom: 150px;
    .title-con {
      display: none;
    }

    .images {
      margin-top: 0px;
    }
    .three,
    .one {
      margin-top: 0px;
    }
    .two {
      margin-top: -50px;
    }
    .imageOne {
      top: 5px;
      right: -3px;
    }
    .imageTwo {
      top: 5px;
    }
    .imageThree {
      top: 5px;
      left: -3px;
    }
    .child-image-container {
      width: 28%;
      height: 168px;
      img {
        width: 100%;
        height: 162px;
      }
    }
  }
`;

const BackgrounImageWrapper = styled.div`
background: url(${background});
background-repeat: no-repeat;
background-size: cover;
background-position: bottom;
height: 85vh;
width: 100%;


@media (max-width: 990px) {
  height: 75vh;
}

@media (max-width: 576px) {
  height: 12vh;
}

`;
