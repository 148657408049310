import React, { useState } from "react";
import { ViewContainer } from ".";
import CustomAccordion from "../../../Components/Profile/CustomAccordion";
import { Button, Group, Text, Textarea } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";


const AboutView = ({ user, publicUser, t }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [userBio, setUserBio] = useState(user?.bio || "");

  const { mutate: editUser } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.patch(
        `/api/v1/edit-user/${user?.id}/`,
        data
      );
      return response.data;
    },
    onSuccess: (data) => {
      localStorage.setItem("taskooluser", JSON.stringify(data));
      setIsEditable(false);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  return (
    <ViewContainer>

      <div style={{ padding: "10px 30px 10px 20px" }}>
        <CustomAccordion
          handleActiveEditForm={setIsEditable}
          isEditFormActive={isEditable}
          defaultValue="about"
          value="about"
          title={t('about')}
          isPublic={publicUser ? true : false}
        >
          {isEditable ? (
            <>
              <Textarea
                size="md"
                radius={10}
                value={userBio}
                onChange={(event) => setUserBio(event.target.value)}
                autosize
                minRows={4}
              />
              <Group position="right">
                <Button
                  styles={(theme) => ({
                    root: {
                      background: "#F9FAFC",
                      borderRadius: "10px",
                      border: "1px solid #E7E9ED",
                      height: "47px",
                      width: "135px",
                      color: "#364152",
                      fontSize: "16px",
                      fontWeight: "500",
                      "&:hover": {
                        background: "#F9FAFC",
                      },
                    },
                  })}
                  mt={15}
                  type="button"
                  onClick={() => {setIsEditable(false); setUserBio(user?.bio)}}
                >
                  Back
                </Button>

                <Button
                  styles={(theme) => ({
                    root: {
                      background: "#FE6027",
                      borderRadius: "10px",
                      height: "47px",
                      width: "135px",
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "500",
                      "&:hover": {
                        background: "#FE6027",
                      },
                    },
                  })}
                  mt={15}
                  type="submit"
                  onClick={() => editUser({ bio: userBio })}
                >
                  Save
                </Button>
              </Group>
            </>
          ) : (
            <Text size={16} weight={300} color="#364152">
              {publicUser ? publicUser.bio : userBio === "" ? user?.bio : userBio}
            </Text>
          )}
        </CustomAccordion>
      </div>
    </ViewContainer>
  );
};

export default AboutView;
