import { AppShell, Button, Image, Loader, Text } from "@mantine/core";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { ArrowDown, Home } from "tabler-icons-react";
import { typog } from "../../Assets/SVGs/topography";
import { VideosCard } from "./Landing/VideosCard";
import { TestsCard } from "./Landing/TestsCard";
import { Introduction } from "./Landing/Introduction";
import { AppContext } from "../../Helpers/Context";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Payment } from "../../Components/PaymentForTip/_Payment";
import { QuizFinished } from "./Results/QuizFinished";
import { useFetchQuestions } from "./useFetchQuestions";
import logo from "../../Assets/Images/tiplogo.png";
import { useMediaQuery } from "@mantine/hooks";

export const TIP = () => {
  const { user } = useContext(AppContext);

  const [purchaseComponentShown, setPurchaseComponentShown] = useState(false);
  const [programState, setProgramState] = useState({
    programPurchased: false,
    programFinished: false,
  });
  const { programPurchased, programFinished } = programState;

  let { questions } = useFetchQuestions({
    link: "/forum/specialty/multidata/",
    type: "speciality",
  });

  useEffect(() => {
    if (questions?.length === 0) {
      setProgramState((prev) => ({ ...prev, programFinished: true }));
    }
  }, [questions]);

  const { isFetched } = useQuery(
    ["program is purchased"],
    async () =>
      await axios
        .get("/forum/success_order/")
        .then((res) => res.data.successfuly_paid),
    {
      onSuccess: (data) =>
        setProgramState((prev) => ({ ...prev, programPurchased: data })),
    }
  );

  const matches = useMediaQuery("(max-width: 800px)");
  const queryClient = useQueryClient();

  function applyChangesAfterPurchase() {
    queryClient.invalidateQueries("program is purchased");
    setPurchaseComponentShown(false);
  }

  return (
    <AppShell
      padding={0}
      styles={(theme) => ({
        root: {
          backgroundColor: theme.colors.gray[7],
          color: theme.colorScheme === "dark" ? "#000" : "#fff",
          backgroundImage: typog,
        },
      })}
    >
      {isFetched ? (
        <>
          {programFinished === true && (
            <>
              {!matches && <br />}
              <QuizFinished props={{ timePassed: true }} />
            </>
          )}

          {programFinished === false && (
            <AnimatePresence mode="wait">
              <motion.div
                key="speciality"
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -100, transition: { duration: 3 } }}
                transition={{ duration: 0.5 }}
              >
                <SpecialityStyle>
                  <div className="intro">
                    <div className="header">
                      <div
                        style={{
                          display: "flex",
                          gridGap: "1.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          style={{ filter: "saturate(1) brightness(1)" }}
                          width={"50px"}
                          src={logo}
                        />
                        <Text color={"#ffdf00"} mb={0} size={24}>
                          TİP - Təkmilləşmiş İxtisaslaşma Proqramı
                        </Text>
                      </div>
                      <Button
                        className="home"
                        leftIcon={<Home size={20} />}
                        radius="sm"
                        size="sm"
                        component={Link}
                        to="/"
                        variant="filled"
                        color="gray.7"
                      >
                        Ana səhifə
                      </Button>
                    </div>

                    <Introduction />

                    {purchaseComponentShown === true &&
                      programPurchased === false && (
                        <motion.div
                          initial={{ y: 500, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: 500, opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className="paymentComponent"
                        >
                          <Payment props={{ applyChangesAfterPurchase }} />
                          <Button
                            variant="white"
                            color={"dark"}
                            size="sm"
                            className="buttonBack"
                            leftIcon={<ArrowDown />}
                            onClick={() => setPurchaseComponentShown(false)}
                          >
                            Back
                          </Button>
                        </motion.div>
                      )}
                    {purchaseComponentShown === false && (
                      <motion.div
                        initial={{ y: 500, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 500, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="aboutModules"
                      >
                        <VideosCard props={{ user }} />
                        <TestsCard
                          props={{
                            user,
                            programPurchased,
                            setPurchaseComponentShown,
                          }}
                        />
                      </motion.div>
                    )}
                  </div>
                </SpecialityStyle>
              </motion.div>
            </AnimatePresence>
          )}
        </>
      ) : (
        <Loader
          style={{ display: "flex", margin: "10rem auto 0 auto" }}
          visible="true"
          variant="bars"
          color={"violet"}
          size="xl"
        />
      )}
    </AppShell>
  );
};

const SpecialityStyle = styled.div`
  background: #212529;
  border-radius: 0.25rem;
  width: 92%;
  margin: 2rem auto;
  padding: 2rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  h1,
  h2,
  h3,
  a,
  button {
    font-weight: 500;
    margin: 0;
  }
  .intro {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    overflow: hidden;
    .user {
      display: flex;
      flex-direction: column;
      grid-gap: 1.5rem;
      background: #343a40;
      padding: 2rem;
      border-radius: 0.25rem;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .lists {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
    .header {
      display: flex;
      justify-content: space-between;
      grid-gap: 1rem;
      margin-bottom: 0.75rem;
      h1 {
        display: flex;
        align-items: baseline;
        grid-gap: 0.25rem;
      }
      ins {
        text-decoration: none;
        color: gray;
        font-size: 24px;
        margin: 0 0.25rem;
      }
    }
    .paymentComponent {
      display: flex;
      flex-direction: column;
      grid-gap: 0.5rem;
      .buttonBack {
        align-items: center;
        margin: 0 auto;
        width: min(532px, 50%);
        font-size: 15px;
      }
    }
    .aboutModules {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 1rem;
    }
  }
  @media (max-width: 1025px) {
    padding: 1rem;
    margin: 0;
    width: initial;
    border-radius: 0;
    .intro {
      .aboutModules {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
      }
      .header {
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          text-align: center;
        }
        margin-bottom: 0.25rem;
        * {
          line-height: 1;
        }
        .home {
          display: none;
        }
      }
    }
  }
`;
