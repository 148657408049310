import { Card, Image, Text, Badge, Button, Group, Grid } from "@mantine/core";
import styled from "styled-components";

export const StartupCard = ({ startup }) => {
  // const { id, title, description, image } = startup;

  // Mock data
  const id = 1;
  const title = "Hello world my name is something like booom yeees";
  const description =
    "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa quae ullam dolorum cum animi ab eos, sapiente, dolore nam quos tempore! Impedit modi magnam velit, voluptatibus sit repellendus ipsum ratione.Sapiente incidunt ducimus ex totam aspernatur pariatur, quidem asperiores? Facere, a necessitatibus tempore nostrum, consequatur consectetur provident itaque similique nobis quo eligendi, adipisci rem quia neque perspiciatis libero beatae pariatur";
  const image =
    "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png";

  return (
    <CardStyle>
      <Card
        className="card"
        key={id}
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
      >
        <Card.Section>
          <Image src={image} height={160} alt="Norway" />
        </Card.Section>

        <Group justify="space-between" mt="md" mb="xs">
          <Text fw={500}>
            {title?.length > 48 ? title.substring(0, 48) + "..." : title}
          </Text>
        </Group>

        <Text size="sm" c="dimmed">
          {description?.length > 127
            ? description.substring(0, 80) + "..."
            : description}
        </Text>
      </Card>
    </CardStyle>
  );
};

const CardStyle = styled.div`
  .card {
    transition: transform 150ms ease, box-shadow 150ms ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.01);
      box-shadow: 20px;
    }
  }
`;
