import axios from "axios";
import parse from 'html-react-parser';
import { React, useState, useEffect, useContext } from "react";
import {  } from '../../../Components/Layout/LayoutHelper'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Spoiler } from "@mantine/core";
import { AppContext } from "../../../Helpers/Context";
import EditSchool from "./EditSchoolModal";
import DeleteSchool from "./DeleteSchoolModal";

const _SchoolInfo = () => {

  const { id } = useParams();
  const { user } = useContext(AppContext);
  const [ schoolInfo, setSchoolInfo ] = useState([]);

  useEffect(() => {

    (async () => {
      try {
        const response = await axios.get(
          `/school/data/${id}/`
        );
        setSchoolInfo(response.data);
      } catch (error) {

      }
    })()
    return () => {
      setSchoolInfo([]);
    }

  }, []);

  return (
    <div>

        <SchoolStyle>
          <div className="header">
            <img src={schoolInfo?.School?.[0]?.profile_img} alt="" />
            <h1>{schoolInfo?.School?.[0]?.title}</h1>
            {
              ( user?.user_type?.id === 2 && user?.id === schoolInfo?.School?.[0].user ) &&
              <div className="edit">
                <EditSchool props={{ schoolInfo, setSchoolInfo, id }}/>
                <DeleteSchool props={{ schoolInfo, setSchoolInfo, id }}/>
              </div>
            }
          </div>
          <div className="inside">
            <div className="about">
              <div className="left">
                <h2>About</h2>
                <Spoiler maxHeight={95} showLabel="Show more" hideLabel="Hide">
                  {
                    schoolInfo?.School?.[0]?.description && parse(schoolInfo?.School?.[0]?.description)
                  }
                </Spoiler>
                <div className="teachers">
                  <h2>Teachers</h2>
                  <div className="teachersList">
                    {
                      schoolInfo?.SchoolTeachers?.map(teacher => {
                        return(
                          <Link to={`${teacher.link}/`} key={teacher.id}>
                            <p>{teacher.name}</p>
                          </Link>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="programs">
                  <h2>Programs</h2>
                  <div className="programsList">
                    {
                      schoolInfo?.SchoolPrograms?.map(program => {
                        return(
                          <Link to={`${program.link}/`} key={program.id}>
                            <p>{program.name}</p>
                          </Link>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="youtube">
                <iframe
                  style={{ border: "0px", }}
                  src={schoolInfo?.School?.[0]?.video_link?.replace("watch?v=", "embed/")}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            </div>
          </div>
        </SchoolStyle>

    </div>
  )
}

export default _SchoolInfo

const SchoolStyle = styled.div`
  h1,h2,h3{
    font-weight: 500;
  }
  margin-bottom: 10rem;
  .header{
    position: relative;
    img{
      width: 100%;
      height: 300px;
      object-fit: cover;
      filter: brightness(0.75);
    }
    h1{
      position: absolute;
      left: 0;
      top: 30%;
      right: 0;
      text-align: center;
      font-size: 40px;
      color: #fff;
    }
    .edit{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 10%;
      left: 0;
      right: 0;
      button{
        background-color: #eeeeee55;
        color: #fff;
        &:hover{
          background-color: #fff;
          color: black;
        }
      }
      .delete{
        background-color: #ff000055;
        &:hover{
          background-color: #fff;
          color: red;
        }
      }
    }
  }
  .inside{
    width: 90%;
    margin: 3rem auto;
    div{
      margin-bottom: 3rem;
    }
    .about{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 3rem;
      .youtube{
        iframe{
          width: 100%;
          height: 340px;
        }
      }
      .left{
        .programs, .teachers{
        .programsList, .teachersList{
          display: flex;
          flex-wrap: wrap;
          a{
            background-color: #eee;
            border: none;
            color: #000;
            border-radius: 0.25rem;
            text-decoration: none;
            padding: 0rem 1rem;
            transition: all 0.2s;
            &:hover{
              background-color: #ddd;
            }
            p{
              font-weight: 500;
              font-size: 17px;
            }
          }
        }
      }
      }
    }
  }
`