import { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import responsiveBg from "../../../Assets/SVGs/sign/resSign.svg";
import { Grid, Group, Stack, Tabs, Text, Title } from "@mantine/core";
import {
  NotificationsProvider,
  showNotification,
} from "@mantine/notifications";
import { Confetti } from "tabler-icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMediaQuery } from "@mantine/hooks";
import img from "../../../Assets/SVGs/sign/login-img.svg";
import UserProfileForm from "../../../Components/Sign/UserProfileForm";
import StepOne from "../../../Components/Sign/BusinessRegister/StepOne";
import StepTwo from "../../../Components/Sign/BusinessRegister/StepTwo";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { formatDate } from "../../../Helpers";

export function NewRegister() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const matchesXs = useMediaQuery("(max-width: 576px)");
  const matchesSm = useMediaQuery("(max-width: 990px)");
  const matchesMd = useMediaQuery("(min-width: 1024px)");
  const matchesXl = useMediaQuery("(min-width: 1444px)");

  const [activeTab, setActiveTab] = useState("first");
  const [activeStep, setActiveStep] = useState("one");
  const [selectedCountryId, setSelectedCountryId] = useState();
  const {
    register,
    setValue,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const {
    control: businessControl,
    formState: { errors: businessErrors, isValid },
    setValue: setBusinessValue,
    handleSubmit: handleBusineesSubmit,
    watch,
  } = useForm({
    mode: "onChange", // Trigger validation on change
    defaultValues: {
      first_name: "",
      number: "",
      email: "",
      website: "",
      country: "",
      city: "",
      address: "",
      password: "",
      services: "",
    },
  });

  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: async () => {
      const res = await axios.get("/api/countries/");
      return res.data;
    },
  });

  const { data: fields } = useQuery({
    queryKey: ["fields"],
    queryFn: async () => {
      const res = await axios.get("/api/v1/speciality/");
      return res.data;
    },
  });

  const { data: grades } = useQuery({
    queryKey: ["grades"],
    queryFn: async () => {
      const res = await axios.get("/api/v1/student-grades/");
      return res.data;
    },
  });

  const { data: universities } = useQuery({
    queryKey: ["universities"],
    queryFn: async () => {
      const res = await axios.get("/university/list/");
      return res.data;
    },
  });

  const { data: cities } = useQuery({
    queryKey: ["cities", selectedCountryId],
    queryFn: async () =>
      (await axios.get(`/api/cities/${selectedCountryId}`)).data,
    enabled: !!selectedCountryId,
  });

  const { mutate: registerMutate, isLoading: registerLoading } = useMutation({
    mutationFn: async (data) => {
      const res = await axios.post("api/v1/register/", data);
      return res.data;
    },
    onSuccess: (data) => {
      showNotification({
        icon: <Confetti />,
        title: "Email Verify",
        message: "Please check your email",
        color: "teal",
      });
    },
    onError: (error) => {
      showNotification({
        type: "error",
        title: "Error",
        description: error.response.data.detail,
      });
    },
  });

  const {
    mutate: registerForBusineesMutate,
    isLoading: businessRegisterLoading,
  } = useMutation({
    mutationFn: async (data) => {
      const res = await axios.post("api/v1/registerforbusiness/", data);
      return res.data;
    },
    onSuccess: (data) => {
      showNotification({
        icon: <Confetti />,
        title: "Congratulations!",
        message: "A link has been sent to your email.Please click on the link.",
        color: "teal",
      });
    },
    onError: (error) => {
      showNotification({
        type: "error",
        title: "Error",
        description: error.response.data.detail,
      });
    },
  });

  const onSubmit = (data) => {
    data.bio = null;
    data.user_type = activeTab === "first" ? 1 : 0;
    data.birthday = formatDate(data.birthday)
    registerMutate(data);
  };

  const onBusineesSubmit = (data) => {
    data.bio = null;
    registerForBusineesMutate(data);
  };

  return (
    <HelmetProvider>
      <RegisterDiv>
        <Helmet>
          <title>Register | Taskool</title>
          <meta
            name="description"
            content="Register to access exclusive content and features."
          />
          <meta name="keywords" content="register, sign up, create account" />
          <meta name="robots" content="noindex, nofollow" />
          <link rel="canonical" href="https://www.taskool.com/register" />
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Register",
              "url": "https://www.taskool.com/register"
            }
          `}
          </script>
        </Helmet>
        <Grid>
          {!matchesSm && (
            <Grid.Col lg={4} md={4} sm={0}>
              <Link to="/">
                <div className="bg-image"></div>
              </Link>
            </Grid.Col>
          )}
          {matchesXs && (
            <Grid.Col>
              <div className="responsive-bg"></div>
            </Grid.Col>
          )}
          <Grid.Col lg={8} md={8} sm={12}>
            <div className="right-container">
              <Group position={matchesXs ? "center" : "apart"}>
                <Stack ml={10} spacing={10}>
                  <Title
                    sx={
                      matchesSm
                        ? { textAlign: "center" }
                        : { textAlign: "left" }
                    }
                    size={matchesSm ? 24 : 28}
                    color="#1A5059"
                    weight={700}
                  >
                    {t("welcome")}
                  </Title>
                  <Text
                    color="#757575"
                    sx={
                      matchesSm
                        ? { textAlign: "center" }
                        : { textAlign: "left" }
                    }
                    size={matchesSm ? 16 : 18}
                    weight={300}
                  >
                    {t("filled")}
                  </Text>
                </Stack>
                {activeTab === "second" && (
                  <Text
                    onClick={() => activeStep === "two" && setActiveStep("one")}
                    sx={{ cursor: "pointer" }}
                    weight={500}
                    size={16}
                    color="#a7a7a7"
                  >
                    {activeStep === "one" ? `${t("step")} 1` : t("back")}
                  </Text>
                )}
              </Group>

              <Tabs
                mt={30}
                variant="pills"
                value={activeTab}
                onTabChange={setActiveTab}
              >
                <Tabs.List
                  sx={
                    matchesXs
                      ? { justifyContent: "center" }
                      : { marginLeft: "20px" }
                  }
                >
                  <Tabs.Tab value="first">{t("user-profile")}</Tabs.Tab>
                  <Tabs.Tab value="second">{t("business-profile")}</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel className="tabs-panel" value="first">
                  <UserProfileForm
                    props={{
                      registerLoading,
                      t,
                      register,
                      errors,
                      matchesXl,
                      matchesXs,
                      matchesMd,
                      countries,
                      selectedCountryId,
                      cities,
                      grades,
                      fields,
                      Controller,
                      control,
                      handleSubmit,
                      onSubmit,
                      setSelectedCountryId,
                      setValue,
                      reset,
                    }}
                  />
                </Tabs.Panel>
                <Tabs.Panel className="tabs-panel" value="second">
                  {activeStep === "one" ? (
                    <StepOne
                      props={{
                        t,
                        activeStep,
                        matchesXl,
                        matchesXs,
                        matchesMd,
                        countries,
                        cities,
                        selectedCountryId,
                        businessControl,
                        businessErrors,
                        isValid,
                        Controller,
                        watch,
                        setBusinessValue,
                        handleBusineesSubmit,
                        onBusineesSubmit,
                        setActiveStep,
                        setSelectedCountryId,
                        setValue,
                        reset,
                      }}
                    />
                  ) : (
                    <StepTwo
                      props={{
                        businessRegisterLoading,
                        t,
                        matchesXl,
                        matchesXs,
                        matchesMd,
                        activeStep,
                        universities,
                        setBusinessValue,
                        handleBusineesSubmit,
                        onBusineesSubmit,
                        setActiveStep,
                        setValue,
                        reset,
                      }}
                    />
                  )}
                </Tabs.Panel>
              </Tabs>
            </div>
          </Grid.Col>
        </Grid>
      </RegisterDiv>
    </HelmetProvider>
  );
}

const RegisterDiv = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 155vh;
  .left-img {
    width: 100%;
    object-fit: cover;
  }
  label {
    margin-bottom: 7px;
  }
  .bg-image {
    background-image: url(${img});
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .right-container {
    padding: 45px 40px;
    min-height: 100vh;
    .tabs-panel {
    }
    .mantine-2uszzl[data-active] {
      background-color: #696969;
      z-index: -999;
    }
    .mantine-2uszzl {
      &:hover {
        background-color: transparent;
      }
    }
    .mantine-qo1k2.mantine-Button-label {
      font-weight: 400;
    }
    .google-button {
      .mantine-qo1k2.mantine-Button-label {
        font-size: 15;
        font-weight: 500;
        color: #858585;
      }
    }

    .mantine-InputWrapper-label.mantine-RadioGroup-label.mantine-ittua2 {
      font-size: 20px;
      font-weight: 500;
      color: #1a5059;
    }
    .mantine-Tabs-panel.tabs-panel.mantine-yxmaw9 {
      border: 1px solid #c9c9c9;
      border-radius: 20px;
      padding: 35px 55px 35px 35px;
      margin-top: -1.5px;
      z-index: 999999;
      background-color: #fff;
    }
  }
  @media (max-width: 990px) {
    overflow-y: scrool;
    max-height: 100%;
    .bg-image {
      display: none;
    }
    .right-container {
      padding: 20px 20px;
    }
    .responsive-bg {
      background-image: url(${responsiveBg});
      width: 100%;
      height: 20vh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .mantine-Tabs-panel.tabs-panel.mantine-yxmaw9 {
      border: 1px solid #c9c9c9;
      border-radius: 20px;
      padding: 25px 25px 25px 25px !important;
      margin-top: -1.5px;
      z-index: 999999;
      background-color: #fff;
    }
  }
`;
