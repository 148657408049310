import React from "react";
import { useTranslation } from "react-i18next";
import axios from "../../../Helpers/Axios";
import { useQuery } from "@tanstack/react-query";
import {
  Title,
  Stack,
  Group,
  UnstyledButton,
  Image,
  Text,
  Space,
  Accordion,
} from "@mantine/core";
import { Link } from "react-router-dom";

const RelatedPrograms = ({ props }) => {
  const { type } = props;

  const { t } = useTranslation();

  let link =
    type === "courses"
      ? "/api/all-courses/"
      : "/internship/activeinternshiplist/";

  const { data: courses } = useQuery([type], async () => {
    return await axios.get(link).then((res) => res.data);
  });
  return (
    <div>
      <Accordion defaultValue="customization">
        <Accordion.Item style={{borderBottom : "0px"}} value="customization">
          <Accordion.Control>
            <Title
              color="#364152"
              w={500}
              size={18}
              sx={{ textTransform: "capitalize", display: "inline" }}
            >
            {t('intensive-course')}
            </Title>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack>
              {courses?.length > 0 &&
                type !== "university" &&
                [0, 1, 2]?.map((number, index) => {
                  return (
                    courses[number]?.id && (
                      <Stack key={index}>
                        <UnstyledButton
                          className="link"
                          component={Link}
                          to={`/${type}/${courses[number]?.id}`}
                          key={courses[number]?.id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            color: "#000",
                            gridGap: "1rem",
                          }}
                        >
                          <Image
                            withPlaceholder
                            radius={"md"}
                            height={71}
                            width={93}
                            src={courses[number]?.image}
                            alt={courses[number]?.title}
                          />
                          <Text size={14} weight={400} color="#575757" >
                            {courses[number]?.title}
                          </Text>
                        </UnstyledButton>
                        {index !== 1 && (
                          <div
                            style={{
                              width: "100%",
                              height: "1px",
                              backgroundColor: "#eeeeee",
                            }}
                          ></div>
                        )}
                      </Stack>
                    )
                  );
                })}
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

    </div>
  );
};

export default RelatedPrograms;
