import React from "react";
import styled from "styled-components";
import { Button, Grid, Group, Text, Title } from "@mantine/core";
import { Download, HourglassHigh, Language, Tag } from "tabler-icons-react";
import cardImg from "../../Assets/SVGs/programs/cardImage.svg";

const ProgramInfo = () => {
  return (
  <section style={{borderBottom : "1px solid #c2c2c2",paddingBottom : "40px" ,maxWidth: "92%" ,margin : "0 auto"}}>
      <Grid>
      <Grid.Col lg={7}>
        <ProgramInfoStyle>
          <Info>
            <Title mb={25} size={24} weight={500} color="#364152">
              Davamlı Nəqliyyat İnfrastrukturu Dizaynı
            </Title>
            <Text size={18} weight={400} color="#888888">
              Author: Lorem Ipsum
            </Text>
            <Line />
            <Text size={16} weight={400} color="#364152">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures, to generate Lorem Ipsum which looks
              reasonable. The generated Lorem Ipsum is therefore always free
              from repetition, injected humour, or non-characteristic words etc.
            </Text>
          </Info>
          <ButtonGroup>
            <Button
              radius={10}
              variant="filled"
              styles={() => ({
                root: {
                  width: "48%",
                  height: "45px",
                  background: "#029199",
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: 700,
                  "&:hover": {
                    background: "#029199",
                  },
                },
              })}
            >
              Apply
            </Button>
            <Button
              radius={10}
              styles={() => ({
                root: {
                  width: "48%",
                  height: "45px",
                  color: "#029199",
                  background: "#fff",
                  fontSize: "15px",
                  fontWeight: 700,
                  "&:hover": {
                    background: "#fff",
                  },
                },
              })}
              variant="outline"
              leftIcon={<Download />}
            >
              Download Syllabus
            </Button>
          </ButtonGroup>
        </ProgramInfoStyle>
      </Grid.Col>
      <Grid.Col lg={5}>
        <ProgramBanner>
          <Credentials>
            <Group  style={{padding : "20px 30px"}}>
              <Group spacing={7}>
                <Language strokeWidth={1.75} color="#62657D" size={20} />
                <Text size={16} weight={400} color="#3B3F5C" span>Azerbaijan</Text>
              </Group>
              <div
                style={{
                  width: "1px",
                  height: "11px",
                  background: "#62657D",
                }}
              ></div>
              <Group spacing={7}>
                <HourglassHigh strokeWidth={1.75} color="#62657D" size={20} />
                <Text size={16} weight={400} color="#3B3F5C" span>10 weeks</Text>
              </Group>
              <div
                style={{
                  width: "1px",
                  height: "11px",
                  background: "#62657D",
                }}
              ></div>
              <Group spacing={7}>
                <Tag strokeWidth={1.75} color="#62657D" size={20} />
                <Text size={16} weight={400} color="#3B3F5C" span>50 $</Text>
              </Group>
            </Group>
          </Credentials>
        </ProgramBanner>
      </Grid.Col>
    </Grid>
  </section>
  );
};

export default ProgramInfo;

const ProgramInfoStyle = styled.div``;
const Info = styled.div`
  padding: 15px 15px 25px 15px;
  box-shadow: 0px 1px 10px 0px #0000001a;
  background: #fff;
  border-radius: 11px;
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #dcdcdc;
  margin: 20px 0;
`;

const ButtonGroup = styled(Group)`
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const ProgramBanner = styled.div`
  background: url(${cardImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: cover;
  height: 433px;
  width: 100%;
  position: relative;
  box-shadow: 0px 5.2px 10.4px 0px #d3dae2;
  border-radius: 11px;
`;
const Credentials = styled.div`
  background: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 0 0 11px 11px;
`;
