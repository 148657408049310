import { useQuery } from "@tanstack/react-query"
import axios from "axios"

export const useFetchQuestions = ({ link, type }) => {

  const { data:questions } = useQuery(["questions"],
    async () => {
      return await axios.get(link).then(res => type === "speciality" ? res.data.QuestionSpeciality : res.data.QuestionNonSpeciality)
    }
  )

  return { questions }
}
