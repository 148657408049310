import {
  Button,
  Center,
  Container,
  Divider,
  Group,
  Image,
  Loader,
  Menu,
  Modal,
  Spoiler,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import axios from "axios";
// import { AnimatePresence, motion } from "framer-motion"
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { useContext, useRef, useState } from "react";
import { Clock, DotsVertical, ThumbUp, Trash, X } from "tabler-icons-react";
import { AppContext } from "../Helpers/Context";
import { CommentSection } from "./CommentSection";
import { ProgramCard } from "./ProgramCard";
import LinkPreview from "./LinkPreview";
import { AnimatePresence, motion } from "framer-motion";

export function Post({ props }) {
  TimeAgo.addLocale(en);
  const timeAgo = new TimeAgo("en-US");
  const { user } = useContext(AppContext);

  const { post ,t } = props;
  const queryClient = useQueryClient();

  let [postReview, setPostReview] = useState({
    current: post?.user_like,
    count: post?.likes_c,
  });

  const { data: comments, refetch } = useQuery(
    [`get comments ${post.id}`],
    async () =>
      axios.get(`/blog/comments/?post_id=${post?.id}`).then((res) => res.data)
  );

  const { mutate: deletePost, isLoading } = useMutation(
    ["delete post"],
    async () => await axios.delete(`/blog/${post?.id}/`),
    { onSuccess: () => queryClient.invalidateQueries([`feed`]) }
  );

  const { mutate: ratePost } = useMutation(
    ["rate post"],
    async () => {
      if (postReview.current === false) {
        setPostReview((prev) => ({ current: true, count: prev.count + 1 }));
      } else {
        setPostReview((prev) => ({ current: false, count: prev.count - 1 }));
      }
      return await axios.patch(
        `/blog/${post?.id}/?${
          post?.user_like === true ? "dislikes" : "likes"
        }=${user?.id}`
      );
    }
    // { onSuccess: () => queryClient.invalidateQueries("feed") }
  );

  const [imageFull, setImageFull] = useState(false);

  const renderDescription = (givenText) => {
    const urlRegex = /(https?:\/\/\S+)/g;
    const matches = givenText?.match(urlRegex);

    if (matches) {
      const elements = givenText?.split(urlRegex).map((part, index) =>
        index % 2 === 0 ? (
          <p key={index}>{part}</p>
        ) : (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        )
      );

      return <>{elements}</>;
    } else {
      return <p>{givenText}</p>;
    }
  };

  return (
    <AnimatePresence mode="wait">
      <div
        style={{
          border: "1px solid #DADADA",
          backgroundColor: "#fff",
          padding: "1rem",
          borderRadius: "24px",
        }}
      >
        <Group
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: "0.5rem",
            padding: ".5rem",
          }}
        >
          <Group>
            <Image
              radius={100}
              src={post?.user?.profile_img}
              width={45}
              height={45}
              withPlaceholder
            />
            <div>
              <Text size={16} weight={500} color="#364152">
                {post?.user?.first_name}{" "}
                {post?.user?.last_name !== "." && post?.user?.last_name}
              </Text>
              <Group style={{ gap: "0.25rem" }}>
                <Clock color="#A3A3A3" size={14} />
                <Text size={14} color="#A3A3A3">
                  {timeAgo.format(new Date(post?.created_at))}
                </Text>
              </Group>
            </div>
          </Group>

          {post?.user?.id === user?.id && (
            <div>
              <Menu trigger="hover">
                <Menu.Target>
                  <Button px={12} variant="subtle" color={"gray.8"}>
                    <DotsVertical color="#495057" />
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    color={"red"}
                    icon={
                      isLoading ? <Loader size={"sm"} color="red" /> : <Trash />
                    }
                    onClick={() => deletePost()}
                  >
                    {t('delete')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </div>
          )}
        </Group>

        <Container my={20} px={10}>
          {post?.video && post?.video.includes("youtube.com/watch?") ? (
            <iframe
              src={`https://www.youtube.com/embed/${
                post.video.split("/watch?v=")[1].split("&t=")[0]
              }`}
              allow="autoplay; encrypted-media"
              allowFullScreen
              width="100%"
              height="320"
              title="video"
              style={{ border: "0", borderRadius: ".25rem" }}
            />
          ) : (
            // :
            // post?.video && post?.video.includes("youtube.com/shorts") ?
            // <iframe src={`https://www.youtube.com/embed/${post?.video}`}
            //   allow='autoplay; encrypted-media'
            //   allowFullScreen
            //   width="100%" height="320"
            //   title='video'
            //   style={{ border:"0", borderRadius:".25rem" }}
            // />
            post?.video && (
              <div>
                <a
                  style={{ textAlign: "justify", maxWidth: "100%" }}
                  href={post?.video}
                >
                  {post?.video?.length > 90
                    ? post?.video.slice(0, 90) + "..."
                    : post?.video}
                </a>
              </div>
            )
          )}

          {post?.description !== "." && (
            <Spoiler
              mt={-10}
              mb={10}
              maxHeight={120}
              showLabel="Show more"
              hideLabel="Hide"
              styles={{ control: { color: "#868E96" } }}
            >
              {renderDescription(post?.description)}
            </Spoiler>
          )}

          {post?.image && (
            <Center
              mt={5}
              style={{ cursor: "pointer", backgroundColor: "#F1F3F5" }}
              onClick={() => setImageFull(true)}
            >
              <Image
                fit="contain"
                height={"100%"}
                src={post?.image}
                styles={{ image: { maxHeight: "500px" } }}
              />
            </Center>
          )}

          <Modal
            size="auto"
            opened={imageFull}
            onClose={() => setImageFull(false)}
            withCloseButton={false}
            bg={"0"}
            overlayBlur={"2"}
            overlayColor="#495057"
            shadow="0"
            transitionDuration={200}
            styles={{ modal: { backgroundColor: "#00000000" } }}
          >
            <div
              style={{
                display: "flex",
                borderRadius: "0 1rem 1rem 0",
                backgroundColor: "#f1f3f5",
                alignItems: "start",
                gap: "1rem",
              }}
            >
              <Image
                fit="contain"
                height={"100%"}
                src={post?.image}
                styles={{ image: {} }}
              />
              <div style={{ margin: "1rem 1rem 1rem 0", minWidth: "450px" }}>
                <Group
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      padding: ".25rem .5rem",
                    }}
                  >
                    <Image
                      radius={100}
                      src={post?.user?.profile_img}
                      width={45}
                      height={45}
                      withPlaceholder
                    />
                    <div>
                      <Text size={15}>
                        {post?.user?.first_name}{" "}
                        {post?.user?.last_name !== "." && post?.user?.last_name}
                      </Text>
                      <Group style={{ gap: "0.25rem" }}>
                        <Clock color="gray" size={14} />
                        <Text size={14} color="dimmed">
                          {timeAgo.format(new Date(post?.created_at))}
                        </Text>
                      </Group>
                    </div>
                  </div>
                  {post?.user?.id === user?.id && (
                    <div>
                      <Menu trigger="hover" position="left" radius={"lg"}>
                        <Menu.Target>
                          <Button px={12} variant="subtle" color={"gray.8"}>
                            <DotsVertical color="#495057" />
                          </Button>
                        </Menu.Target>

                        <Menu.Dropdown>
                          <Menu.Item
                            color={"red"}
                            icon={
                              isLoading ? (
                                <Loader size={"sm"} color="red" />
                              ) : (
                                <Trash />
                              )
                            }
                            onClick={() => deletePost()}
                          >
                            {t('delete')}
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                    </div>
                  )}
                </Group>
                {post?.description !== "." && (
                  <Spoiler
                    mx=".5rem"
                    my={"md"}
                    maxHeight={120}
                    showLabel="Show more"
                    hideLabel="Hide"
                    styles={{ control: { color: "#868E96" } }}
                  >
                    <Text size={15}>{post?.description}</Text>
                  </Spoiler>
                )}
                <Divider mt={"sm"} mb={"lg"} />
                <CommentSection props={{ post }} />
              </div>
            </div>
          </Modal>
        </Container>

        {(post?.course || post?.internship) && (
          <Center mb={10}>
            <ProgramCard
              props={{
                link: `${post?.course ? "/courses/" : "/internships/"}${
                  post?.course ? post?.course?.id : post?.internship?.id
                }`,
                image: post?.course?.image || post?.internship?.image,
                title: post?.course?.title || post?.internship?.title,
              }}
            />
          </Center>
        )}

        <Group spacing={7} ml={10} mb={5}>
          <ThumbUp
            color="#1990A7"
            fill={postReview.current === true ? "#90e0ef" : "white"}
          />
          <Text size={16} weight={500} color="#575757">
            {postReview.count} {t('like-this')}
          </Text>
        </Group>

        <div
        style={{
          width : "98%",
          height : "1px",
          background : "#EEEEEE",
          margin : "10px auto 12px auto",
        }}
        >

        </div>

        <CommentSection
          props={{
            t,
            post,
            ratePost,
            postReview,
            comments,
            refetch,
          }}
        />
      </div>
    </AnimatePresence>
  );
}
