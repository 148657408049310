import { useContext, lazy, useState, useEffect } from "react";
import { AppContext } from "./Context";
import { Routes, Route, Navigate, BrowserRouter,useNavigate } from "react-router-dom";
// Auth
import Login from "../Pages/Authentication/Login/_Login";
import ResetPasswordSuccess from "../Pages/Authentication/ResetPassword/_ResetPassword";
// Users
import PublicProfilePage from "../Pages/User/PublicProfile/_Profile";
import UserSettings from "../Pages/User/UserSettings/_UserSettings";
import ConsultantProfile from "../Pages/User/Consultant/_Consultant";

// Informative Pages
import ErrorPage from "../Pages/Informative/ErrorPage";
import About from "../Pages/Informative/About";
import PrivacyPolicy from "../Pages/Informative/PrivacyPolicy";
import TermsOfService from "../Pages/Informative/TermsAndConds";
import Notifications from "../Pages/Informative/Notifications";
import CurrentVersion from "../Components/CurrentVersion";

import { AllCommunities } from "../Components/Communities/AllCommunities";
import { Community } from "../Components/Communities/Community";

// Course
import CourseContent from "../Pages/Course/CourseContent/_CourseContent";

// Schools
import Schools from "../Pages/Schools/AllSchools/_AllSchools";
import SchoolInfo from "../Pages/Schools/SchoolInfo/_SchoolInfo";
import CreateSchool from "../Pages/Schools/CreateSchool/_CreateSchool";

// Grade
import CourseMentorContent from "../Pages/Course/CourseMentorContent/_CourseMentorContent";
import Mentoring from "../Pages/Mentoring/Mentoring";
import { CourseUsers } from "../Pages/Mentoring/CourseUsers";

import Game from "../Pages/Game/SingleGame";
import { AllContents } from "../Components/AllContents";
import { Program } from "../Components/ProgramInfo/_Program";
// TIP
import { TIP } from "../Pages/TIP/_TIP";
import { Videos } from "../Pages/TIP/Videos/_Videos";
import { Vision } from "../Pages/TIP/Vision/_Vision";
import { Profession } from "../Pages/TIP/Profession/_Profession";
import { NewRegister } from "../Pages/Authentication/Register/_NewRegister";
import { CompanyPage } from "../Components/CompanyPage";

import { AllPosts } from "../Components/AllPosts";
import { Post } from "../Components/ScpecificBlogPost";

import CommunityPostPublic from "../Components/RedirectToLogin/CommunityPostPublic";

import Event from "../Components/Events/Event";

import Events from "../Components/Events/Events";

import Startups from "../Components/Startups/Startups";

import Olympics from "../Pages/MainOlympic/Olympics";

import { OlympicProject } from "../Pages/MainOlympic/Project";
import { MainOlympic } from "../Pages/MainOlympic/Olympic";
import NewPayment from "../Pages/NewPayment/NewPayment";
import NewProfile from "../Pages/User/NewProfile/NewProfile";
import ScrollToTop from "../Components/ScrollToTop";
import Cookies from "universal-cookie";
import NewDashboard from "../Components/NewDashboard/index";
import EmailVerify from "../Pages/Authentication/EmailVerify";
import DashboardLayout from "../Components/NewDashboard/Layout";
import Universities from "../Pages/StudyAbroad/Universities.jsx"
import SingleUniversity from "../Pages/StudyAbroad/SingleUniversity.jsx";
import ApplyForm from "../Pages/StudyAbroad/ApplyForm.jsx";
import {useLocation} from 'react-router-dom'
import Agents from "../Pages/StudyAbroad/Agents.jsx";
import SingleAgent from "../Pages/StudyAbroad/SingleAgent.jsx";

// Informative Pages
const Landing = lazy(() => import("../Pages/Informative/Landing/_Landing"));


export let isTaskool = true;

export function RouteLogic() {
  const cookie = new Cookies();

  const [token, setToken] = useState(cookie.get("access"));
  let { user } = useContext(AppContext);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <>
          <DashboardLayout>
{            user ? 
            <PrivatePages /> :
            <PublicPages />}
          </DashboardLayout>
      </>
    </BrowserRouter>
  );
}

function PrivatePages() {
  let { user } = useContext(AppContext);
  const location = useLocation()
  const navigate = useNavigate()
  console.log('location: ', location);

  useEffect(() => {
    (user && location.pathname === "/") && navigate("/home")
   }, [user]);
  return (
    <Routes>
      {/* New pages */}
      <Route path="/newprofile" props={isTaskool} element={<NewProfile />} /> 
      <Route path="/newpayment" element={<NewPayment />} /> 

      {/* Speciality */}
      <Route path="/tip" element={<TIP />} />  
      <Route path="/tip/videos" element={<Videos />} /> 
      <Route path="/tip/vision" element={<Vision />} /> 
      <Route path="/tip/profession" element={<Profession />} /> 

      <Route path="/events" element={<Events />} />  
      <Route path="/startups" element={<Startups />} /> 

      <Route path="/company/:id" element={<CompanyPage props={{}} />} /> 

      {/* Informational Pages */}
      <Route path="/home" element={<NewDashboard />} />
      <Route path="/about" element={<About />} /> 
      <Route path="/privacy_policy" element={<PrivacyPolicy />} /> 
      <Route path="/terms-of-usage" element={<TermsOfService />} /> 
      <Route path="/version" element={<CurrentVersion />} /> 
      <Route path="/notifications" element={<Notifications />} /> 
      <Route path="*" element={<ErrorPage />} />

      <Route path="/eventtest" element={<Event />} />

      {/* Community Groups */}
      <Route path="/communities" element={<AllCommunities />} />
      <Route path="/groups/:id" element={<Community />} />

      {/* Olympics */}
      <Route path="/olympics" element={<Olympics />} />
      <Route path="/project/:id" element={<OlympicProject />} />
      <Route
        path="/olympic/:id"
        element={<MainOlympic props={{ isTaskool }} />}
      />

      {/* university */}
      <Route path="/universities" element={<Universities />} />
      <Route path="/universities/:uId/programs/:pId" element={<SingleUniversity />} />
      <Route path="/universities/apply-form/programs/:pId" element={<ApplyForm />} />
      <Route path="/agents" element={<Agents />} />
      <Route path="/agents/:id" element={<SingleAgent />} />




      {/* Users */}
      <Route path="/profile" element={<NewProfile props={{ isTaskool }} />} />
      <Route
        path="/profile/:id"
        element={<PublicProfilePage props={{ isTaskool }} />}
      />
      <Route path="/consultant/:id" element={<ConsultantProfile />} />
      <Route
        path="/settings"
        element={<UserSettings props={{ isTaskool }} />}
      />
      <Route
        path="/password-reset/:uuid/:token"
        element={<ResetPasswordSuccess />}
      />

  
      <Route
        path="/mentor/course/:courseId"
        element={<CourseMentorContent />}
      />
      <Route path="/mentor" element={<Mentoring />} />
      <Route path="/course-users/:courseId" element={<CourseUsers />} /> 
      {/* Course */}
      <Route
        path="/courses"
        element={
          <AllContents props={{ defaultValue: "all", type: "course" }} />
        }
      />

      <Route
        path="/courses/purchased"
        element={
          <AllContents props={{ defaultValue: "purchased", type: "course" }} />
        }
      />

      <Route
        path="/courses/:id"
        element={<Program props={{ isTaskool, type: "course" }} />}
      />

      <Route
        path="/course/:courseId"
        element={<CourseContent props={{ type: "course" }} />}
      />

      {/* Internships */}
      {/* <Route path="/internships" element={<AllContents props={{ defaultValue:"all", type:"internship" }} />} />
      <Route path="/internships/purchased" element={<AllContents props={{ defaultValue:"purchased", type:"internship" }} /> }/>
      <Route path="/internships/:id" element={<Program props={{ isTaskool, type:"internship" }}/>}/>
      <Route path="/internship/:internshipId" element={<InternshipContent />} /> */}

      {/* Game */}
      <Route
        path="/games"
        element={<AllContents props={{ defaultValue: "all", type: "game" }} />}
      />
      <Route
        path="/games/purchased"
        element={
          <AllContents props={{ defaultValue: "purchased", type: "game" }} />
        }
      />
      <Route path="/games/:id" element={<Game />} />

      <Route
        path="/internships"
        element={
          <AllContents props={{ defaultValue: "all", type: "change later" }} />
        }
      />

      {/* STEAM */}
      <Route
        path="/steam"
        element={<AllContents props={{ defaultValue: "all", type: "steam" }} />}
      />

      <Route
        path="/steam/:id"
        element={<Program props={{ isTaskool, type: "steam" }} />}
      />

      <Route
        path="/steams/:courseId"
        element={<CourseContent props={{ type: "steam" }} />}
      />

      {/* Posts */}
      <Route path="/posts" element={<AllPosts />} />
      <Route path="/posts/:id" element={<Post />} />

      {/* Schools */}
      <Route path="/all-schools" element={<Schools />} />
      <Route path="/school/:id" element={<SchoolInfo />} />
      <Route path="/create-school" element={<CreateSchool />} />

      {/* Redirects to the "/" page if the user is logged in */}
      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route path="/register" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

function PublicPages() {

  return (
    <Routes>
      {/* TIP */}
      <Route path="/tip" element={<TIP />} />

      {/* Site Auth */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<NewRegister />} />
      <Route path="/email-verify/:token" element={<EmailVerify />} />

      {/* Informative */}
      <Route path="/" element={<Landing />} />
      {/* <Route path="/home" element={<NewDashboard />} /> */}
      <Route path="/about" element={<About />} />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-usage" element={<TermsOfService />} />
      <Route path="/version" element={<CurrentVersion />} />
      
      <Route path="*" element={<ErrorPage />} />

      {/* Communities Groups */}
      <Route path="/communities" element={<AllCommunities />} />
      <Route path="/groups/:id" element={<Community />} />

      {/* Users */}
      <Route
        path="/profile/:id"
        element={<PublicProfilePage props={{ isTaskool }} />}
      />
      <Route path="/consultant/:id" element={<ConsultantProfile />} />
      <Route
        path="/password-reset/:uuid/:token"
        element={<ResetPasswordSuccess />}
      />

      {/* Course */}
      <Route
        path="/courses/:id"
        element={<Program props={{ isTaskool, type: "course" }} />}
      />
      <Route
        path="/courses"
        element={
          <AllContents props={{ defaultValue: "all", type: "course" }} />
        }
      />

      {/* Olympics */}
      <Route path="/olympics" element={<Olympics />} />
      <Route path="/project/:id" element={<OlympicProject />} />
      <Route
        path="/olympic/:id"
        element={<MainOlympic props={{ isTaskool }} />}
      />

      {/* university */}
      <Route path="/universities" element={<Universities />} />
      <Route path="/universities/:uId/programs/:pId" element={<SingleUniversity />} />
      <Route path="/agents" element={<Agents />} />
      <Route path="/agents/:id" element={<SingleAgent />} />



      {/* Internships */}
      <Route
        path="/internships"
        element={
          <AllContents props={{ defaultValue: "all", type: "change later" }} />
        }
      />
      {/* <Route path="/internship/:id" element={<Program props={{ isTaskool, type:"internship" }}/>}/>
      <Route path="/all-internships" element={<AllContents type="internship" link="/internship/activeinternshiplist/" linkName="internships"/>}/> */}

      {/* STEAM */}
      <Route
        path="/steam"
        element={<AllContents props={{ defaultValue: "all", type: "steam" }} />}
      />

      <Route
        path="/steam/:id"
        element={<Program props={{ isTaskool, type: "steam" }} />}
      />

      {/* Game */}
      <Route
        path="/games"
        element={<AllContents props={{ defaultValue: "all", type: "game" }} />}
      />

      {/* Posts */}
      {/* <Route path="/posts" element={<AllPosts />} /> */}
      <Route path="/posts/:id" element={<CommunityPostPublic />} />

      {/* <Route path="/all-games" element={<AllContents type="game" link="/api/game-list/" linkName="games" />} /> */}
      <Route path="/game" element={<Game />} />
    </Routes>
  );
}
