import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Select,
  TextInput,
  FileInput,
  Button,
  Grid,
  Group,
  Loader,
} from "@mantine/core";
import { Eye, Paperclip } from "tabler-icons-react";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../../../../Helpers/Axios";

const CertificateForm = ({
  t,
  user,
  certificateId,
  formType,
  handleActiveCreateForm,
  handleActiveEditForm,
  handleActiveIcons,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const queryClient = useQueryClient();

  const { data: certificate } = useQuery({
    queryKey: ["certificate", certificateId],
    queryFn: async () => {
      return await axios(`/api/certificate/${certificateId}/`).then(
        (res) => res.data
      );
    },
    enabled: !!certificateId,
  });

  const { mutate: createCertificate, isLoading: createLoading } = useMutation({
    mutationFn: async (data) => {
      return await axios.post("/api/create-certificate/", data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userCertificates"]);
      handleActiveCreateForm(false);
    },
    onError: (error) => {},
    enabled: formType === "create",
  });

  const { mutate: editCertificate, isLoading: updateLoading } = useMutation({
    mutationFn: async (data) => {
      return await axios.patch(`/api/certificate/${certificateId}/`, data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userCertificates"]);
      handleActiveEditForm(false);
    },
    onError: (error) => {},
    enabled: formType === "edit",
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("user", user.id);
    formData.append("title", data.title);
    data.certificate && formData.append("certificate", data.certificate);
    if (formType === "create") {
      createCertificate(formData);
    } else {
      editCertificate(formData);
      handleActiveEditForm(false);
    }
  };

  useEffect(() => {
    if (formType === "edit" && certificate) {
      setValue("title", certificate.title);
    }
  }, [certificate, setValue, formType]);

  const handleBack = () => {
    if (formType === "edit") {
      handleActiveEditForm(false);
      handleActiveIcons(false);
    } else {
      handleActiveCreateForm(false);
    }
  };

  return (
    <Container formType={formType}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Col md={6}>
            <TextInput
              label={t("certificate-name")}
              size="md"
              radius={10}
              placeholder={t("certificate-name")}
              {...register("title", {
                required: t("certification_name_required"),
              })}
              error={errors.title?.message}
              styles={{ marginBottom: 20 }}
            />
          </Grid.Col>

          <Grid.Col md={6}>
            <StyledFileInput
              radius={10}
              size="md"
              placeholder={t("pdf-file")}
              label={t("language-certification")}
              {...register("certificate", {
                required: t("certificate_required"),
              })}
              error={errors.certificate?.message}
              withAsterisk
              style={{ fontSize: "16px", width: "100%" }}
              onChange={(e) => setValue("certificate", e)}
              rightSection={
                formType === "create" ? (
                  <Button
                    variant="outline"
                    size="sm"
                    radius={10}
                    rightIcon={
                      <Paperclip
                        style={{ rotate: "-45deg" }}
                        size={15}
                        strokeWidth={1.5}
                        color={"black"}
                      />
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      document.querySelector('input[type="file"]').click();
                    }}
                  >
                    {t("upload-file")}
                  </Button>
                ) : (
                  <Button
                    variant="outline"
                    component="a"
                    href=""
                    target="_blank"
                    size="sm"
                    radius={10}
                    rightIcon={
                      <Eye size={15} strokeWidth={1.5} color={"black"} />
                    }
                  >
                    {t("preview")}
                  </Button>
                )
              }
            />
          </Grid.Col>
        </Grid>

        <Group mt={5} position="right">
          <Button
            styles={(theme) => ({
              root: {
                background: "#f9fafc",
                border: "1px solid #cdd5df",
                borderRadius: "10px",
                height: "43px",
                width: "135px",
                color: "#364152",
                fontSize: "16px",
                fontWeight: "500",
                "&:hover": {
                  background: "#f9fafc",
                },
              },
            })}
            mt={15}
            type="button"
            onClick={handleBack}
          >
            {t("back")}
          </Button>
          <Button
            styles={(theme) => ({
              root: {
                background: "#FE6027",
                borderRadius: "10px",
                height: "43px",
                width: "135px",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "500",
                "&:hover": {
                  background: "#FE6027",
                },
              },
            })}
            mt={15}
            type="submit"
            disabled={createLoading || updateLoading}
          >
            {updateLoading || createLoading ? (
              <Loader color="#fff" style={{ color: "#fff" }} variant="dots" />
            ) : (
              t("save")
            )}
          </Button>
        </Group>
      </form>
    </Container>
  );
};

export default CertificateForm;

const Container = styled.div`
  .mantine-o3oqoy.mantine-Input-rightSection.mantine-FileInput-rightSection {
    right: ${(props) =>
      props.formType === "create" ? "75px !important" : "56px !important"};
    button {
      border: 1px solid #cdd5df;
      font-size: 16px;
      font-weight: 500;
      color: #1f2a37;
    }
  }

  .mantine-rxtk87 {
    border: 1px solid #cdd5df;
    font-size: 16px;
    font-weight: 500;
    color: #1f2a37;
  }
`;

const StyledFileInput = styled(FileInput)`
  .mantine-FileInput-input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1 !important;
  }
`;
