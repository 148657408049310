import { useContext, useState } from "react";
import { CreateCommunity } from "./CreateCommunity";
import {
  Button,
  Divider,
  Image,
  Skeleton,
  Tabs,
  Text,
  Grid,
  Group,
  Menu,
} from "@mantine/core";
import { DotsVertical, User, Users } from "tabler-icons-react";
import { ShowSidebar } from "../Layout/LayoutHelper";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import axios from "axios";
import { RecommendedPrograms } from "../RecommendedPrograms";
import { AppContext } from "../../Helpers/Context";
import { FilterCommunities } from "./CommunitiesInFeed";
import { UserCardImage } from "./CommunityCard";
import { useMediaQuery } from "@mantine/hooks";
import { MobileMenuNew } from "../Layout/Navbar/Menu";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const AllCommunities = () => {
  let [createCommunityModalOpen, setCreateCommunityModalOpen] = useState(false);

  const { user } = useContext(AppContext);
  const lg = useMediaQuery("(max-width: 1360px)");
  const md = useMediaQuery("(max-width: 768px)");
  const sm = useMediaQuery("(max-width: 400px)");
  const {t} = useTranslation()

  const { data: communities, isLoading: loadingOne } = useQuery(
    ["communities"],
    () => axios.get("/blog/communities/").then((res) => res.data)
  );


  const {data : userCommunities , isLoading: loadingTwo} = useQuery({
    queryKey: ["user communities"],
    queryFn: async () => {
      const response = await axios.get(`/blog/communitywithuserid/${user?.id}/`);
      return response.data;
    },
  })

  const { data: specificCommunityOne, isLoading: specificLoadingOne } =
    useQuery(["specific community one"], () =>
      axios.get("/blog/communitywithid/10").then((res) => res.data[0])
    );

  const { data: specificCommunityTwo, isLoading: specificLoadingTwo } =
    useQuery(["specific community two"], () =>
      axios.get("/blog/communitywithid/11").then((res) => res.data[0])
    );

    const maxSize = useMediaQuery('(min-width: 1496px)');


  if (loadingOne || loadingTwo) {
    return (
      <div
        style={{
          gap: ".5rem",
          display: "grid",
          gridTemplateColumns: "1fr .625fr",
        }}
      >
        <div>
          <FilterCommunities t={t} />
          <Skeleton radius={"md"} height={400} />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        padding: `${lg ? "0 10px" : "0"}`,
      }}
    >
      <CreateCommunity
        props={{ createCommunityModalOpen, setCreateCommunityModalOpen }}
      />

      <div>
        <FilterCommunities t={t} />

        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: ".75rem",
            border: "1px solid #ddd",
            display: "flex",
            flexDirection: "column",
            gap: "0",
          }}
        >
          <div
            style={{
              padding: "1.5rem 1rem 0.5rem 1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Tabs
                style={{ width: "100%" }}
                radius={"md"}
                color="gray"
                defaultValue="all"
                variant="default"
              >
                <Tabs.List
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    overflowX: "scroll",
                    overflowY: "hidden",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: ".5rem",
                    }}
                  >
                    <Tabs.Tab value="all">
                      <Group noWrap spacing={8}>
                        <Text>{t('all-groups')}</Text>
                        <Text>|</Text>
                        <Text span>{communities?.length}</Text>
                      </Group>
                    </Tabs.Tab>
                    <Tabs.Tab value="mine">
                      <Group noWrap spacing={8}>
                        <Text>{t('managed-groups')}</Text>
                        <Text>|</Text>
                        <Text>{userCommunities?.length}</Text>
                      </Group>
                    </Tabs.Tab>
                  </div>
                  <div>
                    <Menu trigger="hover">
                      <Menu.Target>
                        <Button px={12} variant="subtle" color={"gray.8"}>
                          <DotsVertical color="#495057" />
                        </Button>
                      </Menu.Target>

                      <Menu.Dropdown>
                        <Menu.Item>
                          <Button
                            radius={"md"}
                            color="gray"
                            onClick={() => setCreateCommunityModalOpen(true)}
                          >
                            <Text weight={400}>{t('create-group')}</Text>
                          </Button>
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </div>
                </Tabs.List>

                <Divider my={15} color="#dee2e6" />

                <Tabs.Panel value="all" pt="xs">
                  <div className="communities">
                    <Grid>
                      <Grid.Col align="center" span={maxSize && 4}  lg={maxSize ? 4 : 6}  >
                        <UserCardImage
                        t={t}
                          key={specificCommunityOne?.id}
                          name={specificCommunityOne?.name}
                          description={specificCommunityOne?.description}
                          image={specificCommunityOne?.image}
                          coverImage={specificCommunityOne?.cover_image}
                          membersCount={specificCommunityOne?.members?.length}
                          link={`/groups/${specificCommunityOne?.id}`}
                          component={Link}
                        />
                      </Grid.Col>
                      <Grid.Col align="center" span={maxSize && 4}  lg={maxSize ? 4 : 6} >
                        <UserCardImage
                        t={t}
                          key={specificCommunityTwo?.id}
                          name={specificCommunityTwo?.name}
                          description={specificCommunityTwo?.description}
                          image={specificCommunityTwo?.image}
                          coverImage={specificCommunityTwo?.cover_image}
                          membersCount={specificCommunityTwo?.members?.length}
                          link={`/groups/${specificCommunityTwo?.id}`}
                          component={Link}
                        />
                      </Grid.Col>
                      {communities?.length > 0 &&
                        communities
                          ?.filter((com) => com?.id !== 10 && com?.id !== 11) // Filter out communities with IDs 10 and 11
                          .map((com ,index) => (
                            <Grid.Col key={com.id} align="center" span={maxSize && 4}  lg={maxSize ? 4 : 6} >
                              <UserCardImage
                              t={t}
                                key={com?.id}
                                name={com?.name}
                                description={com?.description}
                                image={com?.image}
                                coverImage={com?.cover_image}
                                membersCount={com?.members?.length}
                                link={`/groups/${com?.id}`}
                                component={Link}
                              />
                            </Grid.Col>
                          ))}
                    </Grid>
                  </div>
                </Tabs.Panel>

                <Tabs.Panel value="mine" pt="xs">
                  <div
                    style={{
                      padding: "0.5rem .5rem 1.5rem .5rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Grid>
                      {userCommunities?.length > 0 &&
                        userCommunities.map((com) => {
                          return (
                            <Grid.Col key={com.id} span={sm ? 12 : lg ? 6 : 4}>
                              <UserCardImage
                              t={t}
                                key={com?.id}
                                name={com?.name}
                                description={com?.description}
                                image={com?.image}
                                coverImage={com?.cover_image}
                                membersCount={com?.members?.length}
                                link={`/groups/${com?.id}`}
                                component={Link}
                              />
                            </Grid.Col>
                          );
                        })}
                    </Grid>
                  </div>
                </Tabs.Panel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileTab = styled.div`
  @media (min-width: 1025px) {
    .mobileMenuMain {
      display: none;
    }
  }
`;
