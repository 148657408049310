import { React } from "react"
import { ShowSidebar } from "../../../Components/Layout/LayoutHelper"
import { EditUserInfo } from "./EditUserInfo"
import { DeleteProfile } from "./DeleteProfile"

const UserSettings = ({ props }) => {

  const { isTaskool } = props

  return (
      <EditUserInfo props={{ isTaskool }} />
  );
};

export default UserSettings