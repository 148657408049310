import { React } from "react";
import styled from "styled-components";
import lamp from "../../Assets/SVGs/lamp.svg";
import notes from "../../Assets/SVGs/notes.svg";
import tasks from "../../Assets/SVGs/tasks.svg";
import bookSaved from "../../Assets/SVGs/bookSaved.svg";

export function ProgramFiller({ props }){

  const { isTaskool, program } = props;
  console.log('isTaskool', isTaskool);
  console.log('program', program)

  const courseEng =
  {
    title: "Course Roadmap",
    desc: "Follow the steps below and enhance your portfolio with the brand new skills!",
    a1: "Enroll the course",
    a2: "Get ready with intro video and start your journey!",
    b1: "Master each lesson",
    b2: "Learn every lesson thoroghly! P.S. Don’t forget to take notes.",
    c1: "Take quizzes",
    c2: "Challenge yourself and be aware of your weak spots",
    d1: "Get certified",
    d2: "Finish the program and make your skills visible to everyone!",
  }

  const courseAz =
  {
    title: "Mərhələlər",
    desc: "Kursa başlamağa qərar vermisən? Aşağıdakı addımları izlə yeni bacarıq qazan!",
    a1: "Qeydiyyatdan keç",
    a2: "İntro videosuna bax, kursla tanış ol və qeydiyyatdan keç",
    b1: "Dərsləri öyrən",
    b2: "Hər bir dərsi dərindən öyrən və qeydlər aparmağı unutma!",
    c1: "Özünü test et",
    c2: "Öyrəndiyin bilikləri yoxlamaq üçün test tapşırıqlarını yerinə yetir",
    d1: "Sertifikat al",
    d2: "Proqramı bitir, öyrəndiyin yeni bacarıqları təsdiqləyən sertifikata sahib ol!",
  }

  const internshipEng =
  {
    title: "Internship Roadmap",
    desc: "Follow the steps below and gain experience!",
    a1: "Apply",
    a2: "Choose the field you are good at and let the fun begin!",
    b1: "HR Test",
    b2: "Show up what you are able to!",
    c1: "Interview",
    c2: "Let's get to know each other up close!",
    d1: "Start your internship",
    d2: "Make a first move in your career path!",
  }

  const intenrshipAz =
  {
    title: "Mərhələlər",
    desc: "Kursa başlamağa qərar vermisən? Aşağıdakı addımları izlə yeni bacarıq qazan!",
    a1: "Qeydiyyatdan keç",
    a2: "İntro videosuna bax, kursla tanış ol və qeydiyyatdan keç",
    b1: "Dərsləri öyrən",
    b2: "Hər bir dərsi dərindən öyrən və qeydlər aparmağı unutma!",
    c1: "Özünü test et",
    c2: "Öyrəndiyin bilikləri yoxlamaq üçün test tapşırıqlarını yerinə yetir",
    d1: "Sertifikat al",
    d2: "Proqramı bitir, öyrəndiyin yeni bacarıqları təsdiqləyən sertifikata sahib ol!",
  }

  let texts = {}

  if(isTaskool && program === "course"){
    texts = courseAz
  }

  if(isTaskool && program === "steam"){
    texts = intenrshipAz
  }

  if(!isTaskool && program === "course"){
    texts = courseEng
  }

  if(!isTaskool && program === "steam"){
    texts = internshipEng
  }

  return(
    <FillerStyle>
      <div className="wrapper">
        <div className="info">
          <h1>{texts.title}</h1>
          <p>{texts.desc}</p>
        </div>
        <div className="parts">
          <div>
            <img src={bookSaved} alt="" />
            <h2>{texts.a1}</h2>
            <p>{texts.a2}</p>
          </div>
          <div>
            <img src={tasks} alt="" />
            <h2>{texts.b1}</h2>
            <p>{texts.b2}</p>
          </div>
          <div>
            <img src={lamp} alt="" />
            <h2>{texts.c1}</h2>
            <p>{texts.c2}</p>
          </div>
          <div>
            <img src={notes} alt="" />
            <h2>{texts.d1}</h2>
            <p>{texts.d2}</p>
          </div>
        </div>
      </div>
    </FillerStyle>
  )
}

const FillerStyle = styled.div`
  margin: 3rem auto 2rem auto;
  background-color: #fff;
  .wrapper{
  max-width: 92%;
  margin : 0 auto;
  padding: 2rem 0;
    .info{
      text-align: center;
      width: 55%;
      margin: 0 auto;
      h1{
        font-weight: 500;
        font-size: 36px;
      }
      p{
        color: #828282;
        font-size: 17px;
        font-weight: 500;
        line-height: 1.5;
      }
    }
    .parts{
      margin: 4rem auto 0 auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: space-between;
      div{
        align-items: center;
        text-align: center;
        margin: 0 .7rem;
        padding: 2rem 0;
        border-radius: 0.75rem;
        border: 1px solid #eee;
        h2{
          font-weight: 500;
          color: #424242;
          font-size: 22px;
          margin: 2rem 0 .5rem 0;
        }
        p{
          font-size: 17px;
          line-height: 1.5;
          max-width: 240px;
          margin: 0 auto;
        }
      }
    }
  }
  @media screen and (max-width:1024px){
    width: 95%;
    margin: 3rem auto;
    .wrapper{
      .info{
        text-align: center;
        width: 100%;
        margin: 0 auto;
        h1{
          font-size: 22px;
        }
        p{
          font-size: 16px;
        }
      }
      .parts{
        margin: 2.5rem auto;
        grid-template-columns: 1fr;
        grid-gap: 1.5rem;
        div{
          padding: 1.5rem;
          h2{
            margin: 1rem 0;
          }
        }
      }
    }
  }
`