import { useContext, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { ProgramCard } from "./ProgramCard";
import { useQuery } from "@tanstack/react-query";
import { SkeletonLoader } from "./Loaders/SkeletonLoader";
import { Button, Center, Container, Tabs, Text } from "@mantine/core";
import { motion } from "framer-motion";
import { AnimateOpa } from "./AnimateOpa";
import { ComingSoon } from "./ComingSoon";
import { BorderAll, ChevronLeft, Package } from "tabler-icons-react";
import { ShowSidebar } from "./Layout/LayoutHelper";
import { AppContext } from "../Helpers/Context";
import { Link, useLocation } from "react-router-dom";
import { MobileMenuNew } from "./Layout/Navbar/Menu";
import { useTranslation } from "react-i18next";

function Skeletons() {
  return (
    <div className="contents">
      {[...Array(9)].map((e, i) => {
        return (
          <SkeletonLoader
            key={i}
            height={260}
            width={320}
            mb={10}
            visible={true}
          />
        );
      })}
    </div>
  );
}

function Errors({ error }) {
  return (
    <Center>
      <Container my={120}>
        <Text weight={600} mr={2} align="center" size={32}>
          Error
        </Text>
        <Text size={20}>{error}</Text>
      </Container>
    </Center>
  );
}

function NoPurchasedPrograms({ to }) {
  return (
    <Container my={100}>
      <Text align="center" mb={20} size={28}>
        You haven't purchased any programs yet
      </Text>
      <Center>
        <Button
          component={Link}
          to={to}
          style={{ color: "#000", fontWeight: "500" }}
          color={"ts"}
          leftIcon={<ChevronLeft />}
        >
          Check out all the programs
        </Button>
      </Center>
    </Container>
  );
}

function AllPrograms({ props }) {
  const { contentAmount, setContentAmount, type, userId } = props;

  let linkAll =
    type === "course"
      ? "/api/all-courses/"
      : type === "internship"
      ? "internship/activeinternshiplist"
      : type === "hackathon"
      ? "/hackathon/activate_hackatons_list/"
      : type === "steam"
      ? "/steam/activesteamlist/"
      : "/api/game-list/";

  const {
    data: contents,
    isError,
    error,
    isLoading,
  } = useQuery(
    [linkAll, linkAll],
    async () => await axios.get(linkAll).then((res) => res.data),
    { enabled: !!type }
  );

  if (isLoading) {
    return <Skeletons />;
  }

  if (isError) {
    return <Errors error={error?.message} />;
  }

  if (contents?.length === 0) {
    return <ComingSoon />;
  }

  return (
    <motion.div
      animate={{ y: [100, 0] }}
      transition={{ ease: "easeOut", duration: 0.3 }}
      style={{ paddingTop: userId ? "0rem" : "1.5rem" }}
    >
      <div className="contents">
        {contents?.slice(0, contentAmount)?.map((content) => (
          <ProgramCard
            key={content.id}
            props={{
              link:
                type === "course"
                  ? `/courses/${content.id}`
                  : type === "hackathon"
                  ? `/hackathons/${content?.id}`
                  : type === "steam"
                  ? `/steam/${content?.id}`
                  : `/internships/${content.id}`,
              image: content.image,
              title: content.title,
            }}
          />
        ))}
      </div>
      {contents[contentAmount] && (
        <Center mb={30}>
          <Button
            px={50}
            color={"dark.7"}
            onClick={() => setContentAmount(contentAmount + 9)}
          >
            See more
          </Button>
        </Center>
      )}
    </motion.div>
  );
}

function PurchasedPrograms({ props }) {
  const { contentAmount, setContentAmount, type } = props;

  const { user } = useContext(AppContext);
  let linkPur =
    type === "course"
      ? "/api/studentapplycourselist/"
      : type === "internship"
      ? "/internship/studentapplyinternlist/"
      : type === "hackathon"
      ? `/hackathon/hackathonjoineduserid/${user?.id}/`
      : type === "steam" 
      ? `/steam/studentapplysteamlist/`
      : type === "game" && "/api/game-list/";

  const {
    data: contents,
    isError,
    error,
    isLoading,
  } = useQuery(
    [linkPur],
    async () => await axios.get(linkPur).then((res) => res.data),
    { enabled: !!type }
  );

  if (isLoading) {
    return <Skeletons />;
  }

  if (isError) {
    return <Errors error={error?.message} />;
  }

  if (contents?.length === 0) {
    return (
      <NoPurchasedPrograms
        to={type === "course" ? "/courses" : "/internships"}
      />
    );
  }

  return (
    <motion.div
      animate={{ y: [100, 0] }}
      transition={{ ease: "easeOut", duration: 0.3 }}
    >
      <div className="contents">
        {contents?.slice(0, contentAmount).map((content) => (
          <ProgramCard
            key={content.id}
            props={{
              link:
                type === "course"
                  ? `/courses/${content.id}`
                  : type === "hackathon"
                  ? `/hackathons/${content?.id}`
                  : type === "steam"
                  ? `/steam/${content?.id}`
                  : `/internships/${content.id}`,
              image: content.image,
              title: content.title,
            }}
          />
        ))}
      </div>
      {contents[contentAmount] && (
        <Center mb={30}>
          <Button
            px={50}
            color={"dark.7"}
            onClick={() => setContentAmount(contentAmount + 9)}
          >
            See more
          </Button>
        </Center>
      )}
    </motion.div>
  );
}

export const AllContents = ({ props }) => {
  const { defaultValue, type } = props;
  const {t} = useTranslation()

  const [contentAmount, setContentAmount] = useState(9);

  const { user } = useContext(AppContext);

  let location = useLocation();

  if (location.pathname === "/games" || location.pathname === "/steam") {
    return (
        <ComingSoon margin={500} />
    );
  }

  if (!user?.id) {
    return (
      <AnimateOpa>
        <ContentStyle>
          <AllPrograms
            props={{ contentAmount, setContentAmount, type, userId: user?.id }}
          />
        </ContentStyle>
      </AnimateOpa>
    );
  }

  return (
      <AnimateOpa>
        <ContentStyle>
          <Tabs
            radius={".75rem"}
            variant="pills"
            defaultValue={defaultValue}
            styles={{
              root: { width: "100%", padding: 0 },
              tabsList: { gap: 0, padding: 0 },
              tab: { backgroundColor: "#E9ECEF", padding: 0 },
              panel: { margin: 0, padding: 0 },
            }}
          >
            <Tabs.List
              grow
              style={{
                borderRadius: ".75rem",
                border: "1px solid #dee2e6",
                marginBottom: ".65rem",
              }}
            >
              <Tabs.Tab
                style={{ color: "black", borderRadius: ".75rem 0 0 .75rem" }}
                p={8}
                value="all"
                color={"white"}
                icon={<BorderAll />}
              >
                <Text size={17} weight={500}>
                  {t('all')}
                </Text>
              </Tabs.Tab>
              <Tabs.Tab
                style={{ color: "black", borderRadius: "0 .75rem .75rem 0" }}
                p={8}
                value="purchased"
                color={"white"}
                icon={<Package />}
              >
                <Text size={17} weight={500}>
                  {t('purchased')}
                </Text>
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="all" style={{}}>
              <AllPrograms
                props={{
                  contentAmount,
                  setContentAmount,
                  type,
                  userId: user?.id,
                }}
              />
            </Tabs.Panel>

            <Tabs.Panel value="purchased" style={{}}>
              <PurchasedPrograms
                props={{ contentAmount, setContentAmount, type }}
              />
            </Tabs.Panel>
          </Tabs>
        </ContentStyle>
      </AnimateOpa>
  );
};

const ContentStyle = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 1.5rem;
  background-color: #f1f3f5;
  .contents {
    border-radius: 0.75rem;
    display: flex;
    justify-content: center;
    max-width: 1360px;
    margin: 0 auto;
    flex-wrap: wrap;
    grid-gap: 0.75rem;
  }
`;

const MobileTab = styled.div`
  @media (min-width: 1025px) {
    .mobileMenuMain {
      display: none;
    }
  }
`;
