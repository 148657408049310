import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../../../Helpers/Context";
import { useTranslation } from "react-i18next";

//import logo
import newLogo from "../../../Assets/SVGs/new-logo.svg";

//import Icons
import menuIcon from "../../../Assets/SVGs/menu-icon.svg";
import subscriptionIcon from "../../../Assets/SVGs/subscription-icon.svg";

import notificationIcon from "../../../Assets/SVGs/header/notification.svg";
import arrowDownIcon from "../../../Assets/SVGs/header/chevron-down.svg";
import NewSearchBar from "./NewSearchBar";
import NewNavbarProfile from "./NewNavbarProfile";
import { Button, Divider, Grid, Group, Select, Title } from "@mantine/core";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import { ChevronDown, Language, Menu2 } from "tabler-icons-react";
import LanguageIcon from "../../../Assets/SVGs/landing/langIcon.svg";
import Logo from "../../../Assets/SVGs/landing/logo.svg";
import { useAnimate, stagger, motion } from "framer-motion";

function NewNavbar({ isTaskool, register }) {
  const { user, setUser } = useContext(AppContext);

  // Logs the current user out
  const location = useLocation();

  function logout() {
    localStorage.setItem("taskooluser", null);
    setUser(null);
    location.pathname = "/";
    // remove cookies
    document.cookie =
      "refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  const { newSidebarOpen, setNewSidebarOpen } = useContext(AppContext);
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("tsklng") || "az"
  );
  const { t, i18n } = useTranslation();

  const langs = [
    {
      iso: "AZ",
      click: () => changeLang("az"),
    },
    {
      iso: "EN",
      click: () => changeLang("en"),
    },
    {
      iso: "RU",
      click: () => changeLang("ru"),
    },
    {
      iso: "TR",
      click: () => changeLang("tr"),
    },
  ];

  function changeLang(lng) {
    i18n.changeLanguage(lng);
    setSelectedLang(lng);
    localStorage.setItem("tsklng", lng);
  }

  const texts = isTaskool
    ? {
        logIn: "Daxil ol",
        register: "Qeydiyyat",
      }
    : {
        logIn: "Log in",
        register: "Register",
      };

  if (register) {
    return (
      <NewNavbarStyle>
        <div className="container">
          <Grid
            align="center"
            justify="space-between"
            style={{ width: "100%" }}
          >
            <Grid.Col span={2}>
              <Group noWrap>
                <Menu2 size={33} strokeWidth={1} color="#fff" />
                <Title color="#fff" weight={400} size={20}>
                  Menu
                </Title>
              </Group>
            </Grid.Col>
            <Grid.Col span={6}>
              <Group position="center" >
              <img src={Logo} alt="" />
              </Group>
            </Grid.Col>
            <Grid.Col span={3}>
              <Group spacing={4} position="right" align="center"  >
               <Language color="#fff" size={20} strokeWidth={1.5} />
                <Select
                  rightSection={<ChevronDown size={14} color="#FFF" />}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  data={["Az", "En", "Ru", "Tr"]}
                  defaultValue="Az"
                  className="select-lang"
                  size="lg"
                />
                <div
                  style={{
                    width: "1px",
                    backgroundColor: "#fff",
                    height: "20px",
                    marginRight :"10px"
                  }}
                ></div>
                <Group>
                <Link className="sign-btn" to="/login">
                  Login
                </Link>
                <Link className="sign-btn" to="/register">
                  Register
                </Link>
                </Group>

              </Group>
            </Grid.Col>
          </Grid>
        </div>
      </NewNavbarStyle>
    );
  }

  return (
    <NewNavbarStyle>
      <div className="container">
        <div className="header-left">
          <div
            onClick={() => setNewSidebarOpen(!newSidebarOpen)}
            className="menu-icon"
          >
            <img src={menuIcon} alt="menu-icons" /> <span>Menu</span>
          </div>
          <div className="subscription-icon">
            <Link to="/newpayment">
              {" "}
              <img src={subscriptionIcon} alt="subscription-icon" />
              Subscription
            </Link>
          </div>
        </div>

        <div className="logo">
          <Link to="/">
            {" "}
            <img src={newLogo} alt="taskilled logo" />{" "}
          </Link>
        </div>

        <div className="header-icons">
          <NewSearchBar />

          <NewNavbarProfile props={{ user, logout, register, isTaskool }} />

          <div className="icon-item">
            <img src={notificationIcon} alt="" />
          </div>
          <div className="icon-item language">
            {selectedLang.toUpperCase()}
            <img src={arrowDownIcon} alt="" />
            <div className="language-select-box">
              <ul>
                {langs.map((item, index) => (
                  <li key={index} onClick={item.click}>
                    {item.iso}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </NewNavbarStyle>
  );
}

export default NewNavbar;

const NewNavbarStyle = styled.div`
  position: sticky;
  top: 0px;
  background: #00c9ff;
  z-index: 999999;
  box-shadow: 0px 8px 12px 0px #0000000a;

  * {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
  }

  a {
    text-decoration: none;
  }

  .sign-btn {
    color: #fff;
    background-color: transparent;
    border-radius: 29px;
    font-size: 17px;
    font-weight: 500;
    padding: 5.5px 10px;
    &:hover {
      background-color: #ccf075;
      color: #323922;
      padding: 11px 33px;
      transition : all 0.3s linear 

    }
  }

  .select-lang {
    width: 14%;
    .mantine-1ugtrwt {
      background-color: #00c9ff;
      border: none;
      color: #fff;
    }
    .mantine-1cam711 {
      right: 0px;
      top: 4px;
    }
  }
  .container {
    padding: 0.5rem 0;
    width: 90%;
    margin: 0 auto;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #eee; */

    .header-left {
      display: flex;
      gap: 16px;

      .menu-icon {
        cursor: pointer;
        transition: 0.3s;
        border-radius: 8px;
        &:hover {
          background: #eef2f6;
        }

        @media screen and (max-width: 1024px) {
          span {
            display: none;
          }
        }

        font-family: "Euclid";
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        padding: 8px 12px 8px 16px;
        img {
          margin-right: 8px;
        }
      }

      .subscription-icon {
        transition: 0.3s;
        border-radius: 8px;
        &:hover {
          background: #fffaeb;
        }
        a {
          display: flex;
          align-items: center;
          cursor: pointer;

          gap: 4px;
          color: #eaaa08;
          font-family: "Euclid";
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          padding: 8px 12px 8px 16px;
        }
        img {
          margin-right: 8px;
        }
      }

      @media screen and (max-width: 1024px) {
        .subscription-icon {
          display: none;
        }
      }
    }
    .logo {
      img {
        width: 144px;
      }
    }
    .header-icons {
      width: 20%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;

      @media screen and (max-width: 1024px) {
        .icon-item:not(.language),
        .search-box {
          display: none !important;
        }
      }

      .icon-item {
        cursor: pointer;
        transition: 0.3s;
        border-radius: 8px;
        &:hover {
          background: #eef2f6;
        }

        border-radius: 8px;
        padding: 10px;
        display: flex;
      }

      .search-box {
        display: flex;
        align-items: center;
        position: relative;

        .search-list {
          z-index: 99;
          position: absolute;
          top: 55px;
          left: 0px;
          width: 280px;
          background: #fff;
          border-radius: 8px;
          max-height: 200px;
          padding: 6px 10px;
          border: 1px solid #ccc;
          list-style: none;
          font-size: 12px;
          font-family: "Inter";
          cursor: pointer;

          li {
            padding: 5px;
            border-radius: 5px;
            &:hover {
              background: #eee;
            }
          }
        }

        .icon-item {
          background: none;
          position: absolute;
          right: 10px;
        }

        &:hover {
          .search-input {
            width: 280px;
            border: 1px solid #cdd5df;
            opacity: 1;
          }
        }

        .search-input {
          opacity: 0;
          width: 0px;
          overflow: hidden;
          transition: 0.3s;
          cursor: pointer;
          border: none;
          transition: 0.5s;
          height: 44px;

          padding: 6px 10px 6px 10px;
          border-radius: 8px;
          &:focus {
            outline: 1px solid #697586;
            width: 280px;
            border: 1px solid #cdd5df;
            opacity: 1;
          }
        }
      }

      .language {
        padding: 8px 12px 8px 16px;
        display: flex;
        gap: 8px;
        position: relative;
        &:hover {
          .language-select-box {
            display: block;
          }
        }
        .language-select-box {
          z-index: 999;
          display: none;
          position: absolute;
          top: 45px;
          left: 0px;
          right: 0px;
          border: 8px;
          background: #fff;
          box-shadow: 0px 8px 12px -4px #00000014;

          padding: 12px;
          border-radius: 12px;
          ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 4px;
            li {
              border-radius: 5px;
              transition: 0.3s;
              text-align: center;
              padding: 8px, 12px, 8px, 12px;
              color: #202939;
              font-family: "Inter";
              font-size: 16px;
              font-weight: 500;

              &:hover {
                background: #eef2f6;
              }
            }
          }
        }
      }

      font-family: "Euclid";
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;
