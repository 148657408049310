import React from 'react';
import {  } from '../../Components/Layout/LayoutHelper';
import styled from "styled-components";

function PrivacyPolicy() {

  window.scrollTo(0, 0, 'smooth');

  return (
    <>

        <PrivacyPolicyStyle>
          <div>
            <h1>Privacy Policy</h1>
            <p>You agree to the terms of this Privacy Policy by using the Services. If you do not agree with this Privacy Policy or any other agreement that governs your use of the Services, you should not use them.</p>
            <h2>What Information We Collect</h2>
            <p>We acquire certain data directly from you, such as information you enter yourself, data on your content consumption, and data from third-party sites you link with Taskilled. We also automatically collect some data, such as information about your device and which areas of our Services you engage with or spend time using.Depending on how you use the Services, we may collect different types of data from or about you. The examples below will help you better comprehend the data we collect.We collect whatever data you provide directly when you register an account and use the Services, including through a third-party platform, including:</p>
            <h2>Account Data</h2>
            <p>To use some services (such as viewing material), you must first register a user account, which necessitates the collection and storage of your email address, password, and account preferences. We gather and keep your name, email address, password, and account settings in order to create an instructor account.</p>
            <h2>Profile Data</h2>
            <p>You may also provide profile information such as a photo, headline, biography, language, website link, social network accounts, country, or other information. Others will be able to see your Profile Data.</p>
            <h2>Shared Content</h2>
            <p>Parts of the Services allow you to engage with other users or publicly share content, such as by writing content evaluations, asking or answering questions, sending messages to students or instructors, or uploading images or other work. Depending on where it is placed, such shared content may be publicly viewed by others.</p>
            <h2>Promotions, and Surveys</h2>
            <p>We may contact you via the Services or a third-party platform to encourage you to conduct a survey or participate in a promotion (such as a contest, sweepstakes, or challenge). We will collect and retain the data you supply as parts of your participation, such as your name, email address, postal address, date of birth, or phone number if you participate. Unless otherwise indicated in the official regulations of the promotion or in another privacy policy, the data is subject to this Privacy Policy. The information gathered will be used to manage the campaign or survey, such as informing winners and providing prizes.</p>
            <h2>System Data</h2>
            <p>Technical data about your computer or device, such as its IP address, device type, operating system type and version, unique device identifiers, browser, browser language, domain and other system data, and platform types.</p>
            <h2>Usage Data</h2>
            <p>Usage statistics on your activities with the Services, such as content accessed, time spent on sites or the Service, pages visited, features used, your search queries, click data, date and time, referrer, and other data.</p>
            <h2>What We Use Your Data For</h2>
            <p>We use your data to deliver our Services, interact with you, troubleshoot problems, protect against fraud and abuse, enhance and update our Services, analyze how people use our Services, serve targeted advertising, and as needed by law or for safety and integrity. We keep your information for as long as it is required to fulfill the objectives for which it was acquired.</p>
            <h2>Who We Share Your Data With</h2>
            <p>We share information about you with instructors, other students, companies performing services for us, Taskilled affiliates, our business partners, analytics and data enrichment providers, your social media providers, companies assisting us in running promotions and surveys, and advertising companies assisting us in promoting our Services. We may also disclose your information if it is required for security, legal compliance, or as part of a business reorganization. Finally, we can share data in other ways if it has been aggregated or de-identified, or if we have your permission.</p>
            <h2>Security</h2>
            <p>We employ appropriate security measures based on the kind and sensitivity of the data held. As with any internet-enabled system, there is always the possibility of illegal access, so secure your password and notify us if you suspect improper access to your account.</p>
            <h2>Your Rights</h2>
            <p>You have specific rights about the use of your data, including the opportunity to opt out of promotional emails, cookies, and third-party data collecting. You can amend or deactivate your account using our Services, and you can also contact us for specific data rights requests. Parents who feel we have inadvertently gathered personal information about their underage child can contact us for assistance in removing such information.</p>
          </div>
        </PrivacyPolicyStyle>

    </>
  );
}

export default PrivacyPolicy

const PrivacyPolicyStyle = styled.div`
  margin: 5%;
  margin-top: 2rem;
  div {
    background-color: #f8f8f8;
    width: min(90%, 50rem);
    padding: 5%;
    margin: 0 auto;
    border-radius: 10px;
    line-height: 1.35;
    h1{
      margin-top: 0;
    }
    h1,h2 {
      font-weight: 500;
    }
    b {
      font-weight: 500;
    }
    p{
      font-size: clamp(.9rem, 2rem, 1.1rem);
    }
    ins{
      text-decoration: none;
      background-color: var(--yellow);
      border-radius: 0 0.3rem 0 .3rem;
    }
  }
`