import React from "react";
import styled from "styled-components";
import { Group, Progress, Stack, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";

const JoinedPrograms = ({t}) => {
  const { data: joinedCourses } = useQuery({
    queryKey: ["joinedCourses"],
    queryFn: async () => {
      const response = await axios.get("/api/studentapplycourselist/");
      return response.data;
    },
  });

  return (
    <ActivitiesContainer>
      <div className="activities">
        <Title
          sx={{ marginBottom: "15px" }}
          weight={500}
          color="#364152"
          size={16}
        >
          {t('joined-course')}
        </Title>
        <Stack>
          {joinedCourses?.map((course, index) => (
            <React.Fragment key={index}>
              <Group noWrap align="center">
                <div
                  style={{
                    width: "70px",
                    height: "45px",
                    borderRadius: "11px",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "11px",
                    }}
                    src={course?.image}
                    alt=""
                  />
                </div>
                <Text size={14} weight={400} color="#364152">
                  {course?.title}
                </Text>
              </Group>
              {index !== joinedCourses.length - 1 && (
                <div
                  style={{
                    height: "1px",
                    width: "100%",
                    backgroundColor: "#DCDCDC",
                  }}
                ></div>
              )}
            </React.Fragment>
          ))}
        </Stack>
      </div>
    </ActivitiesContainer>
  );
};

export default JoinedPrograms;

const ActivitiesContainer = styled.div`
  width: 100%;
  border-radius: 11px;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  .activities {
    padding: 16px 18px;
  }
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;
