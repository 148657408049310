import { useContext, useEffect, useState } from "react";
import {
  AppShell,
  Navbar,
  Aside,
  MediaQuery,
  Burger,
  useMantineTheme,
} from "@mantine/core";
import Footer from "../../Components/Layout/Footer/index";
import NavbarContent from "./Navbar";
import HeaderContent from "./Header";
import AsideContent from "./Aside";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../../Helpers/Context";
import Header from "../Layout/Header/index";

export default function DashboardLayout({ children }) {
  const location = useLocation();
  const path = location?.pathname;
  let hiddenAsidePaths = [
    "login",
    "register",
    "tip",
    "courses",
    "steam",
    "olympics",
    "groups",
    "settings",
    "informative",
    "profile",
    "email-verify",
    "universities",
    "agents"
  ];
  let hiddenNavbarPaths = ["login", "register", "tip","informative","courses/","profile","email-verify","universities","agents"];

  const [isShowAside, setIsShowAside] = useState(false);
  const [isShowNavbar, setIsShowNavbar] = useState(false);

  useEffect(() => {
    setIsShowAside(hiddenAsidePaths.some((x) => path.includes(x)));
    setIsShowNavbar(hiddenNavbarPaths.some((x) => path.includes(x)));
  }, [path]);
  

  let { user } = useContext(AppContext);
  return (
    <div
      style={
        !user && path !== "/" && path !== "/login" && path !== "/register" && path !== "/informative" && !path?.includes("universities") && !path?.includes("agents")
          ? { backgroundColor: "#f2f2f2", height: "100%" }
          : {}
      }
    >
      {!(path === "/login" || path === "/register") &&
        (user ? <HeaderContent /> : <Header register />)}
      <LayoutContainer isShowAside={isShowAside} isShowNavbar={isShowNavbar} user={user} path={path}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <NavbarContainer isShowNavbar={isShowNavbar} path={path} >
            {(!isShowNavbar && path !== "/" ) && <NavbarContent />}
          </NavbarContainer>
          <MainContainer
            isShowAside={isShowAside}
            isShowNavbar={isShowNavbar}
            user={user}
            path={path}
          >
            {children}
          </MainContainer>

          <AsideContainer isShowAside={isShowAside} path={path} >
            {(!isShowAside && path !== "/") && <AsideContent />}
          </AsideContainer>
        </div>
      </LayoutContainer>
      {!(path === "/login" || path === "/register") && <Footer />}
    </div>
  );
}

const LayoutContainer = styled.div`
  max-width: ${props => (props.isShowNavbar === true || props.path === "/") ? "100%" : "96.5%"};
  margin: 0 auto;
`;
const NavbarContainer = styled.div`
  width: ${(props) => ((props.isShowNavbar || props.path === "/") ? "0%" : "21.5%")};
  height: 600px;
  position: sticky;
  top: 100px;
  @media screen and (max-width: 576px){
    display: none;
  }
`;
const MainContainer = styled.div`
 width: ${(props) =>
  ( (props.isShowAside === true && props.isShowNavbar === true) || props.path === "/")
     ? "100%"
     : (props.isShowAside === true && props.isShowNavbar === false)
     ? "70%"
     : "50%"};
  
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`;
const AsideContainer = styled.div`
  width: ${(props) => ((props.isShowAside || props.path === "/") ? "0%" : "21.5%")};
  position: sticky;
  height: 600px;
  top: 100px;
  @media screen and (max-width: 576px) {
    display: none;
  }
`;
