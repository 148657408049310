import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

export const Quiz = ({ props }) => {

  const { chooseOption, haveBackedOnce, questions, currentQuestion, chosenOptions, setChosenOptions, pendingOptions, setPendingOptions } = props

  return (
    <QuizStyle>
      <AnimatePresence mode="wait">
        {
          questions?.map((q, index) => {
            return(
              currentQuestion.id === q.id &&
              <motion.div
                key={q.id}
                initial={{ opacity:0 }}
                animate={{ opacity:1 }}
                exit={{ opacity:0 }}
                transition={{ duration: .5 }}
              >
                <div className="question">
                  <div className="questionDiv">
                    <h2 className="index">{q?.order + "."}</h2>
                    <h2>{q.question}</h2>
                  </div>
                  <div className="options">
                    {
                      q.optionspeciality_set &&
                      [...q.optionspeciality_set].map(v => {
                        return(
                          <button key={v.id} className={(chosenOptions?.id?.includes(v.id) || pendingOptions?.id?.includes(v.id)) ? "option choosenOption" : "option"} onClick={() => chooseOption({ id:v.id, multi:q.multichoice })}>
                            <p className="label">{v.option.toUpperCase()}</p>
                            <p className="optionText">{v.description}</p>
                          </button>
                        )}
                      )
                    }
                  </div>
                </div>
              </motion.div>
            )
          })
        }
      </AnimatePresence>
    </QuizStyle>
  )
}

const QuizStyle = styled.div`
  h1,h2,h3,h4,h5,h6,p,button,a{
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  .question{
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    .questionDiv{
      display: flex;
      grid-gap: 1rem;
      .index{
        color:#ffffff88;
      }
    }
    .explanation{
      padding: 1rem;
      border: 1px solid #ADB5BD;
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
      p{
        font-size: 17px;
      }
    }
    .options{
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      width: 100%;
      .option{
        width: 100%;
        display: flex;
        align-items: center;
        border: none;
        border-radius: 0.25rem;
        padding: 0;
        cursor: pointer;
        background-color: #343A40;
        color: #fff;
        border: 1px solid transparent;
        transition: all 0.15s;
        .label{
          margin: 1rem;
          font-size: 17px;
          color:#ddd;
        }
        .optionText{
          margin: .75rem .75rem .75rem 0;
          font-size: 17px;
        }
        &:hover{
          background-color: #495057;
        }
      }
      .choosenOption{
        background-color: #495057;
        border: 1px solid #ADB5BD;
      }
    }
  }
  @media (max-width: 800px) {
    padding-bottom: 10rem;
    .question{
      .options{
        .explanation{
          grid-template-columns: 1fr;
          border: 0;
          padding: 0;
          .text{
            max-height: initial;
            overflow-x: initial;
            overflow-y: initial;
          }
        }
        .option{
          .label{
          }
          .optionText{
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 0 0.5rem 0 0;
            margin: 0.5rem auto;
          }
        }
      }
    }
  }
`