import { Slider, Textarea } from '@mantine/core';
import axios from 'axios';
import { React, useContext, useState, useEffect } from 'react'
import { AppContext } from '../../../Helpers/Context';
import styled from "styled-components";
import RichTextEditor from '@mantine/rte';

const GiveGrade = ({props}) => {

  const { user, aToken } = useContext(AppContext)
  const { userAnswer, showPart, section, currentMaterial } = props;



  const [ teacherGrade, setTeacherGrade ] = useState(null)
  const [ grade, setGrade ] = useState({
    text:'',
    point:0
  })

  useEffect(() => {
    if(userAnswer.id){
      (async () => {
        try {
          const response = await axios.get(`/grade/course/${userAnswer.id}/`)
          if(response.data.id){
            setTeacherGrade({...response.data, answered:true})
          }
        } catch (error) {

        }
      })()
    }
    return () => {
      setTeacherGrade(null)
    }
  }, []);

  async function submitGrade(){
    try {
      const response = await axios.post(`/grade/course/`, {
        description: grade.text,
        grade: JSON.stringify(grade.point),
        answer: userAnswer?.id,
        user: user.id
      })

    } catch (error) {

    }
  }

  return (
    <GradeStyle>
      {
        teacherGrade?.answered !== undefined ?

        <div className="gradeWrapper">
          <h2>Grade</h2>
          <h3>Given points: {teacherGrade?.grade}</h3>
          <p>Notes: {teacherGrade?.description}</p>
        </div>
        :
        <div className="gradeWrapper">
          <h2>Give grade</h2>
          <p>Note for the student</p>
          {/* <Textarea
            placeholder="Write your opinion.."
            value={grade.text}
            onChange={(e) => setGrade({...grade, text:e.target.value})}
          /> */}
          <RichTextEditor
            value={grade.text}
            onChange={(e) => setGrade({...grade, text:e})}
          />
          <br />
          <div className="slider">
            <p>Points: {grade.point}</p>
            <Slider
              min={0}
              max={10}
              marks={[
                { value: 1, label: '1' },
                { value: 2, label: '2' },
                { value: 3, label: '3' },
                { value: 4, label: '4' },
                { value: 5, label: '5' },
                { value: 6, label: '6' },
                { value: 7, label: '7' },
                { value: 8, label: '8' },
                { value: 9, label: '9' },
                { value: 10, label: '10' },
              ]}
              value={grade.point}
              onChange={(e) => setGrade({...grade, point:e})}
              />
          </div>
          <br /><br />
          <button onClick={() => submitGrade()}>Submit</button>
        </div>
      }
    </GradeStyle>
  )
}

export default GiveGrade

const GradeStyle = styled.div`
  margin-top: 2rem;
  .gradeWrapper{
    background-color: #f2f8ff;
    padding: 1.5rem 2rem;
    max-width: 70%;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    h2{
      margin-top: 0;
      margin-bottom: 2rem;
    }
    .slider{
      margin-top: 1rem;
      p{
        margin-top: 0;
      }
    }
    button{
      background-color: var(--yellow);
      padding: 0.5rem 3rem;
      margin-top: 1.5rem;
      border: 0;
      font-size: 15px;
      &:hover{
        background-color: var(--yellow-shadow);
      }
    }
  }
`