import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Divider,
  Grid,
  Group,
  Menu,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import logo from "../../../Assets/SVGs/landing/logo.svg";
import {
  ChevronDown,
  ChevronUp,
  Language,
  Menu2,
  Search,
} from "tabler-icons-react";
import { Link, useLocation } from "react-router-dom";
import { useClickAway } from "react-use";
import { useTranslation } from "react-i18next";
import SideBar from "../SideBar/index";
import { useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";
import { AppContext } from "../../../Helpers/Context";

const NonUserHeader = () => {
  const [currentNav, setCurrentNav] = useState();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => setOpen(false));
  const toggleSidebar = () => setOpen((prev) => !prev);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("tsklng") || "az"
  );

  const matches = useMediaQuery("(max-width: 1350px)");

  const handleScroll = () => {
    if (window.scrollY > 70 && !location?.pathname.includes("/community")) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const { t, i18n } = useTranslation();

  const langs = [
    {
      iso: "Az",
      click: () => changeLang("az"),
    },
    {
      iso: "En",
      click: () => changeLang("en"),
    },
    {
      iso: "Ru",
      click: () => changeLang("ru"),
    },
    {
      iso: "Tr",
      click: () => changeLang("tr"),
    },
  ];

  function changeLang(lng) {
    i18n.changeLanguage(lng);
    setSelectedLang(lng);
    localStorage.setItem("tsklng", lng);
  }

  const { user } = useContext(AppContext);
  const path = location.pathname;

  return (
    <>
      <HeaderStyle
      selectedLang={selectedLang}
        path={path}
        style={
          scrolled
            ? {
                position: "sticky",
                boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.1)",
                padding: "12px 0",
              }
            : { position: "block", padding: "20px 0" }
        }
      >
        <div className="container">
          <Grid align="center">
            <Grid.Col className="burger-menu" lg={0} md={1}>
              <Menu2 onClick={toggleSidebar} strokeWidth={2} color="#fff" />
            </Grid.Col>

            <Grid.Col xl={2} lg={2} md={1}>
              <Link to="/">
                <img src={logo} alt="logo" className="logo" />
              </Link>
            </Grid.Col>

            <Grid.Col xl={6} lg={6} md={7} sm={0}>
              <Group className="nav" position="right" spacing={selectedLang === "ru"? 5 : 25}>
                <Link to="/steam" style={{ textDecoration: "none" }}>
                  <Group className="nav-link" position="right">
                    <Title
                      order={5}
                      size={matches ? 16 : selectedLang === "ru" ? 17 : 19}
                      weight={500}
                      color="#fff"
                    >
                      {t("steam")}
                    </Title>
                  </Group>
                </Link>

                <Menu
                  offset={-7}
                  trigger="hover"
                  openDelay={100}
                  closeDelay={200}
                >
                  <Menu.Target>
                    <Link style={{ textDecoration: "none" }} to="/universities">
                      <Group
                        className="nav-link"
                        noWrap
                        spacing={4}
                        position="right"
                        onMouseEnter={() => setCurrentNav("university")}
                        onMouseLeave={() => setCurrentNav("")}
                        sx={
                          currentNav === "university" && {
                            backgroundColor: "#1a5059",
                            transition: "all 1s linear",
                          }
                        }
                      >
                        <Title
                          order={5}
                          size={matches ? 16 : selectedLang === "ru" ? 17 : 19}
                          weight={500}
                          color="#fff"
                        >
                          {t("university")}
                        </Title>
                        {
                          (currentNav === "university" ? (
                            <ChevronUp
                              color="#fff"
                              size={17}
                              strokeWidth={2.5}
                              style={{ marginTop: "3px" }}
                            />
                          ) : (
                            <ChevronDown
                              color="#fff"
                              size={17}
                              strokeWidth={2.5}
                              style={{ marginTop: "4px" }}
                            />
                          ))}
                      </Group>
                    </Link>
                  </Menu.Target>

                  <Menu.Dropdown
                    onMouseEnter={() => setCurrentNav("university")}
                    onMouseLeave={() => setCurrentNav("")}
                    sx={{ border: "none" }}
                    className="university-dropdown"
                  >
                    <Stack spacing={10} align="center">
                      <Link to="/universities">
                        <Text
                          sx={{ cursor: "pointer",maxWidth:"80%",margin :"0 auto",textAlign: "center"}}
                          size={15}
                          weight={500}
                          color="#fff"

                        >
                          {t("study-abroad")}
                        </Text>
                      </Link>
                      {selectedLang === "az" && (
                        <>
                          <div
                            style={{
                              width: "100%",
                              height: "1px",
                              backgroundColor: "#CCF075",
                            }}
                          ></div>
                          <Link to="/tip">
                            <Text
                              sx={{ cursor: "pointer", textAlign: "center" }}
                              size={16}
                              weight={500}
                              color="#fff"
                            >
                              TIP - <br /> Ixtisaslaşma
                            </Text>
                          </Link>
                        </>
                      )}
                    </Stack>
                  </Menu.Dropdown>
                </Menu>

                <Link to="/communities">
                  <Group className="nav-link" position="right">
                    <Title
                      order={5}
                      size={matches ? 16 : selectedLang === "ru" ? 17 : 19}
                      weight={500}
                      color="#fff"
                    >
                      {t("community")}
                    </Title>
                  </Group>
                </Link>

                <Menu
                  offset={-7}
                  trigger="hover"
                  openDelay={100}
                  closeDelay={200}
                >
                  <Menu.Target>
                    <Group
                      className="nav-link"
                      noWrap
                      spacing={4}
                      position="right"
                      onMouseEnter={() => setCurrentNav("study")}
                      onMouseLeave={() => setCurrentNav("")}
                      sx={
                        currentNav === "study" && {
                          backgroundColor: "#1a5059",
                          transition: "all 1s linear",
                        }
                      }
                    >
                      <Title
                        order={5}
                        size={matches ? 16 : selectedLang === "ru" ? 16 : 19}
                        weight={500}
                        color="#fff"
                      >
                        {t("study")}
                      </Title>
                      {currentNav === "study" ? (
                        <ChevronUp
                          color="#CCF075"
                          size={18}
                          strokeWidth={2.5}
                          style={{ marginTop: "4px" }}
                        />
                      ) : (
                        <ChevronDown
                          color="#CCF075"
                          size={18}
                          strokeWidth={2.5}
                          style={{ marginTop: "4px" }}
                        />
                      )}
                    </Group>
                  </Menu.Target>

                  <Menu.Dropdown
                    onMouseEnter={() => setCurrentNav("study")}
                    onMouseLeave={() => setCurrentNav("")}
                    sx={{ border: "none" }}
                    className="study-dropdown"
                  >
                    <Stack spacing={10} align="center">
                      <Link to="/courses">
                        <Text
                          sx={{ cursor: "pointer" }}
                          size={16}
                          weight={500}
                          color="#fff"
                        >
                          {t("course")}
                        </Text>
                      </Link>
                      <div
                        style={{
                          width: "100%",
                          height: "1px",
                          backgroundColor: "#CCF075",
                        }}
                      ></div>
                      <Link to="/">
                        <Text
                          sx={{ cursor: "pointer" }}
                          size={16}
                          weight={500}
                          color="#fff"
                        >
                          {t("olympic")}
                        </Text>
                      </Link>
                    </Stack>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </Grid.Col>

            <Grid.Col xl={4} lg={4} md={4}>
              <Group spacing={6} align="center" position="right">
                <Group spacing={4}>
                  <Menu
                    offset={-4}
                    trigger="hover"
                    openDelay={100}
                    closeDelay={200}
                  >
                    <Menu.Target>
                      <Group
                        className="language"
                        noWrap
                        spacing={4}
                        position="right"
                        onMouseEnter={() => setCurrentNav("language")}
                        onMouseLeave={() => setCurrentNav("")}
                        sx={
                          currentNav === "language" && {
                            backgroundColor: "#1a5059",
                            transition: "all 1s linear",
                          }
                        }
                      >
                        <Language size={16} strokeWidth={2} color="#fff" />
                        <Title
                          size={matches ? 14 : 16}
                          weight={500}
                          color="#fff"
                          style={{ textTransform: "capitalize" }}
                        >
                          {selectedLang}
                        </Title>
                        {currentNav === "language" ? (
                          <ChevronUp
                            color="#fff"
                            size={matches ? 14 : 18}
                            strokeWidth={2.5}
                            style={{ marginTop: "4px" }}
                          />
                        ) : (
                          <ChevronDown
                            color="#fff"
                            size={matches ? 14 : 18}
                            strokeWidth={2.5}
                            style={{ marginTop: "4px" }}
                          />
                        )}
                      </Group>
                    </Menu.Target>

                    <Menu.Dropdown
                      onMouseEnter={() => setCurrentNav("language")}
                      onMouseLeave={() => setCurrentNav("")}
                      sx={{ border: "none" }}
                      className="language-dropdown"
                    >
                      <Stack spacing={10} align="center">
                        {langs.map((item, index) => (
                          <React.Fragment key={index}>
                            <Text
                              key={index}
                              sx={{ cursor: "pointer" }}
                              size={matches ? 14 : 16}
                              weight={500}
                              color="#fff"
                              onClick={item.click}
                            >
                              {item.iso}
                            </Text>
                            {index !== langs.length - 1 && (
                              <div
                                style={{
                                  width: "150%",
                                  height: `${
                                    item.iso === "en" ? "0.5px" : "1.5px"
                                  }`,
                                  backgroundColor: "#CCF075",
                                }}
                              ></div>
                            )}
                          </React.Fragment>
                        ))}
                      </Stack>
                    </Menu.Dropdown>
                  </Menu>

                  <div
                    style={{
                      width: "1px",
                      height: "16px",
                      backgroundColor: "#fff",
                    }}
                    className="line"
                  ></div>
                </Group>
                <Group className="sign">
                  <Link className="login" to="/login">
                    {t("login")}
                  </Link>
                  <Link className="register" to="/register">
                    {t("register")}
                  </Link>
                </Group>
              </Group>
            </Grid.Col>
          </Grid>
        </div>
      </HeaderStyle>

      {open && (
        <SideBar
          props={{
            ref,
            currentNav,
            setCurrentNav,
            toggleSidebar,
            open,
            setOpen,
            main: false,
          }}
        />
      )}
    </>
  );
};

export default NonUserHeader;

const HeaderStyle = styled.div`
  margin-bottom: ${(props) =>
    props.path === "/" ||
    props.path?.includes("universities") ||
    props.path?.includes("agents")
      ? "0px"
      : "20px"};
  top: 0px;
  background: #029199;
  z-index: 99;
  width: 100%;
  transition: all 0.5s ease;
  box-shadow: 0 4px 15px 0 (0, 0, 0, 0, 1);
  .container {
    max-width: 90%;
    margin: 0 auto;
  }
  .burger-menu {
    display: none;
  }
  .login {
    color: #fff;
    background-color: transparent;
    border-radius: 29px;
    font-size: 17px;
    font-weight: 500;
    padding: 5.5px 10px;
  }
  .register {
    background-color: #ccf075;
    border-radius: 29px;
    font-size: 17px;
    font-weight: 500;
    padding: 10px 33px;
    color: #1a5059;
  }
  .logo {
    width: 130px;
    height: 30px;
  }
  .language {
    cursor: pointer;
    padding: 8px 10px;
    border-radius: 12px;
    z-index: 97;
    transition: all 0.6s linear;
    &:hover {
      background-color: #1a5059;
      transition: all 0.6s linear;
    }
  }
  .nav-link {
    cursor: pointer;
    padding: 6px 16px;
    border-radius: 14px;
    position: relative;
    z-index: 97;
    transition: all 0.6s linear;
    &:hover {
      background-color: #1a5059;
      transition: all 0.6s linear;
    }
  }
  .mantine-Menu-dropdown {
    background-color: #246d79;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 14px 14px;
    z-index : 2 !important;
  }
  .language-dropdown {
    padding: 14px 26px;

  }
  .study-dropdown {
    padding: ${(props) =>
      props.selectedLang === "az"
        ? "14px 7px"
        : props.selectedLang === "en"
        ? "14px 32px"
        : props.selectedLang === "ru"
        ? "14px 30px"
        : "14px 16px"} ;
    height: 108px !important;
  }
  .university-dropdown {
    height: ${props => props.selectedLang !== "az" ? "80px" : "145px"};
    padding: ${(props) =>
      props.selectedLang === "az" ? "16px 23px" : "0px 0px"} ; 
      max-width: ${(props) =>
      props.selectedLang === "az" ? "100%" :   props.selectedLang === "ru" ? "148px" : "290px"};
  }

  a {
    text-decoration: none;
  }
  @media (max-width: 1350px) {
    .login {
      font-size: 15px;
      padding: 5.5px 10px;
    }
    .logo {
      width: 130px;
      height: 25px;
    }
    .register {
      font-size: 15px;
      padding: 10px 26px;
    }
    .study-dropdown {
      padding: ${(props) =>
        props.selectedLang === "az"
          ? "14px 2px"
          : props.selectedLang === "en"
          ? "14px 25px"
          : props.selectedLang === "ru"
          ? "14px 30px"
          : "14px 12px"} ;
      height: 108px !important;
    }
    .university-dropdown {
      height: ${props => props.selectedLang !== "az" ? "80px" : "145px"};
      padding: ${(props) =>
        props.selectedLang === "az" ? "16px 20px" : "0px 0px"} ; 
        max-width: ${(props) =>
        props.selectedLang === "az" ? "100%" :   props.selectedLang === "ru" ? "145px" : "122px"};
    }
  }
  @media (max-width: 990px) {
    .search,
    .sign,
    .line,
    .nav {
      display: none;
    }
    .logo {
      width: 110px;
      height: 25px;
    }
    .login {
      font-size: 15px;
      padding: 5.5px 10px;
    }
    .register {
      font-size: 15px;
      padding: 10px 33px;
    }
    .burger-menu {
      display: block;
    }
    .mantine-Grid-col.mantine-1umeq0 {
      max-width: 25%;
    }
    .mantine-Grid-col.mantine-1pe16y6 {
      max-width: 45%;

      img {
        width: 100%;
      }
    }
    .mantine-Grid-col.mantine-1n77143 {
      max-width: 0%;
      display: none;
    }
    .mantine-Grid-col.mantine-1117n7b {
      max-width: 30%;
    }
  }
`;
