import React from "react";
import { Button, Group, Stack, Text, TextInput } from "@mantine/core";
import styled from "styled-components";
import avatar from "../../../Assets/SVGs/profile/avatar.svg";
import { Check, Edit, Eye, EyeOff, Lock, X } from "tabler-icons-react";
import { ViewContainer } from ".";
import userImg from "../../../Assets/SVGs/header/user.png";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { DatePicker } from "@mantine/dates";
import { formatDate } from "../../../Helpers";
import {Helmet} from 'react-helmet-async'
import SEOConfig from "../../../Seo/SEOConfig";

const MyView = ({ t, user, publicUser, isChangedLocal, setIsChangedLocal }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState();
  const fileInputRef = useRef(null);
  const queryClient = useQueryClient();

  const [userData, setUserData] = useState({
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    number: user?.number || "",
    birthday: user?.birthday || "",
    address: user?.address || "",
  });

  const { mutate: editUser } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.patch(
        `/api/v1/edit-user/${user?.id}/`,
        data
      );
      return response.data;
    },
    onSuccess: (data) => {
      localStorage.setItem("taskooluser", JSON.stringify(data));
      setIsEditable(false);
      setIsChangedLocal(!isChangedLocal);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const { data: showEmail } = useQuery({
    queryKey: ["showEmail", publicUser ? publicUser?.id : user?.id],
    queryFn: async () => {
      const response = await axios.get(
        `/api/v1/profile-permission/${publicUser ? publicUser?.id : user?.id}/`
      );
      return response.data;
    },
  });

  const { mutate: editShowEmail } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.patch(
        `/api/v1/profile-permission/${user?.id}/`,
        data
      );
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["showEmail", user.id]);
    },
  });

  const handleShowEmail = (isBoolean) => {
    const formData = new FormData();
    formData.append("email", isBoolean ? "True" : "False");
    editShowEmail(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditIconClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("profile_img", selectedFile);
      editUser(formData);
    }
  }, [selectedFile]);

  const seoData = {
    title: publicUser
      ? `${publicUser?.first_name} ${publicUser?.last_name} - Profile`
      : `${user?.first_name} ${user?.last_name} - Profile` || 'Profile',
    description: publicUser
      ? `Explore ${publicUser?.first_name} ${publicUser?.last_name}'s profile, including their skills, activities, and professional background. Discover their achievements and expertise in detail.`
      : `Explore ${user?.first_name} ${user?.last_name}'s profile, including their skills, activities, and professional background. Discover their achievements and expertise in detail.`,
    keywords: 'profile, professional profile, skills, achievements, work experience, education, expertise',
  };

  return (
    <ViewContainer>
      {/* <SEOConfig
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
      /> */}

      <div style={{ padding: "20px 30px 30px 20px" }}>
        <Group noWrap position="apart" align="flex-start">
          <Group>
            <div style={{ position: "relative" }}>
              {!publicUser ? (
                <img
                  style={{ width: "88px", height: "88px", borderRadius: "50%" }}
                  src={user?.profile_img}
                  alt={`${user?.first_name} ${user?.last_name}`}
                />
              ) : (
                <img
                  style={{ width: "88px", height: "88px", borderRadius: "50%" }}
                  src={publicUser?.profile_img}
                  alt={`${publicUser?.first_name} ${publicUser?.last_name}`}
                />
              )}
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              {isEditable && (
                <div
                  onClick={handleEditIconClick}
                  style={{
                    background: "#fff",
                    width: "28px",
                    height: "28px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    position: "absolute",
                    bottom: "2px",
                    right: "2px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    boxShadow: "0px 4px 15px 0px #0000001A",
                  }}
                >
                  <Edit size={17} color="#FFB800" />
                </div>
              )}
            </div>
            <Stack spacing={0}>
              {isEditable ? (
                <Group>
                  <TextInput
                    size="md"
                    radius={10}
                    name="first_name"
                    value={userData?.first_name}
                    onChange={(event) => handleChange(event)}
                  />
                  <TextInput
                    size="md"
                    radius={10}
                    name="last_name"
                    value={userData?.last_name}
                    onChange={(event) => handleChange(event)}
                  />
                </Group>
              ) : (
                <Text size={20} weight={700} color="#364152">
                  {publicUser ? publicUser.first_name : userData?.first_name}{" "}
                  {publicUser ? publicUser.last_name : userData?.last_name}
                </Text>
              )}
              <Text
                mt={isEditable ? 10 : 0}
                ml={isEditable ? 5 : 1}
                size={16}
                weight={400}
                color="#979797"
              >
                {publicUser ? publicUser?.user_type.type : user?.user_type.type}
              </Text>
            </Stack>
          </Group>
          {isEditable ? (
            <Group spacing={10}>
              <div
                onClick={() => editUser(userData)}
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "100%",
                  backgroundColor: "#D6F4F6",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Check color="#029199" strokeWidth={1.75} size={16} />
              </div>
              <div
                onClick={() => {
                  setIsEditable(false);
                }}
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "100%",
                  backgroundColor: "#fff3f3",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <X color="#E41D1D" strokeWidth={1.75} size={16} />
              </div>
            </Group>
          ) : (
            !publicUser && (
              <Edit
                onClick={() => setIsEditable(true)}
                color="#364152"
                strokeWidth={1.75}
                style={{ marginTop: "10px", cursor: "pointer" }}
              />
            )
          )}
        </Group>
        <Group mt={25}>
          {publicUser && showEmail?.email ? (
            <>
              {!isEditable && (
                <DataContainer>
                  <Text size={14} weight={500} color="#979797">
                    {t("e-mail")}
                  </Text>
                  <Group spacing={40} align="center">
                    <Text size={16} weight={400} color="#364152">
                      {publicUser ? publicUser.email : user?.email}
                    </Text>
                    {!publicUser && (
                      <>
                        {!showEmail?.email ? (
                          <EyeOff
                            style={{ cursor: "pointer" }}
                            strokeWidth={1.75}
                            color="#686868"
                            size={15}
                            onClick={() => handleShowEmail(true)}
                          />
                        ) : (
                          <Eye
                            style={{ cursor: "pointer" }}
                            strokeWidth={1.75}
                            color="#686868"
                            size={15}
                            onClick={() => handleShowEmail(false)}
                          />
                        )}
                      </>
                    )}
                  </Group>
                </DataContainer>
              )}
            </>
          ) : !publicUser ? (
            <>
              {!isEditable && (
                <DataContainer>
                  <Text size={14} weight={500} color="#979797">
                    {t("e-mail")}
                  </Text>
                  <Group spacing={40} align="center">
                    <Text size={16} weight={400} color="#364152">
                      {publicUser ? publicUser.email : user?.email}
                    </Text>
                    {!publicUser && (
                      <>
                        {!showEmail?.email ? (
                          <EyeOff
                            style={{ cursor: "pointer" }}
                            strokeWidth={1.75}
                            color="#686868"
                            size={15}
                            onClick={() => handleShowEmail(true)}
                          />
                        ) : (
                          <Eye
                            style={{ cursor: "pointer" }}
                            strokeWidth={1.75}
                            color="#686868"
                            size={15}
                            onClick={() => handleShowEmail(false)}
                          />
                        )}
                      </>
                    )}
                  </Group>
                </DataContainer>
              )}
            </>
          ) : null}
          <DataContainer>
            <Text size={14} weight={500} color="#979797">
              {t("birth-of-date")}
            </Text>
            {isEditable ? (
              <Group>
                <DatePicker
                  size="md"
                  radius={10}
                  placeholder="Pick a date"
                  name="birthday"
                  defaultValue={
                    new Date(userData?.birthday ? userData.birthday : "")
                  }
                  onChange={(e) =>
                    setUserData((prevData) => ({
                      ...prevData,
                      birthday: formatDate(e),
                    }))
                  }
                />
              </Group>
            ) : (
              <Text size={16} weight={400} color="#364152">
                {publicUser ? publicUser?.birthday : userData?.birthday}
              </Text>
            )}
          </DataContainer>
          <DataContainer>
            <Text size={14} weight={500} color="#979797">
              {t("location")}
            </Text>
            {isEditable ? (
              <TextInput
                size="md"
                radius={10}
                name="address"
                value={userData?.address}
                onChange={(event) => handleChange(event)}
              />
            ) : (
              <Text size={16} weight={400} color="#364152">
                {publicUser
                  ? publicUser.address
                  : userData?.address
                  ? userData.address
                  : "N/A"}
              </Text>
            )}
          </DataContainer>
          {!publicUser && (
            <DataContainer>
              <Group spacing={60}>
                <Text size={14} weight={500} color="#979797">
                  {t("mobile")}
                </Text>
                <Group spacing={2}>
                  <Lock
                    style={{ cursor: "pointer" }}
                    size={14}
                    color="#818181"
                  />
                  <Text size={14} weight={500} color="#818181">
                    {t("me")}
                  </Text>
                </Group>
              </Group>
              {isEditable ? (
                <TextInput
                  size="md"
                  radius={10}
                  name="number"
                  type="number"
                  value={userData?.number}
                  onChange={(event) => handleChange(event)}
                />
              ) : (
                <Text size={16} weight={400} color="#364152">
                  {publicUser ? publicUser.number : userData?.number}
                </Text>
              )}
            </DataContainer>
          )}
        </Group>
      </div>
    </ViewContainer>
  );
};

export default MyView;

const Cicrle = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.width};
`;

const DataContainer = styled.div`
  border: 1px solid #e7e9ed;
  background-color: #f9fafc;
  padding: 10px 20px;
  border-radius: 17px;
`;
