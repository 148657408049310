import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import userIcon from "../../../Assets/SVGs/header/user.svg";
import { Settings } from "tabler-icons-react";

//Import images
import avatar from "../../../Assets/Images/profile/avatar.png";
import subscriptionIcon from "../../../Assets/SVGs/subscription-icon.svg";
import logoutIcon from "../../../Assets/SVGs/header/logout.svg";

function NewNavbarProfile({ props }) {
  const { user, logout, register, isTaskool } = props;
  return (
    <NewNavbarProfileStyle>
      <div className="icon-item">
        <Link to="/profile">
          <img src={userIcon} alt="" />
        </Link>

        <div className="navbar-profile-box">
          <div className="navbar-user-detail">
            <div className="navbar-user-img">
              <img src={user?.profile_img} alt="avatar" />
            </div>

            <h3>
              {user?.first_name} {user?.last_name}
            </h3>
            <h5>{user?.speciality?.content}</h5>

            <Link to="/profile">
              {" "}
              <p className="view-profile">View profile</p>
            </Link>
          </div>

          <ul className="navbar-profile-box-menu">
            <li>
              <Link to="/newpayment">
                <img src={subscriptionIcon} alt="subscribe" />
                <span>Subscribe to premium</span>
              </Link>
            </li>

            <li>
              <Link to="/settings">
                <Settings />
                <span> Account setting </span>
              </Link>
            </li>
            <li onClick={logout}>
              <a href="#">
                <img src={logoutIcon} alt="logout" />
                <span>Log out</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </NewNavbarProfileStyle>
  );
}

export default NewNavbarProfile;

const NewNavbarProfileStyle = styled.div`
  position: relative;
  &:hover {
    .navbar-profile-box {
      display: block;
    }
  }
  .navbar-profile-box {
    z-index: 999;
    display: none;
    background: #fff;
    position: absolute;
    top: 53px;
    right: -150px;

    width: 300px;
    /* height: 422px; */
    padding: 12px 20px 24px 20px;
    border-radius: 12px;
    border: 1px solid #cdd5df;
    box-shadow: 0px 8px 12px -4px #00000014;
    .navbar-user-detail {
      padding: 12px;
      text-align: center;
      margin-bottom: 12px;

      .navbar-user-img {
        margin-bottom: 8px;

        width: 72px;
        height: 72px;
        margin: 0 auto;

        border-radius: 50%;
        overflow: hidden;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      h3 {
        color: #121926;
        font-family: "Inter";
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 4px;
      }

      h5 {
        color: #697586;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }

      .view-profile {
        color: #4b5565;
        font-family: "Euclid";
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
      }
    }

    .navbar-profile-box-menu {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 12px;
      li {
        border-radius: 8px;
        a {
          display: flex;
          align-items: center;
          gap: 8px;

          font-family: "Euclid";
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          text-align: left;
          color: #121926;
          padding: 8px 16px;
        }

        &:first-child {
          a {
            color: #eaaa08;
          }
        }

        &:last-child {
          a {
            color: #d92d20;
          }
          background: #fffbfa;

          border: 1px solid #fda29b;
          margin-top: 12px;
        }
      }
    }
  }
`;
