import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import advertising from "../../../Assets/SVGs/dashboard/advertising1.svg";
import advertising2 from "../../../Assets/SVGs/dashboard/advertising2.svg";

import styled from "styled-components";
import { Text } from "@mantine/core";
const Advertising = () => {
  return (
    <AdvertisingStyle>
      <Swiper
        pagination={{ clickable: true }}
        modules={[Pagination]}
        className="mySwiper advertisingSwiper "
        style={{ paddingBottom: "45px" }}
        autoplay
      >
        <SwiperSlide>
          <div className="advertising one">
            <div style={{ padding: "20px 30px" }}>
              <Text
                style={{ lineHeight: "30px", width: "40%" }}
                size={17}
                weight={500}
                color="#f2f2f2"
              >
                <span className="text-header">Xaricdə təhsil </span>
                xəyalınızı reallaşdırmağın vaxtıdır.
              </Text>
              <Text
                mt={10}
                style={{ lineHeight: "30px", width: "40%" }}
                size={17}
                weight={500}
                color="#f2f2f2"
              >
                <span className="text-header">Taskool </span>
                ilə müraciətləriniz də, xəyallarınız da güvəndədir.
              </Text>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="advertising two">
            <div style={{ padding: "20px 30px" }}>
              <Text
                style={{ lineHeight: "30px", width: "40%" }}
                size={17}
                weight={500}
                color="#f2f2f2"
              >
                Oyun oynamağı sevirsiniz, bəs oyunu yaratmağı necə?
              </Text>
              <Text
                mt={10}
                style={{ lineHeight: "31px", width: "40%" }}
                size={18.4}
                weight={700}
                color="#f2f2f2"
              >
                <span className="text-header-two">OYUN MÜHƏNDİSLİYİ kursumuza {(" ")}</span>
                <i style={{fontSize : "24px"}} >50%</i> <i>ENDİRİM </i>
              </Text>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </AdvertisingStyle>
  );
};

export default Advertising;

const AdvertisingStyle = styled.div`
  .advertising {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 175px;
    border-radius: 24px;
  }
  .one {
    background-image: url(${advertising2});
  }
  .two {
    background-image: url(${advertising});
  }
  .text-header {
    color: #f2f2f2;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
  .text-header-two {

  }
`;
