import axios from "axios";
import { useState } from "react";
import { SyllabusStyle } from "./SyllabusStyle";
import { SyllabusInfo } from "./SyllabusInfo";
import { useQuery } from "@tanstack/react-query";
import PDFCert from "../../Certification";
import { useTranslation } from "react-i18next";

export const ProgramSyllabus = ({props}) => {

  const { type, user, entryData } = props;
  const {t} = useTranslation()

  const [ syllabus, setSyllabus ] = useState([]);
  const [ showCertificate, setShowCertificate ] = useState(false);
  const [ showEditModal, setShowEditModal ] = useState(false);

  useQuery(["syllabus", entryData?.id, showEditModal],
    async () => {
      return await axios.get(type === "course" ? `/syllabus/coursesyllabus/list/${entryData?.id}/` :  type === "steam" ? `/steam/syllabus/list/${entryData?.id}/` : `/syllabus/internshipsyllabus/list/${entryData?.id}/`).then(response => { return response.data });
    },
    {
      onSuccess: (data) => {
        setSyllabus(data);
      }
    }
  )

  return (
    <SyllabusStyle>
      {
        showCertificate &&
        <PDFCert props={{t, setShowCertificate, username:`${user.first_name} ${user.last_name}`, programName:entryData?.title, programPartners:entryData?.partners }}/>
      }
      <SyllabusInfo props={{t, type, setShowCertificate, user, entryData, syllabus, showEditModal, setShowEditModal }} />
    </SyllabusStyle>
  )
}