import axios from 'axios';
import styled from 'styled-components'
import { Button, PasswordInput } from '@mantine/core'
import { React, useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const ResetPasswordSuccess = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [passwordsUnmatched, setPasswordsUnmatched] = useState(false)
  const [password, setPassword] = useState({
    password: '',
    passwordConfirm: ''
  });

  const { uuid, token } = useParams();

  const [ passwordChangedSuccess, setPasswordChangedSuccess ] = useState(false);

  useEffect(() => {
    if(passwordChangedSuccess){
      location.pathname = '/login'
    }
  }, [passwordChangedSuccess]);

  useEffect(() => {
    setPasswordsUnmatched(false)
    return () => {
      setPasswordsUnmatched(false)
    }
  }, [password.password, password.passwordConfirm]);

  const resetPassword = async (e) => {
    e.preventDefault();

    if (password.password !== password.passwordConfirm) {
      setPasswordsUnmatched(true);
      return;
    } else {
      try {
        const response = await axios.patch('password-reset-complete/', {
          password: password.password,
          uidb64: uuid,
          token: token,
        });

        if (response.status === 200) {
          setPasswordChangedSuccess(true)
          setTimeout(() => {
            navigate('/login', { replace: true });
          }, 2000);
        }
      } catch (error) {

      }
    }
  }

  return (
    <>

        <ResetStyle>
          <form onSubmit={(e) => resetPassword(e)}>
            {passwordChangedSuccess && <p className='success'>Password changed successfully!</p>}
            <PasswordInput size="md" toggleTabIndex={0} className='password' required label="Password" placeholder="Please make me strong"
              onChange={(e) => setPassword({...password, password: e.target.value})}
              />
            <PasswordInput size="md" toggleTabIndex={0} className='password' required label="Password Confirm" placeholder="Passwords should match"
              onChange={(e) => setPassword({...password, passwordConfirm: e.target.value})}
              />
            {passwordsUnmatched && <p className='noMatch'>Passwords do not match!</p>}
            <Button size="md" type="submit" >Submit</Button>
          </form>
        </ResetStyle>

    </>
  )
}

export default ResetPasswordSuccess

const ResetStyle = styled.div`
  margin: 8rem auto;
  width: 350px;
  .noMatch{
    color: red;
    text-align: center;
  }
  .success{
    color: green;
    text-align: center;
  }
  *{
    font-size: 15px;
  }
  form{
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
  }
  .password{
    button{
      margin-right: .5rem;
    }
    svg{
    }
  }
  button{
    font-weight: 500;
    width: 100%;
    background-color: var(--yellow);
    color: #000;
  }
`