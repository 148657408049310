import React from "react";
import {
  Button,
  Grid,
  PasswordInput,
  Select,
  Space,
  TextInput,
  Textarea,
} from "@mantine/core";
import {
  showNotification,
} from "@mantine/notifications";
import { X } from "tabler-icons-react";
const StepOne = ({ props }) => {
  const {
    t,
    matchesXl,
    matchesMd,
    countries,
    cities,
    selectedCountryId,
    setBusinessValue,
    setSelectedCountryId,
    businessControl,
    businessErrors,
    isValid,
    Controller,
    handleBusineesSubmit,
    onBusineesSubmit,
    watch,
    setActiveStep
  } = props;

  const formValues = watch();
  const handleContinueClick = () =>{
    if(isValid) {
      setActiveStep('two')

    }else{
      showNotification({
        icon: <X />,
        title: "Warning!",
        message: `${t('all_required')}`,
        color: "yellow",
      });
    }
  }


  return (
    <form onSubmit={handleBusineesSubmit(onBusineesSubmit)}>
      <Grid>
        <Grid.Col span={matchesXl ? 6 : matchesMd ? 6 : 12}>
          <Controller
            name="first_name"
            control={businessControl}
            rules={{
              required: t('name_required'),
              minLength: { value: 2, message: t('name_min_length') }
            }}
            render={({ field }) => (
              <TextInput
                {...field}
                radius="md"
                size="md"
                label={t('name-of-company')}
                withAsterisk
                error={businessErrors.first_name && t(businessErrors.first_name.message)}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={matchesXl ? 6 : matchesMd ? 6 : 12}>
          <Controller
            name="number"
            control={businessControl}
            rules={{
              required: t('contact_number_required'),
              pattern: { value: /^\d{10}$/, message: t('contact_number_pattern') }
            }}
            render={({ field }) => (
              <TextInput
                {...field}
                radius="md"
                size="md"
                label={t('contact-number')}
                withAsterisk
                error={businessErrors.number && t(businessErrors.number.message)}
              />
            )}
          />
        </Grid.Col>
      </Grid>
      <Space my={6} />
      <Grid>
        <Grid.Col span={matchesXl ? 6 : matchesMd ? 6 : 12}>
          <Controller
            name="email"
            control={businessControl}
            rules={{
              required: t('email_required'),
              pattern: { value: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/, message: t('email_pattern') }
            }}
            render={({ field }) => (
              <TextInput
                {...field}
                radius="md"
                size="md"
                label={t('e-mail-address')}
                withAsterisk
                error={businessErrors.email && t(businessErrors.email.message)}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={matchesXl ? 6 : matchesMd ? 6 : 12}>
          <Controller
            name="website"
            control={businessControl}
            rules={{ required: t('website_required') }}
            render={({ field }) => (
              <TextInput
                {...field}
                radius="md"
                size="md"
                label={t('website')}
                withAsterisk
                error={businessErrors.website && t(businessErrors.website.message)}
              />
            )}
          />
        </Grid.Col>
      </Grid>
      <Space my={6} />
      <Grid>
        <Grid.Col span={matchesXl ? 6 : matchesMd ? 6 : 12}>
          <Controller
            name="country"
            control={businessControl}
            rules={{ required: t('country_required') }}
            render={({ field }) => (
              <Select
                {...field}
                radius="md"
                size="md"
                withAsterisk
                label={t('country')}
                searchable
                onChange={(e) => {
                  setSelectedCountryId(e);
                  setBusinessValue("country", e);
                }}
                data={
                  countries
                    ? countries.map((country) => ({
                        value: country.id,
                        label: country.name,
                      }))
                    : []
                }
                error={businessErrors.country && t(businessErrors.country.message)}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={matchesXl ? 6 : matchesMd ? 6 : 12}>
          <Controller
            name="city"
            control={businessControl}
            rules={{ required: t('city_required') }}
            render={({ field }) => (
              <Select
                {...field}
                radius="md"
                size="md"
                withAsterisk
                label={t('city')}
                searchable
                disabled={!selectedCountryId}
                onChange={(e) => setBusinessValue("city", e)}
                data={
                  cities
                    ? cities.map((city) => ({
                        value: city.id,
                        label: city.name,
                      }))
                    : []
                }
                error={businessErrors.city && t(businessErrors.city.message)}
              />
            )}
          />
        </Grid.Col>
      </Grid>
      <Space my={6} />
      <Grid>
        <Grid.Col span={matchesXl ? 6 : matchesMd ? 6 : 12}>
          <Controller
            name="address"
            control={businessControl}
            rules={{ required: t('location_required') }}
            render={({ field }) => (
              <TextInput
                {...field}
                radius="md"
                size="md"
                label={t('location')}
                withAsterisk
                error={businessErrors.address && t(businessErrors.address.message)}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={matchesXl ? 6 : matchesMd ? 6 : 12}>
          <Controller
            name="password"
            control={businessControl}
            rules={{
              required: t('password_required'),
              minLength: { value: 6, message: t('password_min_length') }
            }}
            render={({ field }) => (
              <PasswordInput
                {...field}
                radius="md"
                size="md"
                label={t('password')}
                withAsterisk
                error={businessErrors.password && t(businessErrors.password.message)}
              />
            )}
          />
        </Grid.Col>
      </Grid>
      <Space my={12} />
      <Controller
        name="services"
        control={businessControl}
        rules={{ required: t('service_required') }}
        render={({ field }) => (
          <Textarea
            {...field}
            label={t('services')}
            withAsterisk
            autosize
            minRows={4}
            radius="md"
            size="md"
            error={businessErrors.services && t(businessErrors.services.message)}
          />
        )}
      />
      <Space my={30} />
      <Button
        onClick={handleContinueClick}
        style={{ background: "#FE6027" }}
        fullWidth
        size="lg"
        radius="md"
      >
        {t('continue')}
      </Button>
      <Space my={20} />
    </form>
  );
};

export default StepOne;
