import React, { useContext, useEffect } from "react";
import Footer from "../Components/Layout/Footer";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import NavbarContent from "../Components/NewDashboard/Navbar";
import AsideContent from "../Components/NewDashboard/Aside";
import { AppContext } from "../Helpers/Context";
import UserHeader from "../Components/NewDashboard/Header";
import NonUserHeader from "../Components/Layout/Header";
import LottieLoading from "../Components/LottieLoading";
import { useIsFetching } from "@tanstack/react-query";
import { useLoading } from "../Helpers/Contexts/LoadingContext";
import { useTranslation } from "react-i18next";

export const MainLayout = ({ props }) => {
  const { isSidebar, isAside } = props;
  let { user } = useContext(AppContext);
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();


  
  return (
    <LayoutContainer pathname={pathname}>
      {user && (pathname !== "/tip/videos" && pathname !== "/tip/vision" ) ? <UserHeader /> : !user && (pathname !== "/tip/videos" && pathname !== "/tip/vision") ? <NonUserHeader /> : null}

      <main>
        <Container
          isAside={isAside}
          isSidebar={isSidebar}
          className="container"
        >
          <Flex>
            {isSidebar && (
              <SideBarContainer isSidebar={isSidebar}>
                <NavbarContent t={t} />
              </SideBarContainer>
            )}
            <MainContainer isAside={isAside} isSidebar={isSidebar}>
              <Outlet />
            </MainContainer>
            {isAside && (
              <AsideContainer isAside={isAside}>
                <AsideContent t={t} />
              </AsideContainer>
            )}
          </Flex>
        </Container>
      </main>

{(pathname !== "/tip/videos" && pathname !== "/tip/vision") &&      <footer>
        <Footer />
      </footer>}
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
  background-color: ${(props) =>
    props.pathname === "/home"
      ? "#f2f2f2"
      : props.pathname === "/profile"
      ? "#eff3f4"
      :props.pathname.includes("/courses") ?
      "#f6f6f6"
      : "#fff"};
`;
const Container = styled.div`
  max-width: ${(props) => (props.isAside || props.isSidebar ? "95%" : "100%")};
  margin: 0 auto;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SideBarContainer = styled.div`
  width: ${(props) => (props.isSidebar ? "19.5%" : "0%")};
  height: 600px;
  position: sticky;
  top: 100px;
  @media screen and (max-width: 1376px) {
    width: ${(props) => (props.isSidebar ? "21.5%" : "0%")};
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
`;

const AsideContainer = styled.div`
  width: ${(props) => (props.isAside ? "19.5%" : "0%")};
  position: sticky;
  height: 800px;
  top: -170px;
  @media screen and (max-width: 1376px) {
    width: ${(props) => (props.isAside ? "21.5%" : "0%")};
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
`;
const MainContainer = styled.div`
  width: ${(props) =>
    props.isAside && props.isSidebar
      ? "53%"
      : props.isSidebar && !props.isAside
      ? "75%"
      : "100%"};

  @media screen and (max-width: 1376px) {
    width: ${(props) =>
      props.isAside && props.isSidebar
        ? "50%"
        : props.isSidebar && !props.isAside
        ? "70%"
        : "100%"};
  }
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`;
