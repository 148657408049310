import { React, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNotifications, showNotification } from "@mantine/notifications";
import { Button, TextInput, Input, FileInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";

//Import icons
import educationIcon from "../../../Assets/SVGs/profile/education.svg";
import addIcon from "../../../Assets/SVGs/profile/plus.svg";
import editIcon from "../../../Assets/SVGs/profile/edit.svg";
import deleteIcon from "../../../Assets/SVGs/profile/minus.svg";

//Import sound
import succesSound from "./sound/success.mp3";
import errorSound from "./sound/error.mp3";

function Certificates({ props }) {
  const { t, i18n } = useTranslation();
  const { user, isTaskool } = props;
  const [manageShow, setManageShow] = useState(false);

  let [certArray, setCertArray] = useState([]);
  let [loading, setLoading] = useState(false);
  let [currentEditing, setCurrentEditing] = useState(0);
  const [fileValue, setFileValue] = useState(null);

  const notifications = useNotifications();

  async function fetchData() {
    try {
      const fetchedCerts = await axios.get("api/certificate/");
      fetchedCerts.data.sort((a, b) => a.id - b.id);
      setCertArray(
        Object.keys(fetchedCerts.data).map(function (key) {
          return fetchedCerts.data[key];
        })
      );
    } catch (error) {}
  }

  // Fetches data when the page is loaded
  useEffect(() => {
    fetchData();
    return () => {
      setCertArray([]);
    };
  }, []);

  // Adds new skill
  function addInput() {
    // If there is no current editing, set current editing to the last index

    let certIds = certArray.map(function (cert) {
      return cert.id;
    });

    if (certIds.includes(currentEditing.id)) {
      setCurrentEditing(0);
      setCertArray((certs) => {
        const newCertificate = [...certs];
        newCertificate.push({
          id: certArray.length ? certArray[certArray?.length - 1]?.id + 1 : 2,
        });
        setCurrentEditing({
          id: certArray.length ? certArray[certArray?.length - 1]?.id + 1 : 2,
        });
        return newCertificate;
      });
    }

    if (currentEditing === 0) {
      // setCurrentEditing(0)
      setCertArray((certs) => {
        const newCertificate = [...certs];
        newCertificate.push({
          id: certArray.length ? certArray[certArray?.length - 1]?.id + 1 : 2,
        });
        setCurrentEditing({
          id: certArray.length ? certArray[certArray?.length - 1]?.id + 1 : 2,
        });
        return newCertificate;
      });
    }
  }

  function handleEdit(index) {
    setCurrentEditing(certArray[index]);
    setCertArray(certArray.filter((cert) => cert.certificate !== undefined));
  }

  // Deletes skill
  async function handleDelete(e, index) {
    setCurrentEditing(0);
    setCertArray(certArray.filter((cert) => cert.job_position !== undefined));

    try {
      // deletes skill from database
      const deletedCert = await axios.delete(
        `api/certificate/${certArray[index].id}/`
      );
      // "🚀 | handleDelete | deletedCert", deletedCert)

      if (deletedCert.status === 204) {
        notificationSound("success");
        showNotification({
          title: t("certificates-nt3"),
          message: t("certificates-ntm3"),
          color: "white",
        });
      }

      // reFetches certs and sorts them in ascending order after deleting one
      const fetchedCerts = await axios.get("api/certificate/");
      fetchedCerts.data.sort((a, b) => a.id - b.id);

      setCertArray(
        Object.keys(fetchedCerts.data).map(function (key) {
          return fetchedCerts.data[key];
        })
      );
    } catch (error) {}
  }

  // currentEditing);

  async function updateCert(e, index) {
    setLoading(true);

    try {
      let formX = new FormData();
      formX.append("id", currentEditing.id);
      formX.append("user", user.id);
      currentEditing.title && formX.append("title", currentEditing.title);
      currentEditing.certificate.name &&
        formX.append("certificate", currentEditing.certificate);

      const response = await axios.patch(
        `api/certificate/${currentEditing.id}/`,
        formX
      );

      if (response.status === 200) {
        fetchData();
        setLoading(false);
        notificationSound("success");
        showNotification({
          title: t("certificates-nt2"),
          message: t("certificates-ntm2"),
          color: "white",
        });

        setCurrentEditing(0);
      }
    } catch (error) {
      if (error?.response?.status !== 200) {
        postCert();
      }
    }
  }

  async function postCert() {
    setLoading(true);

    try {
      let formX = new FormData();
      formX.append("id", currentEditing.id);
      formX.append("user", user.id);
      formX.append("title", currentEditing.title);
      formX.append("certificate", currentEditing.certificate);

      const response = await axios.post("api/create-certificate/", formX);

      if (response.status === 201) {
        setLoading(false);
        setCurrentEditing(0);
        fetchData();
        notificationSound("success");
        showNotification({
          title: t("certificates-nt1"),
          message: t("certificates-ntm1"),
          color: "green",
        });
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (fileValue) {
      setCurrentEditing({
        ...currentEditing,
        certificate: fileValue,
      });
    }
  }, [fileValue]);

  const handleChangeFile = () => {
    setFileValue(fileValue);
  };
  // Cancel editing
  function handleCancel() {
    setCurrentEditing(0);
    setCertArray(certArray.filter((cert) => cert.certificate !== undefined));
  }

  const notificationSound = (type) => {
    if (type == "success") {
      var audio = new Audio(succesSound);
    } else {
      var audio = new Audio(errorSound);
    }

    audio.play();
  };

  return (
    <CertificatesStyle>
      <div className="certificates-header">
        <h2>{t("certificates-header")}</h2>

        <div className="header-icons">
          <div onClick={() => addInput(true)} className="header-icon-item">
            <img src={addIcon} alt="add-icon" />
          </div>

          <div className="header-icon-item">
            <img
              onClick={() => setManageShow(!manageShow)}
              src={editIcon}
              alt="edit-icon"
            />
          </div>
        </div>
      </div>

      <div className="certificates-box">
        <form onSubmit={(e) => e.preventDefault()}>
          {certArray.map((certification, index) => {
            return currentEditing?.id === certification?.id ? (
              <div
                key={index}
                className="editing insideWrapper"
                id={certification?.id}
              >
                <div>
                  <TextInput
                    size="md"
                    type="text"
                    placeholder={t("certificates-name")}
                    label={t("certificates-name")}
                    className="certificationLink"
                    value={currentEditing?.title}
                    onChange={(e) =>
                      setCurrentEditing({
                        ...currentEditing,
                        title: e.target.value,
                      })
                    }
                  />
                  <br />

   <FileInput
                      size="lg"
                      value={fileValue}
                      radius="md"
                      label={t("certificates-photo")}
                      placeholder="Şəkil yükləyin"
                      onChange={setFileValue}
                    />
                  {/* <Input.Wrapper label={t("certificates-photo")}>
                    <br />
                    <input
                      type="file"
                      onChange={(e) =>
                        setCurrentEditing({
                          ...currentEditing,
                          certificate: e.target.files[0],
                        })
                      }
                    />

                 
                  </Input.Wrapper> */}
                  <br />

                  {currentEditing?.certificate?.name && (
                    <div>
                      <img
                        src={URL.createObjectURL(currentEditing?.certificate)}
                        alt="certificate"
                      />
                      <br />
                      <br />
                    </div>
                  )}

                  {currentEditing?.certificate &&
                    !currentEditing?.certificate?.name && (
                      <div>
                        <img
                          src={currentEditing?.certificate}
                          alt="certificate"
                        />
                        <br />
                        <br />
                      </div>
                    )}
                </div>

                <div className="buttons">
                  <Button
                    className="btn btn-submit"
                    loading={loading}
                    variant="filled"
                    color="teal"
                    type="button"
                    onClick={(e) => updateCert(e, index)}
                  >
                    {t("certificates-bSend")}
                  </Button>
                  <Button
                    className="btn btn-cancel"
                    variant="filled"
                    color="gray"
                    type="button"
                    onClick={() => handleCancel()}
                  >
                    {t("certificates-bCancel")}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="certificate-item">
                <div
                  key={index}
                  id={certification.id}
                  className="certificate-item-left"
                >
                  <div className="certificate-img">
                    <img src={certification?.certificate} alt="" />
                    <h5>
                      {certification?.title?.length > 80
                        ? certification?.title?.substring(0, 80) + ".."
                        : certification?.title}
                    </h5>
                  </div>
                </div>
                <div className="item-manage">
                  {manageShow && (
                    <>
                      <div onClick={(e) => handleDelete(e, index)}>
                        <img src={deleteIcon} alt="" />
                      </div>

                      <div onClick={() => handleEdit(index)}>
                        <img src={editIcon} alt="" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </form>
      </div>
    </CertificatesStyle>
  );
}

export default Certificates;

const CertificatesStyle = styled.div`
  background: #fff;
  padding: 40px 32px;
  border-radius: 12px;
  margin-bottom: 45px;
  .certificates-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    h2 {
      color: #202939;
      font-family: "Euclid";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 140% */
    }

    .header-icons {
      display: flex;
      gap: 12px;
      .header-icon-item {
        padding: 12px;
        border-radius: 8px;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          background: #eef2f6;
        }
      }
    }
  }

  .certificates-box {
    .certificate-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0px;
      border-bottom: 1px solid #cdd5df;
      .certificate-item-left {
        display: flex;
        align-items: center;
        gap: 14px;
        .certificate-img {
          img {
            border-radius: 8px;
            width: 300px;
            height: 200px;
            object-fit: cover;
          }
        }

        h5 {
          margin: 0px;
          color: #121926;
          font-family: "Euclid";
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 100% */
          /* display: flex;
        align-items: center; */
          margin-bottom: 4px;
          margin-top: 7px;
        }
      }

      .item-manage {
        display: flex;
        gap: 12px;
        div {
          padding: 12px;
        }
      }
    }
  }

  //OLD Styles

  button {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: clamp(1rem, 2vw, 1.05rem);
  }
  .box img {
    object-fit: cover;
    border-radius: 0.25rem;
    width: 240px;
    height: 140px;
  }
  h2,
  h3 {
    font-weight: 500;
  }
  .insideWrapper {
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
    hr {
      border: none;
      border-bottom: 1px solid #ddd;
      margin-top: 1rem;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .addItem {
      background: none;
      font-size: 17px;
      color: #2f80ed;
      font-weight: 400;
      border: none;
      cursor: pointer;
    }
    svg {
      color: #000;
    }
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .manageEntry {
      display: flex;
      button {
        display: flex;
        border: none;
        font-weight: 500;
      }
    }
    .info {
      display: flex;
      align-items: flex-start;
      width: 100%;
      align-items: center;
      h2 {
        font-size: clamp(1rem, 2vw, 1.1rem);
        margin: 0 auto;
        margin-left: 1rem;
        margin-right: 1rem;
      }
      p {
        margin: 0.5rem 0;
      }
      ins {
        margin: 0;
        text-decoration: none;
        font-size: 14px;
        color: #666;
      }
    }
  }
  .editing {
    padding: 1rem 1.25rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    align-items: center;
    .buttons {
      /* display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem; */
      display: flex;
      gap: 20px;
      button {
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 768px) {
    img {
      width: max(140px, 30%);
      height: 70px;
      order: 2;
    }
    .insideWrapper {
      width: 100%;
      margin: 0 auto;
    }
    .title {
      width: 100%;
      margin: 0 auto;
    }
    .box {
      margin: 0;
      .manageEntry {
        display: flex;
        margin-right: 0.5rem;
      }
      .info {
        display: flex;
        align-items: flex-start;
        width: 100%;
        align-items: flex-start;
        justify-content: left;
        flex-direction: column;
        h2 {
          font-size: clamp(1.1rem, 2vw, 1.1rem);
          margin: 0;
          margin-bottom: 1rem;
          order: 1;
        }
      }
    }
    .editing {
      margin: 0 1rem;
    }
  }
  @media screen and (max-width: 335px) {
    .cert {
      .box {
        display: block;
        .manageEntry {
        }
      }
    }
  }
`;
