import { Card, Image, Text, Badge, Button, Group, Grid } from "@mantine/core";
import { Link as LinkIcon } from "tabler-icons-react";
import styled from "styled-components";

export const EventCard = ({ event }) => {
  // const { id, title, image, date, location, price  } = event;

  // Mock data
  const id = 1;
  const title = "Hello world my name is something like booom yeees";
  const image =
    "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png";
  const date = "01.01.2024 / 09:30";
  const location = "Caspian Plaza";
  const price = 25;

  return (
    <CardStyle>
      <Card
        key={id}
        className="card"
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
      >
        <Card.Section>
          <Image src={image} height={160} alt="Norway" />
        </Card.Section>

        <Group justify="space-between" mt="md" mb="xs">
          <Text fw={500}>
            {title?.length > 48 ? title.substring(0, 48) + "..." : title}
          </Text>
        </Group>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="columns">
            <p
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "10px",
              }}
            >
              Date:
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "10px",
              }}
            >
              Location:
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "10px",
              }}
            >
              Price:
            </p>
          </div>
          <div className="columns-values">
            <p
              style={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "10px",
              }}
            >
              {date}
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "10px",
              }}
            >
              {location?.length > 18
                ? location.substring(0, 18) + "..."
                : location}
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "10px",
              }}
            >
              {price} AZN
            </p>
          </div>
        </div>
      </Card>
    </CardStyle>
  );
};

const CardStyle = styled.div`
  .card {
    transition: transform 150ms ease, box-shadow 150ms ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.01);
      box-shadow: 20px;
    }
  }
`;
