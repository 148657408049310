import React from "react";
import { Button, Group, Stack, Table, Text } from "@mantine/core";
import styled from "styled-components";
import {} from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { formatDateToMonthDayYear } from "../../Helpers";

const YourPlan = ({t, plans, handleUpgrade }) => {

  return (
    <PlanContainer>
      {plans && plans.length > 0 && (
        <Text mb={20} size={24} weight={500} color="#202939">
          {t('your-plan')}
        </Text>
      )}

      <Stack>
        {plans?.map((plan, index) => (
          <PackageContainer>
            <Text mb={15} size={20} weight={500} color="#364152">
              {t('subs-info')}
            </Text>

            <Group position="apart" align="center">
              <div className="package-info">
                <Table verticalSpacing={10} horizontalSpacing={0}>
                  <thead>
                    <tr>
                      <th>{t('package-name')}</th>
                      <th>{t('payment')}</th>
                      <th>{t('next-payment')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={plan.borderRadius}>
                      <td>{t('packages')} {plan.package.title}</td>
                      <td>
                        {plan.package.price}₼/{plan.package.duration} {t('months')}
                      </td>
                      <td>
                        {plan?.is_upgraded || plan.is_expired
                          ? t('expired')
                          : formatDateToMonthDayYear(plan.expired_date)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              {plan.is_upgraded === false &&  (
                <div className="upgrade-btn">
                  <Button
                    onClick={handleUpgrade}
                    styles={() => ({
                      root: {
                        height: "52px",
                        padding: "0 40px",
                        background: "#FE6027",
                        color: "#ffffff",
                        fontSize: "16px",
                        fontWeight: 500,
                        borderRadius: "10px",
                        "&:hover": {
                          background: "#FF6633",
                        },
                      },
                    })}
                  >
                    {t('upgrade-subscription')}
                  </Button>
                </div>
              )}
            </Group>
          </PackageContainer>
        ))}
      </Stack>
    </PlanContainer>
  );
};

export default YourPlan;

const PlanContainer = styled.div`
  max-width: 90%;
  margin: 0 auto;
`;

const PackageContainer = styled.div`
  border: 1px solid #cdcdcd;
  border-radius: 13px;
  padding: 20px 40px;
  .package-info {
    width: 60%;
  }
  .upgrade-btn {
    width: 30%;
    display: flex;
    justify-content: flex-end;
  }
  tbody {
    tr {
      td {
        color: #364152;
        font-weight: 400;
        font-size: 17px;
      }
    }
  }
  thead {
    tr {
      th {
        color: #979797;
        font-weight: 500;
        font-size: 17px;
      }
    }
  }
`;
