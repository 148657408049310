import React from "react";
import styled from "styled-components";
import { Card, Overlay, Button, Text } from "@mantine/core";
import { Link } from "react-router-dom";

// Styled components with props
const StyledCard = styled(Card)`
  height: 220px;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns: 1 for image, 1 for content */
  background-size: cover;
  background-position: center;
  background-image: ${(props) => `url(${props.backgroundImage})`};

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Display as a single column on small screens */
    height: auto;
  }
`;

const OverlayWrapper = styled(Overlay)`
  background-color: transparent;
  background-image: linear-gradient(105deg, #000 20%, #312f2f 50%, #ccc 100%);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  z-index: 1;

  @media (max-width: 768px) {
    text-align: center; /* Center content on small screens */
  }
`;

const ActionButton = styled(Button)`
  margin-top: auto; /* Push the button to the bottom */
`;

const HighlightContainer = styled.span`
  position: relative;
  display: inline-block;
`;

const Highlight = styled.span`
  position: absolute;
  content: " ";
  display: block;
  height: 90%;
  width: 100%;
  margin-left: -3px;
  margin-right: -3px;
  background: #fde14c;
  transform: rotate(-2deg);
  top: -1px;
  left: -1px;
  border-radius: 20% 25% 20% 24%;
  padding: 10px 3px 3px 10px;
`;

const Title = styled(Text)`
  color: #000;
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 500;
  position: relative;
  text-align: center;
  z-index: 2;
`;

const ImageContainer = styled.div`
  position: relative;
  width: "100%";
  height: "100px";
`;

const Image = styled.img`
  max-width: 70%;
  border: 2px solid #fff;
  border-radius: 8px;
  margin: 0 auto; /* Center the image */
`;

export function CoursePreview({ backgroundImage, title }) {
  return (
    <StyledCard radius="md" backgroundImage={backgroundImage}>
      <OverlayWrapper opacity={0.55} zIndex={0} />

      <ImageContainer>
        {/* Left side: Image */}
        <Image src={backgroundImage} alt="Background" />
      </ImageContainer>

      {/* Right side: Title, Description, and Button */}
      <Content>
        <HighlightContainer>
          <Highlight />
          <Title size="xl">{title}</Title>
        </HighlightContainer>

        <ActionButton
          component={Link}
          to={`/courses/58`}
          variant="white"
          color="dark"
          size="xs"
        >
          View course
        </ActionButton>
      </Content>
    </StyledCard>
  );
}
