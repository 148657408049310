import styled from "styled-components";
import { Skills } from "./Skills";
import { Education } from "./Education";
import { Experience } from "./Experience";
import { Certifications } from "./Certificates";
import { Tasks } from "./Tasks";
import { ProfileIntro } from "./ProfileIntro";
import { Internship } from "./Internship";
import { NotificationsProvider } from "@mantine/notifications";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import axios from "axios";
import { SideBarNav } from "../../../Components/SideBarNav";
import { RecommendedCourses } from "../../../Components/RecommendedCourses";

const StudentProfile = ({ props }) => {
  const { id: profileId } = useParams();

  const { isTaskool } = props;

  const { data } = useQuery(["user", profileId], async () => {
    return await axios.get(`/public-user/${profileId}/`);
  });

  return (
    <NotificationsProvider>
      <ProfileStyle>
        <div className="wrapperAll">
          <div className="profileParts">
            <ProfileIntro props={{ userData: data?.data?.User[0] }} />
            {/* {
              !isTaskool &&
              <Internship props={{ userInternships: data?.data?.UserInternships[0], isTaskool }}/>
            } */}
            <Tasks props={{ isTaskool, user: data?.data?.User[0] }} />
            <Skills props={{ skillsData: data?.data?.UserSkill, isTaskool }} />
            <ExperienceAndEducation
              props={{
                experienceData: data?.data?.Experience,
                educationData: data?.data?.Education,
                isTaskool,
              }}
            />
            <Certifications
              props={{
                certificationData: data?.data?.UserCertificate,
                isTaskool,
              }}
            />
          </div>
          <div className="profileParts">
            <SideBarNav props={{ isTaskool }} />
            <RecommendedCourses props={{ isTaskool }} />
          </div>
        </div>
      </ProfileStyle>
    </NotificationsProvider>
  );
};

export default StudentProfile;

const ProfileStyle = styled.div`
  display: flex;
  background-color: #f5f5f5;
  .wrapperAll {
    width: 90%;
    margin: 1.5rem auto;
    background-color: #f5f5f5;
    display: grid;
    grid-template-columns: 2.1fr 1fr;
    grid-gap: 1.5rem;
    .profileParts {
      display: flex;
      flex-direction: column;
      grid-gap: 1.5rem;
    }
  }
  @media (max-width: 768px) {
    display: block;
    padding: 0;
    .wrapperAll {
      width: 100%;
      margin: 0 auto;
      display: block;
      background-color: #f2f2f2;
      .profileParts {
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
      }
    }
  }
`;

const ExperienceAndEducation = ({ props }) => {
  const { experienceData, educationData, isTaskool } = props;

  return (
    <E2Style>
      <Experience props={{ experienceData, isTaskool }} />
      <Education props={{ educationData, isTaskool }} />
    </E2Style>
  );
};

const E2Style = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  @media (max-width: 768px) {
    border-radius: 0;
  }
`;
