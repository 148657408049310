import styled from "styled-components";
import defaultImage from "../../../Assets/Images/defaultImage.webp";
import { Spoiler } from "@mantine/core";
import { ChevronDown, ChevronUp } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

//Public profile

export const ProfileIntro = ({ props }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const { userData } = props;

  return (
    <IntroStyle>
      <img
        className="cover"
        src="https://image.freepik.com/free-vector/abstract-grey-white-tech-geometric-corporate-design-background-eps-10-vector-illustration_73729-217.jpg"
        alt=""
      />
      <div className="wrapperIntro">
        <div>
          <img
            src={
              userData?.profile_img ===
              "https://task-store-taskilled.s3.eu-central-1.amazonaws.com/media/download.png"
                ? defaultImage
                : userData?.profile_img
            }
            alt=""
          />
        </div>
        <div>
          <h2>{`${userData?.first_name}  ${userData?.last_name}`}</h2>
        </div>
        <h3>{userData?.speciality?.content}</h3>
      </div>
      <Spoiler
        className="bio"
        maxHeight={120}
        showLabel={<ChevronDown size={30} />}
        hideLabel={<ChevronUp size={30} />}
        style={{ marginTop: userData?.bio?.length > 160 ? "-.75rem" : "" }}
      >
        <p>{userData?.bio}</p>
      </Spoiler>
    </IntroStyle>
  );
};

const IntroStyle = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  padding-bottom: 2rem;
  .bio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg {
      color: #000;
      width: 100%;
      background-color: #f8f8f8;
      border-radius: 0.25rem;
      margin-top: 0.5rem;
    }
  }
  .cover {
    width: 100%;
    height: 205px;
    object-fit: cover;
  }
  .bio {
    width: 93%;
    margin: 0 auto;
    p {
      width: 95%;
      padding-bottom: 1rem;
    }
  }
  .copy {
    align-items: center;
    width: 93%;
    margin: 0 auto;
    margin-top: 1rem;
    ins {
      text-decoration: none;
      color: #555;
      font-weight: lighter;
    }
    .wrapper {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      border: 1px solid #eee;
      border-radius: 0.25rem;
      max-width: min-content;
      button {
        width: max-content;
        padding: 0.5rem 1rem;
        border: none;
        transition: all 1s;
        cursor: pointer;
      }
      .copied {
        background-color: #afedc5;
      }
      p {
        margin: 0 1rem;
      }
    }
  }
  .wrapperIntro {
    width: 93%;
    margin: 0 auto;
    button {
      border: 1px solid #eee;
      padding: 0.5rem 1rem;
      border-radius: 0.2rem;
      cursor: pointer;
      &:hover {
        background-color: #ddd;
      }
    }
    div {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        border-bottom: 2px solid #000;
        text-decoration: none;
        color: #000;
        margin-top: -1.5rem;
        margin-right: 0.5rem;
        position: relative;
        ins {
          margin-right: 0.5rem;
        }
      }
      img {
        object-fit: cover;
        width: 6rem;
        border-radius: 12%;
        height: 6rem;
        transform: translateY(-65%) translateX(10%);
        border: 2px solid #fff;
      }
    }
    p {
      max-width: 80%;
      b {
        font-weight: 500;
      }
      i {
        color: #888;
      }
      button {
        cursor: pointer;
        background: none;
        border: none;
        color: #741a1a;
        font-size: 16px;
        line-height: 0.8;
        border-bottom: 2px solid #741a1a;
        margin: 0;
        padding: 0;
      }
    }
    h2 {
      margin-top: -2rem;
      font-weight: 500;
      font-size: 22px;
    }
    h3 {
      margin-top: -0.8rem;
      font-weight: 400;
      font-size: 17px;
      color: #8c8c8c;
    }
  }
  @media screen and (max-width: 768px) {
    border-radius: 0;
    .bio {
      width: 92%;
      p {
        width: 100%;
      }
    }
    .cover {
      height: 150px;
    }
    .copy {
      width: 92%;
    }
    .wrapperIntro {
      width: 92%;
    }
  }
`;
