import React, { useContext } from "react";
import { AppContext } from "../Helpers/Context";
import { Outlet, Navigate } from "react-router-dom";
import LottieLoading from "../Components/LottieLoading";

export const PrivateRoutes = () => {
  const { user, isAuthenticating } = useContext(AppContext);

  if (isAuthenticating) {
    return (<LottieLoading />);
  }

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export const SignRoutes = () => {
  const { user, isAuthenticating } = useContext(AppContext);

  if (isAuthenticating) {
    return (<LottieLoading />);
  }

  return !user ? <Outlet /> : <Navigate to="/" />;
};

export const AuthRoutes = ({ children }) => {
  const { user, isAuthenticating } = useContext(AppContext);

  if (isAuthenticating) {
    return (<LottieLoading />);
  }

  return !user ? children : <Navigate to="/home" />;
};
