  import {
  Button,
  Divider,
  Image,
  Tabs,
  Text,
  TextInput,
  Menu,
  Avatar,
  Group,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import {
  AdjustmentsHorizontal,
  ChevronDownLeft,
  ChevronLeft,
  ChevronRight,
  GridDots,
  Plus,
  Search,
  User,
  Users,
} from "tabler-icons-react";
import { CreateCommunity } from "./CreateCommunity";
import { AppContext } from "../../Helpers/Context";
import { UserCardImage } from "./CommunityCard";
import { DotsVertical } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper";

export function FilterCommunities({t}) {
  const sm = useMediaQuery("(max-width: 400px)");
  const md = useMediaQuery("(min-width: 401px)");
  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "1rem",
        borderRadius: ".75rem",
        border: "1px solid #ddd",
        marginBottom: ".5rem",
      }}
    >
      <Text weight={500} mb={10}>
        {t('find-groups')}
      </Text>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          flexWrap: `${sm ? "wrap" : md ? "no-wrap" : ""}`,
        }}
      >
        <TextInput
          style={{ width: "100%" }}
          radius={"md"}
          placeholder={t('search-for-groups')}
          icon={<Search />}
          rightSection={
            <Button size="xs" px={5} variant="default" style={{ border: "0" }}>
              <AdjustmentsHorizontal />
            </Button>
          }
          iconWidth={45}
          rightSectionWidth={45}
        />
        <Button size="sm" radius={"md"} color="gray.8">
          {t('search')}
        </Button>
      </div>
    </div>
  );
}

SwiperCore.use([Navigation, Pagination]);

export const CommunitiesInFeed = ({t}) => {
  let [createCommunityModalOpen, setCreateCommunityModalOpen] = useState(false);
  
  const { data: communities, isLoading: loadingOne } = useQuery(
    ["all communities"],
    () => axios.get("/blog/communities/").then((res) => res.data)
  );

  const { user } = useContext(AppContext);

  const { data: userCommunities } = useQuery(["my communities"], () =>
    axios.get(`/blog/communitywithuserid/${user?.id}/`).then((res) => res.data)
  );

  const { data: joinedCommunities } = useQuery(["joined communities"], () =>
    axios
      .get(`/blog/communitiesmemberwithuserid/${user?.id}/`)
      .then((res) => res.data)
  );

  const { data: specificCommunityOne, isLoading: specificLoadingOne } =
    useQuery(["specific community one"], () =>
      axios.get("/blog/communitywithid/10").then((res) => res.data[0])
    );

  const { data: specificCommunityTwo, isLoading: specificLoadingTwo } =
    useQuery(["specific community two"], () =>
      axios.get("/blog/communitywithid/11").then((res) => res.data[0])
    );
  const sizeXs = useMediaQuery("(max-width: 576px)");
  const sizeSm = useMediaQuery("(max-width: 1350px)");
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="FilterCommunities">
      <CreateCommunity
        props={{ createCommunityModalOpen, setCreateCommunityModalOpen }}
      />

      {/* <FilterCommunities /> */}

      <div className="FilterCommunities-main">
        <div
          style={{
            padding: "1rem 0 0.6rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Tabs
              style={{ width: "100%", borderBottom: 0 }}
              radius={"md"}
              color="gray"
              defaultValue="all"
              variant="default"
            >
              <Tabs.List
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  overflowX: "scroll",
                  overflowY: "hidden",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", gap: ".5rem" }}>
                  <Tabs.Tab value="all">
                    <Group noWrap spacing={8}>
                      <Text>{t('all-groups')}</Text>
                      <Text>|</Text>
                      <Text span>{communities?.length}</Text>
                    </Group>
                  </Tabs.Tab>
                  <Tabs.Tab value="joined">
                    <Group noWrap spacing={8}>
                      <Text>{t('joined-groups')}</Text>
                      <Text>|</Text>
                      <Text>{joinedCommunities?.length}</Text>
                    </Group>
                  </Tabs.Tab>
                  <Tabs.Tab value="mine">
                    <Group noWrap spacing={8}>
                      <Text>{t('managed-groups')}</Text>
                      <Text>|</Text>
                      <Text>{userCommunities?.length}</Text>
                    </Group>
                  </Tabs.Tab>
                </div>

                <div>
                  <Menu trigger="hover">
                    <Menu.Target>
                      <Button px={12} variant="subtle" color={"gray.8"}>
                        <DotsVertical color="#495057" />
                      </Button>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item>
                        <Button
                          radius={"md"}
                          color="gray"
                          onClick={() => setCreateCommunityModalOpen(true)}
                        >
                          <Text weight={400}>{t('create-group')}</Text>
                        </Button>
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </div>
              </Tabs.List>

              <Divider my={sizeXs ? 5 : 7} color="transparent" />

              {!sizeXs && (
                <Tabs.Panel value="all">
                  <div style={{ position: "relative" }}>
                    <Swiper
                      modules={[Navigation]}
                      slidesPerView={sizeSm ? 2 : 3}
                      spaceBetween={17}
                      className="mySwiper"
                      navigation={{
                        nextEl: ".swiper-right",
                        prevEl: ".swiper-left",
                      }}
                    >
                      <SwiperSlide>
                        <UserCardImage
                        t={t}
                          key={specificCommunityOne?.id}
                          loading={specificLoadingOne}
                          name={specificCommunityOne?.name}
                          description={specificCommunityOne?.description}
                          image={specificCommunityOne?.image}
                          coverImage={specificCommunityOne?.cover_image}
                          membersCount={specificCommunityOne?.members?.length}
                          link={`/groups/${specificCommunityOne?.id}`}
                          component={Link}
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <UserCardImage
                        t={t}
                          key={specificCommunityTwo?.id}
                          loading={specificLoadingTwo}
                          name={specificCommunityTwo?.name}
                          description={specificCommunityTwo?.description}
                          image={specificCommunityTwo?.image}
                          coverImage={specificCommunityTwo?.cover_image}
                          membersCount={specificCommunityTwo?.members?.length}
                          link={`/groups/${specificCommunityTwo?.id}`}
                          component={Link}
                        />
                      </SwiperSlide>
                      {communities?.length > 0 &&
                        communities
                          ?.filter((com) => com?.id !== 10 && com?.id !== 11) // Filter out communities with IDs 10 and 11
                          .map((com) => {
                            return (
                              <SwiperSlide key={com?.id}>
                                <UserCardImage
                                t={t}
                                  name={com?.name}
                                  description={com?.description}
                                  image={com?.image}
                                  coverImage={com?.cover_image}
                                  membersCount={com?.members?.length}
                                  link={`/groups/${com?.id}`}
                                  component={Link}
                                />
                              </SwiperSlide>
                            );
                          })}
                    </Swiper>

                    {/* Custom navigation buttons */}
                    <Button
                      className="swiper-left"
                      styles={() => ({
                        root: {
                          background: "transparent",

                          "&:hover": {
                            background: "transparent",
                          },
                          "&:disabled": {
                            background: "transparent",
                          },
                        },
                      })}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "-40px",
                        zIndex: "10",
                        cursor: "pointer",
                        padding: "10px",
                        borderRadius: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <ChevronLeft color="#364152" />
                    </Button>
                    <Button
                      className="swiper-right"
                      styles={() => ({
                        root: {
                          background: "transparent",

                          "&:hover": {
                            background: "transparent",
                          },
                          "&:disabled": {
                            background: "transparent",
                          },
                        },
                      })}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "-40px",
                        zIndex: "10",
                        cursor: "pointer",
                        padding: "10px",
                        borderRadius: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <ChevronRight color="#364152" />
                    </Button>
                  </div>
                </Tabs.Panel>
              )}

              {!sizeXs && (
                <Tabs.Panel value="joined">
                  <div style={{ position: "relative" }}>
                    <Swiper
                      modules={[Navigation]}
                      slidesPerView={sizeSm ? 2 : 3}
                      spaceBetween={17}
                      className="mySwiper"
                      navigation={{
                        nextEl: ".swiper-right",
                        prevEl: ".swiper-left",
                      }}
                    >
                      {joinedCommunities?.length > 0 &&
                        joinedCommunities.map((com) => {
                          return (
                            <SwiperSlide key={com?.id}>
                              <UserCardImage
                              t={t}
                                key={com?.id}
                                name={com?.name}
                                description={com?.description}
                                image={com?.image}
                                coverImage={com?.cover_image}
                                membersCount={com?.members?.length}
                                link={`/groups/${com?.id}`}
                                component={Link}
                              />
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                    <Button
                      className="swiper-left"
                      styles={() => ({
                        root: {
                          background: "transparent",

                          "&:hover": {
                            background: "transparent",
                          },
                          "&:disabled": {
                            background: "transparent",
                          },
                        },
                      })}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "-40px",
                        zIndex: "10",
                        cursor: "pointer",
                        padding: "10px",
                        borderRadius: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <ChevronLeft color="#364152" />
                    </Button>
                    <Button
                      className="swiper-right"
                      styles={() => ({
                        root: {
                          background: "transparent",

                          "&:hover": {
                            background: "transparent",
                          },
                          "&:disabled": {
                            background: "transparent",
                          },
                        },
                      })}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "-40px",
                        zIndex: "10",
                        cursor: "pointer",
                        padding: "10px",
                        borderRadius: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <ChevronRight color="#364152" />
                    </Button>
                  </div>
                </Tabs.Panel>
              )}

              {!sizeXs && (
                <Tabs.Panel value="mine">
                  <div style={{ position: "relative" }}>
                    <Swiper
                      modules={[Navigation]}
                      slidesPerView={sizeSm ? 2 : 3}
                      spaceBetween={17}
                      className="mySwiper"
                      navigation={{
                        nextEl: ".swiper-right",
                        prevEl: ".swiper-left",
                      }}
                    >
                      {userCommunities?.length > 0 &&
                        userCommunities.map((com) => {
                          return (
                            <SwiperSlide key={com?.id}>
                              <UserCardImage
                              t={t}
                                key={com?.id}
                                name={com?.name}
                                description={com?.description}
                                image={com?.image}
                                coverImage={com?.cover_image}
                                membersCount={com?.members?.length}
                                link={`/groups/${com?.id}`}
                                component={Link}
                              />
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>

                    <Button
                      className="swiper-left"
                      styles={() => ({
                        root: {
                          background: "transparent",

                          "&:hover": {
                            background: "transparent",
                          },
                          "&:disabled": {
                            background: "transparent",
                          },
                        },
                      })}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "-40px",
                        zIndex: "10",
                        cursor: "pointer",
                        padding: "10px",
                        borderRadius: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <ChevronLeft color="#364152" />
                    </Button>
                    <Button
                      className="swiper-right"
                      styles={() => ({
                        root: {
                          background: "transparent",

                          "&:hover": {
                            background: "transparent",
                          },
                          "&:disabled": {
                            background: "transparent",
                          },
                        },
                      })}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "-40px",
                        zIndex: "10",
                        cursor: "pointer",
                        padding: "10px",
                        borderRadius: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <ChevronRight color="#364152" />
                    </Button>
                  </div>
                </Tabs.Panel>
              )}

              {sizeXs && (
                <Tabs.Panel value="all">
                  <div
                    className="tabsScroll"
                    style={{ marginBottom: "-.5rem" }}
                  >
                    <div className="scrollMenu">
                      <div style={{ width: "200px", display: "inline-block" }}>
                        <UserCardImage
                        t={t}
                          key={specificCommunityOne?.id}
                          loading={specificLoadingOne}
                          name={specificCommunityOne?.name}
                          description={specificCommunityOne?.description}
                          image={specificCommunityOne?.image}
                          coverImage={specificCommunityOne?.cover_image}
                          membersCount={specificCommunityOne?.members?.length}
                          link={`/groups/${specificCommunityOne?.id}`}
                          component={Link}
                        />
                      </div>
                      <div style={{ width: "200px", display: "inline-block" }}>
                        <UserCardImage
                        t={t}
                          key={specificCommunityTwo?.id}
                          loading={specificLoadingOne}
                          name={specificCommunityTwo?.name}
                          description={specificCommunityTwo?.description}
                          image={specificCommunityTwo?.image}
                          coverImage={specificCommunityTwo?.cover_image}
                          membersCount={specificCommunityTwo?.members?.length}
                          link={`/groups/${specificCommunityTwo?.id}`}
                          component={Link}
                        />
                      </div>
                      {communities?.length > 0 &&
                        communities
                          ?.filter((com) => com?.id !== 10 && com?.id !== 11) // Filter out communities with IDs 10 and 11
                          .map((com) => {
                            return (
                              <div
                                  key={com?.id}
                                style={{
                                  width: "200px",
                                  display: "inline-block",
                                }}
                              >
                                <UserCardImage
                                t={t}
                                  name={com?.name}
                                  description={com?.description}
                                  image={com?.image}
                                  coverImage={com?.cover_image}
                                  membersCount={com?.members?.length}
                                  link={`/groups/${com?.id}`}
                                  component={Link}
                                />
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </Tabs.Panel>
              )}

              {sizeXs && (
                <Tabs.Panel value="joined">
                  <div
                    className="tabsScroll"
                    style={{ marginBottom: "-.5rem" }}
                  >
                    <div className="scrollMenu">
                      {joinedCommunities?.length > 0 &&
                        joinedCommunities.map((com) => {
                          return (
                            <div
                              key={com?.id}
                              style={{
                                width: "200px",
                                display: "inline-block",
                              }}
                            >
                              <UserCardImage
                              t={t}
                                name={com?.name}
                                description={com?.description}
                                image={com?.image}
                                coverImage={com?.cover_image}
                                membersCount={com?.members?.length}
                                link={`/groups/${com?.id}`}
                                component={Link}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Tabs.Panel>
              )}

              {sizeXs && (
                <Tabs.Panel value="mine">
                  <div
                    className="tabsScroll"
                    style={{ marginBottom: "-.5rem" }}
                  >
                    <div className="scrollMenu">
                      {userCommunities?.length > 0 &&
                        userCommunities.map((com) => {
                          return (
                            <div
                            key={com?.id}

                              style={{
                                width: "200px",
                                display: "inline-block",
                              }}
                            >
                              <UserCardImage
                              t={t}
                                name={com?.name}
                                description={com?.description}
                                image={com?.image}
                                coverImage={com?.cover_image}
                                membersCount={com?.members?.length}
                                link={`/groups/${com?.id}`}
                                component={Link}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Tabs.Panel>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CommunitiesInFeedMobile = () => {
  const { data: communities, isLoading: loadingOne } = useQuery(
    ["all communities"],
    () => axios.get("/blog/communities/").then((res) => res.data)
  );

  const { data: specificCommunityOne, isLoading: specificLoadingOne } =
    useQuery(["specific community one"], () =>
      axios.get("/blog/communitywithid/10").then((res) => res.data[0])
    );

  const { data: specificCommunityTwo, isLoading: specificLoadingTwo } =
    useQuery(["specific community two"], () =>
      axios.get("/blog/communitywithid/11").then((res) => res.data[0])
    );

  return (
    <>
      <div className="mobileCards">
        <div style={{ marginBottom: "-.5rem" }}>
          <Text mb={10}>Groups</Text>
          <div
            className="scrollMenu"
            style={{
              overflow: "auto",
              whiteSpace: "nowrap",
              display: "flex",
              gap: "5px",
              width: "100%",
            }}
          >
            <Link
              to={`/groups/${specificCommunityOne?.id}`}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="mobileCard" key={specificCommunityOne?.id}>
                <div
                  className="imageCard"
                  style={{
                    width: "70px",
                    height: "70px",
                    border: "2px solid #1245a1",
                    borderRadius: "8px",
                    margin: "0 auto",
                  }}
                >
                  <Avatar
                    src={specificCommunityOne?.image}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div style={{ textAlign: "center" }} className="textCard">
                  <h5>{specificCommunityOne?.name.slice(0, 12) + "..."}</h5>
                </div>
              </div>
            </Link>
            <Link
              to={`/groups/${specificCommunityTwo?.id}`}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="mobileCard" key={specificCommunityTwo?.id}>
                <div
                  className="imageCard"
                  style={{
                    width: "70px",
                    height: "70px",
                    border: "2px solid #1245a1",
                    borderRadius: "8px",
                    margin: "0 auto",
                  }}
                >
                  <Avatar
                    src={specificCommunityTwo?.image}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div style={{ textAlign: "center" }} className="textCard">
                  <h5>{specificCommunityTwo?.name.slice(0, 12) + "..."}</h5>
                </div>
              </div>
            </Link>
            {communities?.length > 0 &&
              communities
                ?.filter(
                  (community) => community?.id !== 10 && community?.id !== 11
                )
                .map((community) => (
                  <Link
                    to={`/groups/${community?.id}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div className="mobileCard" key={community?.id}>
                      <div
                        className="imageCard"
                        style={{
                          width: "70px",
                          height: "70px",
                          border: "2px solid #1245a1",
                          borderRadius: "8px",
                          margin: "0 auto",
                        }}
                      >
                        <Avatar
                          src={community?.image}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      <div style={{ textAlign: "center" }} className="textCard">
                        <h5>{community?.name.slice(0, 12) + "..."}</h5>
                      </div>
                    </div>
                  </Link>
                ))}
          </div>
        </div>
      </div>
    </>
  );
};
