import styled from "styled-components";
import { AboutProgram } from "./AboutProgram";
import { ProgramSpecs } from "./ProgramSpecs";
import { CurrentLocation } from "../CurrentLocation";
import { Payment } from "../Payment/_Payment";
import { useTranslation } from "react-i18next";
import { Button, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../../Helpers/Context";
import axios from "../../Helpers/Axios";
import { useQuery } from "@tanstack/react-query";
import { PaymentStyle } from "../Payment/PaymentStyle";

export function ProgramInfo({ props }) {
  const { entryData, user, type } = props;
  const { setPostURL } = useContext(AppContext);
  const { t } = useTranslation();
  const isLoggedin = user?.id ? true : false;
  const [purchased, setPurchased] = useState(false);

  let links =
    type === "internship"
      ? {
          successfuly_paid: `/internship/successfuly_paid_intern/${entryData?.id}/`,
          section_list: `/api/section-detail/${entryData?.id}/`,
        }
      : type === "course"
      ? {
          successfuly_paid: `/api/successfuly_paid_course/${entryData?.id}/`,
          section_list: `/api/section-detail/${entryData?.id}/`,
        }
      : type === "steam"
      ? {
          successfuly_paid: `/steam/successfuly_paid_steam/${entryData?.id}/`,
          section_list: `/api/section-detail/${entryData?.id}/`,
        }
      : type === "hackathon" && {
          successfuly_paid: `/api/successfuly_paid_course/${entryData?.id}/`,
          section_list: `/api/section-detail/${entryData?.id}/`,
        };

  useQuery(
    ["user owns", entryData?.id],
    async () => {
      return await axios.get(links.successfuly_paid).then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        if (data.successfuly_paid === true || data.id) {
          setPurchased(true);
        }
      },
      enabled: !!entryData?.id,
    }
  );
  const { data: sectionList, isLoading } = useQuery(
    ["sections list", entryData?.id],
    async () => {
      return await axios.get(links.section_list).then((res) => res.data);
    }
  );
  const allActive = sectionList?.every((section) => section.section_active);
  const allInactive = sectionList?.every((section) => !section.section_active);
  const someActive = sectionList?.some((section) => section.section_active);
  const courseIsFree = entryData?.price <= 0;
  console.log('sectionList', sectionList);

  return (
    <ProgramInfoStyle>
      <div className="wrapper">
        <AboutProgram props={{ t, entryData }} />
        <div className="right">
          <ProgramSpecs props={{ t, entryData }} />

          {!isLoggedin ? (
            <Button
              fullWidth
              size="lg"
              component={Link}
              className="loggedOutPayButton"
              to="/login"
            >
              Log in to purchase
            </Button>
          ) :  allActive ? (
            <Button
              component={Link}
              to={`/${type === "course" ? "course" : ""}/${entryData?.id}`}
              styles={() => ({
                root: {
                  backgroundColor: "#029199",
                  color: "#fff",
                  height: "45px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  borderRadius: "10px",
                  width: "100%",
                  "&:hover": { backgroundColor: "#029199", color: "#fff" },
                },
              })}
            >
              {t("enter")}
            </Button>
          ) : allInactive ? (
            <Button
              component={Link}
              to={`/${type === "course" ? "courses" : ""}/${
                entryData?.id
              }/payment`}
              styles={() => ({
                root: {
                  backgroundColor: "#029199",
                  color: "#fff",
                  height: "45px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  borderRadius: "10px",
                  width: "100%",
                  "&:hover": { backgroundColor: "#029199", color: "#fff" },
                },
              })}
            >
              {t("apply")}
            </Button>
          ) : someActive ? (
            <>
              <Button
                component={Link}
                to={`/${type === "course" ? "course" : ""}/${entryData?.id}`}
                styles={() => ({
                  root: {
                    backgroundColor: "#029199",
                    color: "#fff",
                    height: "45px",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    borderRadius: "10px",
                    width: "100%",
                    "&:hover": { backgroundColor: "#029199", color: "#fff" },
                  },
                })}
              >
                {t("enter")}
              </Button>
              <Button
                component={Link}
                to={`/${type === "course" ? "courses" : ""}/${
                  entryData?.id
                }/payment`}
                styles={() => ({
                  root: {
                    marginTop: "7px",
                    backgroundColor: "#029199",
                    color: "#fff",
                    height: "45px",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    borderRadius: "10px",
                    width: "100%",
                    "&:hover": { backgroundColor: "#029199", color: "#fff" },
                  },
                })}
              >
                Buy other Sections
              </Button>
            </>
          ) : null}

          {/* <Payment props={{t, user, entryData, type }} /> */}
        </div>
      </div>
    </ProgramInfoStyle>
  );
}

const ProgramInfoStyle = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 0.5rem auto 5rem auto;
  .wrapper {
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    grid-gap: 1.5rem;
    margin: 0.5rem 0;
  }
  @media screen and (max-width: 1024px) {
    width: 90%;
    margin: 0 auto;
    padding: 0rem;
    .wrapper {
      grid-template-columns: 1fr;
      margin-top: 0rem;
      margin-bottom: 3rem;
    }
  }
`;
