import React, { useContext, useState } from "react";
import {} from "@mantine/core";
import styled from "styled-components";
import MyView from "./MyView";
import AboutView from "./About";
import ActivityView from "./ActivityView";
import EducationView from "./EducationView";
import StudyInterest from "./StudyInterest";
import ProfessionSkill from "./ProfessionSkill";
import SikllEndorsement from "./SkillEndorsement";
import SoftSkills from "./SoftSkills";
import Language from "./Language";
import Certificate from "./Certificate";
import ShareProfile from "../Aside/ShareProfile";
import Subscription from "../Aside/Subscription";
import JoinedPrograms from "../Aside/JoinedPrograms";
import { AppContext } from "../../../Helpers/Context";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../Helpers/Contexts/LanguageContext";
import SEOConfig from "../../../Seo/SEOConfig";

const Profile = ({ type }) => {
  const { user, isChangedLocal, setIsChangedLocal } = useContext(AppContext);
  const { id: guid } = useParams();
  const link = guid ? `/api/userskill?guid=${guid}` : `/api/userskill/`;
  const { t, i18n } = useTranslation();

  const sm = useMediaQuery("(max-width: 768px)");
  const {language : localLanguage} = useLanguage()
  console.log('localLanguage',localLanguage);

  const {
    data: publicUser,
  } = useQuery(
    ["user", guid],
    async () => {
      const response = await axios.get(`/api/v1/user-data-with-guid/${guid}/`);
      return response.data;
    },
    {
      enabled: !!guid, // Query will not execute until the `guid` exists
    }
  );

  const [selectedSkillHeaderId, setSelectedSkillHeaderId] = useState(null);
  const { data: skillDatas } = useQuery({
    queryKey: ["skillDatas", selectedSkillHeaderId],
    queryFn: async () => {
      return await axios
        .get(`/api/skillsbyheaderid/${selectedSkillHeaderId}/`)
        .then((res) => res.data);
    },
    enabled: !!selectedSkillHeaderId,
  });
  const queryClient = useQueryClient();

  const { mutate: createUserSkill } = useMutation({
    mutationFn: async (data) => {
      return await axios.post(`/api/create-userskill/`, data);
    },
    onSuccess: async (data, variables, context) => {
      queryClient.invalidateQueries(["userSkills"]);
    },
    onError: (error, variables, context) => {
      console.log("error", error);
    },
  });

  const { mutate: deleteUserSkill } = useMutation({
    mutationFn: async (id) => {
      return await axios.delete(`/api/userskill/${id}/`);
    },
    onSuccess: async (id, variables, context) => {
      queryClient.invalidateQueries(["userSkills"]);
      console.log("success", id);
    },
    onError: (error, variables, context) => {
      console.log("error", error);
    },
  });

  const { data: userSkills } = useQuery({
    queryKey: guid ? ["userSkills", guid] : ["userSkills"],
    queryFn: async () => {
      return await axios.get(link).then((res) => res.data);
    },
    enabled: !!link, // Ensure the query runs when `link` changes
  });

  const filterSkillsByHeader = (header) => {
    return userSkills?.filter((skill) => skill.skill.header === header);
  };

  const profileUrl = `https://taskool.com/profile/${user?.guid}`;



  const profileSEO = {
    title: `${publicUser?.first_name || user?.first_name} ${publicUser?.last_name || user?.last_name} - Profile`,
    description: `${publicUser?.first_name || user?.first_name} ${publicUser?.last_name || user?.last_name}'s profile showcases their professional journey, skills, accomplishments, and educational background. Explore their experience in detail, including their achievements, job roles, and educational qualifications. Learn about their expertise and how they contribute to their field.`,
    url: `${publicUser ? `https://taskool.com/profile/${publicUser?.guid}` : 'https://taskool.com/profile/'}`,
    image: `${publicUser?.profile_img || user?.profile_img}` || 'https://taskool.com/images/defaultProfileImage.jpg',
    keywords: 'profile, professional profile, skills, achievements, work experience, education, expertise, professional background, career summary',
  };
  
  return (
    <StyledProfile guid={guid}>
       {/* <SEOConfig
        title={profileSEO.title}
        description={profileSEO.description}
        url={profileSEO.url}
        image={profileSEO.image}
        type='profile'
        keywords={profileSEO.keywords}
      /> */}
      <ProfileWrapper>
        <ProfileContainer guid={guid}>
          <MyView
            t={t}
            user={user}
            publicUser={publicUser}
            isChangedLocal={isChangedLocal}
            setIsChangedLocal={setIsChangedLocal}
          />
          {!guid && sm && (
            <AsideContainer>
              <ShareProfile t={t} url={profileUrl} />
              <Subscription t={t} />
              <JoinedPrograms t={t} />
            </AsideContainer>
          )}
          <AboutView t={t} user={user} publicUser={publicUser} />
          <ActivityView t={t} user={user} publicUser={publicUser} />
          <EducationView t={t} user={user} publicUser={publicUser} />
          <StudyInterest
            t={t}
            userSkills={filterSkillsByHeader(4)}
            user={user}
            selectDatas={skillDatas}
            handleSkillHeaderId={setSelectedSkillHeaderId}
            handleCreateUserSkill={createUserSkill}
            handleDeleteUserSkill={deleteUserSkill}
            publicUser={publicUser}
          />
          <SikllEndorsement
            t={t}
            user={user}
            selectDatas={skillDatas}
            handleSkillHeaderId={setSelectedSkillHeaderId}
            handleCreateUserSkill={createUserSkill}
            userSkills={filterSkillsByHeader(5)}
            handleDeleteUserSkill={deleteUserSkill}
            publicUser={publicUser}
          />
          <ProfessionSkill
            t={t}
            userSkills={filterSkillsByHeader(2)}
            user={user}
            selectDatas={skillDatas}
            handleSkillHeaderId={setSelectedSkillHeaderId}
            handleCreateUserSkill={createUserSkill}
            handleDeleteUserSkill={deleteUserSkill}
            publicUser={publicUser}
          />
          <SoftSkills
            t={t}
            userSkills={filterSkillsByHeader(1)}
            user={user}
            selectDatas={skillDatas}
            handleSkillHeaderId={setSelectedSkillHeaderId}
            handleCreateUserSkill={createUserSkill}
            handleDeleteUserSkill={deleteUserSkill}
            publicUser={publicUser}
          />
          <Language
            t={t}
            userSkills={filterSkillsByHeader(3)}
            user={user}
            selectDatas={skillDatas}
            handleSkillHeaderId={setSelectedSkillHeaderId}
            handleCreateUserSkill={createUserSkill}
            handleDeleteUserSkill={deleteUserSkill}
            publicUser={publicUser}
          />
          <Certificate t={t} user={user} publicUser={publicUser} />
        </ProfileContainer>
        {!guid && !sm && (
          <AsideContainer>
            <ShareProfile t={t} url={profileUrl} />
            <Subscription t={t} />
            <JoinedPrograms t={t} />
          </AsideContainer>
        )}
      </ProfileWrapper>
    </StyledProfile>
  );
};

export default Profile;

export const ViewContainer = styled.div`
  box-shadow: 0px 1px 10px 0px #0000001a;
  width: 100%;
  border-radius: 11px;
  background-color: #fff;
`;

const StyledProfile = styled.div`
  max-width: ${(props) => (props.guid ? "90%" : "95%")};
  margin: 0 auto;
`;

const ProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    margin-bottom: 5px;
  }
`;

const ProfileContainer = styled.div`
  width: ${(props) => (props.guid ? "100%" : "77%")};
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0 0 40px 0;

  //   accordion custom styles
  .mantine-1h6pkea.mantine-Accordion-chevron {
    display: none !important;
  }
  .mantine-Accordion-item.mantine-v4lv9f {
    border-bottom: 0px !important;
  }
  .mantine-UnstyledButton-root.mantine-Accordion-control.mantine-21wjnm {
    border-bottom: 1px solid #e1e1e1 !important;
    padding-left: 0px !important;
    margin-bottom: 8px;
  }
  .mantine-UnstyledButton-root.mantine-Accordion-control.mantine-21wjnm[aria-expanded="false"] {
    border-bottom: 0px !important;
    padding: 10px 0px !important;
    margin-bottom: 0px;

    .mantine-Text-root.mantine-o3i2x9 {
      font-weight: 500 !important;
      font-size: 16px !important;
      color: #979797 !important;
    }
  }
  .mantine-ukbbnm.mantine-Accordion-content {
    padding-left: 0px !important;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const AsideContainer = styled.div`
  width: 20%;
  height: 160vh;
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    top: 0;
    height: 100%;
  }
`;
