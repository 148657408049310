import React, { useRef, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useLoading } from "../Helpers/Contexts/LoadingContext";
import { useIsFetching } from "@tanstack/react-query";
import RouteLogic from "./RouteLogic";

const AppContent = () => {


  return (
    <Routes>
      <Route path="*" element={<RouteLogic />} />
    </Routes>
  );
};

export default AppContent;
