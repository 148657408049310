import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

export const Quiz = ({ props }) => {

  const { questions, chosenOption, setChosenOption, currentQuestion, optionNotChosen, setOptionNotChosen } = props

  function chooseOption({index, id, text, video}){
    setOptionNotChosen(false)
    setChosenOption({ index, id, text, video, confirmed:false })
  }

  return (
    <QuizStyle>
      <AnimatePresence mode="wait">
        {
          questions?.map((q, index) => {
            return(
              currentQuestion.id === q.id &&
              <motion.div
                key={q.id}
                initial={{ opacity:0 }}
                animate={{ opacity:1 }}
                exit={{ opacity:0 }}
                transition={{ duration: .5 }}
              >
                <div key={q.id} className="question">
                  <div className="questionDiv">
                    <h2 className="index">{(q.order) + "."}</h2>
                    <h2>{q.question}</h2>
                  </div>
                  <div className="options">
                    {
                      chosenOption.confirmed === true ?
                      <AnimatePresence mode="wait">
                        <motion.div
                          key={chosenOption.text}
                          initial={{ opacity:0 }}
                          animate={{ opacity:1 }}
                          exit={{ opacity:0 }}
                          transition={{ duration: .3 }}
                          className="explanation"
                        >
                          <div style={{padding:"56.25% 0 0 0", position:"relative"}}><iframe src={chosenOption?.video} allow="autoplay; fullscreen; picture-in-picture" style={{ border: "0px", position:"absolute", top:"0", left:"0", width:"100%", height:"100%"}} title="Video #1"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                          <div className="text">
                            {/* <h1>Şərh</h1> */}
                            <p>{chosenOption.text}</p>
                          </div>
                        </motion.div>
                      </AnimatePresence>
                      :
                      q.optionnonspeciality_set &&
                      [...q.optionnonspeciality_set].reverse().map(v => {
                        return(
                          <button key={v.id} className={chosenOption.id === v.id ? "option choosenOption" : "option"} onClick={() => chooseOption({ index:index + 1, id:v.id, text:v.answer, video:v.video })}>
                            <p className="label">{v.option.toUpperCase()}</p>
                            <p className="optionText">{v.explanation}</p>
                          </button>
                        )}
                      )
                    }
                    {
                      optionNotChosen === true &&
                      <AnimatePresence mode="wait">
                        <motion.div
                          key={chosenOption.text}
                          initial={{ opacity:0 }}
                          animate={{ opacity:1 }}
                          exit={{ opacity:0 }}
                          transition={{ duration: .3 }}
                          className="comment"
                        >
                          <h3 style={{ color:"#E67700" }}>Diqqət!</h3>
                          <p>Zəhmət olmasa bir variant seçin</p>
                        </motion.div>
                      </AnimatePresence>
                    }
                  </div>
                </div>
              </motion.div>
            )
          })
        }
      </AnimatePresence>
    </QuizStyle>
  )
}

const QuizStyle = styled.div`
  h1,h2,h3,h4,h5,h6,p,button,a{
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  .question{
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    .questionDiv{
      display: flex;
      grid-gap: 1rem;
      .index{
        color:#ffffff88;
      }
    }
    .options{
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      width: 100%;
      .comment{
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;
        padding: 1rem;
        border: 1px solid #ADB5BD;
        border-radius: 0.25rem;
        p{
          font-size: 17px;
        }
      }
      .explanation{
        display: grid;
        grid-gap: 2rem;
        /* align-items: center; */
        grid-template-columns: 1fr .5fr;
        padding: 1rem;
        border: 1px solid #ADB5BD;
        border-radius: 0.25rem;
        .text{
          max-height: 500px;
          overflow-x: hidden;
          overflow-y: scroll;
        }
        p{
          font-size: 24px;
          color: #ccc;
        }
        div{
          display: flex;
          flex-direction: column;
          grid-gap: 1rem;
        }
      }
      .option{
        width: 100%;
        display: flex;
        align-items: center;
        border: none;
        border-radius: 0.25rem;
        padding: 0;
        cursor: pointer;
        background-color: #343A40;
        color: #fff;
        border: 1px solid transparent;
        transition: all 0.15s;
        .label{
          margin: 1rem;
          font-size: 17px;
          color:#ddd;
        }
        .optionText{
          margin: .75rem .75rem .75rem 0;
          font-size: 17px;
        }
        &:hover{
          background-color: #495057;
        }
      }
      .choosenOption{
        background-color: #495057;
        border: 1px solid #ADB5BD;
      }
    }
  }
  @media (max-width: 800px) {
    .question{
      .options{
        .explanation{
          grid-template-columns: 1fr;
          border: 0;
          padding: 0;
          .text{
            max-height: initial;
            overflow-x: initial;
            overflow-y: initial;
          }
        }
        .option{
          .optionText{
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 0 0.5rem 0 0;
            margin: 0.5rem auto
          }
        }
      }
    }
  }
`