import { Button, Text } from '@mantine/core'
import { Flag3, GenderFemale, GenderMale } from 'tabler-icons-react'

export const PickGender = ({ props }) => {

  const { finishQuiz, chooseOption, chosenOptions, pendingOptions } = props

  return (
    <div className="question">
      <div className="questionDiv">
        <Text align="center" size={28} weight={600} mb={30}>Cinsiniz:</Text>
      </div>
      <div className="options"
        style={{ display:"flex", gridGap:"1rem" }}
      >
        <Button size='xl' leftIcon={<GenderFemale size={30}/>} fullWidth color={"gray.7"} key={158} style={{ border: (chosenOptions?.id?.includes(158) || pendingOptions?.id?.includes(158)) ? "1px solid #eee" : "1px solid transparent" }} onClick={() => chooseOption({ id:158, multi:false })}>
          <Text size className="optionText">Qadın</Text>
        </Button>
        <Button size='xl' leftIcon={<GenderMale size={30}/>} fullWidth color={"gray.7"} key={159} style={{ border: (chosenOptions?.id?.includes(159) || pendingOptions?.id?.includes(159)) ? "1px solid #eee" : "1px solid transparent" }} onClick={() => chooseOption({ id:159, multi:false })}>
          <Text size className="optionText">Kişi</Text>
        </Button>
      </div>
      <Button size='md' mt={30} fullWidth className={"addBorder buttonNav"} leftIcon={<Flag3/>} onClick={() => chosenOptions.id.length > 0 && finishQuiz()} radius="xs" variant="gradient" gradient={{ from: 'teal', to: 'green' }}>Sorğunu bitir</Button>
    </div>
  )
}
