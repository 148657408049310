import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingOverlay, Modal } from "@mantine/core";
import axios from "axios";
import styled from "styled-components";
import { Trash } from "tabler-icons-react";

const DeleteSchool = ({ props }) => {

  const { schoolInfo, setSchoolInfo, id } = props;

  const [ showModal, setShowModal ] = useState(false)
  const [ loaderVisible, setLoaderVisible ] = useState(false);

  const navigate = useNavigate()

  async function handleDelete(){
    setLoaderVisible(true);
    try {
      const response = await axios.delete(`/school/${schoolInfo?.id}/`)
      setLoaderVisible(false);
      navigate('/all-schools');
    } catch (error) {
      setLoaderVisible(false);

    }
  }

  return(
    <DeleteSchoolStyle>
      <button className="delete" onClick={() => setShowModal(true)}><Trash/> Delete school</button>
      <Modal
        opened={showModal}
        onClose={() => setShowModal(false)}
        title="Delete school"
        >
        <LoadingOverlay visible={loaderVisible} loaderProps={{ color: 'var(--yellow)' }}/>
        <div className="deleteCourseModal">
          <p style={{color:"red"}}>Are you sure you want to delete this school?<br />This action cannot be undone.</p>
          <div style={{display:"flex",justifyContent:"center"}}>
            <button onClick={() => handleDelete()} style={{border:"none", background:"#fee", fontSize:"16px",margin:"0.5rem",padding:"0.5rem 0.8rem", borderRadius:"0.2rem", cursor:"pointer"}}>Yes</button>
            <button onClick={() => setShowModal(false)} style={{border:"none", fontSize:"16px",margin:"0.5rem",padding:"0.5rem 0.8rem", borderRadius:"0.2rem", cursor:"pointer"}}>No</button>
          </div>
        </div>
      </Modal>
    </DeleteSchoolStyle>
  )
}

export default DeleteSchool

const DeleteSchoolStyle = styled.div`
  border-radius: 0.5rem;
  button{
    display: flex;
    align-items: center;
    margin-right: 1rem;
    font-weight: 500;
    font-size: 15px;
    color: red;
    background: none;
    background-color: #fff;
    border: 1px solid #eee;
    padding: .5rem 1rem;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: all 0.2s;
    min-width: max-content;
    svg{
      margin-right: .5rem;
    }
    :hover{
      background: #ddd;
    }
  }
`