import React, { useContext } from "react";
import styled from "styled-components";
import ProfileHeader from "./ProfileHeader";
import AboutGoals from "./AboutGoals";
import Experience from "./Experience";
import Education from "./Education";
import ProfileSidebar from "./ProfileSidebar";
import NewSidebar from "../../../Components/Layout/NewSidebar/NewSidebar";
import { AppContext } from "../../../Helpers/Context";
import Certificates from "./Certificates";

function NewProfile(props) {
  const { isTaskool } = props;
  let { user } = useContext(AppContext);

  return (
    <div>
      <NewProfileStyle>
        <NewSidebar />
        <div className="container">
          <div className="profile">
            <ProfileHeader props={{ user, isTaskool }} />
            <AboutGoals props={{user}} />
            {/* <ProjectTasks props={user}/> */}
            {/* <Analytics props={user}/> */}
            <Experience props={{ user, isTaskool }} />
            {/* <Volunteering props={{ user, isTaskool }} /> */}
            <Education props={{ user, isTaskool }} />
            <Certificates props={{ user, isTaskool }} />
          </div>

          <ProfileSidebar />
        </div>
      </NewProfileStyle>
    </div>
  );
}

export default NewProfile;

const NewProfileStyle = styled.div`
  * {
    box-sizing: border-box;
  }

  form{
    border-radius: none;
  }

  label{
    //styleName: Text md/Regular;
    color:#364152;
font-family: "Inter";
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
margin-bottom: 12px;

  }
  input:not([type="checkbox"]){
    height: 56px;
    border-radius: 10px;
  }

  input[type="file"]{
    height: 56px;
    border-radius: 10px;
  }


  .btn {
    border-radius: 10px;
    height: 48px;

    font-family: "Inter";
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    transition: 0.3s;
  }
  .btn-submit {
    background: #fdb022;
    color: #fff;
    border: 1px solid #fdb022;
    &:hover {
      background: #fff;
      color: #fdb022;
    }
  }

  .btn-cancel {
    background: #fff;
    color: #202939;
    border: 1px solid #cdd5df;
    &:hover {
      background: #eee;
    }
  }

  background-color: #f9fafb;
  .container {
    width: 1300px;
    margin: 0 auto;
    padding-top: 32px;
    display: flex;
    justify-content: space-between;
    .profile {
      border-radius: 20px;
      width: 970px;
      overflow: hidden;
    }
  }
`;
