import React from "react";
import { Accordion, Group, Text } from "@mantine/core";
import { Edit, Plus } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";

const CustomAccordion = ({
  defaultValue,
  value,
  title,
  handleActiveCreateForm,
  isCreateFormActive,
  handleActiveEditForm,
  isEditFormActive,
  handleActiveIcons,
  isShowIcons,
  handleId,
  handleSkillHeaderId,
  children,
  isPublic,
}) => {
  const handleIconClick = ({ event, type }) => {
    event.stopPropagation();
    if (type === "create") {
      handleActiveEditForm && handleActiveEditForm(false);
      handleId && handleId(null);
      handleActiveIcons && handleActiveIcons(false);
      handleActiveCreateForm && handleActiveCreateForm(!isCreateFormActive);
    } else {
      handleActiveCreateForm && handleActiveCreateForm(false);
      handleActiveIcons && handleActiveIcons(!isShowIcons);
      handleActiveEditForm && handleActiveEditForm(!isEditFormActive);
    }

    if (handleSkillHeaderId && value === "interest") {
      handleSkillHeaderId(4);
    } else if (handleSkillHeaderId && value === "endorsement") {
      handleSkillHeaderId(5);
    } else if (handleSkillHeaderId && value === "language") {
      handleSkillHeaderId(3);
    } else if (handleSkillHeaderId && value === "professional-skill") {
      handleSkillHeaderId(2);
    } else if (handleSkillHeaderId && value === "soft-skill") {
      handleSkillHeaderId(1);
    }
  };

  const xs = useMediaQuery("(max-width: 576px)");

  return (
    <Accordion disableChevronRotation defaultValue={defaultValue}>
      <Accordion.Item value={value}>
        <Accordion.Control>
          <Group position="apart" align="center">
            <Text size={xs ? 16 : 18} weight={500} color="#364152" component="h2">
              {title}
            </Text>
            {!isPublic && (
              <Group style={{ zIndex: 1 }}>
                {value !== "about" && (
                  <Plus
                    size={18}
                    color="#364152"
                    strokeWidth={1.75}
                    onClick={(event) =>
                      handleIconClick({ event, type: "create" })
                    }
                    aria-label="Add new item"
                  />
                )}
                <Edit
                  size={18}
                  color="#364152"
                  strokeWidth={1.75}
                  onClick={(event) => handleIconClick({ event, type: "edit" })}
                  aria-label="Edit item"
                />
              </Group>
            )}
          </Group>
        </Accordion.Control>
        <Accordion.Panel>{children}</Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default CustomAccordion;
