import { React, useEffect, useContext, useState } from "react"
import axios from "axios";
import parse from 'html-react-parser';
import styled from "styled-components";
import { QuizFinished } from "./QuizFinished";
import { LoadingOverlay } from "@mantine/core";
import { AppContext } from "../../../../Helpers/Context";

export const Quiz = ({props}) => {

  const { content } = props
  const { user } = useContext(AppContext);

  const [ quizData, setQuizData ] = useState([]);
  const [ questions, setQuestions ] = useState([])
  const [ currentQuestion, setCurrentQuestion ] = useState(0)
  const [ optionChosen, setOptionChosen ] = useState("")
  const [ answerIs, setAnswerIs ] = useState(null)
  const [ answered, setAnswered ] = useState(false)
  const [ finished, setFinished ] = useState(undefined)

  const [ score, setScore ] = useState({
    correct: 0,
    false: 0,
  })

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`/quiz/multipledata/${content?.id}/`)
        setQuizData(response?.data?.Quiz)
        setQuestions(response?.data?.Question)
      } catch (error) {

      }
    })()
    return () => {
      setQuizData([])
      setQuestions([])
    }
  }, [])

  useEffect(() => {
    if(quizData[0]?.id){
      (async () => {
        try {
          const response = await axios.get(`/grade/quiz/${quizData[0]?.id}/`)

          if(response?.data?.user){
            let unJsoned = JSON.parse(response?.data?.description)
            setScore({
              correct: unJsoned.correct,
              false: unJsoned.false,
            })
            setFinished(true)
          }

        } catch (error) {
          setFinished(false)
          //
        }
      })()
    }
    return () => {
      setScore({
        correct: 0,
        false: 0,
      })
      setFinished(undefined)
    }
  }, [quizData])

  function returnLetter(i){
    let letter = "A";
    for(let j = 0; j < i; j++){
      letter = String.fromCharCode(letter.charCodeAt(0) + 1);
    }
    return letter;
  }

  function nextQuestion(){
    if(currentQuestion < questions.length - 1){
      setCurrentQuestion(prev => prev + 1)
      setOptionChosen(null)
      setAnswerIs(null)
      setAnswered(false)
    }
    // setChosenOptionTarget(null)
  }

  async function finishQuiz(){
    try {
      const response = await axios.post(`/grade/quiz/`, {
        grade: score.correct !== 0 ? (score?.correct / questions.length) * 100 : 0,
        quiz: content.id,
        user: user?.id,
        description: JSON.stringify(score),
      })

      if(response.data.id){
        setFinished(true)
      }
    } catch (error) {

    }
  }

  function confirmQuiz(){

    setAnswered(true)
    if(optionChosen?.is_correct === true){
      setAnswerIs("correct")
      setScore({
        ...score,
        correct: score.correct + 1
      })
    } else {
      setAnswerIs("error")
      setScore({
        ...score,
        false: score.false + 1
      })
    }

    // if(optionChosen !== null){
  }

  function chooseAnswer(e, option){
    setOptionChosen(option)
  }

  return(
    finished === undefined ?
      <LoadingOverlay visible={finished} loaderProps={{ color: 'var(--yellow)' }}/>
    : finished === false ?
    <QuizStyle>
      <h2>Quiz: {quizData[0]?.name}</h2>

        <div className="questions">
          <h2 className="numberOQ">Question {currentQuestion + 1}/{questions.length}</h2>
          {
            questions.map((question, i) => {
              return(
                i === currentQuestion &&
                <div className="questionInside" key={i}>
                  {/* <h2>{question?.name}</h2> */}
                  <div>
                    {parse(question?.name)}
                  </div>
                  <div className="questionOptions">
                    {
                      question?.option_set?.map((option, i) => {
                        return(
                          <div key={i}
                            onClick={(e) => !answered && chooseAnswer(e, option)}
                            className={
                              (!answered && optionChosen?.id === option.id) ? "chosen questionOption"
                              : (answered && optionChosen?.is_correct && optionChosen?.id === option.id) ? "success questionOption"
                              : (answered && !optionChosen?.is_correct  && optionChosen?.id === option.id) ? "fail questionOption"
                              : "questionOption"
                            }
                          >
                            <ins>{returnLetter(i)}</ins>
                            <p>{option?.name}</p>
                          </div>
                        )
                      })
                    }
                  </div>
                  {
                    answered &&
                    <div className="answerIs">
                      {
                        answerIs === "correct" ?
                        <h3 className="correct">Correct!</h3>
                        :
                        <h3 className="wrong">Wrong!</h3>
                      }
                      {
                        answerIs === "correct" ?
                        <p>Well done! Continue with next step!</p>
                        :
                        <p>Oops!! Looks like you have forgotten something. Try again!</p>
                      }
                    </div>
                  }
                  <div className='mark'>
                    {
                      answered ?
                      <div>
                        {
                          ((currentQuestion + 2) > questions.length) === false ?
                          <button onClick={() => nextQuestion()}>Sonrakı</button>
                          :
                          <button onClick={() => finishQuiz()}>Testi bitir</button>
                        }
                      </div>
                      :
                      <button onClick={() => confirmQuiz()}>Təsdiqlə</button>
                    }
                  </div>
                </div>
              )
            })
          }
        </div>

    </QuizStyle>
    :
    <QuizFinished props={{ quizData, score, qlen:questions.length }}/>
  )
};

const QuizStyle = styled.div`
  .questions{
    width: 60%;
    margin: 3.5rem auto 2rem auto;
    .numberOQ{
      font-weight: lighter;
      margin-bottom: 2rem;
    }
    .questionInside{
      h2{
        font-weight: 400;
      }
      .questionOptions{
        margin-top: 1.1rem;
        width: 100%;
        transition: all .1s;
        div{
          display: flex;
          align-items: center;
          margin: .8rem 0;
          max-height: 3rem;
          height: 3rem;
          cursor: pointer;
          transition: all .1s;
          ins{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.5rem;
            height: 3rem;
            border: 2px solid #f2f2f2;
            text-decoration: none;
            border-right: none;
            background-color: #faf9ff;
            font-size: 17px;
            border-radius: 1.1rem 0 0 1.1rem;
          }
          p{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 1.2rem;
            border-radius: 0 1.1rem 1.1rem 0;
            height: 100%;
            width: 100%;
            border: 2px solid #f2f2f2;
            border-left: none;
            img{
              padding-right: 1rem;
              display: none;
            }
          }
        }
        .chosen{
          ins{
            background-color: #cfc3fb;
            border: 2px solid #cfc3fb;
            border-right: none;
            color: #fff;
          }
          p{
            border: 2px solid #cfc3fb;
            border-left: none;
          }
        }
        .success{
          img{
            display: block;
          }
          ins{
            background-color: #50c493;
            border: 2px solid #50c493;
            border-right: none;
            color: #fff;
          }
          p{
            border: 2px solid #50c493;
            border-left: none;
          }
        }
        .fail{
          ins{
            background-color: #fdeeee;
            border: 2px solid #eb5757;
            border-right: none;
            color: #000;
          }
          p{
            border: 2px solid #eb5757;
            border-left: none;
          }
        }
      }
      .answerIs{
        border: 1px solid #ccc;
        border-radius: 0.2rem;
        margin: 2.5rem 0 6rem 0;
        padding: 1.5rem 3rem 1rem 1.5rem;
        h3{
          font-size: 15px;
          margin: 0;
        }
        .correct{
          color: #50c493;
        }
        .wrong{
          color: #eb5757;
        }
      }
      .mark{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 3rem 0 5rem 0;
        button{
          border: 1px solid var(--yellow);
          background-color: var(--yellow);
          border-radius: 0.5rem;
          margin: 0;
          padding: .75rem 4rem;
          min-width: max-content;
          font-weight: 500;
          font-size: 15px;
          cursor: pointer;
          transition: all .1s;
          &:hover{
            background-color: var(--yellow-shadow);
            border-color: var(--yellow-shadow);
          }
        }
      }
    }
  }
  @media screen and (max-width:1024px){
    .questions{
      width: 100%;
      margin: 3.5rem auto 2rem auto;
      .questionInside{
        .answerIs{
          margin: 2.5rem 0 0rem 0;

        }
      }
    }
  }
`