import { React } from "react";
import styled from "styled-components";
import lamp from "../../Assets/SVGs/lamp.svg";
import notes from "../../Assets/SVGs/notes.svg";
import tasks from "../../Assets/SVGs/tasks.svg";
import bookSaved from "../../Assets/SVGs/bookSaved.svg";
import { useTranslation } from "react-i18next";

export function ProgramFiller({ props }){

  const { isTaskool, type } = props;
  const {t} = useTranslation()

  const courseData =
  {
    title: t('title'),
    desc : t('desc'),
    a1 : t('a1'),
    a2 : t('a2'),
    b1 : t('b1'),
    b2 : t('b2'),
    c1 : t('c1'),
    c2 : t('c2'),
    d1 : t('d1'),
    d2 : t('d2'),
  }

  const internData =
  {
    title: t('title_intern'),
    desc : t('desc_intern'),
    a1 : t('a1_intern'),
    a2 : t('a2_intern'),
    b1 : t('b1_intern'),
    b2 : t('b2_intern'),
    c1 : t('c1_intern'),
    c2 : t('c2_intern'),
    d1 : t('d1_intern'),
    d2 : t('d2_intern'),
  }

  let texts = {}

  if(isTaskool && type === "course"){
    texts = courseData
  }

  if(isTaskool && type === "internship"){
    texts = internData
  }

 

  return(
    <FillerStyle>
      <div className="wrapper">
        <div className="info">
          <h1>{texts.title}</h1>
          <p>{texts.desc}</p>
        </div>
        <div className="parts">
          <div>
            <img src={bookSaved} alt="" />
            <h2>{texts.a1}</h2>
            <p>{texts.a2}</p>
          </div>
          <div>
            <img src={tasks} alt="" />
            <h2>{texts.b1}</h2>
            <p>{texts.b2}</p>
          </div>
          <div>
            <img src={lamp} alt="" />
            <h2>{texts.c1}</h2>
            <p>{texts.c2}</p>
          </div>
          <div>
            <img src={notes} alt="" />
            <h2>{texts.d1}</h2>
            <p>{texts.d2}</p>
          </div>
        </div>
      </div>
    </FillerStyle>
  )
}

const FillerStyle = styled.div`
  width: 90%;
  margin: 5rem auto;
  .wrapper{
    .info{
      text-align: center;
      width: 55%;
      margin: 0 auto;
      h1{
        font-weight: 500;
        font-size: 36px;
      }
      p{
        color: #828282;
        font-size: 17px;
        font-weight: 500;
        line-height: 1.5;
      }
    }
    .parts{
      margin: 4rem auto 0 auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: space-between;
      div{
        align-items: center;
        text-align: center;
        margin: 0 .7rem;
        padding: 2rem 0;
        border-radius: 0.75rem;
        border: 1px solid #eee;
        h2{
          font-weight: 500;
          color: #424242;
          font-size: 22px;
          margin: 2rem 0 .5rem 0;
        }
        p{
          font-size: 17px;
          line-height: 1.5;
          max-width: 240px;
          margin: 0 auto;
        }
      }
    }
  }
  @media screen and (max-width:1024px){
    width: 95%;
    margin: 3rem auto;
    .wrapper{
      .info{
        text-align: center;
        width: 100%;
        margin: 0 auto;
        h1{
          font-size: 22px;
        }
        p{
          font-size: 16px;
        }
      }
      .parts{
        margin: 2.5rem auto;
        grid-template-columns: 1fr;
        grid-gap: 1.5rem;
        div{
          padding: 1.5rem;
          h2{
            margin: 1rem 0;
          }
        }
      }
    }
  }
`