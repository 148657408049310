export function Conversation({ props }){

  const { messages, user } = props

  return(
    <div className="chatMessages" id='chatMessages'>
      {messages.map((message, index) => {
        return(
          message?.user_type?.id === undefined ?
          <div className={(message?.user_type?.id != user?.user_type?.id) ? 'studentMessage' : 'teacherMessage marginate'} key={index}>
            <p className="messageText">
              ...
            </p>
          </div>
          :
          <div className={(message?.user_type?.id == user?.user_type?.id) ? 'studentMessage' : 'teacherMessage marginate'} key={index}>
            <div className="messageContent">
              <p className="messageText">
                {message?.content}
              </p>
              {
                message?.file?.name ?
                <p>{message.file.name}</p>
                :
                (message?.file && message?.file?.includes('mp4')) || message?.file?.includes('webm') ?
                <video className="convFiles" src={message?.file} controls />
                :
                (message?.file && message?.file?.includes('mp3')) || message?.file?.includes('wav') || message?.file?.includes('m4a') || message?.file?.includes('ogg') ?
                <audio src={message?.file} controls />
                :
                (message?.file && message?.file?.includes('jpg')) || message?.file?.includes('png') || message?.file?.includes('jpeg') ?
                <img className="convFiles" src={message?.file} alt=""/>
                :
                (message?.file && message?.file?.includes('exe')) || message?.file?.includes('zip') || message?.file?.includes('rar') ?
                <h3>{message?.file}</h3>
                :
                ""
              }
            </div>
          </div>
        )
      })}
    </div>
  )
}