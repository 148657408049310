import React, { useState, useContext, useRef, useEffect } from "react";
import parse from "html-react-parser";
import styled from "styled-components";
import axios from "axios";
import birbankIcon from "../../Assets/Images/icons/birbank_icon.png";
import { ArrowNarrowLeft, Link as LinkIcon, Tag } from "tabler-icons-react";
import {
  Container,
  Image,
  Grid,
  Avatar,
  Card,
  Text,
  Tabs,
  Modal,
  TextInput,
  Button,
  AspectRatio,
  Overlay,
  Stepper,
  Group,
  Skeleton,
  Spoiler,
  LoadingOverlay,
  Select,
} from "@mantine/core";
import { ProjectCard } from "../../Components/OlympicComponents/ProjectCard/ProjectCard";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import { AppContext } from "../../Helpers/Context";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { PromoStyle } from "../../Components/Payment/PaymentStyle";

import moment from "moment";
import { useMediaQuery } from "@mantine/hooks";

import { MobileMenuNew } from "../../Components/Layout/Navbar/Menu";

import { ShowSidebar } from "../../Components/Layout/LayoutHelper";

import { Speaker } from "../../Components/OlympicComponents/OlympicSpeaker";

export const MainOlympic = ({ props }) => {
  // Data from params and context
  const { id } = useParams();
  const { user } = useContext(AppContext);
  const [olympicInstallmentData, setOlympicInstallmentData] = useState([]);

  const queryClient = useQueryClient();

  // State to keep olympic data entries
  const [entryData, setEntryData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [activeTabOlympic, setActiveTabOlympic] = useState("promo");

  const [joinedOlympicId, setJoinedOlympicId] = useState(0);
  const [promocodeText, setPromocodeText] = useState("");
  const [directPromocodeText, setDirectPromocodeText] = useState("");

  const [promocodeEntered, setPromocodeEntered] = useState("");
  const [promocodeEnteredDirect, setPromocodeEnteredDirect] = useState("");

  const [sendingPromocode, setSendingPromocode] = useState("");
  const [sendingPromocodeDirect, setSendingPromocodeDirect] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageDirect, setErrorMessageDirect] = useState("");

  const [queryEnabled, setQueryEnabled] = useState(false);
  const [doneMessage, setDoneMessage] = useState("");

  const [queryEnabledDirect, setQueryEnabledDirect] = useState(false);
  const [doneMessageDirect, setDoneMessageDirect] = useState("");

  // Media queries
  const xs = useMediaQuery("(max-width: 568px)");
  const sm = useMediaQuery("(max-width: 768px)");
  const sm2 = useMediaQuery("(max-width: 850px)");
  const lg = useMediaQuery("(min-width: 995px)");
  const md = useMediaQuery("(max-width: 1150px)");
  const xs3 = useMediaQuery("(max-width: 600px)");
  const xs4 = useMediaQuery("(max-width: 460px)");
  const xl = useMediaQuery("(min-width: 1024.5px)");

  const { data: olympicProjects, isLoading: projectsLoading } = useQuery(
    ["olympic all projects", id],
    async () =>
      axios
        .get(`/olympic/project/getprojectsbyolympicid/${id}/`)
        .then((res) => res.data)
  );

  const { data: olympicProject, isLoading: projectLoading } = useQuery(
    ["olympic project", user?.id],
    async () => await axios.get(`/olympic/project/byuser_id/${user?.id}`)
  );

  const changeLink = () => {
    window.location.href = `/project/${olympicProject?.data[0].id}`;
  };

  // Get olympic data
  const { isLoading, isFetching } = useQuery(
    ["olympic", id],
    async () => await axios.get(`/olympic/olympicbyid/${id}/`),
    {
      onSuccess: (data) => {
        setEntryData(data?.data[0]);
      },
    }
  );

  let formXinstallment = {
    user: user?.id,
    olympic: entryData?.id,
    bank_installment_paid: true,
    price: Number(entryData?.price),
    promocode: promocodeText,
  };

  let formXDirect = {
    user: user?.id,
    olympic: entryData?.id,
    bank_installment_paid: false,
    price: Number(entryData?.price),
    promocode: directPromocodeText,
  };

  const {
    data: getInstallmentPrice,
    isLoading: getInstallmentPriceLoading,
    refetch,
  } = useQuery(
    ["installment prices", promocodeText],
    async () => {
      try {
        const response = await axios.get(
          `/olympic/getinstallmentpricefromkapitalforolympic/?olympic_id=${id}&promocode=${promocodeText}`
        );

        return response?.data;
      } catch (error) {
        setDoneMessage("");
        setErrorMessage("Daxil etdiyiniz promocode mövcud deyil.");
        setPromocodeText("");
        refetch();
      }
    },
    {
      enabled: queryEnabled, // Only enable the query when queryEnabled is true
      onSuccess: () => {
        // queryClient.invalidateQueries(["installment prices", promocodeText]);
        setSendingPromocode(promocodeText);
        if (promocodeText !== "") {
          setErrorMessage("");
          setDoneMessage("Promocode tətbiq edildi.");
        }
      },
    }
  );

  useEffect(() => {
    setQueryEnabled(true);
  }, []);

  const {
    data: getDirectPrice,
    isLoading: getDirectPriceLoading,
    refetch: directRefetch,
  } = useQuery(
    ["direct prices", directPromocodeText],
    async () => {
      try {
        const response = await axios.get(
          `/olympic/promocodeForOlympic/?olympic_id=${id}&promocode=${directPromocodeText}`
        );

        return response?.data;
      } catch (error) {
        setDoneMessageDirect("");
        setErrorMessageDirect("Daxil etdiyiniz promocode mövcud deyil.");
        setDirectPromocodeText("");
        directRefetch();
      }
    },
    {
      enabled: queryEnabledDirect, // Only enable the query when queryEnabledDirect is true
      onSuccess: () => {
        // queryClient.invalidateQueries(["direct prices", promocodeText]);
        setSendingPromocodeDirect(directPromocodeText);
        if (promocodeText !== "") {
          setErrorMessageDirect("");
          setDoneMessageDirect("Promocode tətbiq edildi.");
        }
      },
    }
  );

  useEffect(() => {
    setQueryEnabledDirect(true);
  }, []);

  const handleSelectChange = (selected) => {
    setSelectedMonth(selected);
  };

  const getPriceForSelectedMonth = () => {
    if (selectedMonth) {
      const selectedData = getInstallmentPrice?.filter(
        (item) => item.month == selectedMonth
      )[0];
      return selectedData?.price;
    }
    return "";
  };

  console.log("test: ", getDirectPrice);

  const getMonthNumber = (monthString) => {
    const match = monthString.match(/\d+/);
    return match ? parseInt(match[0]) : "";
  };

  formXinstallment.bank_installment_month =
    selectedMonth && getMonthNumber(selectedMonth);

  const isMentor = entryData?.mentors?.find((mentor) => mentor.id === user?.id);

  const handleSubmitInstallment = async (e) => {
    e.preventDefault();

    try {
      const response = await axios
        .post("/olympic/create-order/", formXinstallment)
        .then((res) => (window.location = res?.data?.url));
    } catch (error) {
      console.log(error?.response?.data);
    }
  };

  const handleSubmitDirect = async (e) => {
    e.preventDefault();

    try {
      const response = await axios
        .post("/olympic/create-order/", formXDirect)
        .then((res) => (window.location = res?.data?.url));
    } catch (error) {
      console.log(error?.response?.data);
    }
  };

  const createProject = async () => {
    axios
      .post("/olympic/project/create/", {
        name: "Project",
        user: user?.id,
        description: "<b>Here you will add project description...<b>",
        olympic: entryData?.id,
      })
      .then(() => window.location.reload());
  };

  if (
    isLoading ||
    isFetching ||
    projectLoading ||
    projectsLoading ||
    getInstallmentPriceLoading ||
    getDirectPriceLoading
  ) {
    return (
      <LoadingOverlay
        visible={true}
        variant="bars"
        loaderProps={{ color: "var(--yellow)" }}
      />
    );
  }

  // Return main component
  return (
    <div>
      <ShowSidebar>
        <Container size={"lg"} mt={20}>
          <MobileTab>
            <div className="mobileMenuMain">
              <MobileMenuNew />
            </div>
          </MobileTab>
          <Grid>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "4px",
                padding: "30px 20px",
              }}
            >
              <Grid.Col span={12}>
                <div
                  className="title"
                  style={{ display: "flex", marginBottom: "14px" }}
                >
                  <Link to={"/olympics"}>
                    <ArrowNarrowLeft
                      size={32}
                      strokeWidth={2}
                      color={"black"}
                    />
                  </Link>
                  <div className="text" style={{ marginLeft: "16px" }}>
                    <a
                      href="/"
                      style={{
                        color: "#1a1d1c",
                        textDecoration: "none",
                        fontSize: "24px",
                        fontWeight: "400",
                        fontStyle: "normal",
                        lineHeight: "24px",
                      }}
                    >
                      {entryData?.title}
                    </a>
                    <span
                      style={{
                        display: "block",
                        marginTop: "4px",
                        fontSize: "12px",
                        color: "#5e5e5e",
                        marginTop: "-3px",
                      }}
                    >
                      Olympic event
                    </span>
                  </div>
                </div>

                <Grid>
                  <Grid.Col xl={6} md={12}>
                    <div
                      style={{
                        // width: xl ? "425px" : "",
                        // height: xl ? "240px" : "",
                        borderRadius: "4px",
                        overflow: "hidden",
                      }}
                    >
                      <Image
                        src={
                          entryData?.image?.name
                            ? URL.createObjectURL(entryData?.image)
                            : entryData?.image
                        }
                        alt="event-image"
                        style={{
                          maxWidth: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </Grid.Col>
                  <Grid.Col xl={6} md={12}>
                    <div>
                      <div className="about" style={{ maxWidth: "100%" }}>
                        <h1
                          style={{
                            fontSize: "20px",
                            fontWeight: 500,
                            color: "#202939",
                          }}
                        >
                          About
                        </h1>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="columns">
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "10px",
                              }}
                            >
                              Type:
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "10px",
                              }}
                            >
                              Start date:
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "10px",
                              }}
                            >
                              End date:
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "10px",
                              }}
                            >
                              Language:
                            </p>
                          </div>
                          <div className="columns-values">
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "10px",
                              }}
                            >
                              Olympic
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "10px",
                              }}
                            >
                              {moment(entryData?.start_date).format(
                                "DD.MM.YYYY"
                              )}
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "10px",
                              }}
                            >
                              {moment(entryData?.end_date).format("DD.MM.YYYY")}
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "10px",
                              }}
                            >
                              {entryData?.language?.language}
                            </p>
                          </div>
                        </div>

                        {isMentor && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "center",
                              fontWeight: 600,
                            }}
                          >
                            Mentors cannot participate in the contest.
                          </p>
                        )}

                        {!isMentor &&
                          entryData?.user_has_join == false &&
                          entryData?.price > 0 && (
                            <Tabs
                              defaultValue="promo"
                              onTabChange={(value) => {
                                setActiveTabOlympic(value);
                              }}
                              value={activeTabOlympic}
                              radius={0}
                              mt={0}
                              variant="pills"
                              styles={{
                                tabsList: { gap: 0 },
                                tab: {
                                  backgroundColor: "#F8F9FA",
                                  border: "2px solid #F8F9FA",
                                },
                              }}
                            >
                              <Tabs.List grow>
                                <Tabs.Tab
                                  icon={<Tag size={24} />}
                                  style={{
                                    color: "black",
                                    borderRadius: ".25rem 0 0 .25rem",
                                  }}
                                  p={8}
                                  color={"white"}
                                  value="promo"
                                >
                                  <Text size={17} weight={500}>
                                    Promo
                                  </Text>
                                </Tabs.Tab>
                                <Tabs.Tab
                                  icon={
                                    <Image
                                      width={35}
                                      height={35}
                                      src={birbankIcon}
                                    />
                                  }
                                  style={{
                                    color: "black",
                                    borderRadius: ".25rem 0 0 .25rem",
                                  }}
                                  p={8}
                                  color={"white"}
                                  value="installment"
                                >
                                  <Text size={17} weight={500}>
                                    Taksitlə ödəniş
                                  </Text>
                                </Tabs.Tab>
                              </Tabs.List>

                              <Tabs.Panel value="promo" pt="sm">
                                <PromoStyle>
                                  <div className="promoPart">
                                    <p> Promo kodu daxil et, daha az ödə </p>
                                    {errorMessageDirect && (
                                      <span style={{ color: "red" }}>
                                        {errorMessageDirect}
                                      </span>
                                    )}

                                    <div
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <TextInput
                                        id="promo"
                                        value={promocodeEnteredDirect}
                                        placeholder="Enter promo code"
                                        onChange={(e) =>
                                          setPromocodeEnteredDirect(
                                            e.target.value
                                          )
                                        }
                                      />

                                      <Button
                                        onClick={() => {
                                          if (
                                            directPromocodeText.trim() !== ""
                                          ) {
                                            refetch(); // Manually trigger a refetch to make the API call
                                          }
                                          setDirectPromocodeText(
                                            promocodeEnteredDirect
                                          );
                                        }}
                                        variant="default"
                                        disabled={
                                          promocodeEnteredDirect.trim() === ""
                                        }
                                      >
                                        Check
                                      </Button>
                                    </div>
                                    {doneMessageDirect && (
                                      <span style={{ color: "green" }}>
                                        {doneMessageDirect}
                                      </span>
                                    )}
                                  </div>

                                  <form onSubmit={handleSubmitDirect}>
                                    <h3>Price: {getDirectPrice[0]?.price}</h3>

                                    <Button
                                      style={{ backgroundColor: "yellow" }}
                                      variant="default"
                                      type="submit"
                                    >
                                      Buy
                                    </Button>
                                  </form>
                                </PromoStyle>
                              </Tabs.Panel>

                              <Tabs.Panel value="installment" pt="sm">
                                <PromoStyle>
                                  {selectedMonth && (
                                    <div className="promoPart">
                                      <p> Promo kodu daxil et, daha az ödə </p>
                                      {errorMessage && (
                                        <span style={{ color: "red" }}>
                                          {errorMessage}
                                        </span>
                                      )}

                                      <div
                                        style={{
                                          display: "flex",
                                        }}
                                      >
                                        <TextInput
                                          id="promo"
                                          value={promocodeEntered}
                                          placeholder="Enter promo code"
                                          onChange={(e) =>
                                            setPromocodeEntered(e.target.value)
                                          }
                                        />

                                        <Button
                                          onClick={() => {
                                            if (promocodeText.trim() !== "") {
                                              refetch(); // Manually trigger a refetch to make the API call
                                            }
                                            setPromocodeText(promocodeEntered);
                                          }}
                                          variant="default"
                                          disabled={
                                            promocodeEntered.trim() === ""
                                          }
                                        >
                                          Check
                                        </Button>
                                      </div>
                                      {doneMessage && (
                                        <span style={{ color: "green" }}>
                                          {doneMessage}
                                        </span>
                                      )}
                                    </div>
                                  )}

                                  <form onSubmit={handleSubmitInstallment}>
                                    <Select
                                      label="Neçə aylıq taksitlə ödəmək istəyirsiniz?"
                                      placeholder="Pick value"
                                      data={
                                        getInstallmentPrice?.map(
                                          (data) => data?.month
                                        ) ?? []
                                      }
                                      value={selectedMonth}
                                      onChange={handleSelectChange}
                                    />
                                    <Button
                                      style={{ backgroundColor: "yellow" }}
                                      variant="default"
                                      type="submit"
                                      disabled={!selectedMonth}
                                    >
                                      Buy
                                    </Button>
                                  </form>
                                  {selectedMonth && (
                                    <p style={{ textAlign: "left" }}>
                                      Aylıq ödəniş:{" "}
                                      <b>{getPriceForSelectedMonth()}</b> AZN
                                    </p>
                                  )}
                                </PromoStyle>
                              </Tabs.Panel>
                            </Tabs>
                          )}

                        {!isMentor &&
                          entryData?.user_has_join == true &&
                          olympicProject?.data?.length == 0 && (
                            <div className="link" style={{ marginTop: "2px" }}>
                              <div>
                                <Button
                                  color="gray"
                                  mt={25}
                                  fullWidth
                                  onClick={createProject}
                                >
                                  Create project
                                </Button>
                              </div>
                            </div>
                          )}

                        {!isMentor &&
                          entryData?.user_has_join == true &&
                          olympicProject?.data?.length > 0 && (
                            <div className="link" style={{ marginTop: "2px" }}>
                              <div>
                                <Button
                                  color="yellow"
                                  mt={25}
                                  fullWidth
                                  onClick={changeLink}
                                >
                                  Enter
                                </Button>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </Grid.Col>
                </Grid>
                <Grid.Col span={12}>
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: 500,
                      color: "#202939",
                    }}
                  >
                    Description
                  </h1>
                  <Spoiler
                    mt={-14}
                    maxHeight={160}
                    showLabel="Show more"
                    hideLabel="Hide"
                    styles={{ control: { color: "#868E96" } }}
                  >
                    <div style={{ maxWidth: "96%" }}>
                      {/* <p style={{ textAlign: "justify", maxWidth: "100%" }}> */}
                      {entryData?.description && parse(entryData?.description)}
                      {/* </p> */}
                    </div>
                  </Spoiler>
                </Grid.Col>
              </Grid.Col>
            </div>
          </Grid>

          <div
            style={{
              marginTop: "6px",
              marginBottom: "15px",
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "30px",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <div style={{ marginBottom: "14px" }}>
              <a
                style={{
                  color: "#1a1d1c",
                  fontSize: "24px",
                  fontWeight: "500",
                  fontStyle: "normal",
                  lineHeight: "24px",
                  marginLeft: "16px",
                }}
              >
                Mentors
              </a>
            </div>
            {entryData?.mentors?.length ? (
              <div className="mentors">
                <Grid>
                  {entryData?.mentors &&
                    entryData?.mentors.map((mentor, index) => (
                      <Grid.Col key={index} xl={6} lg={6} sm={6} xs={12}>
                        <Speaker speaker={mentor} />
                      </Grid.Col>
                    ))}
                </Grid>
              </div>
            ) : (
              <p style={{ marginLeft: "16px", fontSize: "14px" }}>
                Mentors are not specified yet.
              </p>
            )}
          </div>

          <OtherProjects>
            <div
              style={{
                marginTop: "6px",
                backgroundColor: "white",
                borderRadius: "4px",
                padding: "30px",
                width: "100%",
                boxSizing: "border-box", // Ensure padding is included in the width
              }}
            >
              <div style={{ marginBottom: "14px" }}>
                <a
                  style={{
                    color: "#1a1d1c",
                    fontSize: "24px",
                    fontWeight: "500",
                    fontStyle: "normal",
                    lineHeight: "24px",
                    marginLeft: "16px",
                  }}
                >
                  Projects
                </a>
              </div>
              {olympicProjects?.length > 0 ? (
                <div className="projects">
                  <Grid>
                    {olympicProjects?.map((project) => (
                      <Grid.Col
                        key={project?.id}
                        span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}
                      >
                        <ProjectCard project={project} />
                      </Grid.Col>
                    ))}
                  </Grid>
                </div>
              ) : (
                <div style={{ marginLeft: "16px", fontSize: "14px" }}>
                  There are no projects yet.
                </div>
              )}
            </div>
          </OtherProjects>
        </Container>
      </ShowSidebar>
    </div>
  );
};

const OtherProjects = styled.div`
  z-index: 0;
  position: relative;
  // &::after {
  //   backdrop-filter: blur(10px);
  //   width: 100%;
  //   height: 100%;
  //   content: "Bu hissə olimpiada bitəndə aktiv olacaq.";
  //   position: absolute;
  //   top: -30px;
  //   left: 0;
  //   text-align: center;
  //   font-size: 60px;
  //   font-weight: 600;
  //   color: #f5df4d;
  //   z-index: 200;
  // }
  .otherProjects {
    margin-top: 50px;
    padding: 16px 30px;

    .title {
      p {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
  @media (max-width: 500px) {
    .search-other-projects {
      flex-wrap: wrap;
      button {
        width: 100%;
      }
    }
  }
`;

const MobileTab = styled.div`
  @media (min-width: 1025px) {
    .mobileMenuMain {
      display: none;
    }
  }
`;
