import axios from "axios";
import { Button, TextInput } from "@mantine/core";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { UlduzumStyle } from "./PaymentStyle";

export function Ulduzum({ props }) {
  const {
    phoneNumber,
    setPhoneNumber,
    ulduzumResponse,
    setUlduzumResponse,
    noUlduzumPromo,
    setNoUlduzumPromo,
    setCoursePrice,
  } = props;

  useEffect(() => {
    if (phoneNumber && phoneNumber.length > 9 && phoneNumber[0] === "0") {
      setPhoneNumber(phoneNumber.substring(1, phoneNumber.length));
    }
  }, [phoneNumber]);

  const { mutate: checkPromoMutate, isLoading } = useMutation(
    [""],
    async (e) => {
      e.preventDefault();
      if (phoneNumber) {
        const response = await axios.get(
          `forum/getpromocodepercentforforum/?forum_id=1&phone=994${phoneNumber}`
        );
        if (noUlduzumPromo === true) {
          setNoUlduzumPromo(false);
        }

        setUlduzumResponse(response.data);
        if (response?.data?.price) {
          setCoursePrice(response?.data?.price);
        } else {
          setCoursePrice(80);
        }

        if (response.status === 204) {
          setNoUlduzumPromo(true);
        }
      }
    }
  );

  return (
    <UlduzumStyle>
      <form onSubmit={(e) => checkPromoMutate(e)}>
        <TextInput
          id="promo"
          value={phoneNumber}
          placeholder="Telefon nömrəsini daxil et"
          icon={"+994"}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <Button
          disabled={isLoading}
          loading={isLoading}
          variant="default"
          onClick={(e) => checkPromoMutate(e)}
        >
          Yoxla
        </Button>
      </form>

      {(noUlduzumPromo || ulduzumResponse) && (
        <div className="submit">
          {noUlduzumPromo && (
            <h3 className="noPromo">Ulduzum promo kode mövcud deyil</h3>
          )}
          {ulduzumResponse && (
            <h3 className="yesPromo">15% endirim tətbiq olundu!</h3>
          )}
        </div>
      )}
    </UlduzumStyle>
  );
}
