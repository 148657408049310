import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AppContext } from "../../../Helpers/Context";
import { Link, useLocation } from "react-router-dom";
import {
  ChevronDown,
  Map,
  Logout,
  Settings,
  User,
  BrandAppleArcade,
  Shape2,
  BuildingBank,
  Social,
  Ticket,
  Home2,
  School,
  Microscope,
  UserPlus
} from "tabler-icons-react";
//import icons
import close from "../../../Assets/SVGs/sidebar/close-sidebar.svg";
import home from "../../../Assets/SVGs/sidebar/home.svg";
import profile from "../../../Assets/SVGs/sidebar/profile.svg";
import internship from "../../../Assets/SVGs/sidebar/internship.svg";
import bootcamp from "../../../Assets/SVGs/sidebar/internship.svg";
import commmunities from "../../../Assets/SVGs/sidebar/communities.svg";
import skillBuilding from "../../../Assets/SVGs/sidebar/skillbuilding.svg";
import hackathons from "../../../Assets/SVGs/sidebar/hacakthons.svg";
import simulations from "../../../Assets/SVGs/sidebar/simulations.svg";
import tipLogo from "../../../Assets/Images/tiplogo.png";

function NewSidebar({ isTaskool, register}) {
  const { newSidebarOpen, setNewSidebarOpen } = useContext(AppContext);
  const { t, i18n } = useTranslation();
    // Logs the current user out
    const location = useLocation()

  useEffect(() => {
    setNewSidebarOpen(false);
  }, [location]);


  const { user, setUser } = useContext(AppContext)



  function logout(){
    localStorage.setItem("taskooluser", null);
    setUser(null);
    location.pathname = "/";
    // remove cookies
    document.cookie = "refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }


  if(register){
    return (
      <NewNavbarStyle>
        <div className={`sidebar-overlay ${newSidebarOpen ? "active" : ""}`}>
          <div className={`sidebar ${newSidebarOpen ? "active" : ""}`}>
            <div className="sidebar-header">
              <div className="menu">Menu</div>
  
              <div
                onClick={() => setNewSidebarOpen(!newSidebarOpen)}
                className="close-sidebar"
              >
                <img src={close} alt="" />
              </div>
            </div>
  
            <div className="sidebar-menu">
              <ul>
                <li className="active">
                  <Link to="/">
                    <img src={home} alt="" /> {t("home")}
                  </Link>
                </li>


                <li>
                  <Link to="/login">
                  <img src={profile} alt="" /> 
                    Daxil ol
                  </Link>
                </li>


                <li>
                  <Link to="/register">
                  <UserPlus/>
                    Qeydiyyat
                  </Link>
                </li>
                <li>
                  <Link to="/steam">
                    <Microscope />
                    {t("steam-projects")}
                  </Link>
                </li>
  
                <li>
                  <Link to="/courses">
                    <Shape2 />
                    {t("courses")}
                  </Link>
                </li>
  
                <li>
                  <Link to="/olympics">
                    <Ticket />
                    Olympics
                  </Link>
                </li>
  
                <li>
                  <Link to="/groups">
                    <Social />
                    Groups
                  </Link>
                </li>
  
                <li>
                  <Link to="/tip">
                    <img
                      style={{ filter: "saturate(0) brightness(0)" }}
                      width={"20px"}
                      src={tipLogo}
                      alt=""
                    />
                    {t("tip")}
                  </Link>
                </li>
  
                <li>
                  <Link to="/games">
                    {" "}
                    <img src={simulations} alt="" />
                    {t("simulations")}
                  </Link>
                </li>
  
                {/* <li>
                  <img src={skillBuilding} alt="" />
                  Skillbuiding
                </li>
                <li>
                  <img src={internship} alt="" />
                  Internship
                </li>
                <li>
                  <img src={commmunities} alt="" />
                  Communities
                </li>
                <li>
                  <img src={hackathons} alt="" />
                  Hackathons
                </li>
                <li>
                  <img src={bootcamp} alt="" />
                  Bootcamp
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </NewNavbarStyle>
    );
  }
  return (
    <NewNavbarStyle>
      <div className={`sidebar-overlay ${newSidebarOpen ? "active" : ""}`}>
        <div className={`sidebar ${newSidebarOpen ? "active" : ""}`}>
          <div className="sidebar-header">
            <div className="menu">Menu</div>

            <div
              onClick={() => setNewSidebarOpen(!newSidebarOpen)}
              className="close-sidebar"
            >
              <img src={close} alt="" />
            </div>
          </div>

          <div className="sidebar-menu">
            <ul>
              <li className="active">
                <Link to="/">
                  <img src={home} alt="" /> {t("home")}
                </Link>
              </li>
              <li>
                <Link to="/profile">
                  <img src={profile} alt="" />
                  {t("profile")}
                </Link>
              </li>

              <li>
                <Link to="/steam">
                  <Microscope />
                  {t("steam-projects")}
                </Link>
              </li>

              <li>
                <Link to="/courses">
                  <Shape2 />
                  {t("courses")}
                </Link>
              </li>

              <li>
                <Link to="/olympics">
                  <Ticket />
                  Olympics
                </Link>
              </li>

              <li>
                <Link to="/groups">
                  <Social />
                  Groups
                </Link>
              </li>

              <li>
                <Link to="/tip">
                  <img
                    style={{ filter: "saturate(0) brightness(0)" }}
                    width={"20px"}
                    src={tipLogo}
                    alt=""
                  />
                  {t("tip")}
                </Link>
              </li>

              <li>
                <Link to="/games">
                  {" "}
                  <img src={simulations} alt="" />
                  {t("simulations")}
                </Link>
              </li>

              <li>
                <Link to="/settings">
                  <Settings />
                  {t("settings")}
                </Link>
              </li>

              <li onClick={logout}>
                <Link to="/">
                  <Logout />
                  {t("log_out")}
                </Link>
              </li>

              {/* <li>
                <img src={skillBuilding} alt="" />
                Skillbuiding
              </li>
              <li>
                <img src={internship} alt="" />
                Internship
              </li>
              <li>
                <img src={commmunities} alt="" />
                Communities
              </li>
              <li>
                <img src={hackathons} alt="" />
                Hackathons
              </li>
              <li>
                <img src={bootcamp} alt="" />
                Bootcamp
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </NewNavbarStyle>
  );
}

export default NewSidebar;

const NewNavbarStyle = styled.div`
  * {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
  }
  .sidebar-overlay {
    z-index: 9999999;
    background-color: #0000005a;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .sidebar {
      background: #fff;
      height: 100%;
      width: 0px;
      padding: 18px 12px;
      transition: 0.3s; /* Added transition property */
      overflow: hidden;
      &.active {
        width: 280px;
      }

      .sidebar-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        .menu {
          color: #121926;
          font-family: "Inter";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 155.556% */
        }

        .close-sidebar {
          cursor: pointer;
        }
      }

      .sidebar-menu {
        ul {
          list-style: none;
          li {
            a {
              display: flex;
              align-items: center;
              gap: 12px;
              text-decoration: none;
              color: #121926;
            }

            padding: 8px 16px;
            margin-bottom: 8px;

            font-family: "Euclid";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 175% */
            cursor: pointer;
            transition: 0.3s ease;
            border-radius: 8px;

            &.active {
              background: #ffe500;
            }
            :hover {
              background: #ffe500;
            }
          }
        }
      }
    }
  }
`;
