import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotifications,showNotification } from "@mantine/notifications";
import { Button, Checkbox, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import styled from "styled-components";
import axios from "axios";

//Import icons
import educationIcon from "../../../Assets/SVGs/profile/education.svg";
import addIcon from "../../../Assets/SVGs/profile/plus.svg";
import editIcon from "../../../Assets/SVGs/profile/edit.svg";
import deleteIcon from "../../../Assets/SVGs/profile/minus.svg";
import educationImg1 from "../../../Assets/Images/profile/education-1.png";

//Import sound
import succesSound from "./sound/success.mp3"
import errorSound from "./sound/error.mp3"

function Education({ props }) {
  const { t, i18n } = useTranslation();
  const { user, isTaskool } = props;

  let [educationArray, setEducationArray] = useState([]);
  let [currentEditing, setCurrentEditing] = useState(0);
  const [manageShow, setManageShow] = useState(false);

  const notifications = useNotifications();

  async function fetchData() {
    try {
      const fetchedEducation = await axios.get("api/education/");
      fetchedEducation.data.sort((a, b) => a.id - b.id);
      setEducationArray(
        Object.keys(fetchedEducation.data).map(function (key) {
          return fetchedEducation.data[key];
        })
      );
    } catch (error) {}
  }

  // Fetches data when the page is loaded
  useEffect(() => {
    fetchData();
    return () => {
      setEducationArray([]);
    };
  }, [user]);

  // Adds new skill
  function addInput() {
    // If there is no current editing, set current editing to the last index

    let educationIds = educationArray.map(function (education) {
      return education.id;
    });

    if (educationIds.includes(currentEditing.id)) {
      setCurrentEditing(0);
      setEducationArray((educations) => {
        const newEducation = [...educations];
        newEducation.push({
          id: educationArray.length
            ? educationArray[educationArray?.length - 1]?.id + 1
            : 2,
        });
        setCurrentEditing({
          id: educationArray.length
            ? educationArray[educationArray?.length - 1]?.id + 1
            : 2,
        });
        return newEducation;
      });
    }

    if (currentEditing === 0) {
      // setCurrentEditing(0)
      setEducationArray((educations) => {
        const newEducation = [...educations];
        newEducation.push({
          id: educationArray.length
            ? educationArray[educationArray?.length - 1]?.id + 1
            : 2,
        });
        setCurrentEditing({
          id: educationArray.length
            ? educationArray[educationArray?.length - 1]?.id + 1
            : 2,
        });
        return newEducation;
      });
    }
  }

  function handleEdit(index) {
    setCurrentEditing(educationArray[index]);

    // filter out entries from educationArray if college is undefined
    setEducationArray(
      educationArray.filter((education) => education.college !== undefined)
    );
  }

  // Deletes skill
  async function handleDelete(e, index) {
    setCurrentEditing(0);
    // filter out entries from educationArray if college is undefined
    setEducationArray(
      educationArray.filter((education) => education.college !== undefined)
    );

    try {
      // deletes skill from database
      const deletedEducation = await axios.delete(
        `api/education/${educationArray[index].id}/`
      );

      if (deletedEducation.status === 204) {

        notificationSound("success")
        showNotification({
          title: t("education-nt3"),
          message: t("education-ntm3"),
          color: "white",
        });
      }

      // reFetches educations and sorts them in ascending order after deleting one
      const fetchedEducation = await axios.get("api/education/");
      fetchedEducation.data.sort((a, b) => a.id - b.id);

      setEducationArray(
        Object.keys(fetchedEducation.data).map(function (key) {
          return fetchedEducation.data[key];
        })
      );
    } catch (error) {}
  }

  // Requests change to skill
  async function patchOrPost(e, index) {
    let item = educationArray[educationArray.length - 1];

    if (!item.college || !item.education || !item.start_date) {
      notificationSound("error")
      showNotification({
        title: t("education-e1"),
        message: t("education-e2"),
        color: "red",
      });
    } else if (
      (item.is_present === undefined || item.is_present === false) &&
      item.end_date === undefined
    ) {
      notificationSound("error")
      showNotification({
        title: t("education-e1"),
        message: t("education-e3"),
        color: "yellow",
      });
    } else {
      setCurrentEditing(0);

      try {
        const updatedEducation = await axios.patch(
          `api/education/${currentEditing.id}/`,
          currentEditing
        );

        if (updatedEducation.status === 200) {
          setEducationArray((educations) => {
            const newEducation = [...educations];
            newEducation[index].is_present = false;
            return newEducation;
          });

          fetchData();
          notificationSound("success")
          showNotification({
            title: t("education-nt2"),
            message: t("education-ntm2"),
            color: "white",
          });
        }
      } catch (error) {
        try {
          if (
            error?.response?.status === 404 ||
            error?.response?.status === 403 ||
            error?.response?.status === 400
          ) {
            const formX = educationArray[educationArray.length - 1];
            formX.id = currentEditing.id;
            formX.user = user.id;
            const response = await axios.post("api/create-education/", formX);

            if (response.status === 201) {
              fetchData();
              notificationSound("success")
              showNotification({
                title: t("education-nt1"),
                message: t("education-ntm1"),
                color: "green",
              });
            }
          }
        } catch (error) {}
      }
    }
  }

  // Cancel editing
  function handleCancel() {
    setCurrentEditing(0);
    // filter out entries from educationArray if college is undefined
    setEducationArray(
      educationArray.filter((education) => education.college !== undefined)
    );
  }

  const notificationSound=(type)=>{
    if(type=="success")
      {
        var audio = new Audio(succesSound);
      }
      else{
        var audio = new Audio(errorSound)
      }
    
    audio.play();
  }

  return (
    <EducationStyle>
      <div className="education-header">
        <h2>{t("education-header")}</h2>

        <div className="header-icons">
          <div onClick={() => addInput(true)} className="header-icon-item">
            <img src={addIcon} alt="add-icon" />
          </div>

          <div className="header-icon-item">
            <img onClick={() => setManageShow(!manageShow)} src={editIcon} alt="edit-icon" />
          </div>
        </div>
      </div>

      <div className="education-box">
        <form onSubmit={(e) => e.preventDefault()}>
          {educationArray.map((education, index) => {
            return currentEditing?.id === education?.id ? (
              <div
                key={index}
                className="editing insideWrapper"
                id={education.id}
              >
                <div>
                  <TextInput
                    required
                    name="college"
                    placeholder={t("education-p1")}
                    label={t("education-l1")}
                    value={education.college}
                    onChange={(e) => {
                      setEducationArray((educations) => {
                        const newEducation = [...educations];
                        newEducation[index].college = e.target.value;
                        return newEducation;
                      });
                    }}
                  />
                  <br />

                  <TextInput
                    required
                    name="education"
                    placeholder={t("education-p2")}
                    label={t("education-l2")}
                    value={education.education}
                    onChange={(e) => {
                      setEducationArray((educations) => {
                        const newEducation = [...educations];
                        newEducation[index].education = e.target.value;
                        return newEducation;
                      });
                    }}
                  />
                  <br />

                  <DatePicker
                    required
                    name="start_date"
                    label={t("education-l3")}
                    placeholder="15.09.2016"
                    value={
                      education.start_date
                        ? new Date(education.start_date)
                        : null
                    }
                    onChange={(date) => {
                      setEducationArray((educations) => {
                        const newEducation = [...educations];
                        let dateX = new Date(date);
                        dateX.setDate(dateX.getDate() + 1);
                        newEducation[index].start_date = dateX
                          .toISOString()
                          .split("T")[0];
                        return newEducation;
                      });
                    }}
                  />
                  <br />

                  <Checkbox
                    name="is_present"
                    label={t("education-l4")}
                    required
                    checked={education.is_present}
                    onChange={(e) => {
                      setEducationArray((educations) => {
                        const newEducation = [...educations];
                        newEducation[index].is_present = e.target.checked;
                        return newEducation;
                      });
                    }}
                  />
                  <br />

                  {!education.is_present && (
                    <DatePicker
                      required={!education.is_present}
                      name="end_date"
                      label={t("education-l5")}
                      placeholder="21.05.2020"
                      value={
                        education.end_date ? new Date(education.end_date) : null
                      }
                      onChange={(date) => {
                        setEducationArray((educations) => {
                          const newEducation = [...educations];
                          let dateX = new Date(date);
                          dateX.setDate(dateX.getDate() + 1);
                          newEducation[index].end_date = dateX
                            .toISOString()
                            .split("T")[0];
                          return newEducation;
                        });
                      }}
                    />
                  )}
                  <br />
                </div>

                <div className="buttons">
                  <div className="groupOne">
                    <Button
                      className="btn btn-submit"
                      variant="yellow"
                      type="button"
                      onClick={(e) => patchOrPost(e, index)}
                    >
                      {t("education-bSend")}
                    </Button>
                    <Button
                      className="btn btn-cancel"
                      variant="filled"
                      type="button"
                      onClick={() => handleCancel()}
                    >
                      {t("education-bCancel")}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="education-item">
                <div key={index} id={education.id} className="education-item-left">
                  <div className="education-img">
                    <img src={educationIcon} alt="" />
                  </div>
                  <div className="education-detail">
                    <div className="education-date">
                      {" "}
                      {education.is_present === true
                        ? education?.start_date + " - İndi"
                        : education?.start_date + " - " + education?.end_date}
                    </div>
                    <h5>
                      {education.education} UX/UI Design{" "}
                      <span className="position">Bachelor’s degree</span>
                    </h5>
                    <div className="education-company">
                      {education.college} Baku Engineering University
                    </div>
                  </div>
                </div>
                <div className="item-manage">
                  {manageShow && (
                    <>
                      <div onClick={(e) => handleDelete(e, index)}>
                        <img src={deleteIcon} alt="" />
                      </div>

                      <div onClick={() => handleEdit(index)}>
                        <img src={editIcon} alt="" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </form>
      </div>
    </EducationStyle>
  );
}

export default Education;

const EducationStyle = styled.div`
  background: #fff;
  padding: 40px 32px;
  border-radius: 12px;
  margin-bottom: 45px;
  .education-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    h2 {
      color: #202939;
      font-family: "Euclid";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 140% */
    }

    .header-icons {
      display: flex;
      gap: 12px;
      .header-icon-item {
        padding: 12px;
        border-radius: 8px;
        transition:0.3s;
        cursor: pointer;
        &:hover{
         background: #EEF2F6;
        }
      }
    }
  }

  .education-box {
    .education-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0px;
      border-bottom: 1px solid #cdd5df;
      .education-item-left{
        display: flex;
      align-items: center;
      gap: 14px;
        .education-img {
        width: 64px;
        height: 64px;
        background: #efefef;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        img {
          width: 60%;
        }
      }

      .education-date {
        color: #9aa4b2;
        font-family: "Euclid";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        margin-bottom: 4px;
      }

      h5 {
        margin: 0px;
        color: #121926;
        font-family: "Euclid";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 100% */
        /* display: flex;
        align-items: center; */
        margin-bottom: 4px;
        .position {
          color: #4b5565;
          font-size: 12px;
          font-weight: 400;
          line-height: 150%;
          margin-left: 12px;
        }
      }

      .education-company {
        color: #4b5565;
        font-family: "Euclid";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
      }


      .item-manage {
        display: flex;
        gap: 12px;
        div {
          padding: 12px;
        }
      }
    }
  }

  //OLD Styles

  button {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: clamp(1rem, 2vw, 1.05rem);
  }
  h2,
  h3 {
    font-weight: 500;
  }
  .insideWrapper {
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
    hr {
      border: none;
      border-bottom: 1px solid #ddd;
      margin-top: 1rem;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .addItem {
      background: none;
      font-size: 17px;
      color: #2f80ed;
      font-weight: 400;
      border: none;
      cursor: pointer;
    }
    svg {
      color: #000;
    }
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .manageEntry {
      display: flex;
      button {
        display: flex;
        border: none;
        font-weight: 500;
      }
    }
    .info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      h3 {
        margin: 0;
        font-size: 17px;
      }
      p {
        margin: 0.5rem 0;
      }
      ins {
        margin: 0;
        text-decoration: none;
        font-size: 14px;
        color: #666;
      }
    }
  }
  .editing {
    padding: 1rem 1.25rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    align-items: center;
    .buttons {
      .groupOne {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
      }
      button {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .insideWrapper {
      width: 100%;
      margin: 0 auto;
    }
  }
`;
