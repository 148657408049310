import { useEffect, useState } from "react";
import { Spoiler } from "@mantine/core";
import { useParams } from "react-router-dom";
import { BrandFacebook, BrandInstagram, BrandTwitter } from "tabler-icons-react";
import styled from "styled-components";
import defaultImage from "../../../Assets/Images/defaultImage.webp";
import axios from "axios";
import PersonalCourses from "../../../Components/PersonalCourses";

const ConsultantProfile = () => {

  const { id } = useParams();
  const [ userData, setUserData ] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.get(`user/${id}/`);
      setUserData(response.data);
    })()
    return () => {
      setUserData([]);
    };
  }, []);

  return (
    <ProfileStyle>
      <div className="wrapper">
        <div className="userInfo">
          <img
            src={
              userData?.profile_img?.includes("media/download.png")
                ? defaultImage
                : userData?.profile_img
            }
            alt=""
          />
          <div>
            <h1 style={{marginTop: userData?.id === 9 ? "2.25rem" : ""}}>{userData?.first_name + " " + userData?.last_name}</h1>
            <div className="occuPoints">
              <h3>
                {(userData?.speciality?.content) && (userData?.id !== 9 ) && (
                  <p style={{ color: "#aaa", margin: "0" }}>
                    speciality not chosen
                  </p>
                )}
              </h3>
            </div>
          </div>
        </div>
        <div className="about">
          <div className="bioAndCerts">
            <div className="bio">
              <h2>Biography</h2>
              <Spoiler maxHeight={95} showLabel="Show more" hideLabel="Hide">
                <p>{userData?.bio}</p>
              </Spoiler>
            </div>
            <hr />
          </div>
          <div className="links">
            <a href="">
              <BrandFacebook/> <ins>Facebook</ins>
            </a>
            <a href="">
              <BrandInstagram/> <ins>Instagram</ins>
            </a>
            <a href="">
              <BrandTwitter/> <ins>Twitter</ins>
            </a>
          </div>
        </div>

        <h2>Courses by {`${userData.first_name} ${userData.last_name}`}</h2>

      </div>
      <PersonalCourses userData={userData}  className="oo"/>
    </ProfileStyle>
  );
};

export default ConsultantProfile;

const ProfileStyle = styled.div`
  background-color: #fff;
  margin: 0 auto 5rem 0;
  border: 1px solid transparent;
  h2{
    font-weight: 500;
  }
  .wrapper {
    width: 90%;
    margin: 0 auto;
    padding: 0.5rem auto 5rem auto;
    overflow: hidden;
    .currentLocation {
      color: #888;
      svg {
        margin: 0.3rem;
        margin-bottom: 0.1rem;
        transform: rotate(-90deg);
      }
      ins {
        text-decoration: none;
        color: #000;
        font-weight: 500;
      }
    }
    .userInfo {
      display: flex;
      justify-content: center;
      margin: 3rem 0 5rem 0;
      img {
        width: 7rem;
        height: 7rem;
        border-radius: 100%;
        margin-right: 2rem;
        object-fit: cover;
      }
      h1 {
        margin-bottom: 0;
        font-weight: 500;
      }
      .occuPoints {
        display: flex;
        align-items: center;
        h3 {
          font-weight: 500;
          margin: 0.5rem 0;
        }
        span {
          border-left: 1px solid #ccc;
          height: 1.6rem;
          margin: 0.2rem 0 0 1rem;
        }
        .points {
          margin-left: 1rem;
          display: flex;
          align-items: center;
          svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
    .about {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.5rem;
      img {
        object-fit: cover;
      }
      hr {
        margin: 2rem 0;
        border: none;
        border-bottom: 2px solid #eee;
      }
      .bioAndCerts {
        h2 {
          font-weight: 500;
        }

        .bio {
          p {
            width: 90%;
            padding-bottom: 1rem;
          }
        }
      }
      .links {
        display: flex;
        justify-content: space-around;
        margin-top: 2rem;
        a {
          display: flex;
          border: 1px solid #ddd;
          height: max-content;
          padding: 1rem 1.5rem;
          border-radius: 0.5rem;
          align-items: center;
          text-decoration: none;
          color: #000;
          font-weight: 500;
          ins {
            margin-left: 1rem;
            text-decoration: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .wrapper {
      .about {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.5rem;
        .links {
          display: block;
          margin-top: 1rem;
          a {
            font-size: 14px;
            padding: 0.75rem 1rem;
            margin: 0.5rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    .wrapper {
      padding: 0.5rem 1rem 0rem 1rem;
      .currentLocation {
        text-align: center;
      }
      .userInfo {
        flex-direction: column;
        margin: 3rem auto;
        h1 {
          width: max-content;
        }
        img {
          margin: 0rem auto;
        }
        div {
          margin: 0 auto;
        }
        .occuPoints {
          display: flex;
          width: max-content;
          justify-content: center;
          text-align: center;
        }
        margin: 3rem 0 2rem 0;
      }
      .about {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        img {
          object-fit: cover;
        }
        hr {
          margin: 2rem 0;
          border: none;
          border-bottom: 2px solid #eee;
        }
        .bioAndCerts {
          order: 2;
          h2 {
            font-weight: 500;
          }

          .bio {
            p {
              width: 90%;
            }
          }
        }
        .certificationInfo {
          display: block;
        }
        .links {
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          margin: 0 auto;
          a {
            font-size: 14px;
            padding: 0.5rem 0.75rem;
            margin: 0.5rem;
          }
        }
      }
    }
  }
`;
