import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const Skills = ({ props }) => {
  const { t, i18n } = useTranslation();

  const location = useLocation();

  const { skillsData, isTaskool } = props;

  // let texts = isTaskool ?
  // {
  //   header: "Bacarıqlar",
  //   bSend: "Göndər",
  //   bEdit: "Redaktə et",
  //   bDelete: "Sil",
  //   bCancel: "Ləğv et",
  //   p1: "Bacarıq",
  //   nt1: "Bacarıq əlavə edildi",
  //   nt2: "Bacarıq redaktə edildi",
  //   nt3: "Bacarıq silindi",
  //   ntm1: "Bacarıq uğurla əlavə edildi",
  //   ntm2: "Bacarıq uğurla redaktə edildi",
  //   ntm3: "Bacarıq uğurla silindi",
  //   e1: "Bacarıq əlavə edilərkən xəta baş verdi",
  //   e2: "Zəhmət olmasa tələb olunan xanaları doldurun",
  //   e3: "Hal-hazırda işə getmirsinizsə, bitmə tarixini doldurmalısınız",
  // }
  // :
  // {
  //   header: "Skills",
  //   bSend: "Submit",
  //   bEdit: "Edit",
  //   bDelete: "Remove",
  //   bCancel: "Cancel",
  //   p1: "Skill",
  //   nt1: "Skill added",
  //   nt2: "Skill edited",
  //   nt3: "Skill deleted",
  //   ntm1: "Skill successfully added",
  //   ntm2: "Skill successfully edited",
  //   ntm3: "Skill successfully deleted",
  //   e1: "An error occurred while adding skill",
  //   e2: "Please fill in the required fields",
  //   e3: "If you are not currently working, please fill in the end date",
  // }

  return (
    <SkillsStyle>
      <div className="wrapperSkills">
        <div className="title">
          <h2>{t("skills")}</h2>
        </div>
        <div className="skills">
          {skillsData?.length > 0 &&
            skillsData?.map((skill, index) => {
              return (
                <li key={skill?.id}>
                  {index !== 0 && <hr />}
                  <div className="manageEntry">
                    <b>{skill?.skill?.skill}</b>
                    <p>-</p>
                    <p>{skill?.rank?.rank}</p>
                  </div>
                </li>
              );
            })}
        </div>
      </div>
    </SkillsStyle>
  );
};

const SkillsStyle = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  hr {
    border: none;
    margin-inline: auto;
    border-bottom: 1px solid #eee;
  }
  .wrapperSkills {
    padding: 1.5rem 2rem 2rem 2rem;
    h2 {
      font-weight: 500;
    }
    button {
      font-weight: 500;
      font-size: clamp(1rem, 2vw, 1.05rem);
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .skills {
      li {
        list-style: none;
        margin: 0 auto;
        .manageEntry {
          display: flex;
          align-items: center;
          grid-gap: 0.5rem;
          b {
            font-weight: 500;
          }
          p {
            margin: 0.25rem 0;
            font-size: clamp(1rem, 2vw, 1.1rem);
          }
        }
      }
      .editSkill {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .input {
          width: 100%;
          input {
            font-size: clamp(1rem, 2vw, 1.1rem);
            margin-left: 1rem;
            min-width: max-content;
          }
        }
        .buttonGroup {
          display: flex;
          min-width: max-content;
          margin-left: 2rem;
          margin-right: 2rem;
          grid-gap: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0rem 1rem;
    border-radius: 0rem;
    margin: 0.75rem 0;
    hr {
      width: 100%;
    }
    .wrapperSkills {
      padding: 0.5rem 0.5rem 2rem 0.5rem;
      padding: 0.5rem 0rem 2rem 0rem;
      button {
      }
      .title {
      }
      .skills {
        li {
          .manageEntry {
            p {
              margin: 0;
            }
            .buttonGroup {
              margin: 0;
              margin-right: -0.75rem;
            }
          }
        }
        .editSkill {
          display: block;
          .input {
            margin: 0;
            input {
              margin: 0;
            }
          }
          .buttonGroup {
            display: flex;
            justify-content: center;
            margin: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 330px) {
    .wrapperSkills {
      .skills {
        li {
          .manageEntry {
            display: block;
          }
        }
      }
    }
  }
`;
