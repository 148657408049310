import { React } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const SchoolCreatedModal = () => {
  return(
    <SchoolCreatedModalStyle>
      <div className="containerModule">
        <h2>You have successfully created a school</h2>
        <Link to="/schools">View all schools</Link>
        <button onClick={() => window.location.reload()}>Create another one</button>
      </div>
    </SchoolCreatedModalStyle>
  )
}

const SchoolCreatedModalStyle = styled.div`
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000bb;
  .containerModule{
    display: flex;
    background-color: #eee;
    flex-direction: column;
    align-items: center;
    padding: 5rem;
    border-radius: 0.5rem;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 12%;
    text-align: center;
    a{
      margin: 2rem;
      background-color: var(--meadow);
      padding: 0.75rem 2rem;
      border-radius: 0.25rem;
      transition: 0.1s all;
      &:hover {
        background-color: var(--meadow-shadow);
      }
    }
    button {
      border: none;
      font-weight: 500;
      cursor: pointer;
      font-size: 15px;
      &:hover {
        color: #555;
      }
    }
  }
`