import axios from "axios";
import { getCookie } from "./useCookie";

let aTokenX = getCookie("access");

let backend = "https://backend.taskool.com/"
let backendTest = "https://backend-test.taskool.com/"

axios.defaults.baseURL = backend;
if(aTokenX) {
  axios.defaults.headers.common = {'Authorization': `Bearer ${aTokenX}`};
}

export default axios;