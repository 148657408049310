import { Avatar, Text, Group } from "@mantine/core";
import { Phone, Mail } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";

export const Speaker = () => {
  const isMobile = useMediaQuery("(max-width: 332px)");

  return (
    <div
      style={{
        border: "0.2px solid gray",
        borderRadius: "10px",
      }}
    >
      <Group
        wrap="nowrap"
        style={{
          display: isMobile ? "flex" : "",
          justifyContent: isMobile ? "center" : "",
          textAlign: isMobile ? "center" : "",
          padding: isMobile ? "1rem" : "",
        }}
      >
        <Avatar
          src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-2.png"
          size={94}
          radius="md"
        />
        <div>
          <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
            Software engineer
          </Text>

          <Text fz="lg" fw={500}>
            Robert Glassbreaker
          </Text>

          <Group wrap="nowrap" gap={10} mt={3}>
            <Mail size="1rem" />
            <Text fz="xs" c="dimmed">
              robert@glassbreaker.io
            </Text>
          </Group>

          <Group wrap="nowrap" gap={10} mt={5}>
            <Phone size="1rem" />
            <Text fz="xs" c="dimmed">
              +11 (876) 890 56 23
            </Text>
          </Group>
        </div>
      </Group>
    </div>
  );
};
