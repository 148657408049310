import { React, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SchoolCreatedModal } from "./SchoolCreatedModal";
import { SchoolDetailForm } from "./SchoolDetailForm";

const _CreateSchool = () => {

  const [ creationFinished, setCreationFinished ] = useState(false);

  return (
    <CreateSchoolStyle>
      { creationFinished && <SchoolCreatedModal/> }
      <div className="header">
        <h3>Publish a new school</h3>
        <Link to="/my-games">Exit</Link>
      </div>
      <div className="wrapperUpload">
        <SchoolDetailForm setCreationFinished={setCreationFinished}/>
      </div>
    </CreateSchoolStyle>
  )
};

export default _CreateSchool;

const CreateSchoolStyle = styled.div`
  position: relative;
  background-color: #fff;
  .wrapperUpload{
    width: 85%;
    margin: 0 auto;
    padding-bottom: 8rem;
  }
  a{
    text-decoration: none;
    color: #fff;

  }
  h2,h3,h4{
    font-weight: 500;
    margin: 0;
  }
  .header{
    display: flex;
    justify-content: space-between;
    background-color: var(--umber);
    color: #fff;
    padding: 1rem 7rem;
    border: 1px;
  }
  .submit{
    display: flex;
    justify-content: end;
    button{
      font-family: "Euclid";
      padding: .8rem 4rem;
      border: none;
      border-radius: 0.5rem;
      font-weight: 500;
      background-color: var(--yellow);
      cursor: pointer;
      transition: .1s all;
      &:hover{
        background-color: #aaa;
      }
    }
  }
  @media screen and (max-width:1024px){
    border-bottom: 1px solid transparent;
    .header{
      display: flex;
      justify-content: space-between;
      padding: 1rem;
    }
  }
`