import React, { useState } from "react";
import styled from "styled-components";

const TagList = ({tagDatas}) => {

  return (
    <TagsContainer>
      {tagDatas?.map((tag,index) => (
        <Tag key={index} >{tag?.skill.skill}</Tag>
      ))}
    </TagsContainer>
  );
};

export default TagList;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  background-color: #eafeff;
  padding: 10px 19px;
  border-radius: 17px;
  font-size: 16px;
  color: #029199;
  position: relative;
  font-weight: 500;
  border: 0.5px solid #029199;
`;
