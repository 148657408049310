import styled from "styled-components";

export const Internship = ({ props }) => {

  const { userInternships, isTaskool } = props;

  function ReturnInternshipRequest(){
    return (
      <div className="returnInternshipRequest">
        <div>
          <h3>Speciality: </h3>
          <b>{userInternships?.speciality?.content}</b>
        </div>
        <div>
          {userInternships?.country?.length > 1 ? <h3>Countries: </h3> : <h3>Country: </h3>}
          <b>{userInternships?.country?.map((c, index) => {return userInternships.country.length - 1 > index ? c.name + ", " : c.name})}</b>
        </div>
        <div>
          <h3>Date: </h3>
          <b>{userInternships?.intern_date}</b>
        </div>
      </div>
    )
  }

  return (
    <IntroStyle>
      <div>
        <h2>Internship request</h2>
        <div className="internshipChosen">
          {
            userInternships?.country?.length > 0 ?
            <ReturnInternshipRequest />
            :
            <p>No internship request yet</p>
          }
        </div>
      </div>
    </IntroStyle>
  );
};

const IntroStyle = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 2rem 2rem 0.5rem 2rem;
  h2{
    font-weight: 500;
    padding-top: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  h3{
    font-weight: 500;
  }
  .cancel{
    width: 100%;
    margin-top: 1rem;
  }
  .buttonApply{
    width: 100%;
  }
  .internshipChosen{
    display: flex;
    align-items: center;
    justify-content: space-between;
    div{
      display: flex;
      button{
        padding: 0;
        margin-right: 1rem;
        font-weight: 500;
        &:hover{
          background-color: initial;
        }
      }
    }
    h3{
      font-weight: 400;
    }
    button{
      width: fit-content;
    }
    .returnInternshipRequest{
      display: block;
      margin: 1.5rem 0;
      margin-left: 1rem;
      div{
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        h3{
          margin: 0;
        }
        b{
          font-weight: 500;
          margin-left: 1rem;
          margin-top: .2rem;
        }
      }
    }
  }
  .participate{
    h3{
      font-weight: 400;
    }
    button{
      width: fit-content;
    }
  }
  form{
    h2{
      padding-top: 0;
      margin-top: 0;
    }
  }
  .buttons{
    margin-top: -.5rem;
    margin-right: 2rem;
    button{
      font-size: 16px;
      margin-left: 1rem;
      font-size: clamp(1rem, 2vw, 1.05rem);
    }
  }
  @media screen and (max-width: 768px) {
    border-radius: 0;
    margin-top: .75rem;
    padding: 1rem;
    .internshipChosen{
      display: block;
      .returnInternshipRequest{
        margin-left: 0;
      }
    }
    .buttons{
      display: flex;
      justify-content: center;
      button{
        font-size: 16px;
      }
    }
  }
`;