import React from "react";
import styled from "styled-components";
import editIcon from "../../../Assets/SVGs/profile/edit.svg";
import readMoreIcon from "../../../Assets/SVGs/profile/read-more.svg";
import { Link } from "react-router-dom";


function AboutGoals({ props }) {
  const { user, isTaskool } = props;
  return (
    <AboutGoalsStyle>
      <div className="about">
        <div className="about-header">
          <h2>About</h2>{" "}
          <Link to="/settings">
          <div className="edit">
            <img src={editIcon} alt="" />
          </div>
          </Link>
        </div>
        <p>
         {user.bio}
        </p>
      </div>

      {/* <div className="line"></div>
      <div className="goals">
        <div className="goals-header">
          <h2>Goals</h2>{" "}
          <div className="read-more">
            <img src={readMoreIcon} alt="" />
            Read more
          </div>
        </div>

        <p>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
          If you are going to use a passage of Lorem Ipsum. There are many
          variations of passages of Lorem Ipsum avail
        </p>
      </div>

      <div className="tasks-box">
        <div className="task-item">
          <h3>Weekly</h3>
          <ul>
            <li>C#</li>
            <li>C++</li>
            <li>Raect</li>
          </ul>
        </div>

        <div className="task-item">
          <h3>Monthly</h3>
          <ul>
            <li>MySQL</li>
            <li>PostgreSQL</li>
            <li>MongoDB</li>
          </ul>
        </div>

        <div className="task-item">
          <h3>Yearly</h3>
          <ul>
            <li>Python</li>
            <li>Go</li>
            <li>PHP</li>
          </ul>
        </div>
      </div> */}
      </AboutGoalsStyle>
  );
}

export default AboutGoals;

const AboutGoalsStyle = styled.div`
  background-color: #fff;
  padding: 40px 32px;
  margin-bottom: 24px;
  border-radius: 12px;

  h2 {
    color: #202939;
    font-family: "Euclid";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
  }

  p {
    color: #1a1d1c;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin-bottom: 32px;
  }

  .line {
    width: 906px;
    height: 1px;
    background-color: #cdd5df;
  }

  .about-header {
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .edit{
              padding: 12px;
        border-radius: 8px;
        transition:0.3s;
        cursor: pointer;
        &:hover{
         background: #EEF2F6;
        }
    }
  }

  .goals-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;

    .read-more {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }
  }

  .tasks-box {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    .task-item {
      padding: 16px;
      width: 100%;
      border-radius: 16px;
      background: #fffcf5;

      h3 {
        color: #dc6803;
        font-family: "Euclid";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin-bottom: 14px;
      }

      ul {
        list-style: none;
        padding: 0px;

        li {
          margin-bottom: 5px;
          overflow: hidden;
          color: #121926;
          text-overflow: ellipsis;
          font-family: "Inter";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }
      }
    }
  }
`;
