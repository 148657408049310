import axios from "axios";
import React from "react";
import styled from "styled-components";
import { CourseCard } from "../Components/Cards/CourseCard"
import { useEffect, useState } from "react";

const PersonalCourses = ({ userData }) => {

  const [ teacherCourses, setTeacherCourses ] = useState([]);

  useEffect(() => {
    (async () => {
        if(userData?.id){
          const response = await axios.get(`api/usercourselist/${userData?.id}/`);
          setTeacherCourses(response.data);
        }
      }
    )()
    return () => {
      setTeacherCourses([]);
    }
  }, [userData]);

  return (
    <Container>
      <div className="coursesWrapper">
        <div className="courses">
          {teacherCourses.map((course) => {
            return (
              <CourseCard course={course} key={course.id}/>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default PersonalCourses;

const Container = styled.div`
  padding-left: 2.5%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  .courses{
    display: flex;
    grid-gap: 1.5rem;
  }
`;
