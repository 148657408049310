import React from "react";
import styled from "styled-components";
import { Group, Stack, Text } from "@mantine/core";
import { Check } from "tabler-icons-react";
import { Link } from "react-router-dom";
import CustomCheckbox from "../Filter/CustomCheckbox";

const UniversityCard = ({ props }) => {
  const { tHeadData, item, professionIds, setProfessionIds, type, applyTo } =
    props;

  const columnWidth = `calc(100% / ${tHeadData.length})`;
  const formatCurrency = (value) => {
    return (
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      })
        .format(value)
        .replace("$", "")
        .trim() + " $"
    );
  };

  const handleCheckboxChange = () => {
    if (professionIds.includes(item.id)) {
      setProfessionIds((prev) => prev.filter((id) => id !== item.id));
    } else {
      setProfessionIds((prev) => [...prev, item.id]);
    }
  };

  return (
    <Container>
      <UniData type={applyTo} columnWidth={columnWidth}>
        {applyTo === "agents" && (
          <div>
            <CustomCheckbox
              className="check"
              checked={professionIds.includes(item.id)}
              onChange={handleCheckboxChange}
            />
          </div>
        )}
        <div>
          <Link
            to={`/universities/${item?.university.id}/programs/${item?.id}`}
          >
            <Group
              className="university-title"
              spacing={12}
              noWrap
              align="center"
            >
              <img
                style={{ width: "35px", height: "35px" }}
                src={item?.university.logo}
                alt="Logo"
              />
              <span style={{ width: "70%" }}>{item?.university?.title}</span>
            </Group>
          </Link>
        </div>
        <div>
          <span>{item?.university?.country?.region?.name}</span>
        </div>
        <div>
          <span>{item?.university?.country?.name}</span>
        </div>
        <div>
          <span>{item?.profession}</span>
        </div>
        <div>
          <span>{formatCurrency(item?.cost)}</span>
        </div>
        <div>
          <span>
            {item?.is_scholarship ? <Check /> : <Check color="transparent" />}
          </span>
        </div>
        <div>
          <Stack spacing={2}>
            {item?.degree?.length > 0
              ? item.degree.map((item) => (
                  <Text key={item?.id} size="sm" color="muted" span style={{ marginLeft: 5 }}>
                    {item?.name}
                  </Text>
                ))
              : "N/a"}
          </Stack>

          {/* <Link
            to={`/universities/${item?.university.id}/programs/${item?.id}`}
          >
            <img src={arrow} alt="arrow" />
          </Link> */}
        </div>
      </UniData>
    </Container>
  );
};

export default UniversityCard;

const Container = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 1px 10px 0px #0000001a;
  @media screen and (max-width: 576px) {
    width: 716px;
    margin: 0 4px;
  }
`;

const UniData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 2px 25px 15px;

  span {
    font-size: 14px;
    font-weight: 500;
    color: #606060;
  }
  @media (max-width: 576px) {
    width: 700px;
    position: relative;
    top: 0;
  }

  // div:nth-child(1){
  //   width: 5%;
  //   div{
  //     width: 21px;
  //     height: 21px;
  //   }
  // }
  // div:nth-child(2) {
  //   width: 20%;
  // }
  // div:nth-child(5),
  // div:nth-child(3),
  // div:nth-child(4) {
  //   width: 15%;
  // }
  // div:nth-child(8){
  //   width: 10%;
  //   display:flex;
  //   justify-content: center;
  // }
  // div:nth-child(6) {
  //   width: 10%;
  // }
  // div:nth-child(7)
  //   width: 5%;
  // }

  div:nth-child(1) {
    width: ${(props) => (props.type === "agents" ? "5%" : "20%")};
    div {
      width: ${(props) => (props.type === "agents" ? "21px" : "")};
      height: ${(props) => (props.type === "agents" ? "21px" : "")};
    }
    
  }
  div:nth-child(2) {
    width: ${(props) => (props.type === "agents" ? "20%" : "16%")};
  }

  div:nth-child(3) {
    width: ${(props) => (props.type === "agents" ? "15%" : "16%")};
  }
  div:nth-child(4) {
    width: ${(props) => (props.type === "agents" ? "15%" : "16%")};
  }

  div:nth-child(5) {
    width: ${(props) => (props.type === "agents" ? "15%" : "11%")};
  }
  div:nth-child(6) {
    width: ${(props) => (props.type === "agents" ? "10%" : "6%")};
  }

  div:nth-child(7) {
    width: ${(props) => (props.type === "agents" ? "5%" : "10%")};
    display: ${(props) => (props.type === "university" ? "flex" : "")};
    justify-content: ${(props) => (props.type === "university" ? "" : "")};
  }

  div:nth-child(8) {
    width: ${(props) => (props.type === "agents" ? "10%" : "0%")};

  }
  .university-title {
    width: 100% !important;
  }
`;
