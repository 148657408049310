import React from 'react';
import { Button, FileInput } from '@mantine/core';
import { Download, Paperclip } from 'tabler-icons-react';
import { Link } from 'react-router-dom';

const CustomFileInput = ({t, label, name, setValue, addFileToState, hiddenInputId,type,to,setShowAddButton,disabled,error,watch }) => {
  const handleFileChange = (event) => {
    const file = event;
    if (addFileToState) {
      addFileToState(file);
    } else {
      setValue(name, file);
      setShowAddButton && setShowAddButton(prev => true)
    }
  };

  return (
    <div>
      <FileInput
        radius={10}
        size="lg"
        placeholder={t('pdf-file')}
        label={label}
        withAsterisk={(name === "additional-document" || name === "certificate_graduation") ? false : true}
        style={{ fontSize: "16px",width : "100%" }}
        onChange={handleFileChange}
        disabled={disabled}
        error={error && error}
        watch={watch}
        
        rightSection={
          type !== "download"?
          <Button
            variant="outline"
            size="sm"
            radius={10}
            className="upload-btn"
            rightIcon={
              <Paperclip
                style={{ rotate: "-45deg" }}
                size={12}
                strokeWidth={1.5}
                color={"black"}
              />
            }
            onClick={(e) => {
              e.preventDefault();
              if (label === 'Additional document') {
                document.querySelector(`#${hiddenInputId}`).click();
              } else {
                document.querySelector('input[type="file"]').click();
              }
            }}
          >
            {t("upload-file")}
          </Button> :

          <Button
          rightIcon={<Download strokeWidth={1.5} color='#029199' />}
          style={{
            border : '1px solid #029199',
            background : "#EFF8FF",
            color: "#029199",
            fontSize: "16px",
            fontWeight: 500
          }}
          className='download'
          radius={10}
          >
            <a style={{textDecoration:"none",color:"#029199"}} href={to} target='_blank' rel="noreferrer" >
            {t("download")}
            </a>
          </Button>

        }
      />
    </div>
  );
};

export default CustomFileInput;
