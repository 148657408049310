import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useContext, useEffect, useState } from "react"
import { AppShell } from "@mantine/core"
import { VideoSection } from "./VideoSection"
import axios from "axios"
import { VideoList } from "./VideoList"
import { useMediaQuery } from "@mantine/hooks"
import { typog } from "../../../Assets/SVGs/topography"
import { AppContext } from "../../../Helpers/Context"

export const Videos = () => {

  const [ chosenSection, setChosenSection ] = useState(0)
  const [ modalOpen, setModalOpen ] = useState(false)
  const [ videos, setVideos ] = useState([])

  const { user } = useContext(AppContext)

  useQuery(["videos"],
    async () => {
      return await axios.get("/forum/recommendation_list/").then(res => res.data).then(res => res.reverse())
    },
    {
      onSuccess: (data) => {
        setVideos(data.map(e => ({...e, watched:false })))
        if(chosenSection === 0){
          setChosenSection(data[0]?.id)
        }
      }
    }
  )

  const { data:lastWatched, isFetched } = useQuery(["lastWatchedVideoId"],
    async () => {
      return await axios.get("/forum/user_choose_recommendation/").then(res => res.data)
    },
    {
      onSuccess: (data) => {
        if(data[0]?.recommendation){
          setChosenSection(data[0]?.recommendation)
          setVideos(prev => prev.map(e => ({ ...e, watched:e.id <= data[0]?.recommendation ? true : false })))
        }
      },
      enabled: !!videos.length > 0
    }
  )

  useEffect(() => {
    if(lastWatched && chosenSection !== 0){
      setTimeout(() => {
        if( lastWatched.length < 1 || ( chosenSection > lastWatched[0]?.recommendation ) ){
          axios.post("/forum/create/",{
            user:user.id,
            recommendation:chosenSection
          })
        }
        setVideos(prev => prev.map(e => ({ ...e, watched:(e.id === chosenSection || e.watched === true || e.id <= lastWatched[0]?.recommendation) && true })))
      }, 500);
    }
  }, [chosenSection])

  const matches = useMediaQuery('(min-width: 800px)');

  return (
    <AppShell
      padding={0}
      navbar={matches ? <VideoList props={{ videos, chosenSection, mini:false }}/> : null}
      styles={(theme) => ({
        root: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[7],
          color: theme.colorScheme === 'dark' ? "#000" : "#fff",
          backgroundImage: typog
        }
      })}
    >
      {
        isFetched === true &&
        <VideoSection props={{ videos, chosenSection, setChosenSection, modalOpen, setModalOpen }}/>
      }
      {
        !matches &&
        <VideoList props={{ videos, chosenSection, mini:true }}/>
      }
    </AppShell>
  )
}