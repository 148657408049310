import React, { useContext } from "react";
import greenLogo from "../../../Assets/SVGs/logo/greenLogo.svg";
import styled from "styled-components";
import {
  Button,
  Group,
  Progress,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { navLinks } from "./data";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import profileTop from "../../../Assets/SVGs/dashboard/profileTop.svg";
import { Mail, TrendingUp } from "tabler-icons-react";
import Interest from "../../../Assets/SVGs/dashboard/interest.svg";
import vector from "../../../Assets/SVGs/dashboard/Vector.svg";
import tipLogo from "../../../Assets/Images/tiplogo.png";
import { AppContext } from "../../../Helpers/Context";
import userImg from "../../../Assets/SVGs/header/user.png";
import { useMediaQuery } from "@mantine/hooks";
const NavbarContent = ({t}) => {
  const { user } = useContext(AppContext);
  const sizeXl = useMediaQuery("(min-width: 1460px)");
  const sizeLg = useMediaQuery("(min-width: 1370px)");
  const sizeMd = useMediaQuery("(min-width: 1300px)");

  return (
    <section style={{ width: "100%" }}>
      {user && (
        <ProfileInfo>
          <img
            style={{ borderRadius: "15px 15px 0 0", width: "100%",height:"74px" }}
            src={profileTop}
            alt="Profile-Top"
          />

          <img
            style={{}}
            className="profile-img"
            src={user?.profile_img}
            alt="user-img"
          />

          <Stack
            spacing={10}
            sx={{
              borderRadius: "0 0 15px 15px",
              padding: "60px 20px 20px 20px",
              marginTop: "-4px",
            }}
            align="center"
          >
            <Link to="/profile">
              <Title
                order={3}
                weight={700}
                color="#364152"
                size={sizeXl ? 20 : 18}
              >
                {user?.first_name} {user?.last_name}
              </Title>
            </Link>
            <Group noWrap spacing={5}>
              <Mail size={sizeXl ? 20 : 18} strokeWidth={1.5} color="#989898" />
              <Text size={sizeXl ? 16 : 14} weight={400} color="#989898">
                {user?.email}
              </Text>
            </Group>
            <div
              style={{
                width: "70%",
                height: "1px",
                backgroundColor: "#dcdcdc",
              }}
            ></div>

            <Group>
              <Text size={sizeXl ? 16 : 15} weight={400} color="#989898">
                SAT: N/a
              </Text>
              <Text size={sizeXl ? 16 : 15} weight={400} color="#989898">
                |
              </Text>
              <Text size={sizeXl ? 16 : 15} weight={400} color="#989898">
                IELTS: N/a
              </Text>
            </Group>
            <Button
              styles={{
                root: {
                  backgroundColor: "#D8FDFF",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#029199",
                  borderRadius: "15px",
                  marginTop: "10px",
                  "&:hover": {
                    backgroundColor: "#D8FDFF",
                  },
                },
              }}
              sx={
                sizeXl
                  ? {
                      width: "100%",
                      height: "48px",
                    }
                  : {
                      width: "100%",
                      height: "42px",
                    }
              }
              leftIcon={<img src={Interest} alt="Interest" />}
            >
              {t('interest')} | {user?.fields[0]?.field}
            </Button>
          </Stack>
        </ProfileInfo>
      )}
      {/* <ActivitiesContainer>
        <div className="activities">
          <Title
            sx={{ marginBottom: "15px" }}
            weight={700}
            color="#364152"
            size={18}
          >
            My activities
          </Title>
          <Stack>
            <Group align="start">
              <div
                style={{
                  width: "64px",
                  height: "56px",
                  borderRadius: "11px",
                  border: "1px solid #000",
                }}
              ></div>
              <Stack spacing={7}>
                <Text>It is a long established</Text>
                <Progress animate value={50} />
              </Stack>
            </Group>
            <div
              style={{
                height: "1px",
                width: "100%",
                backgroundColor: "#DCDCDC",
              }}
            ></div>
            <Group align="start">
              <div
                style={{
                  width: "64px",
                  height: "56px",
                  borderRadius: "11px",
                  border: "1px solid #000",
                }}
              ></div>
              <Stack spacing={7}>
                <Text>It is a long established</Text>
                <Progress animate value={50} />
              </Stack>
            </Group>
          </Stack>
        </div>
      </ActivitiesContainer> */}
      <Link to="/tip">
        <TipContainer user={user}>
          <img className="vector-img" src={vector} alt="Vector" />
          {!user && (
            <div style={{ margin: "30px" }}>
              <img width={45} height={46} src={tipLogo} alt="tipLogo" />
            </div>
          )}
          <Stack align="center" mt={user ? 16 : 50}>
            <Group sx={{ zIndex: 99999 }}>
              {user && (
                <img width={25} height={26} src={tipLogo} alt="tipLogo" />
              )}
              {user && (
                <Text weight={700} size={sizeXl ? 16 : 14} color="#CCF075">
                  {t('specialization-career')}
                </Text>
              )}
              {!user && (
                <Text weight={700} size={18} color="#CCF075">
                  {t('specialization-career')}
                </Text>
              )}
            </Group>
            <Button
              size={user ? "sm" : "md"}
              radius={14}
              leftIcon={<TrendingUp strokeWidth={1.5} />}
              sx={{
                background: "#CCF075",
                color: "#364152",
                cursor: "pointer",
                padding: "0 20px",
              }}
            >
              {t('go-to')}
            </Button>
          </Stack>
        </TipContainer>
      </Link>
    </section>
  );
};

export default NavbarContent;

const ProfileInfo = styled.div`
  width: 100%;
  border-radius: 15px;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  .profile-img {
    position: absolute;
    width: 94px;
    height: 94px;
    border-radius: 50px;
    border: 1px solid #c5c5c5;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
  .mantine-qo1k2.mantine-Button-label{
    overflow-x: scroll;
    margin-top:3px;
  }
  @media (max-width: 1376px) {
    .profile-img {
      width: 84px;
      height: 84px;
    }
  }
`;
const ActivitiesContainer = styled.div`
  width: 100%;
  height: 242px;
  margin-top: 25px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  .activities {
    padding: 15px;
  }
`;

const TipContainer = styled.div`
  width: 100%;
  min-height: ${(props) => (props.user ? "100%" : "300px")};
  padding-bottom : 20px;
  border: 1px solid #c5c5c5;
  margin-top: 25px;
  border-radius: 15px;
  background-color: #029199;
  position: relative;
  z-index: -1;
  .vector-img {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 15px 0 0;
    z-index: 0;
  }
`;
