import React, { useContext } from "react";
import styled from "styled-components";
import { Grid, Group, Stack, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import logo from "../../../Assets/SVGs/landing/logo.svg";
import { Link } from "react-router-dom";
import fb from "../../../Assets/SVGs/social/fb.svg";
import instagram from "../../../Assets/SVGs/social/instagram.svg";
import linkedin from "../../../Assets/SVGs/social/linkedin.svg";
import location from "../../../Assets/SVGs/social/location.svg";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../Helpers/Context";

const Footer = () => {
  const sizeXs = useMediaQuery("(max-width: 576px)");
  const sizeMd = useMediaQuery("(max-width: 998px)");
  const sizeLg = useMediaQuery("(max-width: 1444px)");
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const { user } = useContext(AppContext);

  return (
    <FooterStyle pathname={pathname}>
      <div className="container">
        <Grid gutter={sizeXs ? 30 : 0} align="center">
          <Grid.Col span={sizeXs ? 12 : sizeMd ? 6 : 6}>
            <Stack
              align={sizeXs ? "center" : "flex-start"}
              spacing={sizeXs ? 10 : 7}
            >
              <img src={logo} alt="Logo" className="logo-img" />
              <Text
                color="#fff"
                size={14}
                weight={300}
                sx={
                  sizeXs
                    ? { width: "100%", fontWeight: 300, textAlign: "center" }
                    : { width: "55%", fontWeight: 300 }
                }
              >
                {t("footer-slogan")}
              </Text>
              {!user && (
                <Link className="sign-btn register" to="/register">
                  {t("register")}
                </Link>
              )}
            </Stack>
          </Grid.Col>
          <Grid.Col span={sizeXs ? 12 : sizeMd ? 6 : 6}>
            <Stack align={sizeXs ? "center" : "flex-end"}>
              <Stack>
                <Group spacing={60}>
                  <Text size={18} weight={500} color="#fff">
                    {t("main")}
                  </Text>
                  <Text size={18} weight={500} color="#fff">
                    {t("mission")}
                  </Text>
                </Group>
                <Group spacing={60}>
                  <Text size={18} weight={500} color="#fff">
                    {t("about")}
                  </Text>
                  <Text size={18} weight={500} color="#fff">
                    {t("faq")}
                  </Text>
                </Group>
                {!sizeXs && (
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "#fff",
                    }}
                  ></div>
                )}
                {!sizeXs && (
                  <Group>
                    <a href="">
                      <img src={fb} alt="fb" />
                    </a>
                    <a href="">
                      <img src={instagram} alt="instagram" />
                    </a>
                    <a href="">
                      <img src={linkedin} alt="linkedin" />
                    </a>
                    <a href="">
                      <img src={location} alt="location" />
                    </a>
                  </Group>
                )}
              </Stack>
            </Stack>
          </Grid.Col>
        </Grid>
        <Group mt={sizeXs ? 20 : 0} position="center">
          <Text color="#fff" size={16} weight={400}>
            {t("footer-title")}
          </Text>
        </Group>
      </div>
    </FooterStyle>
  );
};

export default Footer;

const FooterStyle = styled.div`
  background-color: #029199;
  margin-top: ${(props) => !props.pathname.includes("tip") && "50px"};
  padding: 20px 0;
  z-index: 99999999999;
  .container {
    max-width: 90%;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
  }
  .logo-img {
    width: 170px;
    height: 60px;
  }

  .sign-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccf075 !important;
    color: #323922 !important;
    border-radius: 29px;
    font-size: 17px;
    font-weight: 500;
    width: 137px;
    height: 54px;
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    .logo-img {
      width: 160px;
      height: 60px;
    }
  }
`;
