import axios from "axios";
import { Button, Text } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { PromoTabs } from "./PromoTabs";
import { PaymentStyle } from "./PaymentStyle";
import { AppContext } from "../../Helpers/Context";
import { useMutation } from "@tanstack/react-query";
import { ShoppingCart } from "tabler-icons-react";
import { useQuery } from "@tanstack/react-query";

export function Payment({ props }) {
  const { applyChangesAfterPurchase } = props;

  const { user, activeTab } = useContext(AppContext);

  const [promoEntered, setPromoEntered] = useState("");
  const [promoResponse, setPromoResponse] = useState(null);
  const [promoDoesNotExist, setPromoDoesNotExist] = useState(false);

  const [coursePrice, setCoursePrice] = useState(0);

  const [order, setOrder] = useState({});

  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [noUlduzumPromo, setNoUlduzumPromo] = useState(false);
  const [ulduzumResponse, setUlduzumResponse] = useState(false);

  const [courseInstallmentData, setCourseInstallmentData] = useState([]);

  useEffect(() => {
    if (order.url) {
      localStorage.setItem("CPOrderIdTaskool", order.order_id);
      window.location = order.url;
    }
  }, [order]);

  const { mutate: createOrder, isLoading: createLoading } = useMutation(
    ["create order"],
    async () => {
      return await axios.post(
        `/forum/create-order/${phoneNumber ? "?&phone=994" + phoneNumber : ""}`,
        {
          user: user?.id,
          forum_section: 1,
          promocode: promoEntered,
          is_campaign: phoneNumber ? true : false,
        }
      );
    },
    { onSuccess: (data) => setOrder(data.data) }
  );

  const { data: tipPrice } = useQuery(
    ["tip data"],
    async () =>
      await axios
        .get("/api/multidata_course_tip/")
        .then((res) => res?.data?.ForumSection[0]?.price)
  );

  useQuery(
    ["tip installment prices"],
    async () =>
      await axios.get(`/forum/bankInstallmentPayForTip/?tip_id=1&promocode=`),
    {
      onSuccess: (data) => {
        setCourseInstallmentData(data.data);
      },
    }
  );

  useEffect(() => {
    if (tipPrice !== undefined) {
      setCoursePrice(tipPrice);
    }
  }, [tipPrice]);

  // console.log(tipPrice);

  const { mutate: buyForFree, isLoading: freeLoading } = useMutation(
    ["create order for free"],
    async () => {
      return await axios.post("/forum/create-order/", {
        user: user?.id,
        forum_section: 1,
        promocode: promoEntered,
        successfuly_paid: true,
        discount_percent: 100,
        price: 0,
      });
    },
    { onSuccess: (data) => applyChangesAfterPurchase() }
  );

  return (
    <PaymentStyle>
      <PromoTabs
        props={{
          coursePrice,
          user,
          promoEntered,
          setPromoEntered,
          promoResponse,
          setPromoResponse,
          promoDoesNotExist,
          setPromoDoesNotExist,
          setCoursePrice,
          phoneNumber,
          setPhoneNumber,
          ulduzumResponse,
          setUlduzumResponse,
          noUlduzumPromo,
          setNoUlduzumPromo,
          courseInstallmentData,
        }}
      />
      {activeTab != "installment" && (
        <>
          <div className="price">
            <Text size={18} color={"dimmed"}>
              Proqramın qiyməti:
            </Text>
            <Text
              size={22}
              weight={600}
              color={promoResponse || ulduzumResponse ? "#38bf4c" : "#000"}
            >
              {coursePrice} AZN
            </Text>
          </div>

          {coursePrice === "0.00" ? (
            <Button
              disabled={freeLoading}
              loading={freeLoading}
              leftIcon={<ShoppingCart />}
              variant="filled"
              color="teal"
              size="md"
              onClick={() => buyForFree()}
            >
              Pulsuz əldə et
            </Button>
          ) : (
            <Button
              disabled={createLoading}
              loading={createLoading}
              leftIcon={<ShoppingCart />}
              variant="filled"
              color="teal"
              size="md"
              onClick={() => createOrder()}
            >
              Ödəniş edin
            </Button>
          )}
        </>
      )}
    </PaymentStyle>
  );
}
