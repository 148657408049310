import { useState } from "react";
import styled from "styled-components";
import { Partition } from "../../../Components/Partition";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Modal, Skeleton } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const Tasks = ({ props }) => {
  const { t, i18n } = useTranslation();

  const location = useLocation();

  const { user, isTaskool } = props;
  const [showDetailsModal, setShowDetailsModal] = useState(null);

  // let texts = isTaskool ?
  // {
  //   t1: "Tapşırıqlar",
  //   n1: "Hələ bitmiş tapşırıq yoxdur"
  // }
  // :
  // {
  //   t1: "Tasks",
  //   n1: "No finished tasks yet"
  // }

  const { data: internshipList, isLoading: dLoad } = useQuery(
    ["internshipList"],
    async () => {
      return await axios(`/grade/internshiplist/${user?.id}/`).then(
        (res) => res.data
      );
    }
  );

  const { data: task, isLoading: taskLoad } = useQuery(
    ["internshipTask", showDetailsModal],
    async () => {
      if (showDetailsModal) {
        return await axios(
          `/grade/internship_answer_grade_list/${user?.id}/${showDetailsModal}/`
        ).then((res) => res.data);
      }
    },
    {
      enabled: !!showDetailsModal,
    }
  );

  function showDetails(id) {
    setShowDetailsModal(id);
  }

  function DetailsModal() {
    return (
      <Modal
        onClose={() => setShowDetailsModal(null)}
        opened={showDetailsModal}
        title="Task"
      ></Modal>
    );
  }

  if (dLoad) {
    return (
      <Partition>
        <TasksStyle>
          <h2>{t("tasks")}</h2>
          <Skeleton width="100%" height="100px" visible={dLoad} />
        </TasksStyle>
      </Partition>
    );
  }

  if (internshipList?.length === 0) {
    return (
      <Partition>
        <TasksStyle>
          <h2>{t("tasks")}</h2>
          <p className="noFinished">{t("no finished tasks")}</p>
        </TasksStyle>
      </Partition>
    );
  }

  return (
    <Partition>
      <DetailsModal />
      <TasksStyle>
        <h2>{t("tasks")}</h2>
        <div className="tasks">
          {internshipList?.map((internship) => {
            return (
              <div key={internship.id} className="task">
                <div className="insideTask">
                  <h3>{internship.title}</h3>
                  {internship.grade > 0 ? (
                    <h4 style={{ color: "#4ac575" }}>
                      Grade <ins>- {internship.grade}</ins>
                    </h4>
                  ) : (
                    <p style={{ color: "#ffa500" }}>On progress</p>
                  )}
                </div>
                {/* <button onClick={() => showDetails(internship?.id)}>See details</button> */}
              </div>
            );
          })}
        </div>
      </TasksStyle>
    </Partition>
  );
};

const TasksStyle = styled.div`
  h2,
  h3 {
    font-weight: 500;
  }
  .noFinished {
    font-size: clamp(1rem, 2vw, 1.1rem);
    color: #555;
    font-weight: 300;
    margin-left: 1rem;
  }
  .tasks {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    width: 95%;
    margin: 0 auto;
    .task {
      display: flex;
      .insideTask {
        width: 100%;
        display: flex;
        grid-gap: 0.25rem;
        flex-direction: column;
        padding: 0.5rem 1rem;
        border: 1px solid #ccc;
        border-left: 10px solid #ccc;
        /* border-right: 0; */
        /* border-radius: 0.25rem 0 0 0.25rem; */
        border-radius: 0.25rem;
        transform: all 0.15s;
        h3,
        h4,
        p,
        ins {
          margin: 0;
          text-decoration: none;
          padding: 0;
        }
        ins {
          color: #000;
          font-weight: 500;
        }
      }
      button {
        cursor: pointer;
        border: 1px solid #ccc;
        border-left: 0;
        font-weight: 500;
        border-radius: 0 0.25rem 0.25rem 0;
        min-width: max-content;
        padding: 0 0.75rem;
        &:hover {
          background-color: #ccc;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .noFinished {
      margin-left: 0;
    }
  }
`;
