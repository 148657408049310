import { Card, Avatar, Text, Progress, Group } from "@mantine/core";

export const AgendaCard = () => {
  return (
    <Card withBorder padding="lg" radius="md">
      <Text fz="lg" fw={500} mt="md">
        9:30-10:00
      </Text>
      <Text fz="sm" c="dimmed" mt={5}>
        Ready to grow the creative business of your dreams? Whether you’re
        itching to go full-time freelance.
      </Text>

      <Group mt={4}>
        <Avatar
          src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-8.png"
          radius="xl"
        />

        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500}>
            Harriette Spoonlicker
          </Text>

          <Text c="dimmed" size="xs">
            hspoonlicker@outlook.com
          </Text>
        </div>
      </Group>
    </Card>
  );
};
