import styled from "styled-components";

export const SyllabusStyle = styled.div`
  background-color: #f7eefd;
  padding-bottom: 2rem;
  h1, h2, h3, h4, h5, h6, span {
    font-weight: 500;
  }
  .wrapperSyllabus{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 2rem auto;
    .syllabusButtons{
      display: flex;
      grid-gap: 1rem;
    }
    .downloadMobile{
      display: none;
    }
    .noSectionsAdded{
      display: flex;
      align-items: center;
      justify-content: center;
      border: 5px dashed #cdbbd9;
      margin: 2rem 0;
      padding: 1rem 0;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: all 0.15s;
      :hover{
        background-color: #ece3f4;
      }
      svg{
        margin-right: .5rem;
      }
      h2{
        font-weight: 500;
      }
    }
    .solid{
      border: 5px solid #cdbbd9;
      cursor: default;
      h2{
        color: #a296ab;
      }
      :hover{
        background-color: initial;
      }
    }
    .syllabusHeader{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1rem 0;
      h1{
        font-weight: 500;
        font-size: 36px;
        color: #4f4f4f;
      }
      .downloadDesktop{
        background-color: #fff;
        border: none;
      }
      .edit{
        display: flex;
        align-items: baseline;
        button{
          margin-left: 1rem;
          color: #000;
          background-color: #cdbbd9;
          font-size: 1.1rem;
          :hover{
            background-color: #b1a2bb;
          }
        }
      }
    }
    .syllabus{
      background-color: #fff;
      border-radius: 0.75rem;
      padding: 4rem 3rem;
      box-shadow: 0 0 15px 5px #00000011;
      p{
        line-height: 1.6;
        font-size: 17px;
      }
      .in{
        display: flex;
        justify-content: space-between;
        width: 95%;
        margin: 0 auto;
        .text{
          width: 60%;
          margin-top: -1.3rem;
        }
        video{
          width: 200px;
          height: 120px;
        }
      }
    }
  }
  @media screen and (max-width:1024px){
    display: flex;
    flex-direction: column;
    padding: 0;
    .wrapperSyllabus{
      width: 90%;
      margin: 0 auto 2rem auto;
      .downloadMobile{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        background-color: #fff;
        border: none;
      }
      .noSectionsAdded{
        display: block;
        text-align: center;
        margin: 2rem 0;
        padding: 1rem;
        :hover{
          background-color: initial;
        }
        svg{
          margin: 0;
          margin-top: 0.5rem;
          margin-bottom: -0.5rem;
        }
        h2{
          font-weight: 500;
        }
      }
      .syllabusHeader{
        margin: 0;
        .downloadDesktop{
          display: none;
        }
        div{
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }
      .syllabus{
        padding: 2.5rem 1rem 2rem 1rem;
        .in{
          flex-direction: column;
          .text{
            width: 95%;
            margin: 0;
            margin-left: -.5rem;
            margin-top: -1.3rem;
          }
          video{
            margin-left: -.5rem;
            width: 100%;
            height: auto;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
`

export const ModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.75);
  z-index: 9999;
  .wrapper{
    position: relative;
    width: 60%;
    height: 600px;
    margin: 3rem auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 0.5rem;
    overflow-y: scroll;
    .removeSection{
      background: none;
      border: none;
      cursor: pointer;
      position: absolute;
      margin-right: 2rem;
      font-weight: 500;
      right: 0;
      color: red;
    }
    .videoDiv{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;
    }
  }
  @media screen and (max-width:1024px){
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    z-index: 9999;
    .wrapper{
      position: relative;
      width: 80%;
      height: 600px;
      margin: 3rem auto;
      padding: 1rem;
      background-color: #fff;
      border-radius: 0.5rem;
      overflow-y: scroll;
      .removeSection{
        background: none;
        border: none;
        cursor: pointer;
        position: absolute;
        margin-right: 2rem;
        font-weight: 500;
        right: 0;
        color: red;
      }
    }
  }
`