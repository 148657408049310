import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./i18n";
import App from "./App";
import "./Styles/index.css";
import "./Helpers/Axios";
import { LoadingProvider } from "./Helpers/Contexts/LoadingContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <LoadingProvider>
        <div className="app">
          <App />
        </div>
      </LoadingProvider>
    </QueryClientProvider>
  </StrictMode>
);
