import styled from "styled-components"
import { Button, Text, ThemeIcon } from "@mantine/core"
import { AnimatePresence, motion } from "framer-motion"
import { Link } from "react-router-dom"
import { ChevronsRight, Confetti } from "tabler-icons-react"

export const QuizFinished = () => {
  return(
    <AnimatePresence mode="wait">
      <motion.div
        key="speciality"
        initial={{ opacity:0, y: -500 }}
        animate={{ opacity:1, y: 0 }}
        exit={{ opacity:0, y: -500 }}
        transition={{ duration: .5 }}
      >
        <QuizFinishedStyle>
          <div className="section">
            <div className="text">
              <Text mb={10} size={24}>Birinci mərhələ sona çatdı!</Text>
              <Text size={20}>
                Konsultasiya mərhələsini bitirdin. İnanırıq ki, sənin üçün faydalı oldu. İxtisas sorğusuna keçid edirsən. Bu bir nailiyyət və ya imtahan testi deyil. Səndən gözləntimiz odur ki, bütün suallara <ins style={{ color:"#ffdf00", textDecoration:"none" }}>diqqətli, şəffaf və dürüst</ins> cavab verəsən. Əks halda bizim təhlilimiz (kompüterin uzlaşdırması) yanlış və ya yaxşı düşünülməmiş cavablara uyğun aparılacaq və nəticədə özünə uyğun olmayan tövsiyələr almaqla itirən tərəf yalnız sən özün olacaqsan.
              </Text>
            </div>
            <ThemeIcon className="yay" size={200} variant="subtle" color="violet"><Confetti size={200}/></ThemeIcon>
          </div>
          <Button component={Link} to="/tip/profession" fullWidth rightIcon={<ChevronsRight/>} variant="gradient" gradient={{ from: 'violet', to: '#b565af', deg: 35 }} style={{ fontSize:"18px" }}>İxtisas sorğusu</Button>
        </QuizFinishedStyle>
      </motion.div>
    </AnimatePresence>
  )
}

const QuizFinishedStyle = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  border-radius: 0.25rem;
  margin: 0 auto;
  padding: 2rem;
  background-color: #212529;
  max-width: 800px;
  h1,h2,h3,a,button{
    font-weight: 500;
  }
  .section{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    grid-gap: 3rem;
    button{
      width: 100%;
    }
  }
  @media (max-width: 800px) {
    padding: 0;
    .section{
      flex-direction: column;
      grid-gap: 1rem;
      .text{
        display: flex;
        flex-direction: column;
        grid-gap: .5rem;
      }
      .yay{
        order: -1;
      }
    }
  }
`