import { React, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";

import { useNotifications } from "@mantine/notifications";
import { showNotification } from '@mantine/notifications';
import { IconBuildingBank } from "tabler-icons-react";
import { Button, Checkbox, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Partition } from "../../../Components/Partition";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Import images
import workIcon from "../../../Assets/SVGs/profile/work.svg";
import addIcon from "../../../Assets/SVGs/profile/plus.svg";
import editIcon from "../../../Assets/SVGs/profile/edit.svg";
import deleteIcon from "../../../Assets/SVGs/profile/minus.svg";
import experienceImg1 from "../../../Assets/Images/profile/experience-1.png";

//Import sound
import succesSound from "./sound/success.mp3"
import errorSound from "./sound/error.mp3"

function Experience({ props }) {
  const { t, i18n } = useTranslation();
  const { user, isTaskool } = props;
  const location = useLocation();

  let [experienceArray, setExperienceArray] = useState([]);
  let [currentEditing, setCurrentEditing] = useState(0);
  const [manageShow, setManageShow] = useState(false);
  const notifications = useNotifications();

  async function fetchData() {
    try {
      const fetchedExperience = await axios.get("api/experience/");
      fetchedExperience.data.sort((a, b) => a.id - b.id);
      setExperienceArray(
        Object.keys(fetchedExperience.data).map(function (key) {
          return fetchedExperience.data[key];
        })
      );
    } catch (error) {}
  }

  // Fetches data when the page is loaded
  useEffect(() => {
    fetchData();
    return () => {
      setExperienceArray([]);
    };
  }, []);

  // Adds new skill
  function addInput() {
    // If there is no current editing, set current editing to the last index

    let experienceIds = experienceArray.map(function (experience) {
      return experience.id;
    });

    if (experienceIds.includes(currentEditing.id)) {
      setCurrentEditing(0);
      setExperienceArray((experiences) => {
        const newExperience = [...experiences];
        newExperience.push({
          id: experienceArray.length
            ? experienceArray[experienceArray?.length - 1]?.id + 1
            : 2,
        });
        setCurrentEditing({
          id: experienceArray.length
            ? experienceArray[experienceArray?.length - 1]?.id + 1
            : 2,
        });
        return newExperience;
      });
    }

    if (currentEditing === 0) {
      // setCurrentEditing(0)
      setExperienceArray((experiences) => {
        const newExperience = [...experiences];
        newExperience.push({
          id: experienceArray.length
            ? experienceArray[experienceArray?.length - 1]?.id + 1
            : 2,
        });
        setCurrentEditing({
          id: experienceArray.length
            ? experienceArray[experienceArray?.length - 1]?.id + 1
            : 2,
        });
        return newExperience;
      });
    }
  }

  function handleEdit(index) {
    setCurrentEditing(experienceArray[index]);

    setExperienceArray(
      experienceArray.filter(
        (experience) => experience.job_position !== undefined
      )
    );
  }

  // Deletes skill
  async function handleDelete(e, index) {
    
    setCurrentEditing(0);
    setExperienceArray(
      experienceArray.filter(
        (experience) => experience.job_position !== undefined
      )
    );

    try {
      // deletes skill from database
      const deletedExperience = await axios.delete(
        `api/experience/${experienceArray[index].id}/`
      );

      if (deletedExperience.status === 204) {
        notificationSound("success")
       showNotification({
          title: t("experience-deleted"),
          message: t("experience-deleted-succesfully"),
          color: "white",
        });
      }

      // reFetches experiences and sorts them in ascending order after deleting one
      const fetchedExperience = await axios.get("api/experience/");
      fetchedExperience.data.sort((a, b) => a.id - b.id);

      setExperienceArray(
        Object.keys(fetchedExperience.data).map(function (key) {
          return fetchedExperience.data[key];
        })
      );
    } catch (error) {}
  }

  // Requests change to skill
  async function patchOrPost(e, index) {
    let item = experienceArray[experienceArray.length - 1];

    if (!item.job_position || !item.job_status || !item.company) {
      notificationSound("error")
      showNotification({
        title: t("experience-error"),
        message: t("please-fill-experience"),
        color: "red",
      });
    } else if (
      (item.is_present === undefined || item.is_present === false) &&
      (item.end_date === undefined || item.end_date === null)
    ) {
      notificationSound("error")
      showNotification({
        title: t("experience-error"),
        message: t("not-work-anymore-exp"),
        color: "yellow",
      });
    } else {
      setCurrentEditing(0);

      try {
        const updatedExperience = await axios.patch(
          `api/experience/${currentEditing.id}/`,
          currentEditing
        );

        if (updatedExperience.status === 200) {
          setExperienceArray((experiences) => {
            const newExperience = [...experiences];
            newExperience[index].is_present = false;
            return newExperience;
          });

          fetchData();
          notificationSound("success")
          showNotification({
            title: t("experience-edited"),
            message: t("experience-edited-succesfully"),
            color: "white",
          });
        }
      } catch (error) {
        try {
          if (
            error?.response?.status === 404 ||
            error?.response?.status === 403 ||
            error?.response?.status === 400
          ) {
            const formX = experienceArray[experienceArray.length - 1];
            formX.id = currentEditing.id;
            formX.user = user.id;
            const response = await axios.post("api/create-experience/", formX);

            if (response.status === 201) {
              fetchData();
                  // Play sound
    const audio = new Audio('/notification-sound/success.wav');
    audio.play();
    notificationSound("success")
              showNotification({
                title: t("experience-added"),
                message: t("experience-added-succesfully"),
                color: "green",
              });
            }
          }
        } catch (error) {}
      }
    }
  }

  // Cancel editing
  function handleCancel() {
    setCurrentEditing(0);
    setExperienceArray(
      experienceArray.filter(
        (experience) => experience.job_position !== undefined
      )
    );
  }

  const notificationSound=(type)=>{
    if(type=="success")
      {
        var audio = new Audio(succesSound);
      }
      else{
        var audio = new Audio(errorSound)
      }
    
    audio.play();
  }

  return (
    <ExperienceStyle>
      <div className="experience-header">
        <h2>{t("experience")}</h2>

        <div className="header-icons">
          <div onClick={() => addInput(true)} className="header-icon-item">
            <img src={addIcon} alt="" />
          </div>

          <div
            onClick={() => setManageShow(!manageShow)}
            className="header-icon-item"
          >
            <img src={editIcon} alt="" />
          </div>
        </div>
      </div>

      <div className="experience-box">
        <form onSubmit={(e) => e.preventDefault()}>
          {experienceArray.map((experience, index) => {
            return currentEditing?.id === experience?.id ? (
              <div
                key={index}
                className="editing insideWrapper"
                id={experience?.id}
              >
                <div>
                  <TextInput
                    required
                    name="job_position"
                    placeholder={t("experience-programmer")}
                    label={t("job-title")}
                    value={experience?.job_position}
                    onChange={(e) => {
                      setExperienceArray((experiences) => {
                        const newExperience = [...experiences];
                        newExperience[index].job_position = e.target.value;
                        return newExperience;
                      });
                    }}
                  />

                  <br />
                  <TextInput
                    required
                    name="company"
                    placeholder={t("experience-taskool")}
                    label={t("company")}
                    value={experience?.company}
                    onChange={(e) => {
                      setExperienceArray((experiences) => {
                        const newExperience = [...experiences];
                        newExperience[index].company = e.target.value;
                        return newExperience;
                      });
                    }}
                  />

                  <br />
                  <TextInput
                    required
                    name="job_status"
                    placeholder={t("experience-fulltime")}
                    label={t("job-status")}
                    value={experience?.job_status}
                    onChange={(e) => {
                      setExperienceArray((experiences) => {
                        const newExperience = [...experiences];
                        newExperience[index].job_status = e.target.value;
                        return newExperience;
                      });
                    }}
                  />
                  <br />

                  <DatePicker
                    required
                    name="start_date"
                    label={t("start-date")}
                    placeholder="15.10.2019"
                    value={
                      experience?.start_date
                        ? new Date(experience?.start_date)
                        : null
                    }
                    onChange={(date) => {
                      setExperienceArray((experiences) => {
                        const newExperience = [...experiences];
                        let dateX = new Date(date);
                        dateX.setDate(dateX.getDate() + 1);
                        newExperience[index].start_date = dateX
                          .toISOString()
                          .split("T")[0];
                        return newExperience;
                      });
                    }}
                  />
                  <br />

                  <Checkbox
                    name="is_present"
                    label={t("currently-work-place")}
                    required
                    checked={experience?.is_present}
                    onChange={(e) => {
                      setExperienceArray((experiences) => {
                        const newExperience = [...experiences];
                        newExperience[index].is_present = e.target.checked;
                        return newExperience;
                      });
                    }}
                  />
                  <br />

                  {!experience?.is_present && (
                    <DatePicker
                      required={!experience?.is_present}
                      name="end_date"
                      label={t("end-date")}
                      placeholder="02.01.2022"
                      value={
                        experience?.end_date
                          ? new Date(experience?.end_date)
                          : null
                      }
                      onChange={(date) => {
                        setExperienceArray((experiences) => {
                          const newExperience = [...experiences];
                          let dateX = new Date(date);
                          dateX.setDate(dateX.getDate() + 1);
                          newExperience[index].end_date = dateX
                            .toISOString()
                            .split("T")[0];
                          return newExperience;
                        });
                      }}
                    />
                  )}
                  <br />
                </div>

                <div className="buttons">
                  <div className="groupOne">
                    <Button
                    className="btn btn-submit"
                      variant="filled"
                      type="button"
                      onClick={(e) => patchOrPost(e, index)}
                    >
                      {t("send-button-experience")}
                    </Button>
                    <Button
                    className="btn btn-cancel"
                      variant="filled"
                      type="button"
                      onClick={() => handleCancel()}
                    >
                      {t("cancel-button-experience")}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div key={index} id={experience?.id} className="experience-item">
                {/* {index !== 0 && <hr />} */}
                <div className="experience-item-left">
                  <div className="experience-img">
                    <img src={workIcon} alt="work-icon" />
                  </div>
                  <div className="experience-detail">
                    <div className="experience-date">
                      {" "}
                      {experience?.is_present === true
                        ? experience?.start_date +
                          ` - ${t("experience-present")}`
                        : experience?.start_date + " - " + experience?.end_date}
                    </div>
                    <h5>{experience?.job_position}</h5>
                    <div className="experience-company">
                      {experience?.company}, {experience?.job_status}
                    </div>
                  </div>
                </div>

                <div className="item-manage">
                  {manageShow && (
                    <>
                      <div onClick={(e) => handleDelete(e, index)}>
                        <img src={deleteIcon} alt="" />
                      </div>

                      <div onClick={() => handleEdit(index)}>
                        <img src={editIcon} alt="" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </form>
      </div>
    </ExperienceStyle>
  );
}

export default Experience;

const ExperienceStyle = styled.div`
  background: #fff;
  padding: 40px 32px;
  border-radius: 12px;
  margin-bottom: 24px;
  .experience-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    h2 {
      color: #202939;
      font-family: "Euclid";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 140% */
    }

    .header-icons {
      display: flex;
      gap: 12px;
      
      .header-icon-item {
        border-radius: 8px;
        cursor: pointer;
        transition:0.3s;
        padding: 12px;
        &:hover{
         background: #EEF2F6;
        }
      }
    }
  }

  .experience-box {
    .experience-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0px;
      border-bottom: 1px solid #cdd5df;
      .experience-img{
        width: 64px;
        height: 64px;
        background: #efefef;
        display: flex;
        justify-content: center;
        align-items:center;
        border-radius: 8px;
        img{
          width: 60%;
        }
        
      }

      .experience-item-left {
        display: flex;
        align-items: center;
        gap: 14px;
        .experience-date {
          color: #9aa4b2;
          font-family: "Euclid";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 18px */
          margin-bottom: 4px;
        }

        h5 {
          margin: 0px;
          color: #121926;
          font-family: "Euclid";
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 100% */
          /* display: flex;
        align-items: center; */
          margin-bottom: 4px;
          .position {
            color: #4b5565;
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
          }
        }

        .experience-company {
          color: #4b5565;
          font-family: "Euclid";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
        }
        .experience-date {
          color: #9aa4b2;
          font-family: "Euclid";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 18px */
          margin-bottom: 4px;
        }

        h5 {
          margin: 0px;
          color: #121926;
          font-family: "Euclid";
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 100% */
          /* display: flex;
        align-items: center; */
          margin-bottom: 4px;
          .position {
            color: #4b5565;
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
          }
        }

        .experience-company {
          color: #4b5565;
          font-family: "Euclid";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
        }
      }

      .item-manage {
        display: flex;
        gap: 12px;
        div {
          padding: 12px;
        }
      }
    }
  }
  //OLD STYLES
  button {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: clamp(1rem, 2vw, 1.05rem);
  }

  .insideWrapper {
    margin-top: 1rem;
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
    hr {
      border: none;
      border-bottom: 1px solid #ddd;
      margin-top: 1rem;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .addItem {
      background: none;
      font-size: 17px;
      color: #2f80ed;
      font-weight: 400;
      border: none;
      cursor: pointer;
    }
    svg {
      color: #000;
    }
  }

  .editing {
    padding: 1rem 1.25rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    align-items: center;
    .buttons {
      .groupOne {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
      }
      button {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .insideWrapper {
      width: 100%;
      margin: 0 auto;
    }
  }
`;
