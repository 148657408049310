import React, { useRef } from "react";
import styled from "styled-components";
import { Badge, Button, Group, Text, createStyles } from "@mantine/core";
import { Calendar, CornerDownRightDouble, Download } from "tabler-icons-react";

const MobileVersion = ({
  steps,
  appliedUni,
  handleButtonClick,
  handleFileUpload,
  linkIcon,
  additionalDocumentProcess,
  fileInputRef,
  interviewProcess,
  additionalTestProcess,
  acceptedProcess,
  rejectedProcess,
}) => {
  const { classes } = useStyles();

  return (
    <MobileStyled>
      <Group spacing={35} align="start">
        <VerticalLine acceptedProcess={acceptedProcess} rejectedProcess={rejectedProcess} />
        <StepContainer>
          {steps.map((step, index) => {
            const existProcess = appliedUni.application_processes
              ? appliedUni.application_processes.find(
                  (process) => process.process === step.process
                )
              : null;

            if (index < 4) {
              return (
                <div
                  style={{ position: "relative" }}
                  key={index}
                  className={classes.stepItem}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "-35px",
                      top: "30%",
                      transform: "translateY(-50%)",
                      width: "100px",
                      height: "1px",
                      background: "#c8c8c8",
                    }}
                  ></div>
                  <StepBadge title={step.title}>{step.title}</StepBadge>
                  {appliedUni?.application_processes && (
                    <Group spacing={10}>
                      <Calendar color="#555555" strokeWidth={1.5} size={14} />
                      <Text
                        align="left"
                        color="#555555"
                        className={classes.date}
                      >
                        {existProcess ? existProcess.date : "N/A"}
                      </Text>
                    </Group>
                  )}{" "}
                  {step.process === 1 ? (
                    <Text align="center" size={15} weight={500} color="#D7AB44">
                      N/A
                    </Text>
                  ) : step.process === 2 ? (
                    <Group position="center" spacing={10}>
                      <Group spacing={0}>
                        <CornerDownRightDouble
                          color="#5EAF8A"
                          size={14}
                          className={classes.icon}
                        />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#5EAF8A",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          onClick={() => {
                            handleButtonClick({
                              processId: additionalDocumentProcess.id,
                              programId: appliedUni.id,
                              applyId:
                                appliedUni.application_processes[0].apply,
                            });
                          }}
                        >
                          Upload file
                        </Button>
                      </Group>
                      <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                      />
                      <div
                        style={{
                          background: "#c8c8c8",
                          width: "1px",
                          height: "12px",
                        }}
                      ></div>
                      <Group spacing={4}>
                        <img width={18} height={18} src={linkIcon} alt="link" />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#929292",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          disabled={!additionalDocumentProcess}
                          component="a"
                          target="_blank"
                          href={
                            additionalDocumentProcess &&
                            additionalDocumentProcess.link
                              ? additionalDocumentProcess.link
                              : ""
                          }
                        >
                          Link
                        </Button>
                      </Group>
                    </Group>
                  ) : step.process === 3 ? (
                    <Group position="center" spacing={10}>
                      <Group spacing={0}>
                        <Download
                          strokeWidth={2.5}
                          color="#3B94FD"
                          size={16}
                          className={classes.icon}
                        />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#3B94FD",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          disabled={!interviewProcess}
                          target="_blank"
                          component="a"
                          href={
                            interviewProcess && interviewProcess.file
                              ? interviewProcess.file
                              : ""
                          }
                        >
                          Download file
                        </Button>
                      </Group>
                      <div
                        style={{
                          background: "#c8c8c8",
                          width: "1px",
                          height: "12px",
                        }}
                      ></div>
                      <Group spacing={4}>
                        <img width={18} height={18} src={linkIcon} alt="link" />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#929292",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          disabled={!interviewProcess}
                          component="a"
                          target="_blank"
                          href={
                            interviewProcess && interviewProcess.link
                              ? interviewProcess.link
                              : ""
                          }
                        >
                          Link
                        </Button>
                      </Group>
                    </Group>
                  ) : step.process === 4 ? (
                    <Group position="center" spacing={10}>
                      <Group spacing={0}>
                        <Download
                          strokeWidth={2.5}
                          color="#3B94FD"
                          size={16}
                          className={classes.icon}
                        />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#3B94FD",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          disabled={!additionalTestProcess}
                          component="a"
                          target="_blank"
                          href={
                            additionalTestProcess && additionalTestProcess.file
                              ? additionalTestProcess.file
                              : ""
                          }
                        >
                          Download file
                        </Button>
                      </Group>
                      <div
                        style={{
                          background: "#c8c8c8",
                          width: "1px",
                          height: "12px",
                        }}
                      ></div>
                      <Group spacing={4}>
                        <img width={18} height={18} src={linkIcon} alt="link" />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#929292",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          disabled={!additionalTestProcess}
                          component="a"
                          target="_blank"
                          href={
                            additionalTestProcess && additionalTestProcess.link
                              ? additionalTestProcess.link
                              : ""
                          }
                        >
                          Link
                        </Button>
                      </Group>
                    </Group>
                  ) : null}
                </div>
              );
            } else {
              console.log("esleExist", existProcess);
              if (existProcess && existProcess.process === 5) {
                return (
                  <div
                    style={{ position: "relative" }}
                    key={index}
                    className={classes.stepItem}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "-35px",
                        top: "30%",
                        transform: "translateY(-50%)",
                        width: "100px",
                        height: "1px",
                        background: "#c8c8c8",
                      }}
                    ></div>
                    <StepBadge title="Accepted">Accepted</StepBadge>
                    {appliedUni?.application_processes && (
                      <Text className={classes.date}>
                        {existProcess ? existProcess.date : "N/A"}
                      </Text>
                    )}{" "}
                    <>
                      {(acceptedProcess || rejectedProcess) && (
                        <Group position="center" spacing={0}>
                          <Download
                            strokeWidth={2.5}
                            color="#3B94FD"
                            size={16}
                            className={classes.icon}
                          />
                          <Button
                            styles={() => ({
                              root: {
                                padding: 0,
                                margin: 0,
                                fontSize: "15px",
                                fontWeight: 500,
                                color: "#3B94FD",
                                background: "transparent",
                                "&:hover": {
                                  background: "transparent",
                                },
                              },
                            })}
                            disabled={!acceptedProcess}
                            component="a"
                            target="_blank"
                            href={
                              acceptedProcess && acceptedProcess?.file
                                ? acceptedProcess.file
                                : rejectedProcess && rejectedProcess.file
                                ? rejectedProcess.file
                                : ""
                            }
                          >
                            Download file
                          </Button>
                        </Group>
                      )}
                    </>
                  </div>
                );
              } else if (existProcess && existProcess.process === 6) {
                return (
                  <div
                    style={{ position: "relative" }}
                    key={index}
                    className={classes.stepItem}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "-35px",
                        top: "30%",
                        transform: "translateY(-50%)",
                        width: "100px",
                        height: "1px",
                        background: "#c8c8c8",
                      }}
                    ></div>
                    <StepBadge title="Rejected">Rejected</StepBadge>
                    {appliedUni?.application_processes && (
                      <Text className={classes.date}>
                        {existProcess ? existProcess.date : "N/A"}
                      </Text>
                    )}{" "}
                    <>
                      {(acceptedProcess || rejectedProcess) && (
                        <Group position="center" spacing={0}>
                          <Download
                            strokeWidth={2.5}
                            color="#3B94FD"
                            size={16}
                            className={classes.icon}
                          />
                          <Button
                            styles={() => ({
                              root: {
                                padding: 0,
                                margin: 0,
                                fontSize: "15px",
                                fontWeight: 500,
                                color: "#3B94FD",
                                background: "transparent",
                                cursor:"pointer",
                                "&:hover": {
                                  background: "transparent",
                                },
                              },
                            })}
                            component="a"
                            target="_blank"
                            href={
                              acceptedProcess && acceptedProcess?.file
                                ? acceptedProcess.file
                                : rejectedProcess && rejectedProcess.file
                                ? rejectedProcess.file
                                : ""
                            }
                          >
                            Download file
                          </Button>
                        </Group>
                      )}
                    </>
                  </div>
                );
              }
            }
          })}
        </StepContainer>
      </Group>
    </MobileStyled>
  );
};

export default MobileVersion;

const MobileStyled = styled.div`
  /* Add styles for mobile version here */
`;

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
    margin: "auto",
  },
  header: {
    marginBottom: "20px",
  },
  stepContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
  },
  stepItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  },
  stepTitle: {
    display: "flex",
    alignItems: "baseline",
    marginBottom: "10px",
  },
  date: {
    color: theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    textAlign: "left",
  },
  icon: {
    marginRight: "5px",
  },
}));

const VerticalLine = styled.div`
  width: 1px;
  height: ${props => props.acceptedProcess  || props.rejectedProcess ? "620px" : "500px"};
  background-color: #c8c8c8;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;
const StepBadge = styled(Badge)`
  position: relative;
  margin-bottom: 10px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.title === "Submitted Application"
      ? "#FEFBEE"
      : props.title === "Additional Documents Required"
      ? "#edf9f0"
      : props.title === "Interview"
      ? "#f5f6ff"
      : props.title === "Additional Test"
      ? "#f1fdff"
      : props.title === "Accepted"
      ? "#f6fff4"
      : "#FFE0E0"};
  border: ${(props) =>
    props.title === "Submitted Application"
      ? "1px solid #DBD5A5"
      : props.title === "Additional Documents Required"
      ? "1px solid #9DB5A9"
      : props.title === "Interview"
      ? "1px solid #BDBBC5"
      : props.title === "Additional Test"
      ? "1px solid #88F1FF"
      : props.title === "Accepted"
      ? "1px solid #7EEF66"
      : "1px solid #E41D1D"};
  color: ${(props) =>
    props.title === "Submitted Application"
      ? "#D7AB44"
      : props.title === "Additional Documents Required"
      ? "#5EAF8A"
      : props.title === "Interview"
      ? "#7F79BB"
      : props.title === "Additional Test"
      ? "#29C3D8"
      : props.title === "Accepted"
      ? "#5DD344"
      : "#E41D1D"};
`;
