import axios from "axios";
import styled from "styled-components";
import { React, useState, useContext } from "react";
import { AppContext } from "../../../Helpers/Context";
import { Input, Textarea, TextInput } from "@mantine/core";
import defaultCoverSmall from "../../../Assets/Images/defaultCoverSmall.jpg"

export const Card = ({ props }) => {

  const { profile_img, title} = props;

  return (
    <CardStyle>
      <div className="wrapper">
        <div className="cardWrapper">
          <div className="courseImage">
            <img src={profile_img ? URL.createObjectURL(profile_img) : defaultCoverSmall} alt="" />
          </div>
          <div className="details">
            <p>{title ? title.substring(0,54) : "A title describing the content of your course"}</p>
          </div>
        </div>
      </div>
    </CardStyle>
  )
}

export const SchoolDetailForm = ({setCreationFinished}) => {

  const { user } = useContext(AppContext);

  const [ values, setValues ] = useState({
    profile_img: '',
    title: '',
    description: '',
    video: '',
    user: user?.id,
  });

  const { title, profile_img, description, video } = values;

  const submitAssignment = async (e) => {
    e.preventDefault();

    try {
      const formX = new FormData();
      formX.append('profile_img', profile_img, profile_img.name);
      formX.append('title', title);
      formX.append('description', description);
      formX.append('video', video);
      formX.append('user', user?.id);

      const response = await axios.post("school/", formX);

      if (response.status === 201) {
        setCreationFinished(true);
        window.scrollTo(0, 0);
      }
    } catch (error) {
    }
  }

  return (
    <SchoolDetailFormStyle>
      <form onSubmit={(e) => submitAssignment(e)}>
        <div className="container">
          <div>
            <Card props={{ profile_img, title, user }}/>
          </div>
          <div className="inputs">
            <Input.Wrapper label="Image" required className="imageUploader">
              {profile_img ?
                <label className="label" htmlFor="uploadPhoto">{profile_img.name}</label>
                :
                <label className="label" htmlFor="uploadPhoto" style={{color:"#999"}}>Choose an image..</label>
              }
              <input type="file" name="photo" id="uploadPhoto"
                onChange={(e) => { setValues({...values, profile_img: e.target.files[0]}) }}
              />
            </Input.Wrapper>
            <TextInput required placeholder="Course Title" label="Title"
              value={title}
              onChange={(e) => setValues({...values, title: e.target.value})}
            />
            <Textarea required placeholder="Mətn" label="Description" minRows={3}
              value={description}
              onChange={(e) => setValues({...values, description: e.target.value})}
            />
            <TextInput required placeholder="Kursun YouTube linki" label="YouTube link"
              value={video}
              onChange={(e) => setValues({...values, video: e.target.value})}
            />
            <br />
            <div className="submit">
              <button>Publish</button>
            </div>
          </div>
        </div>
      </form>
    </SchoolDetailFormStyle>
  )
}

const SchoolDetailFormStyle = styled.div`
  .container{
    padding: 2rem 0;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 5rem;
    .inputs{
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
      input,select,textarea{
        font-family: "Euclid";
        padding: 1.2rem 1rem;
        margin: 0;
        margin-bottom: 1.25rem;
        border-radius: 0.4rem;
        font-size: 15px;
        /* border: none; */
        background-color: #f2f2f2;
      }
      textarea{
        padding: 0.5rem 1rem;
      }
      .calcal{
        margin: 0 auto;
      }
      .imageUploader{
        display: flex;
        flex-direction: column;
        .label{
          cursor: pointer;
          font-family: "Euclid";
          padding: .6rem 1rem;
          margin: 0;
          margin-bottom: 1.25rem;
          border-radius: 0.4rem;
          font-size: 15px;
          border: 1px solid #d8d8d8;
          color: #000;
          background-color: #f2f2f2;
        }
        #uploadPhoto {
          opacity: 0;
          position: absolute;
          z-index: -1;
        }
      }
    }
  }
  @media screen and (max-width:1024px){
    .container{
      padding: 2rem 0;
      margin: 1rem;
      grid-template-columns: 1fr;
      grid-gap: 3rem;
      .dropbox{
        padding-bottom: 2rem;
      }
      .assignmentInfo{
        margin-bottom: 2rem;
      }
    }
  @media screen and (max-width: 360px) {
    .container{
      .assignmentInfo{
        .deadline{
          display: flex;
          flex-direction: column;
          /* align-items: center; */
          /* justify-content: center; */
          .calendar{
            display: none;
          }
          .inputMobile {
            margin: 1rem 0 3rem 0;
            display: block;
          }
        }
      }
      button{
        margin: 0 auto;
      }
    }
  }
  }
`

const CardStyle = styled.div`
  background-color: #aaa;
  border-radius: .5rem;
  padding: 3.5rem 0;
  margin: 2.5rem 0 1rem 0;
  display: flex;
  justify-content: center;
  box-shadow: inset 0 0 20px 4px #00000055;
  .wrapper{
    transition: all .15s;
    a{
      text-decoration: none;
      color: #000;
    }
    .cardWrapper{
      display: flex;
      flex-direction: column;
      min-width: 284px;
      max-width: 284px;
      background-color: #fff;
      border-radius: 0.5rem;
      margin: 0 .5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-bottom: 0.4rem;
      box-shadow: 0 0 30px 3px rgba(0,0,0,0.5);
      border: 1px solid transparent;
      &:hover{
        border: 1px solid #c8c8c8;
      }
      .courseImage{
        display: flex;
        margin: 0.5rem;
        margin-bottom: 0;
        max-height: 180px;
        min-height: 180px;
        img{
          border-radius: 0.5rem;
          max-width: 100%;
          object-fit: cover;
        }
      }
      .details{
        margin: 0rem 1.2rem 0 1.2rem;
        p{
          font-weight: 500;
          font-size: 17px;
          margin-bottom: 0.5rem;
        }
        h4{
          font-size: 14px;
          margin: 0;
          padding: 0.2rem .7rem;
          border-radius: .25rem;
          font-weight: 400;
          border: 1px solid #aaa;
          /* background-color: lightsteelblue; */
          max-width: max-content;
        }
      }
    }
    @media screen and (max-width:1024px){
      .cardWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 320px;
        background-color: #fff;
        border-radius: 0.5rem;
        margin: 0 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-bottom: 0.4rem;
        .courseImage{
          display: flex;
          margin: 0.5rem;
          max-height: 200px;
          img{
            display: flex;
            margin: 0 auto;
            border-radius: 0.5rem;
            max-width: 100%;
            object-fit: cover;
          }
        }
        .details{
          margin: 1.2rem 1.2rem 0 1.2rem;
          p{
            font-weight: 500;
            font-size: 17px;
          }
        }
      }
    }
  }
`