import { React } from "react";
import styled from "styled-components";
import { Grid, Stack, Text, Title } from "@mantine/core";
import bg from "../../../Assets/SVGs/landing/vectorBackground.svg";
import LandingCard from "../../../Components/Cards/LandingCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import icon1 from "../../../Assets/SVGs/landing/icon1.svg";
import icon2 from "../../../Assets/SVGs/landing/icon2.svg";
import icon3 from "../../../Assets/SVGs/landing/icon3.svg";
import icon4 from "../../../Assets/SVGs/landing/icon4.svg";

export const SecondPart = ({ props }) => {
  const matches = useMediaQuery("(max-width: 768px)");
  const matchesSm = useMediaQuery("(max-width: 990px)");

  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.5,
      },
    }),
  };

  const cardVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  const { t } = props;
  return (
    <SecondPartStyle ref={ref}>
      <Grid align="center">
        <Grid.Col xl={6} md={6} sm={12}>
          <Stack align={"flex-start"}>
            <motion.div
              custom={0}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={leftVariants}
            >
              <Title order={3} color="#1A5059" weight="bold">
                {t("question")}
              </Title>
            </motion.div>
            <motion.div
              custom={1}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={leftVariants}
            >
              <Text color="#565656" size={16} weight="400" className="txt">
                {t("answer-1")}
              </Text>
            </motion.div>
            <motion.div
              custom={2}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={leftVariants}
            >
              <Text color="#565656" size={16} weight="400" className="txt">
                {t("answer-2")}
              </Text>
            </motion.div>
          </Stack>
        </Grid.Col>
        <Grid.Col xl={5} md={6} sm={12} className="right">
          <img src={bg} alt="bg" className="bg-image" />
          {matches ? (
            <Swiper
              slidesPerView={1}
              spaceBetween={60}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper programs"
            >
              <SwiperSlide>
                {" "}
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <LandingCard
                    props={{
                      t,
                      icon: icon1,
                      title: "tip-title",
                      text: "tip-content",
                      to:"/tip"
                    }}
                  />
                </motion.div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <LandingCard
                    props={{
                      t,
                      icon: icon2,
                      title: "steam-title",
                      text: "steam-content",
                      to:"/steam"
                    }}
                  />
                </motion.div>
              </SwiperSlide>{" "}
              <SwiperSlide>
                {" "}
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <LandingCard
                    props={{
                      t,
                      icon: icon3,
                      title: "study-title",
                      text: "study-content",
                      to:"universities"
                    }}
                  />
                </motion.div>
              </SwiperSlide>{" "}
              <SwiperSlide>
                {" "}
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <LandingCard
                    props={{
                      t,
                      icon: icon4,
                      title: "community-title",
                      text: "community-content",
                      to:"/communities"
                    }}
                  />
                </motion.div>
              </SwiperSlide>
            </Swiper>
          ) : (
            <div className="card-container">
              <div className="card">
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                >
                  <LandingCard
                    props={{
                      t,
                      icon: icon1,
                      title: "tip-title",
                      text: "tip-content",
                      to : "/tip"
                    }}
                  />
                </motion.div>
              </div>
              <div className="card">
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                >
                  <LandingCard
                    props={{
                      t,
                      icon: icon2,
                      title: "steam-title",
                      text: "steam-content",
                      to : "/steam"
                    }}
                  />
                </motion.div>
              </div>
              <div className="card">
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                >
                  <LandingCard
                    props={{
                      t,
                      icon: icon3,
                      title: "study-title",
                      text: "study-content",
                      to : "/universities"
                    }}
                  />
                </motion.div>
              </div>
              <div className="card">
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                >
                  <LandingCard
                    props={{
                      t,
                      icon: icon4,
                      title: "community-title",
                      text: "community-content",
                      to : "/communities"
                    }}
                  />
                </motion.div>
              </div>
            </div>
          )}
        </Grid.Col>
      </Grid>
    </SecondPartStyle>
  );
};

const SecondPartStyle = styled.div`
  max-width: 90%;
  margin: 0 auto 80px auto;
  position: relative;
  .card-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 25px;

    .card {
      width: 40%;
      border: 1px solid #black;
      z-index :5;
    }
  }

  .right {
    position: relative;
    .bg-image {
      position: absolute;
      left: -70px;
      top: 0;
      z-index:1;
    }
  }

  .txt {
    width: 65%;
    margin-top: 20px;
  }
  @media screen and (max-width: 990px) {
    .right {
      position: relative;
      .bg-image {
        position: absolute;
        left: 80px;
        top: 40px;
        z-index:1;
      }
    }
  }
  @media screen and (max-width: 576px) {
    .programs {
      padding: 10px;
    }
    .cardWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper-pagination-bullets {
      bottom: 22px;
    }
    .right {
      position: relative;
      margin-top: 40px;
      .bg-image {
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0;
        z-index: 1;
      }
    }
    .txt {
      width: 100%;
      margin-top: 20px;
    }
  }
`;
