import React, { useState } from "react";
import { Grid, Image, Spoiler, Avatar, Tabs } from "@mantine/core";
import { CoursePreviewMobile } from "./CoursePreviewMobile";
import { ShowSidebar } from "../Layout/LayoutHelper";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CoursePreview } from "./CoursePreview";
import { useMediaQuery } from "@mantine/hooks";
import DefaultImage from "../../Assets/Images/events/Photo swipe.png";
import { ArrowNarrowLeft, Link as LinkIcon } from "tabler-icons-react";
import { Link } from "react-router-dom";
import { Speaker } from "./Speaker";
import { AgendaCard } from "../Cards/AgendaCard";

export default Event = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [course, setCourse] = useState([]);
  const id = 58;

  useQuery(
    ["course-info", id],
    async () => await axios.get(`/api/course/${id}/`),
    {
      onSuccess: (data) => {
        setCourse(data.data[0]);
      },
    }
  );

  const iconStyle = { width: "12rem", height: "12rem" };

  //   const { data: contents } = useQuery(
  //     ["/api/all-courses/", "/api/all-courses/"],
  //     async () => await axios.get("/api/all-courses/").then((res) => res.data[0])
  //   );

  // console.log(course);

  return (
    <>
      <ShowSidebar>
        <Grid>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "30px 20px",
            }}
          >
            <Grid.Col span={12}>
              <div
                className="title"
                style={{ display: "flex", marginBottom: "14px" }}
              >
                <Link to={"/"}>
                  <ArrowNarrowLeft size={32} strokeWidth={2} color={"black"} />
                </Link>
                <div className="text" style={{ marginLeft: "16px" }}>
                  <a
                    href="/"
                    style={{
                      color: "#1a1d1c",
                      textDecoration: "none",
                      fontSize: "24px",
                      fontWeight: "400",
                      fontStyle: "normal",
                      lineHeight: "24px",
                    }}
                  >
                    Event name
                  </a>
                  <span
                    style={{
                      display: "block",
                      marginTop: "4px",
                      fontSize: "12px",
                      color: "#5e5e5e",
                      marginTop: "-3px",
                    }}
                  >
                    Special event
                  </span>
                </div>
              </div>

              <Grid>
                <Grid.Col xl={6} md={12}>
                  <div
                    style={{
                      // width: "425px",
                      // height: "240px",
                      borderRadius: "4px",
                      overflow: "hidden",
                    }}
                  >
                    <Image
                      src={DefaultImage}
                      alt="event-image"
                      style={{
                        maxWidth: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </Grid.Col>
                <Grid.Col xl={6} md={12}>
                  <div>
                    <div className="about" style={{ maxWidth: "80%" }}>
                      <h1
                        style={{
                          fontSize: "20px",
                          fontWeight: 500,
                          color: "#202939",
                        }}
                      >
                        About
                      </h1>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="columns">
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "10px",
                            }}
                          >
                            Type:
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "10px",
                            }}
                          >
                            Date:
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "10px",
                            }}
                          >
                            Platform:
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "10px",
                            }}
                          >
                            Location:
                          </p>
                        </div>
                        <div className="columns-values">
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              lineHeight: "10px",
                            }}
                          >
                            Hackathon
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              lineHeight: "10px",
                            }}
                          >
                            01.01.2024 / 09:30
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              lineHeight: "10px",
                            }}
                          >
                            Offline
                          </p>
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              lineHeight: "10px",
                            }}
                          >
                            Caspian Plaza
                          </p>
                        </div>
                      </div>
                      <div className="link" style={{ marginTop: "2px" }}>
                        <a
                          href="#"
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            color: "#1a1d1c",
                            textDecoration: "none",
                            display: "flex",
                            alignItems: "center",
                            color: "#1570EF",
                          }}
                        >
                          <LinkIcon size={20} style={{ marginRight: "8px" }} />
                          taskilled.com
                        </a>
                      </div>
                    </div>
                  </div>
                </Grid.Col>
              </Grid>
              <Grid.Col span={12}>
                <h1
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#202939",
                  }}
                >
                  Description
                </h1>
                <Spoiler
                  mt={-14}
                  mb={10}
                  maxHeight={120}
                  showLabel="Show more"
                  hideLabel="Hide"
                  styles={{ control: { color: "#868E96" } }}
                >
                  <div style={{ maxWidth: "96%" }}>
                    <p style={{ textAlign: "justify", maxWidth: "100%" }}>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Deleniti quidem quas fugiat ea, quam facere quaerat porro
                      quisquam ipsa officia. Asperiores ducimus quas, suscipit
                      accusantium similique maiores quidem nostrum neque. Error
                      enim, reprehenderit nesciunt in voluptas sed! Inventore
                      obcaecati ab possimus ad atque nobis sit similique natus
                      id quibusdam? Perferendis optio animi sapiente! Blanditiis
                      corporis molestias, tenetur ipsa illum saepe. Consequatur
                      blanditiis suscipit nostrum animi minus distinctio quam
                      accusamus atque, alias at accusantium corporis hic?
                    </p>
                  </div>
                </Spoiler>
              </Grid.Col>
            </Grid.Col>
          </div>

          <Grid.Col span={12}>
            {isMobile ? (
              <CoursePreviewMobile
                backgroundImage={course?.image}
                title={course?.title}
              />
            ) : (
              <CoursePreview
                backgroundImage={course?.image}
                title={course?.title}
              />
            )}
          </Grid.Col>
          <div
            style={{
              marginTop: "6px",
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "30px",
              width: "100%",
              boxSizing: "border-box", // Ensure padding is included in the width
            }}
          >
            <div style={{ marginBottom: "14px" }}>
              <a
                style={{
                  color: "#1a1d1c",
                  fontSize: "24px",
                  fontWeight: "500",
                  fontStyle: "normal",
                  lineHeight: "24px",
                  marginLeft: "16px",
                }}
              >
                Speakers
              </a>
            </div>
            <div className="speakers">
              <Grid>
                <Grid.Col xl={4} lg={6} sm={6} xs={12}>
                  <Speaker />
                </Grid.Col>
                <Grid.Col xl={4} lg={6} sm={6} xs={12}>
                  <Speaker />
                </Grid.Col>
                <Grid.Col xl={4} lg={6} sm={6} xs={12}>
                  <Speaker />
                </Grid.Col>
                <Grid.Col xl={4} lg={6} sm={6} xs={12}>
                  <Speaker />
                </Grid.Col>
                <Grid.Col xl={4} lg={6} sm={6} xs={12}>
                  <Speaker />
                </Grid.Col>
                <Grid.Col xl={4} lg={6} sm={6} xs={12}>
                  <Speaker />
                </Grid.Col>
                <Grid.Col xl={4} lg={6} sm={6} xs={12}>
                  <Speaker />
                </Grid.Col>
              </Grid>
            </div>
          </div>
          <div
            style={{
              marginTop: "14px",
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "30px",
              width: "100%",
              boxSizing: "border-box", // Ensure padding is included in the width
            }}
          >
            <div style={{ marginBottom: "14px" }}>
              <a
                style={{
                  color: "#1a1d1c",
                  fontSize: "24px",
                  fontWeight: "500",
                  fontStyle: "normal",
                  lineHeight: "24px",
                  marginLeft: "16px",
                }}
              >
                Agenda
              </a>
            </div>
            <div className="agenda">
              <Tabs color="yellow" variant="pills" defaultValue="1">
                <Tabs.List grow>
                  <Tabs.Tab
                    style={{ border: "0.2px solid gray" }}
                    value="1"
                    leftSection={<LinkIcon style={iconStyle} />}
                  >
                    2024, 4 April
                  </Tabs.Tab>
                  <Tabs.Tab
                    style={{ border: "0.2px solid gray" }}
                    value="2"
                    leftSection={<LinkIcon style={iconStyle} />}
                  >
                    2024, 6 April
                  </Tabs.Tab>
                  <Tabs.Tab
                    style={{ border: "0.2px solid gray" }}
                    value="3"
                    leftSection={<LinkIcon style={iconStyle} />}
                  >
                    2024, 10 April
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="1">
                  <Grid mt={4}>
                    <Grid.Col xl={4}>
                      <AgendaCard />
                    </Grid.Col>
                    <Grid.Col xl={4}>
                      <AgendaCard />
                    </Grid.Col>
                    <Grid.Col xl={4}>
                      <AgendaCard />
                    </Grid.Col>
                    <Grid.Col xl={4}>
                      <AgendaCard />
                    </Grid.Col>
                    <Grid.Col xl={4}>
                      <AgendaCard />
                    </Grid.Col>
                  </Grid>
                </Tabs.Panel>

                <Tabs.Panel value="2">Second tab content</Tabs.Panel>

                <Tabs.Panel value="3">Third tab content</Tabs.Panel>
              </Tabs>
            </div>
          </div>
        </Grid>
      </ShowSidebar>
    </>
  );
};
