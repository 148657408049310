import {
  createStyles,
  Card,
  Avatar,
  Text,
  Button,
  Space,
  Group,
  Stack,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { Users } from "tabler-icons-react";
import { useMediaQuery } from '@mantine/hooks';


const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.white,
    height: "256px",
    width: "100%",
    position: "relative",
  },

  avatar: {
    border: `2px solid ${theme.white}`,
  },
}));

export function UserCardImage({
  t,
  name,
  description,
  image,
  coverImage,
  membersCount,
  link,
  component,
}) {
  const { classes, theme } = useStyles();
  const max576 = useMediaQuery('(max-width: 576px)');


  return (
    <Card
      withBorder
      sx={{ paddingBottom: "50px" }}
      padding="xl"
      radius={24}
      className={classes.card}
    >
      <Card.Section
        sx={{
          backgroundImage: `url(${coverImage})`,
          height: 90,
          objectFit: "contain",
          backgroundSize: "100% 100%",
        }}
      />
      <Link to={link}>
        <Avatar
          src={image}
          size={54}
          radius={80}
          mx="auto"
          mt={-30}
          className={classes.avatar}
        />
      </Link>
      <Space my={8} />
      <Stack  spacing={0} align={!max576 ? "flex-start" : "center"}>
        <Link to={link} style={{ textDecoration: "none", color: "inherit" }}>
          <Text size={16} weight={700} color="#1A5059">
            {name?.length < 17 ? name : name?.slice(0, 20) + "..."}
          </Text>
        </Link>
        <Space my={2} />
        <Text size={14} weight={400} color="#575757">
          {description?.length < 34
            ? description
            : description?.slice(0, 23) + "..."}
        </Text>
        <Space my={2} />
        <Text mt={2} size={14} weight={400} color="#a3a3a3">
          <Group spacing={8}>
            <Users size={12} />
            {membersCount} {t('members')}
          </Group>
        </Text>
      </Stack>

      <Button
        radius="lg"
        size="md"
        to={link}
        component={component}
        style={{
          marginTop: "10px",
          backgroundColor: "#029199",
          width: "90%",
          color: "#fff",
          fontWeight: 400,
          fontSize: "14px",
          position: "absolute",
          bottom: "7px",
          right: "9px",
        }}
      >
        {t('view')}
      </Button>
    </Card>
  );
}
