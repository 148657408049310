import parse from 'html-react-parser';
import styled from "styled-components";
import { Center, Group, Image, Spoiler, Text, ThemeIcon } from "@mantine/core";
import { Calendar, Layout2, Clock, Language } from "tabler-icons-react";

export function ProgramSpecs({ props }){

  const { entryData,t } = props;

  const { video, image, language, requirement, speciality, category, duration, duration_time, course_deadline, start_date, description } = entryData

  function Item({ props }){

    const { icon, text} = props;

    return(
      <Center>
        <Group>
          <ThemeIcon color={"gray.7"} variant="outline" size="xl">{icon}</ThemeIcon>
          <Text>{text}</Text>
        </Group>
      </Center>
    )
  }

  return(
    <ProgramSpecsStyle>

      <div className="thumbnail">
        {
          video?.includes("youtube.com") ?
          <iframe src={video?.replace("watch?v=", "embed/")} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Embedded youtube" />
          :
          <Image withPlaceholder height={338} src={image} />
        }
      </div>

      <div className="gridx">
        <div className='item'>
          <Item props={{ icon:<Language/>, text:language?.language }}/>
        </div>

        <div className='item'>
          { speciality && <Item props={{ icon:<Layout2/>, text:speciality[0]?.content }}/> }
          { category && <Item props={{ icon:<Layout2/>, text:category?.title }}/> }
        </div>

        <div className='item'>
          { duration && <Item props={{ icon:<Clock/>, text:duration > 1 ? (duration + " " + t('week')) : (duration + " " + t('week')) }}/> }
          { duration_time && <Item props={{ icon:<Clock/>, text:duration_time > 1 ? (duration_time + " " + t('week')) : (duration_time + " " + t('week')) }}/> }
        </div>

        <div className='item'>
          { course_deadline && <Item props={{ icon:<Calendar/>, text:course_deadline?.replaceAll("-","-") }}/> }
          { start_date && <Item props={{ icon:<Calendar/>, text:start_date?.replaceAll("-","-") }}/> }
        </div>
      </div>

      <div className="aboutMobile">
        <hr />
        <Spoiler maxHeight={150} showLabel="Show more" hideLabel="Hide">
          {requirement && parse(requirement)}
        </Spoiler>
      </div>

    </ProgramSpecsStyle>
  )
}

const ProgramSpecsStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.75rem;
  overflow: hidden;
  .gridx{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: .5rem;
    gap: .5rem;
    padding: 1rem;
    .item{
      display: flex;
      justify-content: flex-start;
    }
  }
  .aboutMobile{
    display: none;
  }
  .about{
    display: none;
  }
  .thumbnail{
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    iframe{
      display: flex;
      justify-content: center;
      width: 100%;
      height: 338px;
      margin: 0 auto;
      border: none;
    }
  }

  @media screen and (max-width:1024px){
    margin: 0;
    max-width: 100%;
    margin-top: -3rem;
    .aboutMobile{
      display: block;
      div{
        padding-bottom: .5rem;
      }
      p{
        font-size: 16px;
        line-height: 1.6rem;
        padding-bottom: 1rem;
      }
      button{
        background: none;
        text-decoration: none;
        margin: 0;
        margin-top: 0rem;
        padding: .5rem 0 0 0;
      }
    }
    .thumbnail{
      display: flex;
      justify-content: center;
      position: relative;
      margin: 0;
      margin-bottom: 1rem;
      iframe{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 200px;
        margin: 0 auto;
      }
    }
    hr{
      width: 100%;
      margin: 1.5rem 0;
    }
    .authoredFeeMobile{
      display: block;
      font-weight: 500;
      text-align: center;
      margin-bottom: 1.75rem;
    }
    .authoredFee{
      display: none;
    }
    button{
      width: 100%;
    }
  }
`