import { useState, useEffect } from "react";
import { Button, Input, LoadingOverlay, Modal, TextInput } from "@mantine/core";
import axios from "axios";
import styled from "styled-components";
import { Edit } from "tabler-icons-react";
import { RichTextEditor } from '@mantine/rte';

const EditSchool = ({ props }) => {

  const { schoolInfo, setSchoolInfo, id } = props;

  const [ showModal, setShowModal ] = useState(false)
  const [ loaderVisible, setLoaderVisible ] = useState(false);
  const [ schoolData, setSchoolData ] = useState({})

  useEffect(() => {

    const defaultValues = {
      profile_img: schoolInfo?.School?.[0].profile_img,
      title: schoolInfo?.School?.[0].title,
      description: schoolInfo?.School?.[0].description,
      video_link: schoolInfo?.School?.[0].video_link,
      schoolId: schoolInfo?.School?.[0].id,
    }

    setSchoolData(defaultValues)

  }, [schoolInfo]);

  const { profile_img, description, title, video_link, schoolId } = schoolData

  async function handleEdit(e){

    e.preventDefault();
    setLoaderVisible(true);

    try {
      const formX = new FormData();
      formX.append('title', title);
      profile_img?.name && formX.append('profile_img', profile_img, profile_img?.name);
      formX.append('description', description);
      formX.append('video_link', video_link);

      const response = await axios.patch(`/school/${schoolId}/`, formX)
      setShowModal(false)

      try {
        const response = await axios.get(`/school/data/${id}/`)
        setSchoolInfo(response?.data);
      } catch (error) {

      }
      setLoaderVisible(false);
    } catch (error) {
      setLoaderVisible(false);

    }
  }

  return(
    <EditSchoolStyle>
      <button onClick={() => setShowModal(true)}><Edit/> Edit school info</button>
      <Modal
        opened={showModal}
        onClose={() => setShowModal(false)}
        title="Edit school"
        className="editSchoolModal"
        size="55%"
        >
        <LoadingOverlay visible={loaderVisible} loaderProps={{ color: 'var(--yellow)' }}/>
          <form onSubmit={(e) => handleEdit(e)}>
            <Input.Wrapper label="Image" required className="imageUploader">
              <br />
              {profile_img ?
                profile_img.name ? <label className="label" htmlFor="uploadPhoto">{profile_img.name}</label> : <label className="label" htmlFor="uploadPhoto">{profile_img}</label>
                :
                <label className="label" htmlFor="uploadPhoto" style={{color:"#999"}}>Choose an image..</label>
              }
              <br />
              <input type="file" name="photo" id="uploadPhoto"
                onChange={(e) => { setSchoolData({...schoolData, profile_img: e.target.files[0]}) }}
              />
            </Input.Wrapper>
            <br />
              <TextInput required placeholder="School name" label="Title"
                value={title}
                onChange={(e) => setSchoolData({...schoolData, title: e.target.value})}
              />
            <br />
            <Input.Wrapper label="Description" required className="imageUploader">
              <RichTextEditor
                value={description}
                onChange={(e) => setSchoolData({...schoolData, description: e})}
              />
            </Input.Wrapper>
            <br />
            <TextInput required placeholder="Kursun YouTube linki" label="YouTube link"
              value={video_link}
              onChange={(e) => setSchoolData({...schoolData, video_link: e.target.value})}
            />
            <br />
            <div className="submit" style={{display:"grid", gridTemplateColumns:"1fr 1fr", gridGap:"1rem"}}>
              <Button type="submit" variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}>Publish</Button>
              <Button type="button" onClick={() => setShowModal(false)} variant="outline" color={"gray"} >Cancel</Button>
            </div>
          </form>
      </Modal>
    </EditSchoolStyle>
  )
}

export default EditSchool

const EditSchoolStyle = styled.div`
  button{
    display: flex;
    align-items: center;
    margin-right: 1rem;
    font-weight: 500;
    font-size: 15px;
    background: none;
    background-color: #fff;
    border: 1px solid #eee;
    padding: .5rem 1rem;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: all 0.2s;
    min-width: max-content;
    svg{
      margin-right: .5rem;
    }
    :hover{
      background: #ddd;
    }
  }
  form{
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    input,select,textarea{
      font-family: "Euclid";
      padding: 1.2rem 1rem;
      margin: 0;
      margin-bottom: 1.25rem;
      border-radius: 0.4rem;
      font-size: 15px;
      /* border: none; */
      background-color: #f2f2f2;
    }
    textarea{
      padding: 0.5rem 1rem;
    }
    .calcal{
      margin: 0 auto;
    }
    .imageUploader{
      display: flex;
      flex-direction: column;
      .label{
        cursor: pointer;
        font-family: "Euclid";
        padding: .6rem 1rem;
        margin: 0;
        margin-bottom: 1.25rem;
        border-radius: 0.4rem;
        font-size: 15px;
        border: 1px solid #d8d8d8;
        color: #000;
        background-color: #f2f2f2;
      }
      #uploadPhoto {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }
    }
  }
  @media screen and (max-width:1024px){
    .container{
      padding: 2rem 0;
      margin: 1rem;
      grid-template-columns: 1fr;
      grid-gap: 3rem;
      .dropbox{
        padding-bottom: 2rem;
      }
      .assignmentInfo{
        margin-bottom: 2rem;
      }
    }
  }
  @media screen and (max-width: 360px) {
    .container{
      .assignmentInfo{
        .deadline{
          display: flex;
          flex-direction: column;
          /* align-items: center; */
          /* justify-content: center; */
          .calendar{
            display: none;
          }
          .inputMobile {
            margin: 1rem 0 3rem 0;
            display: block;
          }
        }
      }
      button{
        margin: 0 auto;
      }
    }
  }
`