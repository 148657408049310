import { React, useContext } from "react"
import axios from "axios"
import styled from "styled-components"
import { useState } from "react"
import { AppContext } from "../../../Helpers/Context"
import { LoadingOverlay, Modal } from "@mantine/core"

export const DeleteProfile = () => {

  const { user, aToken, setUser } = useContext(AppContext)
  const [ showModal, setShowModal ] = useState(false)
  const [ loaderVisible, setLoaderVisible ] = useState(false);



  async function handleDelete(){
    setLoaderVisible(true);
    try {
      const response = await axios.delete(`api/v1/edit-user/${user.id}`)
      setUser(null)
      setLoaderVisible(false);
      localStorage.removeItem('taskooluser')
      window.location.href = '/'
    } catch (error) {
      setLoaderVisible(false);
    }
  }

  return(
    <DeleteProfileStyle>
      <button onClick={() => setShowModal(true)} >Delete profile</button>
      <Modal
        opened={showModal}
        onClose={() => setShowModal(false)}
        title="Delete profile"
        >
        <LoadingOverlay visible={loaderVisible} loaderProps={{ color: 'var(--yellow)' }}/>
        <div className="deleteProfileModal">
          <p style={{color:"red"}}>Are you sure you want to delete your profile? If you delete your profile, you will lose all your data.</p>
          <div style={{display:"flex",justifyContent:"center"}}>
            <button onClick={() => handleDelete()} style={{border:"none", background:"#fee", fontSize:"16px",margin:"0.5rem",padding:"0.5rem 0.8rem", borderRadius:"0.2rem", cursor:"pointer"}}>Yes</button>
            <button onClick={() => setShowModal(false)} style={{border:"none", fontSize:"16px",margin:"0.5rem",padding:"0.5rem 0.8rem", borderRadius:"0.2rem", cursor:"pointer"}}>No</button>
          </div>
        </div>
      </Modal>
    </DeleteProfileStyle>
  )
}

const DeleteProfileStyle = styled.div`
  padding: 1rem;
  margin: 2rem 0;
  background-color: #fff;
  border-radius: 0.5rem;
  button{
    display: flex;
    border: none;
    background: none;
    font-size: 15px;
    margin: 0 auto;
    font-weight: 500;
    color: red;
    text-align: center;
    cursor: pointer;
  }
`