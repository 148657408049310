import React from "react";
import styled, { css } from "styled-components";
import { Button, Group, Image, Stack, Text, Title } from "@mantine/core";
import RelatedPrograms from "./RelatedPrograms";
import universityImg from "../../../Assets/SVGs/dashboard/universityImg.svg";
import { BorderRadius, TrendingUp } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";

const AsideContent = ({t}) => {
  const sizeXs = useMediaQuery("(max-width: 576px)");

  return (
    <AsideContentContainer sizeXs={sizeXs}>
      <Stack>
        <div className="intensive-programs">
          <RelatedPrograms props={{t, type: "courses" }} />
        </div>

        {!sizeXs && (
          <UniversityCard>
            <img src={universityImg} alt="University" />
            <Stack px={25} pt={15} pb={20}>
              <Title
                style={{ width: "100%", textAlign: "center" }}
                w={400}
                size={17}
                c="#364152"
              >
                {t('go-to-apply-to-university')}
              </Title>
              <Button
                styles={{
                  root: {
                    "&:hover": {
                      background: "#CCF075",
                    },
                  },
                }}
                component={Link}
                to="/universities"
                leftIcon={<TrendingUp color="#364152" strokeWidth={1.5} />}
                size="md"
                radius="md"
                sx={{ background: "#CCF075", color: "#364152" }}
              >
                {t('apply')}
              </Button>
            </Stack>
          </UniversityCard>
        )}

        {sizeXs && (
          <MobileUniversityCard>
            <div className="university-img-con"></div>
            <Text className="text">
            {t('go-to-apply-to-university')}

            </Text>
            <Link className="link" to="/universities">
              <Group position="center" align="center" spacing={10}>
                <TrendingUp color="#364152" strokeWidth={1.5} />
                <Text color="#364152" size={17} weight={500}>
                {t('apply')}
                </Text>
              </Group>
            </Link>
          </MobileUniversityCard>
        )}
      </Stack>
    </AsideContentContainer>
  );
};

export default AsideContent;

const AsideContentContainer = styled.div`
  width: 100%;
  .intensive-programs {
    width: 100%;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    background-color: #fff;
  }
`;

const UniversityCard = styled.div`
  border-radius: 20px;
  margin-right: 1rem;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  background-color: #fff;
  img {
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0 0;
  }
`;

const MobileUniversityCard = styled.div`
  a {
    text-decoration: none;
  }
  height: 172px;
  position: relative;
  .text {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .link {
    background-color: #ccf075;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    border-radius: 0 0 14px 14px;
  }
  .university-img-con {
    width: 100%;
    height: 116px;
    background-repeat: no-repeat;
    background-position: center !important;
    background-size: cover;
    border-radius: 14px 14px 0 0;
    background: url(${universityImg});
    background-size: cover;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #0000007a;
      border-radius: 14px 14px 0 0;
    }
  }
  .university-img {
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0 0;
  }
`;
