import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "../Components/ScrollToTop";
import { MainLayout, WithSidebarLayout } from "./RouteLayout";
import { AuthRoutes, PrivateRoutes, SignRoutes } from "./PrivateRoutes";
import Landing from "../Pages/Informative/Landing/_Landing";
import About from "../Pages/Informative/About";
import PrivacyPolicy from "../Pages/Informative/PrivacyPolicy";
import CurrentVersion from "../Components/CurrentVersion";
import TermsOfService from "../Pages/Informative/TermsAndConds";
import ErrorPage from "../Pages/Informative/ErrorPage";
import { Program } from "../Components/ProgramInfo/_Program";
import CourseContent from "../Pages/Course/CourseContent/_CourseContent";
import NewProfile from "../Pages/User/NewProfile/NewProfile";
import { TIP } from "../Pages/TIP/_TIP";
import { Videos } from "../Pages/TIP/Videos/_Videos";
import { Vision } from "../Pages/TIP/Vision/_Vision";
import { Profession } from "../Pages/TIP/Profession/_Profession";
import { OlympicProject } from "../Pages/MainOlympic/Project";
import Mentoring from "../Pages/Mentoring/Mentoring";
import { CourseUsers } from "../Pages/Mentoring/CourseUsers";
import CourseMentorContent from "../Pages/Course/CourseMentorContent/_CourseMentorContent";
import { AllContents } from "../Components/AllContents";
import { Community } from "../Components/Communities/Community";
import UserSettings from "../Pages/User/UserSettings/_UserSettings";
import DashboardLayout from "../Components/NewDashboard/Layout";
import NewDashboard from "../Components/NewDashboard";
import { AllCommunities } from "../Components/Communities/AllCommunities";
import Universities from "../Pages/StudyAbroad/Universities";
import SingleUniversity from "../Pages/StudyAbroad/SingleUniversity";
import ApplyForm from "../Pages/StudyAbroad/ApplyForm";
import Agents from "../Pages/StudyAbroad/Agents";
import SingleAgent from "../Pages/StudyAbroad/SingleAgent";
import { NewRegister } from "../Pages/Authentication/Register/_NewRegister";
import Login from "../Pages/Authentication/Login/_Login";
import EmailVerify from "../Pages/Authentication/EmailVerify";
import UniversityPortal from "../Pages/StudyAbroad/UniversityPortal";
import Subscription from "../Pages/StudyAbroad/Subscription";
import Profile from "../Pages/MyProfile/Profile";
import SingleProgram from "../Pages/Programs";
import NewPayment from "../Pages/NewPayment/NewPayment";

export let isTaskool = true;

const RouteLogic = () => {
  return (
    <Routes>
      <Route
        element={<MainLayout props={{ isAside: false, isSidebar: false }} />}
      >
        {/* Public Routes */}
        <Route
          path="/"
          element={
            <AuthRoutes>
              <Landing />
            </AuthRoutes>
          }
        />
        <Route path="/about" element={<About />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-usage" element={<TermsOfService />} />
        <Route path="/version" element={<CurrentVersion />} />
        <Route path="*" element={<ErrorPage />} />

        <Route
          path="/steam/:id"
          element={<Program props={{ isTaskool, type: "steam" }} />}
        />
        <Route
          path="/courses/:id"
          element={<Program props={{ isTaskool, type: "course" }} />}
        />
        <Route path="/steam/:id/payment" element={<NewPayment props={{type : "steam"}} />} />
        <Route path="/courses/:id/payment" element={<NewPayment props={{type : "course"}} />} />


        {/* <Route
            path="/courses/:id"
            element={<SingleProgram props={{ isTaskool, type: "course" }} />}
          /> */}
        <Route path="/tip" element={<TIP />} />
        <Route path="/project/:id" element={<OlympicProject />} />
        <Route
          path="/universities"
          element={<Universities props={{ applyTo: "university" }} />}
        />
        <Route
          path="/universities/:uId/programs/:pId"
          element={<SingleUniversity />}
        />
        <Route path="/profile/:id" element={<Profile type="public" />} />

        {/* Private Rotes */}
        <Route element={<PrivateRoutes />}>
        <Route path="/subscription" element={<Subscription />} />

          <Route
            path="/universities/apply-form/programs/:pId"
            element={<ApplyForm props={{ applyTo: "university" }} />}
          />
          <Route
            path="/agents/:aId/apply-form"
            element={<ApplyForm props={{ applyTo: "agents" }} />}
          />

          <Route
            path="/agents/:aId/universities"
            element={<Universities props={{ applyTo: "agents" }} />}
          />
          <Route path="/agents" element={<Agents />} />
          <Route path="/agents/:aId" element={<SingleAgent />} />
          <Route
            path="/universities/apply-form/programs/:pId"
            element={<ApplyForm props={{ applyTo: "university" }} />}
          />
          <Route path="/universities/portal" element={<UniversityPortal />} />
          <Route
            path="/course/:courseId"
            element={<CourseContent props={{ type: "course" }} />}
          />
          <Route
            path="/steams/:courseId"
            element={<CourseContent props={{ type: "steam" }} />}
          />
          <Route
            path="/profile"
            element={<Profile type="private" props={{ isTaskool }} />}
          />
          <Route path="/tip/videos" element={<Videos />} />
          <Route path="/tip/vision" element={<Vision />} />
          <Route path="/tip/profession" element={<Profession />} />

          <Route
            path="/mentor/course/:courseId"
            element={<CourseMentorContent />}
          />
          <Route path="/mentor" element={<Mentoring />} />
          <Route path="/course-users/:courseId" element={<CourseUsers />} />
        </Route>
        {/* Private Rotes */}
      </Route>

      <Route
        element={<MainLayout props={{ isAside: false, isSidebar: true }} />}
      >
        <Route
          path="/steam"
          element={
            <AllContents props={{ defaultValue: "all", type: "steam" }} />
          }
        />
        <Route
          path="/courses"
          element={
            <AllContents props={{ defaultValue: "all", type: "course" }} />
          }
        />
        <Route path="/groups/:id" element={<Community />} />

        {/* */}
        <Route element={<PrivateRoutes />}>
          <Route
            path="/settings"
            element={<UserSettings props={{ isTaskool }} />}
          />
        </Route>
      </Route>

      <Route
        element={<MainLayout props={{ isAside: true, isSidebar: true }} />}
      >
        <Route path="/communities" element={<AllCommunities />} />

        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<NewDashboard />} />
        </Route>
      </Route>

      <Route element={<SignRoutes />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<NewRegister />} />
        <Route path="/email-verify/:token" element={<EmailVerify />} />
      </Route>
    </Routes>
  );
};

export default RouteLogic;
