import React from 'react'

const CurrentVersion = () => {
  return (
    <div>
      <h2>Version: 030 - test</h2>
    </div>
  )
}

export default CurrentVersion