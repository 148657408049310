import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import styled, { keyframes } from "styled-components";
import NewNavbar from "../../Components/Layout/NewNavbar/NewNavbar";
import { Footer } from "../../Components/Layout/Footer";
import backgrounPattern from "../../Assets/Images/payment/pattern.png";
import powerBi from "../../Assets/Images/payment/power-bi.png";
import check from "../../Assets/SVGs/payment/check.svg";
import whiteCheck from "../../Assets/SVGs/payment/check-white.svg";
import greenCheck from "../../Assets/SVGs/payment/check-green.svg";
import arrowBack from "../../Assets/SVGs/payment/arrow-left.svg";
import subscriptionIcon from "../../Assets/SVGs/payment/orangeSubsIcon.svg";
import tagIcon from "../../Assets/SVGs/payment/tag.svg";
import birbank from "../../Assets/SVGs/payment/birbank.svg";
import ulduzum from "../../Assets/SVGs/payment/ulduzum.svg";
import { Accordion, Button, List, Text } from "@mantine/core";
import arrowD from "../../Assets/SVGs/payment/arrowD.svg";
import infoIcon from "../../Assets/SVGs/payment/info-icon.svg";
import Subscription from "../StudyAbroad/Subscription";
import { useParams } from "react-router-dom";
import { AppContext } from "../../Helpers/Context";
import { useQuery } from "@tanstack/react-query";
import { PromoCode } from "../../Components/Payment/PromoCode";
import { useTranslation } from "react-i18next";
import { PayWithInstallment } from "../../Components/Payment/PayWithInstallment";
import { PaymentStyle } from "../../Components/Payment/PaymentStyle";
import { FreeCourse } from "../../Components/Payment/FreeCourse";
import { PayForSections } from "../../Components/Payment/PayForSections";

function NewPayment({ props }) {
  const { t } = useTranslation();
  const { type } = props;
  const { id } = useParams();
  const { user } = useContext(AppContext);
  const [entryData, setEntryData] = useState([]);
  const [connecting, setConnecting] = useState(false);

  const [promoEntered, setPromoEntered] = useState("");
  const [promoResponse, setPromoResponse] = useState(null);
  const [promoDoesNotExist, setPromoDoesNotExist] = useState(false);

  const [payInSection, setPayInSection] = useState(false);
  const [sections, setSections] = useState([]);
  const [noSectionChosen, setNoSectionChosen] = useState(false);
  const [noSections, setNoSections] = useState(false);
  const [discountPrice, setDiscountPrice] = useState(0);

  const [courseId, setCourseId] = useState(entryData?.id);

  const [coursePrice, setCoursePrice] = useState(entryData?.price || 1);
  const [subtotalPrice, setSubtotalPrice] = useState(entryData?.price || 1);
  const [purchased, setPurchased] = useState(false);
  const [readyToPurchase, setReadyToPurchase] = useState(false);
  const [order, setOrder] = useState({});

  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [noUlduzumPromo, setNoUlduzumPromo] = useState(false);
  const [ulduzumResponse, setUlduzumResponse] = useState(false);

  const [courseInstallmentData, setCourseInstallmentData] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("promo");
  function emptyFields() {
    setPromoEntered("");
    setPromoResponse(null);
    setPromoDoesNotExist(false);

    setPhoneNumber(undefined);
    setNoUlduzumPromo(false);
    setUlduzumResponse(false);

    setCoursePrice(entryData?.price);
  }

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
    emptyFields();
  };

  useQuery(
    [`${type === "course" ? "course-info" : "internship-info"}`, id],
    async () =>
      await axios.get(
        type === "course"
          ? `/api/course/${id}/`
          : type === "steam"
          ? `/steam/${id}/`
          : `/internship/${id}/`
      ),
    {
      onSuccess: (data) => {
        setEntryData(type === "course" ? data.data[0] : data.data);
      },
    }
  );

  let links =
    type === "internship"
      ? {
          successfuly_paid: `/internship/successfuly_paid_intern/${entryData?.id}/`,
          create_order: `/internship/create-internorder/${
            phoneNumber ? "?&phone=994" + phoneNumber : ""
          }`,
          section_list: `/internshipsection/section-list/${entryData?.id}/`,
          get_promocode: `/internship/getpromocodepercent/?intern_id=${
            entryData?.id
          }${promoEntered ? "&code=" + promoEntered : ""}${
            phoneNumber ? "&phone=994" + phoneNumber : ""
          }`,
        }
      : type === "course"
      ? {
          successfuly_paid: `/api/successfuly_paid_course/${entryData?.id}/`,
          create_order: `/api/create-order/${
            phoneNumber !== undefined ? "?&phone=994" + phoneNumber : ""
          }`,
          section_list: `/api/section-detail/${entryData?.id}/`,
          get_promocode: `/api/getpromocodepercentforcourse/?course_id=${
            entryData?.id
          }${promoEntered ? "&code=" + promoEntered : ""}${
            phoneNumber ? "&phone=994" + phoneNumber : ""
          }`,
        }
      : type === "steam"
      ? {
          successfuly_paid: `/steam/successfuly_paid_steam/${entryData?.id}/`,
          create_order: `/steam/create-steamorder/${
            phoneNumber !== undefined ? "?&phone=994" + phoneNumber : ""
          }`,
          section_list: `/api/section-detail/${entryData?.id}/`,
          get_promocode: `​/steam​/getpromocodepercent​/?steam_id=${
            entryData?.id
          }${promoEntered ? "&code=" + promoEntered : ""}${
            phoneNumber ? "&phone=994" + phoneNumber : ""
          }`,
        }
      : type === "hackathon" && {
          successfuly_paid: `/api/successfuly_paid_course/${entryData?.id}/`,
          create_order: `/hackathon/create-order/${
            phoneNumber !== undefined ? "?&phone=994" + phoneNumber : ""
          }`,
          section_list: `/api/section-detail/${entryData?.id}/`,
          get_promocode: `/api/getpromocodepercentforcourse/?course_id=${
            entryData?.id
          }${promoEntered ? "&code=" + promoEntered : ""}${
            phoneNumber ? "&phone=994" + phoneNumber : ""
          }`,
        };

  let texts =
    type === "internship"
      ? {
          apply: "Apply internship",
          log_in: "Log in to apply",
          buy_full: "Buy full internship",
        }
      : type === "steam"
      ? {
          apply: "Apply STEAM",
          log_in: "Log in to apply",
          buy_full: "Buy full STEAM",
        }
      : {
          apply: "Purchase course",
          log_in: "Log in to purchase",
          buy_full: "Buy full course",
        };

  const { data: sectionList, isLoading } = useQuery(
    ["sections list", entryData?.id],
    async () => {
      return await axios.get(links.section_list).then((res) => res.data);
    },
    { enabled: !!entryData?.id }
  );

  useQuery(
    ["installment prices"],
    async () =>
      await axios.get(
        `api/getinstallmentpricefromkapital/?course_id=${courseId}`
      ),
    {
      onSuccess: (data) => {
        setCourseInstallmentData(data.data);
      },
      enabled: !!courseId, // Burada `enabled` parametrini əlavə edirik
    }
  );

  useEffect(() => {
    if (sectionList) {
      if (sectionList?.length > 0) {
        let filtered = sectionList
          .map((section) => {
            if (section.is_final_project === false) {
              return {
                id: section.id,
                title: section.title,
                chosen: true,
              };
            }
          })
          .filter((section) => section !== undefined)
          .sort((a, b) => a.id - b.id);
        setSections(filtered);
      } else {
        setNoSections(true);
      }
    }
  }, [sectionList, discountPrice]);

  useEffect(() => {
    if (entryData) {
      setCourseId(entryData.id);
      setCoursePrice(entryData.price);
      setSubtotalPrice(entryData.price);
      setDiscountPrice(entryData.price);
    }
  }, [entryData]);

  useEffect(() => {
    console.log('order',order)
    if (order.url) {
      localStorage.setItem("CPOrderIdTaskool", order.order_id);
      window.location = order.url;
    }
  }, [order]);

  useEffect(() => {
    if (readyToPurchase === true) {
      const chosenSections = sections.filter((section) => section.chosen);
      const chosenSectionsIds = chosenSections.map((section) => section.id);
      let formX = {
        user: user?.id,
        promocode: promoEntered,
      };

      if (type === "internship") {
        formX.intershipinfo = entryData?.id;
      }
      if (type === "course") {
        formX.course = entryData?.id;
      }
      if (type === "hackathon") {
        formX.hackathon = entryData?.id;
      }

      if (type === "steam") {
        formX.steam = entryData?.id;
      }
      if (phoneNumber?.length >= 9) {
        formX.is_campaign = true;
      }

      if (payInSection && chosenSectionsIds?.length > 0) {
        formX.installment_paid = true;
        formX.sections = chosenSectionsIds;
      }
      if (payInSection && chosenSectionsIds?.length === 0) {
        formX.installment_paid = false;
        formX.sections = [];
      }

      if (payInSection && chosenSectionsIds?.length < 1) {
        setNoSectionChosen(true);
        setReadyToPurchase(false);
      } else {
        (async () => {
          setConnecting(true);
          const response = await axios.post(links.create_order, formX);
          if (response.status === 201) {
            setOrder(response.data);
          }
        })();
      }
    }
  }, [readyToPurchase]);

  let finalPrice =
    sections.length > 0
      ? (discountPrice / sections.length).toFixed(2)
      : discountPrice;

  let finalDiscountPrice = promoResponse
    ? subtotalPrice * (promoResponse?.promocode[0]?.discount_percent / 100)
    : 0;

  const { title, image } = entryData;
  const userLoggedIn = user?.id ? true : false;
  const courseIsFree = coursePrice < 1;

  const subsPackagesConRef = useRef(null);

  const scrollToSubsPackages = () => {
    if (subsPackagesConRef.current) {
      subsPackagesConRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      {/* <NewNavbar /> */}
      <NewPaymentStyle>
        <div className="container">
          <div className="payment">
            <div className="payment-box">
              <div className="order-summary payment-item">
                <h3 className="payment-item-title">{t("order_summary")}</h3>
                <div className="order-course">
                  <h5>{t("order_course")}</h5>
                  <div className="order-course-item">
                    <div className="order-course-img">
                      <img
                        width={75}
                        height={75}
                        style={{ borderRadius: "7px" }}
                        src={entryData && image}
                        alt="course-img"
                      />
                    </div>

                    <div className="order-course-body">
                      <h6>{entryData && title}</h6>
                      <div className="order-course-detail">
                        <p>
                          {t("price")}:
                          <span className="order-course-price">
                            {finalPrice} AZN
                          </span>{" "}
                          <span className="order-course-price-label">
                            {t("per_one_module")}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="select-modules">
                  <Accordion>
                    <Accordion.Item value="customization">
                      <Accordion.Control>
                        <h5>{t("modules")}</h5>
                      </Accordion.Control>
                      <Accordion.Panel style={{ paddingBottom: "10px" }}>
                        {selectedPayment === "promo" ? (
                          <PayForSections
                            props={{
                              t,
                              type,
                              links,
                              texts,
                              entryData,
                              noSections,
                              noSectionChosen,
                              setNoSectionChosen,
                              purchased,
                              courseIsFree,
                              userLoggedIn,
                              connecting,
                              setConnecting,
                              coursePrice,
                              setCoursePrice,
                              user,
                              sections,
                              setSections,
                              payInSection,
                              setPayInSection,
                              setReadyToPurchase,
                              setCoursePrice,
                              discountPrice,
                            }}
                          />
                        ) : (
                          <List>
                            {sections &&
                              sections.map((section) => (
                                <List.Item key={section.id}>
                                  {section.title}
                                </List.Item>
                              ))}
                          </List>
                        )}
                      </Accordion.Panel>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <div
                  style={{ marginTop: "10px" }}
                  className="payment-item-footer"
                >
                  <div className="payment-item-total">{t("subtotal")}</div>

                  <div className="payment-item-price">{subtotalPrice} AZN</div>
                </div>
              </div>

              <div
                style={{ position: "relative" }}
                className="payment-method payment-item"
              >
                <h3 className="payment-item-title">{t("payment_method")}</h3>
                <div className="choose-payment-row">
                  <h5>{t("choose_payment_method")}</h5>
                  <div className="subscription-btn">
                    <img src={subscriptionIcon} alt="" />
                    {t("subscription")}
                  </div>
                </div>

                <div className="choose-payment-box">
                  <div
                    className={`choose-payment-item ${
                      selectedPayment === "promo" ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="promo"
                      checked={selectedPayment === "promo"}
                      onChange={handlePaymentChange}
                    />
                    <div className="choose-payment-name">
                      <img src={tagIcon} alt="" />
                      {t("promo_code")}
                    </div>
                  </div>

                  <div
                    className={`choose-payment-item ${
                      selectedPayment === "birbank" ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="birbank"
                      checked={selectedPayment === "birbank"}
                      onChange={handlePaymentChange}
                    />
                    <div className="choose-payment-name">
                      <img src={birbank} alt="" />
                      Birbank
                    </div>
                  </div>

                  <div
                    className={`choose-payment-item ${
                      selectedPayment === "ulduzum" ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="ulduzum"
                      checked={selectedPayment === "ulduzum"}
                      onChange={handlePaymentChange}
                    />
                    <div className="choose-payment-name">
                      <img src={ulduzum} alt="" />
                      Ulduzum
                    </div>
                  </div>
                </div>

                {selectedPayment === "promo" && (
                  <div className="promo-code-form">
                    <div className="promo-code-input-box">
                      <PromoCode
                        props={{
                          t,
                          links,
                          type,
                          texts,
                          entryData,
                          coursePrice,
                          sections,
                          purchased,
                          courseIsFree,
                          userLoggedIn,
                          user,
                          promoEntered,
                          setPromoEntered,
                          promoResponse,
                          setPromoResponse,
                          promoDoesNotExist,
                          setPromoDoesNotExist,
                          setCoursePrice,
                          setDiscountPrice,
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedPayment === "birbank" && (
                  <div className="promo-code-form">
                    <div className="promo-code-input-box">
                      <PayWithInstallment
                        props={{
                          t,
                          courseInstallmentData,
                          entryData,
                          type,
                          links,
                          texts,
                          coursePrice,
                          sections,
                          purchased,
                          courseIsFree,
                          userLoggedIn,
                          user,
                          promoEntered,
                          setPromoEntered,
                          promoResponse,
                          setPromoResponse,
                          promoDoesNotExist,
                          setPromoDoesNotExist,
                          setCoursePrice,
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedPayment === "promo" && (
                  <>
                    <Accordion>
                      <Accordion.Item value="customization">
                        <Accordion.Control>
                          <div className="payment-item-footer">
                            <div className="payment-item-total">
                              {t("total")}
                            </div>

                            <div className="payment-item-price">
                              {coursePrice} AZN
                            </div>
                          </div>
                        </Accordion.Control>
                        <Accordion.Panel style={{ paddingBottom: "10px" }}>
                          <div className="receipt-box">
                            <div className="receipt-item">
                              <div className="receipt-item-name">
                                {t("subtotal")}
                              </div>

                              <div className="receipt-item-price">
                                {subtotalPrice} AZN
                              </div>
                            </div>

                            <div className="receipt-item">
                              <div className="receipt-item-name">
                                {t("tax")}
                              </div>

                              <div className="receipt-item-price">0 AZN</div>
                            </div>

                            <div className="receipt-item">
                              <div className="receipt-item-name">
                                {t("discount")}
                              </div>

                              <div className="receipt-item-price">
                                {finalDiscountPrice > 0 && "-"}
                                {finalDiscountPrice} AZN
                              </div>
                            </div>
                          </div>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>

                    {courseIsFree ? (
                      <PaymentStyle>
                        <FreeCourse
                          props={{
                            t,
                            type,
                            links,
                            texts,
                            entryData,
                            purchased,
                            courseIsFree,
                            userLoggedIn,
                            user,
                            sections,
                          }}
                        />
                      </PaymentStyle>
                    ) : (
                      <Button
                        className="payment-btn"
                        variant="default"
                        loading={connecting}
                        onClick={() => setReadyToPurchase(true)}
                      >
                        {t("pay")} - {coursePrice} AZN
                      </Button>
                    )}
                  </>
                )}
                <ArrowImage
                  src={arrowD}
                  alt="arrow"
                  onClick={scrollToSubsPackages}
                  style={{ cursor: "pointer" }} // İstəyə bağlı olaraq cursoru pointer şəklində göstərə bilərik
                />
              </div>
            </div>
          </div>

          <div className="subscription">
            <div className="subscription-title">{t("subscription")}</div>
            <div className="subscription-info">
              <img src={infoIcon} alt="info-icon" />
              <div className="subscription-info-title">
                {t("subs_get_discount")}
              </div>
            </div>

            <div className="subs-packages-con" ref={subsPackagesConRef}>
              <Subscription location="payment" />
            </div>
          </div>
        </div>
      </NewPaymentStyle>
      {/* <Footer /> */}
    </div>
  );
}

export default NewPayment;

const NewPaymentStyle = styled.div`
  * {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
  }
  background-color: #f9fafb;
  padding-top: 32px;
  padding-bottom: 44px;
  .container {
    width: 92%;
    margin: 0 auto;

    .payment {
      margin-bottom: 32px;
      .payment-header {
        margin-bottom: 32px;
        .back {
          display: flex;
          align-items: center;
          gap: 16px;
          color: #0a0d13;
          font-family: "Inter";
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px; /* 126.667% */
        }
      }

      .payment-box {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .payment-item {
          background: #fff;
          border-radius: 24px;
          padding: 24px;
          width: 100%;
          h3 {
            color: #0a0d13;
            font-family: "Inter";
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 30.3px; /* 133.333% */
            padding-bottom: 16px;
            border-bottom: 1px solid #cdd5df;
          }
          h5 {
            color: #697586;
            font-family: "Inter";
            font-size: 17px;
            font-weight: 500;
            line-height: 26px; /* 155.556% */
            margin: 16px 0px;
          }

          .select-modules {
            .select-module-box {
              padding-bottom: 16px;
              margin-bottom: 16px;
              border-bottom: 1px solid #cdd5df;
              .select-module-item {
                padding: 12px 0px 12px 4px;
                display: flex;
                justify-content: space-between;
                .select-module-check {
                  color: #364152;

                  font-family: "Inter";
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px; /* 142.857% */
                  display: flex;
                  align-items: center;
                  input {
                    width: 20px;
                    height: 20px;
                    margin-right: 12px;
                  }
                }

                .select-module-item-price {
                  color: #4b5565;
                  font-family: "Inter";
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 24px; /* 150% */
                }
              }
            }
          }

          .payment-item-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .payment-item-total {
              color: #0a0d13;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px; /* 155.556% */
            }
            .payment-item-price {
              color: #0a0d13;
              font-family: "Inter";
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 41px; /* 122.222% */
              letter-spacing: -0.72px;
              margin-right: 10px;
            }
          }
        }

        .order-course {
          .order-course-item {
            display: flex;
            align-items: center;
            gap: 14px;
            border-bottom: 1px solid #cdd5df;
            padding-bottom: 24px;
            h6 {
              color: #121926;
              font-family: "Inter";
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px; /* 155.556% */
              margin-bottom: 11px;
            }
            p {
              overflow: hidden;
              color: #9aa4b2;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */

              .order-course-price {
                color: #4b5565;
                display: inline-block;
                margin-left: 4px;
                margin-right: 12px;
                font-family: "Inter";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
              }

              .order-course-price-label {
                display: inline-block;
                text-align: center;
                line-height: 22px;
                background: #eff8ff;
                width: 106px;
                height: 22px;
                padding: 2px, 8px;
                border-radius: 16px;
                border: 1px solid #b2ddff;
                color: #1570ef;
                font-family: "Inter";
                font-size: 12px;
                font-weight: 500;
              }
            }
          }
        }

        .payment-method {
          .choose-payment-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .subscription-btn {
              display: flex;
              align-items: center;
              gap: 8px;
              font-family: "Euclid";
              font-size: 16px;
              font-weight: 500;
              line-height: 28px;
              letter-spacing: 0em;
              text-align: left;
              color: #fe6027;
            }
          }

          .choose-payment-box {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            margin-bottom: 24px;
            .choose-payment-item {
              display: flex;
              align-items: center;
              gap: 16px;
              width: 100%;
              height: 48px;
              padding: 12px 10px 12px 8px;
              border-radius: 12px;
              border: 1px solid #cdd5df;

              input {
                width: 20px;
                height: 20px;
              }

              .choose-payment-name {
                display: flex;
                align-items: center;
                gap: 12px;
              }
              &.active {
                background-color: #f8fafc;
              }
            }
          }

          .promo-code-form {
            display: flex;
            gap: 20px;
            align-items: center;
            margin-bottom: 12px;
            .promo-code-input-box {
              position: relative;
              width: 100%;
              label {
                position: absolute;
                top: 8px;
                left: 16px;
                color: #00000099;
                font-family: "Inter";
                font-size: 11px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
              }
              input {
                width: 100%;
                height: 56px;
                padding: 9px 16px;
                border-radius: 10px;
                border: 1px solid #cdd5df;
                background: #fff;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
              }
            }

            .apply-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 12px;
              margin-bottom: 12px;
              height: 54px;
              border-radius: 10px;
              background: #00bd90;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              color: #fff;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px; /* 150% */
            }
          }

          .promo-code-note {
            .promocode-company {
              color: #1570ef;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
              display: inline-block;
              margin-right: 8px;
            }

            .promocode-message {
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
              display: inline-block;
              margin-right: 8px;
            }
            .promocode-green {
              color: #17b26a;
            }

            .promocode-red {
              color: red;
            }

            .promocode-discount {
              color: #6c737f;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
              img {
                margin-right: 8px;
              }
            }
          }

          .receipt-box {
            margin: 16px 0px;
            padding-top: 16px;
            padding-bottom: 4 px;
            border-top: 1px solid #cdd5df;
            .receipt-item {
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;
              .receipt-item-name {
                font-family: "Inter";
                font-size: 15px;
                font-weight: 500;
                color: #0a0d13;
              }

              .receipt-item-price {
                font-family: "Inter";
                font-size: 15px;
                font-weight: 500;
                color: #0a0d13;
              }
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
              letter-spacing: 0em;
              text-align: left;
            }
          }
        }

        .payment-btn {
          cursor: pointer;
          background: #fe6027;
          margin-top: 24px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 56px;
          padding: 16px, 28px, 16px, 28px;
          border-radius: 10px;
          border: 1px;
          color: #ffffff;
          font-family: "Inter";
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
  .subscription {
    border-top: 1px solid #e4e1e1;
    padding-top: 25px;
    .subscription-title {
      font-size: 24px;
      font-weight: 500;
      color: #202939;
    }
    .subscription-info {
      display: flex;
      align-items: center;
      margin-top: 25px;
      gap: 10px;
      border-radius: 10px;
      background: #ffebcc;
      padding: 12px 24px;
      .subscription-info-title {
        font-size: 16px;
        font-weight: 500;
        color: #fe6027;
      }
    }
  }
  .subs-packages-con {
    max-width: 75%;
    margin: 20px auto;
  }
  .mantine-UnstyledButton-root.mantine-Button-root.payment-btn.mantine-aktksq {
    position: relative;
    .mantine-Button-icon.mantine-Button-leftIcon.mantine-1g4orza {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const moveUpDown = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
`;

// Styled Component yaradın
const ArrowImage = styled.img`
  animation: ${moveUpDown} 1s infinite;
  position: absolute;
  right: -35px;
  bottom: 35px;
  cursor: pointer;
`;
