import React, { useContext } from "react";
import DashboardLayout from "./Layout";
import styled from "styled-components";
import {
  CommunitiesInFeed,
  CommunitiesInFeedMobile,
} from "../Communities/CommunitiesInFeed";
import { PostCreator } from "../PostCreator";
import { AllPosts } from "../AllPosts";
import { AppContext } from "../../Helpers/Context";
import Advertising from "./Main/Advertising";
import { useMediaQuery } from "@mantine/hooks";
import AsideContent from "./Aside";
import { useTranslation } from "react-i18next";


const NewDashboard = () => {
  const { user } = useContext(AppContext);
  const userType = user?.user_type?.type;
  const sizeXs = useMediaQuery("(max-width: 576px)");
  const { t, i18n } = useTranslation();


  return (
      <ScrollerStyle>
        {!sizeXs && (
          <>
            <Advertising />
            <div
              style={{
                background: "#dcdcdc",
                height: "1px",
              }}
            ></div>
          </>
        )}

        {sizeXs && <AsideContent t={t} />}

        <div className="wrapperHome">
          <CommunitiesInFeed t={t} />


          {(userType == "Mentor" || userType == "Teacher" || userType == "Admin") && (
            <PostCreator t={t} props={{ community: null }} />
          )}

          <AllPosts props={{t, community: null }} />
        </div>
      </ScrollerStyle>
  );
};

export default NewDashboard;

const ScrollerStyle = styled.div`
  paddingleft: "22px";
  margin-bottom : 40px;
  .FilterCommunities {
    width: 100% !important;
  }
  .advertisingSwiper {
    img {
      width: 100%;
    }
    .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #1a5059;
        border: 1px solid #1a5059;
      }
    }
  }
  .mantine-UnstyledButton-root.mantine-Tabs-tab.mantine-1jsfw95[data-active] {
    .mantine-Text-root.mantine-1h5mlz0 {
      color: #364152;
      font-weight: bold;
    }
  }
  .mantine-UnstyledButton-root.mantine-Tabs-tab.mantine-1jsfw95 {
    .mantine-Text-root.mantine-1h5mlz0 {
      color: #797979;
      font-weight: bold;
    }
  }
  .mantine-150s3z1.mantine-Spoiler-content {
    p {
      color: #575757;
    }
    button {
      font-size: 12px;
    }
  }
  .FilterCommunities-main {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
    .mantine-o8x4z2 {
      border-bottom: 0;
      .mantine-1jsfw95 {
        margin-right: 10px;
        padding: 7px 5px;
        &:hover {
          background-color: transparent;
        }
      }
      .mantine-1jsfw95[data-active] {
        border-bottom: 2px solid #029199;
      }
    }
    .scrollMenu {
      overflow: auto;
      white-space: nowrap;
      display: flex;
      gap: 15px;
      width: 296px;
      padding-bottom: 12px;
    }
  }

  .wrapperHome {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 0.65rem;
    .loading {
      display: flex;
      grid-gap: 1.5rem;
      margin: 2rem 1.5rem;
      .skel {
        display: flex;
        max-width: 284px;
      }
    }
  }
  @media (max-width: 1380px) {
    .FilterCommunities-main {
      .scrollMenu {
        width: 600px;
      }
    }
  }
  @media (max-width: 1308px) {
    .FilterCommunities-main {
      .scrollMenu {
        width: 820px;
      }
    }
  }
  @media (max-width: 1230px) {
    .FilterCommunities-main {
      .scrollMenu {
        width: 750px;
      }
    }
  }
  @media (max-width: 1160px) {
    .FilterCommunities-main {
      .scrollMenu {
        width: 700px;
      }
    }
  }
  @media (max-width: 1110px) {
    .FilterCommunities-main {
      .scrollMenu {
        width: 640px;
      }
    }
  }
  @media (max-width: 1050px) {
    .FilterCommunities-main {
      .scrollMenu {
        width: 600px;
      }
    }
  }
  // @media (max-width: 1024px) {
  //   padding: 0 15px;
  //   .FilterCommunities {
  //     display: none;
  //   }
  // }
  @media (max-width: 990px) {
    padding: 0;
    .wrapperHome {
      grid-gap: 1rem;
    }
  }

  @media (max-width: 569px) {
    .social-btn{
      img {
        width: 18px;
        height: 18px;
      }
       span{
        font-size:13px;
       }
    }
    .FilterCommunities-main {
      .scrollMenu {
        max-width: 360px;
        gap: 17px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 12px;
      }
    }
  }
`;
