import { React, useEffect, useState } from "react";
import styled from "styled-components";
import { FirstPart } from "./FirstPart";
import { SecondPart } from "./SecondPart";
import { ThirdPart } from "./ThirdPart";
import { ForthPart } from "./ForthPart";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import {useMutation} from '@tanstack/react-query'
import axios from '../../../Helpers/Axios'


const Landing = () => {
  const { t } = useTranslation();

  const cookie = new Cookies()
  const [accessToken, setAccessToken] = useState(cookie.get("access"));
  const [redirect, setRedirect] = useState(false);

  
  const { mutate: sendToLocal } = useMutation({
    mutationFn: async () => {
      return await axios
        .post("api/v1/user-data/")
        .then((response) => response.data);
    },
    onSuccess: (data) => {
      localStorage.setItem("taskooluser", JSON.stringify(data));
      setRedirect(true);
    },
    onError: (error) => {},
    enabled: accessToken !== undefined,
  });

  useEffect(() => {
    accessToken && sendToLocal();
  }, [accessToken]);

  useEffect(() => {
    setAccessToken(cookie.get("access"));
  }, []);

  useEffect(() => {
    redirect && window.location.reload();
  }, [redirect]);
  return (
    <LandingStyle props={{ t }}>
      <FirstPart props={{ t }} />
      <SecondPart props={{ t }} />
      <ThirdPart props={{ t }} />
      <ForthPart props={{ t }} />
    </LandingStyle>
  );
};

export default Landing;

const LandingStyle = styled.div`
  overflow: hidden;
`;
