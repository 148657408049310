import React from "react";
import styled from "styled-components";
import { Container, Group, Text, Title } from "@mantine/core";

const stages = [
  { title: "Research and Design Phase", duration: "Week 1-2" },
  { title: "Component Selection and Procurement", duration: "Week 3-4" },
  { title: "Construction and Assembly", duration: "Week 5-6" },
  { title: "Testing and Optimization", duration: "Week 7-8" },
  { title: "Documentation and Presentation Preparation", duration: "Week 9-10" },
];

const ProgramMap = () => {
  return (
    <Section>
      
      <ProgramMapContainer>
        <Title order={3} mb={20} size={24} weight={500} color="#364152">
        Program Map
        </Title>
        <Group noWrap spacing={0}>
        {stages.map((stage, index) => (
          <Stage key={index}>
            <Text size={16} weight={500} color="#029199"> {stage.title}</Text>
            <Text size={14} weight={400} color="#029199" >{stage.duration}</Text>
          </Stage>
        ))}
        </Group>
        
      </ProgramMapContainer>
    </Section>
  );
};

export default ProgramMap;

const Section = styled.section`
  background: #e9f0f1;
  width: 100%;
  margin-top: 30px;
`;

const ProgramMapContainer = styled.div`
  max-width: 92%;
  margin: 0 auto;
  padding: 40px 0;
`;


const Stage = styled.div`
  position: relative;
  background: white;
  padding: 10px 20px;
  width: 19.5%;
  height: 93px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 45px;
  clip-path: polygon(88% 0, 100% 50%, 88% 100%, 0% 100%, 11% 50%, 0% 0%);

`;
