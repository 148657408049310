import { React, useEffect, useState } from "react";
import { Select } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import axios from "axios";
import searchIcon from "../../../Assets/SVGs/header/search.svg";
import { Search } from "tabler-icons-react";


function NewSearchBar({props}) {
  const { t, i18n } = useTranslation();
  const [chosenLocation, setChosenLocation] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showList, setShowList] = useState(false);
const {subsVisible , setSubsVisible} = props
  const handleFocus = () => {

    setShowList(true);
    setSubsVisible(false)
  };
  
  const handleBlur = () => {
    setShowList(false);
    setSearchTerm("")
    setSubsVisible(true);
    
  };

  async function getCourses() {
    if (searchData.length === 0) {
      try {
        const getCourses = await axios.get("api/all-courses/");
        // const getInternships = await axios.get("/internship/activeinternshiplist/");

        let returnedCourses = [];
        returnedCourses = getCourses.data.map((course) => {
          return {
            value: course.id + "c",
            label: course.title,
            group: "Courses",
          };
        });

        // let returnedInternships = [];
        // returnedInternships = getInternships.data.map(internship => {
        //   return (
        //     {
        //       value: internship.id + "i",
        //       label: internship.title,
        //       group: "Internships"
        //     }
        //   )
        // })

        setSearchData([
          ...returnedCourses,
          // ...returnedInternships,
        ]);
      } catch (error) {}
    }
  }

  let navigate = useNavigate();

  const filteredData = searchData.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (chosenLocation !== null) {
      if (chosenLocation.endsWith("c")) {
        navigate(`/courses/${chosenLocation.slice(0, -1)}`);
      } else {
        navigate(`/internships/${chosenLocation.slice(0, -1)}`);
      }
    }
  }, [chosenLocation]);
  

  return (
    <div onClick={() => getCourses()} className="search-box">
      <input
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={t("search")}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="search-input"
        type="text"
        onMouseEnter={() => setSubsVisible(false)}
        onMouseLeave={() => !showList && setSubsVisible(true)}
      />
      <div className="icon-item">
        <Search 
        strokeWidth={1.75}
        color="#364152"
        onMouseEnter={() => setSubsVisible(false)}
        onMouseLeave={() => setSubsVisible(true)}
        style={{zIndex:9999}}

        />
      </div>
      <div>
        {/*  */}
        {showList && (
        <div>
          <ul className="search-list">
            {filteredData.length === 0 && <li>{t("no courses found")}</li>}
            {filteredData.map((item) => (
              <li onClick={() => setChosenLocation(item.value)}>{item.label}</li>
            ))}
          </ul>
        </div>
      )}
      </div>
    </div>
  );
}

export default NewSearchBar;
