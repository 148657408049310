import { React, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Grid, Group, Space, Stack, Text, Title } from "@mantine/core";
import colors from "../../../Assets/SVGs/landing/colors.svg";
import target from "../../../Assets/SVGs/landing/target.svg";
import { useMediaQuery } from "@mantine/hooks";
import {AnimatedSVG , AnimatedXsSVG} from "../../../Components/AnimatedSVG";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const ThirdPart = ({ props }) => {
  const matches = useMediaQuery("(max-width: 576px)");
  const { t } = props;
  const thirdPartRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      // Əgər ThirdPart hissəsi ekranın üstündə görünürsə isVisible'i true edirik
      const top = thirdPartRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisible(top < windowHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const underTextVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.1, // Altdan yuxarı animasiya üçün gecikmə
        duration: 0.2,
      },
    },
  };

  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.5,
      },
    }),
  };

  const rightVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.5,
      },
    }),
  };

  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  return (
    <ThirdPartStyle ref={thirdPartRef}>

       <motion.div
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={underTextVariants}
        >
          <Stack align="center">
          
        <Title color="#1A5059" size={30} weight="bold">
          {t("mission")}
        </Title>
        <img src={colors} alt="colors" />
        </Stack>

        </motion.div>
      
      <Space my={60} />
      <Grid ref={ref} align="flex-start">
        <Grid.Col lg={4} mt={matches ? 40 : 0}>
          <Stack spacing={40}>
            <Stack align={matches ? "center" : "flex-end"}>
              <motion.div
                custom={0}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={leftVariants}
              >
                <Title weight={500} size={20} color="#FF7F51">
                  {t("ambitious-youth")}
                </Title>
              </motion.div>
              <motion.div
                custom={1}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={leftVariants}
              >
                <Text
                  style={
                    matches ? { textAlign: "center" } : { textAlign: "right" }
                  }
                  color="#575757"
                  size={16}
                  weight={400}
                >
                  {t("ambitious-youth-content")}
                </Text>
              </motion.div>
            </Stack>
            <Stack align={matches ? "center" : "flex-end"}>
              <motion.div
                custom={2}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={leftVariants}
              >
                <Title weight={500} size={20} color="#A7CA52">
                  {t("aware-teenagers")}
                </Title>
              </motion.div>

              <motion.div
                custom={2}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={leftVariants}
              >
                <Text
                  style={
                    matches ? { textAlign: "center" } : { textAlign: "right" }
                  }
                  color="#575757"
                  size={16}
                  weight={400}
                >
                  {t("aware-teenagers-content")}
                </Text>
              </motion.div>
            </Stack>
          </Stack>
        </Grid.Col>
        <Grid.Col lg={4} order={matches ? -1 : 0}>
          <Group position="center">
            {isVisible && (matches ? <AnimatedXsSVG /> : <AnimatedSVG />)}{" "}
          </Group>
        </Grid.Col>
        <Grid.Col lg={4} mt={matches ? 30 : 0}>
          <Stack spacing={40}>
            <Stack align={matches ? "center" : "flex-start"}>
              <motion.div
                custom={0}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={rightVariants}
              >
                <Title weight={500} size={20} color="#029199">
                  {t("innovative-learning")}
                </Title>
              </motion.div>

              <motion.div
                custom={1}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={rightVariants}
              >
                <Text
                  sx={matches && { textAlign: "center" }}
                  color="#575757"
                  size={16}
                  weight={400}
                >
                  {t("innovative-learning-content")}
                </Text>
              </motion.div>
            </Stack>
            <Stack align={matches ? "center" : "flex-start"}>
              <motion.div
                custom={2}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={rightVariants}
              >
                <Title weight={500} size={20} color="#CC4237">
                  {t("career-growth")}
                </Title>
              </motion.div>

              <motion.div
                custom={3}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={rightVariants}
              >
                <Text
                  sx={matches && { textAlign: "center" }}
                  color="#575757"
                  size={16}
                  weight={400}
                >
                  {t("career-growth-content")}
                </Text>
              </motion.div>
            </Stack>
          </Stack>
        </Grid.Col>
      </Grid>
    </ThirdPartStyle>
  );
};

const ThirdPartStyle = styled.div`
  max-width: 90%;
  margin: 0 auto;
  @media only screen and (max-width: 1250px) {
    .target {
      width: 70%;
    }
  }

`;
