import React, { useContext, useState } from "react";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDropzone } from "react-dropzone";
import parse from "html-react-parser";
import styled from "styled-components";
import {
  Container,
  Avatar,
  Grid,
  LoadingOverlay,
  Spoiler,
  Button,
  Modal,
  TextInput,
  FileInput,
  Image,
  NumberInput,
} from "@mantine/core";
import {
  ArrowNarrowLeft,
  FileCode,
  Trash,
  Download,
  Upload,
  Pencil,
  FileDatabase,
  File,
  Video,
  DeviceAudioTape,
  FileSpreadsheet,
  FileText,
  FileDescription,
  PresentationAnalytics,
  Photo,
} from "tabler-icons-react";
import { Rating } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useParams } from "react-router-dom";
import { AppContext } from "../../Helpers/Context";
import { Link } from "react-router-dom";
import RichTextEditor from "@mantine/rte";

export const OlympicProject = () => {
  const md = useMediaQuery("(max-width: 850px)");
  const sm = useMediaQuery("(max-width: 568px)");
  const { id } = useParams();
  const { user } = useContext(AppContext);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [fileSizeError, setFileSizeError] = useState("");
  const [values, setValues] = useState({});
  const queryClient = useQueryClient();

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const { data: project, isLoading: projectLoading } = useQuery(
    ["project", id],
    async () => await axios.get(`/olympic/project/edit/${id}`),
    {
      onSuccess: (data) => {
        setValues({
          name: data?.data?.name || "",
          image: data?.data?.image || null,
          description: data?.data?.description || "",
          video: data?.data?.video || "",
        });
      },
    }
  );

  console.log(project);

  const uploadFile = async ({ file, project }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("project", project);

      const response = await axios.post(
        "/olympic/project/file/create/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      setFileSizeError(error?.message);
    }
  };

  const { mutate, isLoading: fileUploadLoading } = useMutation(uploadFile, {
    onSuccess: () => {
      window.location.reload();
    },
  });

  const handleAddFile = (acceptedFiles, project) => {
    acceptedFiles.forEach((file) => {
      if (file.size > 20 * 1024 * 1024) {
        setFileSizeError(`File ${file.name} is larger than 20 MB`);
      } else {
        mutate({ file, project });
      }
    });
  };

  const { data: projectFiles, isLoading: filesLoading } = useQuery(
    ["files", id],
    async () =>
      await axios
        .get(`/olympic/project/files/${id}/`)
        .then((data) => data?.data)
  );

  const { data: olympicData } = useQuery(
    ["olympic data", project?.data?.olympic],
    async () =>
      await axios.get(`/olympic/olympicbyid/${project?.data?.olympic}/`),
    {
      enabled: !!project,
    }
  );

  const isMentor = olympicData?.data[0]?.mentors?.find(
    (mentor) => mentor.id === user?.id
  );

  const isAdmin = project?.data?.user === user?.id;

  const [inputValues, setInputValues] = useState({
    "level 1": "",
    "level 2": "",
    "level 3": "",
  });

  const handleChangeRate = (category, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [category]: Number(value),
    }));
  };

  const submitRating = async (e) => {
    e.preventDefault();

    for (let i = 1; i <= 3; i++) {
      let formRating = new FormData();
      formRating.append("level", i);
      formRating.append("project", id);
      formRating.append("point", inputValues[`level ${i}`]);
      formRating.append("user", user?.id);
      await axios.post(`/olympic/project/rating_create/`, formRating);
    }
  };

  const { data: allProjectRates } = useQuery(
    ["all project rates"],
    async () => {
      await axios
        .get("/olympic/project/ratingbyallprojects/")
        .then((data) => data?.data);
    }
  );

  const { data: projectRate } = useQuery(["project rate", 5], async () => {
    await axios
      .get(`/olympic/project/ratingbyproject_id/${5}/`)
      .then((data) => data?.data);
  });

  const deleteProject = async () => {
    try {
      const response = axios
        .delete(`/olympic/project/edit/${id}/`)
        .then((_) => (window.location.href = `/olympics`));
      return response.data;
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getFileDetails = (url) => {
    const match = url.match(/\.([a-zA-Z0-9]+)$/);

    if (match) {
      const fileExtension = match[1].toLowerCase();

      switch (fileExtension) {
        case "png":
        case "jpg":
        case "jpeg":
        case "svg":
          return "Image file";
        case "ppt":
        case "pptx":
          return "Presentation file";
        case "pdf":
          return "PDF file";
        case "doc":
        case "docx":
          return "Document file";
        case "xls":
        case "xlsx":
          return "Spreadsheet file";
        case "mp3":
        case "wav":
          return "Audio file";
        case "mp4":
        case "avi":
        case "mkv":
          return "Video file";
        case "js":
        case "java":
        case "py":
        case "cpp":
        case "c":
        case "html":
        case "css":
          return "Code file";
        case "sql":
        case "db":
        case "mdb":
          return "Database file";
        default:
          return "Other file type";
      }
    }

    return "Invalid URL";
  };

  const getFileIcon = (url) => {
    const match = url.match(/\.([a-zA-Z0-9]+)$/);

    if (match) {
      const fileExtension = match[1].toLowerCase();

      switch (fileExtension) {
        case "png":
        case "jpg":
        case "jpeg":
        case "svg":
          return (
            <div style={{ backgroundColor: "#64ADF8" }} className="fileIcon">
              <Photo width={30} height={30} />
            </div>
          );
        case "ppt":
        case "pptx":
          return (
            <div style={{ backgroundColor: "#E76F41" }} className="fileIcon">
              <PresentationAnalytics width={30} height={30} />
            </div>
          );
        case "pdf":
          return (
            <div style={{ backgroundColor: "#E2574C" }} className="fileIcon">
              <FileDescription width={30} height={30} />
            </div>
          );
        case "doc":
        case "docx":
          return (
            <div style={{ backgroundColor: "#3A80F6" }} className="fileIcon">
              <FileText width={30} height={30} />
            </div>
          );
        case "xls":
        case "xlsx":
          return (
            <div style={{ backgroundColor: "#4BA665" }} className="fileIcon">
              <FileSpreadsheet width={30} height={30} />
            </div>
          );
        case "mp3":
        case "wav":
          return (
            <div style={{ backgroundColor: "#8D6A54" }} className="fileIcon">
              <DeviceAudioTape width={30} height={30} />
            </div>
          );
        case "mp4":
        case "avi":
        case "mkv":
          return (
            <div style={{ backgroundColor: "#9A66A4" }} className="fileIcon">
              <Video width={30} height={30} />
            </div>
          );
        case "js":
        case "java":
        case "py":
        case "cpp":
        case "c":
        case "html":
        case "css":
          return (
            <div style={{ backgroundColor: "gray" }} className="fileIcon">
              <FileCode width={30} height={30} />
            </div>
          );
        case "sql":
        case "db":
        case "mdb":
          return (
            <div style={{ backgroundColor: "lightgray" }} className="fileIcon">
              <FileDatabase width={30} height={30} />
            </div>
          );
        default:
          return (
            <div style={{ backgroundColor: "#FDFA96" }} className="fileIcon">
              <File width={30} height={30} />
            </div>
          );
      }
    }

    return "Invalid URL";
  };

  const submitProject = async (e) => {
    const { name, image, description, video, img_prev } = values;

    e.preventDefault();
    const formX = new FormData();
    formX.append("name", name);
    formX.append("description", description);
    if (img_prev) formX.append("image", image);
    formX.append("video", video);
    formX.append("user", user?.id);

    const data = await (
      await axios.patch(`/olympic/project/edit/${id}/`, formX)
    ).data;
    setValues({
      name: data?.name || "",
      image: data?.image || null,
      description: description || "",
      video: data?.video || "",
    });
    setShowEditModal(!showEditModal);
    queryClient.invalidateQueries(["project", id]);
  };

  const deleteFile = async (file) => {
    await axios.delete(`/olympic/project/file/edit/${file?.id}/`);
    queryClient.invalidateQueries(["files", id]);
  };

  if (projectLoading) {
    return (
      <LoadingOverlay
        visible={true}
        variant="bars"
        loaderProps={{ color: "var(--yellow)" }}
      />
    );
  }

  if (fileUploadLoading) {
    return (
      <LoadingOverlay
        visible={true}
        variant="bars"
        loaderProps={{ color: "var(--yellow)" }}
      />
    );
  }

  return (
    <div>
      <Container size={"lg"} mt={20}>
        <ProjectMain>
          <div className="header">
            <div className="title">
              <Link to={`/olympic/${project?.data?.olympic}`}>
                <ArrowNarrowLeft size={32} strokeWidth={2} color={"black"} />
              </Link>
              <div className="text">
                <a href="">{project?.data?.name}</a>
                <span>Olympic project</span>
              </div>
            </div>
            {isAdmin && (
              <div
                style={{
                  width: "80%",
                  marginLeft: "10px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  onClick={() => setShowEditModal(true)}
                  style={{
                    background: "#484848",
                    color: "white",
                  }}
                >
                  <Pencil />
                </Button>
                <Button
                  onClick={() => setShowRemoveModal(true)}
                  style={{
                    background: "red",
                    color: "white",
                    marginLeft: "10px",
                  }}
                >
                  <Trash />
                </Button>
              </div>
            )}
          </div>
          <div className="modals">
            <Modal
              size={sm ? "xs" : "xl"}
              opened={showEditModal}
              onClose={() => setShowEditModal(false)}
              title="Edit project"
            >
              <form onSubmit={submitProject}>
                <Grid>
                  <Grid.Col lg={6} md={12}>
                    <TextInput
                      placeholder="Title"
                      defaultValue={values?.name}
                      onChange={(e) =>
                        setValues({ ...values, name: e.target.value })
                      }
                    />
                  </Grid.Col>
                  <Grid.Col lg={6} md={12}>
                    <TextInput
                      placeholder="Video - https://youtube.com/watch..."
                      defaultValue={values?.video}
                      onChange={(e) =>
                        setValues({ ...values, video: e.target.value })
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <RichTextEditor
                      value={values.description}
                      onChange={(e) => {
                        setValues({ ...values, description: e });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col lg={6} md={12}>
                    <FileInput
                      placeholder={values?.image}
                      icon={<Upload size="14px" />}
                      onChange={(e) => {
                        getBase64(e).then((img) => {
                          setValues({ ...values, image: e, img_prev: img });
                        });
                      }}
                    />

                    {typeof values?.image == typeof "values?.image" && (
                      <img
                        src={values.image}
                        alt=""
                        height={360}
                        style={{ marginTop: "20px", maxWidth: "100%" }}
                      />
                    )}
                    {typeof values?.image != typeof "values?.image" && (
                      <img
                        src={values?.img_prev}
                        alt=""
                        width={360}
                        height={360}
                        style={{ marginTop: "20px", maxWidth: "100%" }}
                      />
                    )}
                  </Grid.Col>
                  <Grid.Col lg={6} md={12}>
                    <Button
                      fullWidth
                      style={{ backgroundColor: "#333" }}
                      type="submit"
                    >
                      Save changes
                    </Button>
                  </Grid.Col>
                </Grid>
              </form>
            </Modal>
            <Modal
              size={"sm"}
              opened={showRemoveModal}
              onClose={() => setShowRemoveModal(false)}
              title="Delete project"
            >
              Are you sure you want to delete your project?
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowRemoveModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    deleteProject();
                  }}
                  style={{ backgroundColor: "red", cursor: "pointer" }}
                >
                  Remove
                </Button>
              </div>
            </Modal>
          </div>

          <div
            style={{
              display: sm ? "block" : "flex",
              justifyContent: "space-between",
            }}
            className="descvideo"
          >
            <div className="description">
              <h1>Description</h1>
              <Spoiler
                mt={-14}
                maxHeight={160}
                showLabel="Show more"
                hideLabel="Hide"
                styles={{ control: { color: "#868E96" } }}
              >
                <p>
                  {project?.data?.description &&
                    parse(project?.data?.description)}
                </p>
              </Spoiler>
            </div>
            <ProgramSpecsStyle>
              <div className="thumbnail">
                {project?.data?.video &&
                project?.data?.video?.includes("youtube.com") ? (
                  <iframe
                    src={project?.data?.video?.replace("watch?v=", "embed/")}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                ) : (
                  <Image
                    withPlaceholder
                    width={400}
                    fit="contain"
                    height={368}
                    src={project?.data?.image}
                  />
                )}
              </div>
            </ProgramSpecsStyle>
          </div>

          <div className="files">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "82%",
              }}
            >
              <h1>Files</h1>
            </div>
            {isAdmin && (
              <div className="uploadPart">
                <section className="container">
                  <div
                    {...getRootProps({
                      className: "dropzone",
                      style: {
                        border: "2px dashed #cccccc",
                        borderRadius: "4px",
                        padding: "20px",
                        textAlign: "center",
                        width: "78%",
                      },
                    })}
                  >
                    <input {...getInputProps()} />
                    <p>
                      Drag and drop some files here, or click to select files
                    </p>
                  </div>
                  <aside>
                    {fileSizeError && (
                      <span style={{ color: "red" }}>{fileSizeError}</span>
                    )}

                    {files.length > 0 && (
                      <>
                        <ul>{files}</ul>

                        <div
                          style={{
                            width: "82%",
                            display: "flex",
                            justifyContent: "end",
                            marginBottom: "10px",
                          }}
                        >
                          <Button
                            onClick={() => {
                              handleAddFile(acceptedFiles, id);
                            }}
                            style={{ background: "#484848", color: "white" }}
                          >
                            Add file
                          </Button>
                        </div>
                      </>
                    )}
                  </aside>
                </section>
              </div>
            )}
            <div className="uploadedFiles">
              {projectFiles?.length > 0 ? (
                projectFiles?.map((file, index) => (
                  <div key={index} className="file">
                    <div className="infoPart">
                      {getFileIcon(file?.file)}
                      <p>{getFileDetails(file?.file)}</p>
                    </div>
                    <div>
                      <a
                        className="downloadBtn"
                        download={"downloaded_file"}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={file?.file}
                      >
                        <Download />
                      </a>
                      {isAdmin && (
                        <Trash
                          onClick={() => deleteFile(file)}
                          style={{
                            color: "red",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p style={{ marginTop: "-10px" }}>
                  There are no uploaded files yet.
                </p>
              )}
            </div>
          </div>

          {isMentor && (
            <div className="rating">
              <div className="rates">
                <div className="rate">
                  <p>Creativity:</p>
                  <NumberInput
                    className="rateIt"
                    placeholder="Rate out of 100"
                    clampBehavior="strict"
                    hideControls
                    min={0}
                    max={100}
                    onChange={(value) => handleChangeRate("level 1", value)}
                  />
                </div>

                <div className="rate">
                  <p>Innovative:</p>
                  <NumberInput
                    className="rateIt"
                    placeholder="Rate out of 100"
                    clampBehavior="strict"
                    hideControls
                    min={0}
                    max={100}
                    onChange={(value) => handleChangeRate("level 2", value)}
                  />
                </div>

                <div className="rate">
                  <p>Presentation:</p>
                  <NumberInput
                    className="rateIt"
                    placeholder="Rate out of 100"
                    clampBehavior="strict"
                    hideControls
                    min={0}
                    max={100}
                    onChange={(value) => handleChangeRate("level 3", value)}
                  />
                </div>
              </div>
              <button onClick={submitRating}>Vote</button>
            </div>
          )}
        </ProjectMain>
      </Container>
    </div>
  );
};

const ProjectMain = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
  }
  .title {
    display: flex;

    .text {
      margin-left: 16px;

      a {
        color: #1a1d1c;
        text-decoration: none !important ;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 100% */
      }

      span {
        display: block;
        margin-top: 4px;
        font-size: 15px;
        color: #5e5e5e;
      }
    }
  }

  .projectCarousel {
    margin-top: 30px;
    .carousel {
      width: 880px;
    }
  }

  .projectVideo {
    margin-top: 30px;
    .video {
      width: 880px;
    }
  }

  .description {
    margin-top: 30px;

    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color: #4f4f4f;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      color: #333;
      text-align: justify;
    }
  }

  .files {
    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color: #4f4f4f;
    }

    .uploadedFiles {
      margin-top: 20px;
    }

    .file {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid lightgray;
      border-radius: 8px;
      width: 80%;
      padding: 0 10px;
      margin-bottom: 4px;

      .fileIcon {
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
        border-radius: 50%;
        color: white;
        padding: 10px;
        margin-right: 4px;
      }

      .infoPart {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .downloadBtn {
        cursor: pointer;
        color: black;
      }
    }
  }

  .teamMembers {
    margin-top: 20px;

    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color: #4f4f4f;
    }

    .members {
      .member {
        display: flex;
        margin-bottom: 30px;

        .avatar {
          width: 64px;
          height: 64px;
          flex-shrink: 0;
          border-radius: 50%;
        }

        .info {
          margin-left: 10px;

          h1 {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
            color: #1a1d1c;
          }

          p {
            color: #5e5e5e;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 18px */
            margin-top: -10px;
          }
        }
      }
    }
  }

  .rating {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .rates {
      border-radius: 8px;
      background: #fff7e0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .rate {
        display: flex;
        align-items: center;

        p {
          margin-left: 20px;
          margin-right: 4px;
          color: #1a1d1c;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
        }

        .rateIt {
          margin-right: 26px;
          width: 125px;
        }
      }
    }

    button {
      width: 300px;
      height: 52px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #484848;
      color: white;
      font-size: 16px;
      cursor: pointer;
    }
  }

  @media (max-width: 1050px) {
    .rating {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
  @media (max-width: 995px) {
    .projectCarousel {
      .carousel {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    .rating {
      .rates {
        width: 100%;
      }

      button {
        width: 100%;
      }
    }
  }
`;

const ProgramSpecsStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.75rem;
  overflow: hidden;
  .gridx {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding: 1rem;
    .item {
      display: flex;
      justify-content: flex-start;
    }
  }
  .aboutMobile {
    display: none;
  }
  .about {
    display: none;
  }
  .thumbnail {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    iframe {
      display: flex;
      justify-content: center;
      width: 600px;
      height: 360px;
      margin: 0 auto;
      border: none;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-top: 2rem;
    max-width: 100%;
    .aboutMobile {
      display: block;
      div {
        padding-bottom: 0.5rem;
      }
      p {
        font-size: 16px;
        line-height: 1.6rem;
        padding-bottom: 1rem;
      }
      button {
        background: none;
        text-decoration: none;
        margin: 0;
        margin-top: 0rem;
        padding: 0.5rem 0 0 0;
      }
    }
    .thumbnail {
      display: flex;
      justify-content: center;
      position: relative;
      margin: 0;
      margin-bottom: 1rem;
      iframe {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 200px;
        margin: 0 auto;
      }
    }
    hr {
      width: 100%;
      margin: 1.5rem 0;
    }
    .authoredFeeMobile {
      display: block;
      font-weight: 500;
      text-align: center;
      margin-bottom: 1.75rem;
    }
    .authoredFee {
      display: none;
    }
    button {
      width: 100%;
    }
  }
`;
