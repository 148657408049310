import styled from "styled-components";
import { Link } from "react-router-dom";
import { BrandAppleArcade, Map, Shape2 } from "tabler-icons-react";
import { Image, Text } from "@mantine/core";
import logo from "../Assets/Images/tiplogo.png";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const SideBarNav = ({ props }) => {
  const { isTaskool } = props;

  const { t, i18n } = useTranslation();

  const location = useLocation();

  // let texts = isTaskool
  //   ? {
  //       routes: "İstiqamətlər",
  //       c1: "Kurslar",
  //       c2: "Gələcəyinizi qurun",
  //       g1: "STEAM Labarotoriya",
  //       g2: "Bacarıqlarınızı təkmilləşdirin",
  //     }
  //   : {
  //       routes: "For Building Portfolio",
  //       c1: "Skill building programs",
  //       c2: "Build your future",
  //       i1: "Internships & Business Tasks",
  //       i2: "Learn creating business plan",
  //       g1: "Career mentoring",
  //       g2: "Take a quiz and improve your skills",
  //     };

  return (
    <SideBarNavStyle>
      <Text size={18} weight={500}>
        {t("directions-routes")}
      </Text>
      <Link to="/courses">
        <Shape2 radius={"100%"} color="#7561da" className="seeding" />
        <div>
          <Text className="title" size={16}>
            {t("directions-c1")}
          </Text>
          <Text size={14} color="dimmed">
            {t("directions-c2")}
          </Text>
        </div>
      </Link>
      {!isTaskool && (
        <Link to="/internships">
          <Map radius={"100%"} color="#e94c89" className="chartPie" />
          <div>
            <Text className="title" size={16}>
              {t("directions-i1")}
            </Text>
            <Text size={14} color="dimmed">
              {t("directions-i2")}
            </Text>
          </div>
        </Link>
      )}
      {isTaskool && (
        <Link to="/internships">
          {/* <Map radius={"100%"} color="#e94c89" className="chartPie"/> */}
          <div className="svg">
            <Image
              style={{ filter: "saturate(2) brightness(.95)" }}
              width={"23px"}
              src={logo}
            />
          </div>
          <div>
            <Text className="title" size={16}>
              {t("directions-d1")}
            </Text>
            <Text size={14} color="dimmed">
              {t("directions-d2")}
            </Text>
          </div>
        </Link>
      )}
      <Link to="/games">
        <BrandAppleArcade
          radius={"100%"}
          color={isTaskool ? "#e94c89" : "#20bece"}
          className="notes"
        />
        <div>
          <Text className="title" size={16}>
            {t("directions-g1")}
          </Text>
          <Text size={14} color="dimmed">
            {t("directions-g2")}
          </Text>
        </div>
      </Link>
    </SideBarNavStyle>
  );
};

const SideBarNavStyle = styled.div`
  background-color: #fff;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  border-radius: 0.75rem;
  border: 1px solid #dee2e6;
  h2 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 1.5rem;
  }
  a {
    display: flex;
    align-items: center;
    border-right: 1px solid transparent;
    transition: all 0.15s;
    text-decoration: none;
    color: #000;
    cursor: pointer;
    svg,
    .svg {
      margin-right: 0.75rem;
      padding: 0.5rem;
      border-radius: 0.25rem;
    }
    .svg {
      background-color: #f7f9fc;
    }
    .seeding {
      background-color: #f7f9fc;
    }
    .notes {
      background-color: #f7f9fc;
    }
    .chartPie {
      background-color: #f7f9fc;
    }
    &:hover {
      .title {
        text-decoration: underline;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 24px;
    }
  }
`;
