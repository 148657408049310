import {
  Button,
  Center,
  FileInput,
  Image,
  Modal,
  Select,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useContext, useState } from "react";
import { AppContext } from "../../Helpers/Context";
import { Clock, SquarePlus, Upload } from "tabler-icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

export const CreateCommunity = ({ props }) => {
  let { user } = useContext(AppContext);

  const { createCommunityModalOpen, setCreateCommunityModalOpen } = props;

  let [comName, setComName] = useState("");
  let [comDesc, setComDesc] = useState("");
  let [comCate, setComCate] = useState(null);
  let [comImage, setComImage] = useState(null);

  let formX = new FormData();
  formX.append("user", user?.id);
  formX.append("name", comName);
  formX.append("group", comCate);
  comDesc && formX.append("description", comDesc);
  comImage && formX.append("image", comImage);

  // Ask Dilara to remove this entry
  formX.append("members", [user?.id]);

  const queryClient = useQueryClient();

  const { data: groups, isLoading: loadingTwo } = useQuery(
    ["community groups"],
    () => axios.get(`/blog/community_groups/`).then((res) => res.data)
  );

  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    ["create community"],
    () => {
      if (comName.length > 0 && comCate !== null) {
        return axios.post("/blog/community/", formX).then((res) => res.data);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["communities"]);
      },
    }
  );

  if (isSuccess) {
    return (
      <Modal
        overlayBlur={3}
        size={430}
        radius={"lg"}
        withCloseButton={false}
        opened={createCommunityModalOpen}
        onClose={() => setCreateCommunityModalOpen(false)}
      >
        <Center mt={20}>
          <Clock size={30} color="#12b886" />
        </Center>
        <Text align="center" color="teal" size={26}>
          Creation pending
        </Text>
        <Text px={20} mt={10} pb={20} align="center" size={18}>
          A request for creating your group has been sent!
        </Text>
      </Modal>
    );
  }

  return (
    <Modal
      overlayBlur={3}
      closeOnClickOutside={false}
      size={430}
      radius={"lg"}
      title="Create group"
      opened={createCommunityModalOpen}
      onClose={() => setCreateCommunityModalOpen(false)}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src={comImage ? URL.createObjectURL(comImage) : ""}
            maw={240}
            withPlaceholder
            height={220}
            styles={{ root: { border: "5rem" } }}
            style={{ border: "1px solid #ddd" }}
          />

          <FileInput
            value={comImage}
            onChange={setComImage}
            style={{ width: "100.5%" }}
            clearable
            clearButtonLabel="Remove"
            styles={{
              input: {
                borderTop: "0",
                borderTopLeftRadius: "0",
                borderTopRightRadius: "0",
              },
            }}
            placeholder="Upload a cover image"
            icon={<Upload color="#555" />}
            iconWidth={45}
            radius={"md"}
          />
        </div>

        <TextInput
          value={comName}
          onChange={(e) => setComName(e.currentTarget.value)}
          required
          label="Group name"
          placeholder="A name suitable for your group"
          radius={"md"}
        />

        <Select
          onChange={(e) => setComCate(e)}
          required
          label="Select a category"
          placeholder="Pick one"
          style={{ width: "100%" }}
          radius={"md"}
          data={
            groups?.length > 0
              ? groups.map((e) => ({ value: e?.id, label: e?.name }))
              : []
          }
        />

        <Textarea
          value={comDesc}
          onChange={(e) => setComDesc(e.currentTarget.value)}
          label={`Group description (${comDesc?.length}/500)`}
          placeholder="Describe your group"
          minRows={4}
          radius={"md"}
          style={{ width: "100%" }}
        />

        {isError && <Text color="red">{error?.message}</Text>}

        <Button
          loading={isLoading}
          onClick={() => mutate()}
          disabled={comName.length < 1 || comCate === null}
          radius={"md"}
          fullWidth
          color="gray.8"
          leftIcon={<SquarePlus />}
        >
          Create
        </Button>
      </div>
    </Modal>
  );
};
