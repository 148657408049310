import React from "react";
import styled from "styled-components";
import { Button, Group, Stack, Text, Title } from "@mantine/core";
import agentImg from "../../../Assets/SVGs/university/agent.svg";
import { Link } from "react-router-dom";
import userImg from '../../../Assets/SVGs/header/user.png'
import agentIcon from '../../../Assets/SVGs/university/agentIcon.svg'


const AgentCard = ({t,agent}) => {
  return (
    <CardContainer>
      <Stack style={{padding : "16px 24px"}} >
        <Group>
          <div
            style={{
              width: "55px",
              height: "55px",
              borderRadius: "100%",
              border: "1px solid #D1CCCC",
              background : "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          <img
            style={{ width: "35px", height: "35px" }}
          src={agent?.profile_img === "https://taskilled.blob.core.windows.net/taskool-store/user-profile/0e6cb3b5-f322-455a-b75e-27f3c3e6d5aa.jpeg" ? agentIcon : agent?.profile_img}

            alt="Agent"
          />
          </div>
          <Title color="#364152" size={18} weight={500}  order={2}>{agent?.first_name}</Title>
        </Group>
        <div
        style={{
            background: "#E1E1E1",
            width: "100%",
            height: "1px",
        }}
        >
        </div>
        <Group spacing={30} >
            <Group spacing={5} >
             <Text span text={16} weight={400} color="#676767" >{t('raiting')}:</Text>
             <Text span text={16} weight={400} color="#676767" >{agent?.rank}</Text>
            </Group>
            <Group spacing={5} >
             <Text span text={16} weight={400} color="#676767">{t('students')}:</Text>
             <Text span text={16} weight={400} color="#676767">{agent?.stundets ? agent?.stundets : 0}</Text>
            </Group>
        </Group>
        <Button
        style={{
            background: "#FE6027",
            color: "#fff",
            width: "100%",
            fontSize: "16px",
            fontWeight: 500,
        }}
        size="lg"
        radius={10}
        component={Link}
        to={`/agents/${agent?.id}`}
        >
            {t('see-more')}
        </Button>
      </Stack>
    </CardContainer>
  );
};

export default AgentCard;

const CardContainer = styled.div`
box-shadow: 0px 1px 10px 0px #0000001A;
border-radius:11px;
width:100%;
`;
