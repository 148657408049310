import React, { useState } from "react";
import styled from "styled-components";
import editIcon from "../../../Assets/SVGs/profile/edit.svg";
import moreIcon from "../../../Assets/SVGs/profile/more.svg";
import locationIcon from "../../../Assets/SVGs/profile/location.svg";
import tagIcon from "../../../Assets/SVGs/profile/tag.svg";
import profileImg from "../../../Assets/Images/profile/profile.png";
import backgroundImg from "../../../Assets/Images/profile/background.png";
import { Link } from "react-router-dom";
import { QRCodeModal } from "../../../Components/QRmodal";

function ProfileHeader({props}) {
  const {user}=props
  const profileUrl = `https://taskool.com/profile/${user?.id}`;
  const[qrStatus,setQrStatus]=useState(false)
  return (
    <ProfileHeaderStyle>
      <div className="profile-background">
        <div className="edit-icon">
          <img src={editIcon} alt="" />
        </div>
      </div>

      <div className="profile-title">
        <div className="profile-title-left">
          <div className="profile-picture">
            <img src={user.profile_img} alt="profile-img" />
          </div>

          <div className="user-info">
            <div>
              <div className="name-surname">{user?.first_name} {user?.last_name}</div>

              <div className="user-tag">{user.email}n</div>
            </div>

            <div className="user-profession user-info-item">
              <img src={tagIcon} alt="" />   {user?.speciality?.content || `${"speciality not chosen"}`}
            </div>

            <div className="user-address user-info-item">
              <img src={locationIcon} alt="" /> Baku City, Azerbaijan
            </div>

            <div className="follower-follows">
              <div className="followers">0 Followers</div>

              <div className="follows">0 Followers</div>
            </div>
          </div>
        </div>
        
        <div className="profile-title-right">
          <div className="edit-profile">
            <Link to="/settings"><img src={editIcon} alt="" /></Link>
          </div>

          <div onClick={()=>setQrStatus(!qrStatus)} className="more-btn">
            More <img src={moreIcon} alt="" />
          </div>
         <QRCodeModal url={profileUrl} button={false}  status={qrStatus} setStatus={setQrStatus}/>
         
          
        </div>
      </div>
    </ProfileHeaderStyle>
  );
}

export default ProfileHeader;

const ProfileHeaderStyle = styled.div`
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 24px;
  .profile-background {
    height: 184px;
    background-image: url(${backgroundImg});
    position: relative;
    .edit-icon {
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      position: absolute;
      top: 24px;
      right: 24px;
      border-radius: 8px;
    }
  }

  .profile-title {
    display: flex;
    justify-content: space-between;

    .profile-title-left {
      display: flex;
      .profile-picture {
        z-index: 2;
       padding-left: 24px;
       padding-right: 24px;
        margin-top: -24px;
        img{
          border:4px solid #fff;
          border-radius: 50%;
          width: 100px;
          height: 100px;
          object-fit: cover;
        }
      }

      .user-info {
        margin-top: 24px;

        div {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          .name-surname {
            color: #121926;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px; /* 150% */

            &:after {
              content: "";
              display: inline-block;
              margin-left: 16px;
              width: 1px;
              height: 12px;
              background-color: #cdd5df;
            }
          }

          .user-tag {
            padding-left: 16px;
            color: #364152;
            font-family: "Inter";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
          }
        }

        .user-info-item {
          color: #697586;
          font-family: "Inter";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          margin-bottom: 10px;

          img {
            margin-right: 8px;
          }
        }

        .follower-follows {
          margin-top: 41px;
          color: #697586;
          font-family: "Euclid";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */

          .followers {
            &:after {
              content: "";
              display: inline-block;
              height: 12px;
              width: 1px;
              background: #cdd5df;
              margin: 0px 16px;
            }
          }
        }
      }
    }

    .profile-title-right {
      margin-top: 24px;
      padding-right: 24px;
      .edit-profile {
        text-align: right;
        margin-bottom: 70px;
        transition: 0.3s;
        padding: 5px;
        border-radius: 8px;
        /* :hover{
          
          background: #EEF2F6;
        } */
      }

      .more-btn {
        margin-top: 82px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #202939;
        font-family: "Euclid";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 175% */
        background-color: border-radius: 8px;;
        border-radius: 8px;
        padding: 5px;
        transition: 0.3s;
        &:hover{
         background: #EEF2F6;
        }
        img {
          margin-left: 8px;
        }
      }
    }
  }
`;
