import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const Experience = ({ props }) => {
  const { experienceData, isTaskool } = props;

  const { t } = useTranslation();

  const location = useLocation();

  return (
    <ExperienceStyle>
      <div className="wrapperExperience">
        <div className="experience">
          <div className="title">
            <h2>{t("experience")}</h2>
          </div>

          <form onSubmit={(e) => e.preventDefault()}>
            {experienceData?.map((experience, index) => {
              return (
                <div key={index} id={experience?.id} className="insideWrapper">
                  <div className="box">
                    <div className="info">
                      <h3>{experience?.job_position}</h3>
                      <p>
                        {experience?.company}, {experience?.job_status}
                      </p>
                      <ins>
                        {experience?.is_present === true
                          ? experience?.start_date +
                            ` - ${t("experience-present")}`
                          : experience?.start_date +
                            " - " +
                            experience?.end_date}
                      </ins>
                    </div>
                  </div>
                  <hr />
                </div>
              );
            })}
          </form>
        </div>
      </div>
    </ExperienceStyle>
  );
};

const ExperienceStyle = styled.div`
  display: flex;
  padding-top: 2rem;
  button {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: clamp(1rem, 2vw, 1.05rem);
  }
  .wrapperExperience {
    width: 92%;
    margin: 0rem auto 0 auto;
    h2,
    h3 {
      font-weight: 500;
    }
    .experience {
      margin-bottom: 2rem;
      .insideWrapper {
        margin-top: 1rem;
        hr {
          border: none;
          border-bottom: 1px solid #ddd;
          margin: 1rem;
        }
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addItem {
          background: none;
          font-size: 17px;
          color: #2f80ed;
          font-weight: 400;
          border: none;
          cursor: pointer;
        }
        svg {
          color: #000;
        }
      }
      .box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 1rem;
        margin-top: 1rem;
        .manageEntry {
          display: flex;
          margin-right: 2rem;
          button {
            display: flex;
            border: none;
            font-weight: 500;
          }
        }
        .info {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          h3 {
            margin: 0;
            font-size: 17px;
          }
          p {
            margin: 0.5rem 0;
          }
          ins {
            margin: 0;
            text-decoration: none;
            font-size: 14px;
            color: #666;
          }
        }
      }
      .editing {
        padding: 1rem 1.25rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        align-items: center;
        .buttons {
          .groupOne {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;
          }
          button {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 1rem 0 0 0;
    .wrapperExperience {
      width: 100%;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      padding: 0.5rem 0rem 0.5rem 0rem;
      margin: -1rem auto 0 auto;
      .experience {
        .title {
          width: 92%;
          margin: 0 auto;
        }
        .box {
          .manageEntry {
            display: flex;
            margin-right: 0.5rem;
          }
        }
        .editing {
          margin: 0 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 335px) {
    .wrapperExperience {
      .experience {
        .box {
          display: block;
          .manageEntry {
          }
        }
      }
    }
  }
`;
