import React from "react";
import { AppContextProvider } from "./Helpers/Context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SuspenseSpinner } from "./Components/Loaders/SuspenseSpinner";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { StudyAbroadProvider } from "./Helpers/Contexts/StudyAbroadContext";
import { LoadingProvider } from "./Helpers/Contexts/LoadingContext";
import LottieLoading from "./Components/LottieLoading";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import AppContent from "./Routes/AppContent"; // Import the AppContent component
import { HelmetProvider } from "react-helmet-async";
import { LanguageProvider } from "./Helpers/Contexts/LanguageContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

function App() {
  return (
    <HelmetProvider>
      <MantineProvider
        theme={{
          colorScheme: "light",
          fontFamily: "Euclid, sans-serif",
          colors: { ts: ["#ffffff", "#ffe01b"], white: ["#ffffff"] },
        }}
      >
        <AppContextProvider>
          <LanguageProvider>
          <StudyAbroadProvider>
            <ReactQueryDevtools />
            <SuspenseSpinner>
              <NotificationsProvider position="top-center">
                <BrowserRouter>
                  <ScrollToTop />
                  <AppContent /> {/* Directly render AppContent */}
                </BrowserRouter>
              </NotificationsProvider>
            </SuspenseSpinner>
          </StudyAbroadProvider>
          </LanguageProvider>
        </AppContextProvider>
      </MantineProvider>
    </HelmetProvider>
  );
}

export default App;
