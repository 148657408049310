import React from "react";
import styled from "styled-components";
import { Card, Overlay, Button, Text } from "@mantine/core";
import { Link } from "react-router-dom";

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 320px;
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin: 20px auto;
  }
`;

const OverlayWrapper = styled(Overlay)`
  background-color: transparent;
  background-image: linear-gradient(105deg, #000 20%, #312f2f 50%, #ccc 100%);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  z-index: 1;
`;

const ActionButton = styled(Button)`
  margin-top: 20px;
`;

const HighlightContainer = styled.span`
  position: relative;
  display: inline-block;
`;

const Highlight = styled.span`
  position: absolute;
  content: " ";
  display: block;
  height: 90%;
  width: 100%;
  margin-left: -3px;
  margin-right: -3px;
  background: #fde14c;
  transform: rotate(-2deg);
  top: -1px;
  left: -1px;
  border-radius: 20% 25% 20% 24%;
  padding: 10px 3px 3px 10px;
`;

const Title = styled(Text)`
  color: #000;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  text-align: center;
  z-index: 2;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 160px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid #fff;
  border-radius: 8px;
`;

export function CoursePreviewMobile({ backgroundImage, title }) {
  return (
    <StyledCard radius="md">
      <OverlayWrapper opacity={0.55} zIndex={0} />

      <ImageContainer>
        <Image src={backgroundImage} alt="Background" />
      </ImageContainer>

      <Content>
        <HighlightContainer>
          <Highlight />
          <Title size="xl">{title}</Title>
        </HighlightContainer>

        <ActionButton
          component={Link}
          to={`/courses/58`}
          variant="gradient"
          color="dark"
          size="md"
          sty
          fullWidth
        >
          View course
        </ActionButton>
      </Content>
    </StyledCard>
  );
}
