import styled from "styled-components";

export const Partition = ({ children, color }) => {
  return (
    <PartitionStyle color={color}>
      <div className="wrapperPartition">
        {children}
      </div>
    </PartitionStyle>
  )
}

const PartitionStyle = styled.div`
  background: ${props => props.color ? props.color : "#fff"};
  border-radius: .75rem;
  border: 1px solid #DEE2E6;
  h2{
    font-weight: 500;
  }
  hr{
    border: none;
    border-bottom: 1px solid #eee;
  }
  .wrapperPartition{
    margin: 0 auto;
    padding: 1rem 1.5rem 1rem 1.5rem;
    .box{
      margin: 0;
    }
    .headerX{

    }
    .bodyX{
      width: 95%;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 768px) {
    border-radius: 0;
    .wrapperPartition{
      width: 92%;
      margin: 0 auto;
      padding: 0 0 1rem 0;
      .bodyX{
        width: 100%;
        margin: 0 auto;
      }
      .title{
        button{
          padding: 0;
          margin-right: 0.5rem;
        }
      }
      .insideWrapper{
        margin-top: 1rem;
        hr{
          border: none;
          border-bottom: 1px solid #ddd;
          margin: 1rem 0;
        }
      }
      .box{
        margin: 0;
        .manageEntry{
          grid-gap: 1.5rem;
          display: flex;
          margin-right: 0.5rem;
          button{
            padding: 0;
          }
        }
      }
      .editing{
        margin: 0;
      }
    }
  }
  @media screen and (max-width: 335px) {
    .wrapperPartition{
      .box{
        display: block;
        .manageEntry{
        }
      }
    }
  }
`