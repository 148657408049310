import {
  Button,
  FileInput,
  Image,
  Modal,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useContext, useState } from "react";
import { AppContext } from "../../Helpers/Context";
import { Edit, Upload } from "tabler-icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";

export const EditCommunity = ({ props }) => {
  let { user } = useContext(AppContext);
  let { id } = useParams();

  const {
    community,
    editCommunityModalOpen,
    setEditCommunityModalOpen,
    changeEdit,
  } = props;

  const { image, name, description, cover_image, members, group } =
    community[0];
  //   console.log(image, name, description, cover_image, members, group);

  const queryClient = useQueryClient();

  let [comName, setComName] = useState(name || "");
  let [comDesc, setComDesc] = useState(description || "");
  let [comCate, setComCate] = useState(group.id || "");
  let [comImage, setComImage] = useState(image || "");

  const { data: groups } = useQuery(["community groups"], () =>
    axios.get(`/blog/community_groups/`).then((res) => res.data)
  );

  const submitEdit = async (e) => {
    e.preventDefault();

    let formX = new FormData();
    formX.append("user", user?.id);
    formX.append("name", comName);
    formX.append("group", 1);
    comDesc && formX.append("description", comDesc);
    if (comImage && comImage?.name) {
      formX.append("image", comImage);
    }

    // Ask Dilara to remove this entry
    formX.append("members", [user?.id]);

    const responseUpdate = await axios.patch(`/blog/community/${id}/`, formX);

    setEditCommunityModalOpen(false);
  };

  //   const { mutate, isLoading } = useMutation(
  //     ["create community"],
  //     async () => {
  //       if (comName.length > 0 && comCate !== null) {
  //         const responseUpdate = await axios.patch(
  //           `/blog/community/${id}/`,
  //           formX
  //         );
  //         console.log(responseUpdate);
  //       }
  //     },
  //     {
  //       onSuccess: () => {
  //         setEditCommunityModalOpen(false);
  //         queryClient.invalidateQueries([`community ${id}`]);
  //       },
  //     }
  //   );

  return (
    <Modal
      overlayBlur={3}
      size={430}
      radius={"lg"}
      key={id}
      title="Edit group"
      opened={editCommunityModalOpen}
      onClose={() => setEditCommunityModalOpen(false)}
    >
      <form onSubmit={submitEdit}>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={comImage}
              maw={240}
              withPlaceholder
              height={220}
              styles={{ root: { border: "5rem" } }}
              style={{ border: "1px solid #ddd" }}
            />

            <FileInput
              value={comImage}
              onChange={setComImage}
              style={{ width: "100.5%" }}
              clearable
              clearButtonLabel="Remove"
              styles={{
                input: {
                  borderTop: "0",
                  borderTopLeftRadius: "0",
                  borderTopRightRadius: "0",
                },
              }}
              placeholder="Upload a profile image"
              icon={<Upload color="#555" />}
              iconWidth={45}
              radius={"md"}
            />
          </div>

          <TextInput
            value={comName}
            onChange={(e) => setComName(e.currentTarget.value)}
            label="Group name"
            placeholder="A name suitable for your group"
            radius={"md"}
          />

          {/* <Select
            onChange={(e) => {
              setComCate(e);
            }}
            label="Select a category"
            placeholder="Pick one"
            style={{ width: "100%" }}
            radius={"md"}
            value={comCate}
            data={
              groups?.length > 0
                ? groups.map((e) => ({ value: e?.id, label: e?.name }))
                : []
            }
          /> */}

          <Textarea
            value={comDesc}
            onChange={(e) => setComDesc(e.target.value)}
            label={`Group description (${comDesc?.length}/500)`}
            placeholder="Describe your group"
            minRows={4}
            radius={"md"}
            style={{ width: "100%" }}
          />

          <Button
            //   loading={isLoading}
            //   onClick={() => mutate()}
            radius={"md"}
            fullWidth
            color="gray.8"
            type="submit"
            leftIcon={<Edit />}
          >
            Edit
          </Button>
          {/* <Button size="xs" loading={isLoading} onClick={() => mutate()} disabled={comName.length < 1 || comCate === null} radius={"md"} fullWidth color="red">Delete community</Button> */}
        </div>
      </form>
    </Modal>
  );
};
