import styled from "styled-components"

export const NavbarStyle = styled.div`
  .desktop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 0;
    margin: 0 auto;
    width: 90%;
  }
  .mobile{
    display: flex;
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 auto;
    width: 90%;
    a{
      img{
        width: 140px;
      }
    }
    button{
      border: none;
    }
  }
  @media screen and (max-width: 1250px) {
    .desktop{
      width: 90%;
    }
  }
  @media screen and (max-width: 768px) {
    .mobile{
      display: flex;
    }
    .desktop{
      display: none;
    }
  }
`;